import * as merge from 'merge';
import {
  isPresent, flatten, isNumber, getMinMax, roundUp, roundDown, formatMonthPickerLabel,
  absoluteDecimal, sortDate, array, unique, sortNumbers, getScale, getEnumKeys,
  getEnumStrings, previousMonth, scaleArray, getTraceValues,
} from 'src/app/utils';
import {
  Axis, Forecast, MagicNumber, ActualValuesToShow, MonthsBetweenMonthSeparation,
  XaxisActualTracePadding, ActualValuesToShowInAdjustmentView, ZeroPosition,
  YaxisRangeTickPrecision, LineWidth, Dash, Solid,
} from 'src/app/plotting/constants';
import * as Traces from 'src/app/plotting/traces';
import * as Shapes from 'src/app/plotting/shapes';
import { Color } from 'src/styles/color';
import {
  getDelta, formatDate, formatKpi, createLargerNumberRange,
  notNullValueDates, createDateRange, getFirstOrLastPointOfTrace,
} from 'src/app/plotting/data';

import { KpiValues, FormattedKpiValues } from 'src/app/models/common/kpiValues';
import { Scale } from 'src/app/constants';
import { Lines } from 'src/app/plotting/shapes/line';
import { Trace, Line, Plot, Point } from 'src/app/plotting/interfaces';
import { BarRounding } from 'src/app/plotting/traces';

const createMonthSeparation = (x0: Date, x1: Date) =>
  merge.recursive(Shapes.Rects.Vertical(x0.getTime(), x1.getTime()), {
    layer: 'below',
    // opacity: 1,
    fillcolor: Color.QuarterInGraphs,
  });

const createYAxisGridLine = (y) =>
  merge.recursive(Shapes.Lines.Horizontal(y), {
    layer: 'below',
    line: {
      width: 1,
      dash: '2px',
      color: Color.Line,
    }
  });

export function createThreeTickYaxis(range: number[], scale: Scale = getScale(range), precision: number = YaxisRangeTickPrecision, rangeScale: number = 1.5): any {
  const tag: string = `createThreeTickYaxis()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'range:', range);
  const tickvals: number[] = [range[0], 0, range[1]];
  if (debug) console.log(tag, 'tickvals:', tickvals);

  return {
    range: range.map(v => v * rangeScale),
    tickmode: 'array',
    tickvals,
    ticktext: tickvals.map(v => v === 0 ? 0 : absoluteDecimal(v, scale, precision)),
  };
}

export function createSubPlotTicks(range: number[], zeroPosition: ZeroPosition = ZeroPosition.Middle, scale: Scale = getScale(range), precision: number = YaxisRangeTickPrecision): any {
  const tag: string = `createSubPlotTicks`;
  const debug: boolean = false;

  if (debug) console.log(tag, 'range:', range);
  if (debug) console.log(tag, 'zeroPosition:', ZeroPosition[zeroPosition]);

  let tickvals: number[];
  if (zeroPosition === ZeroPosition.Top) {
    tickvals = [
      range[0],
      range[0] * 0.66,
      range[0] * 0.33,
      0
    ];
  } else if (zeroPosition === ZeroPosition.Bottom) {
    tickvals = [
      0,
      range[1] * 0.33,
      range[1] * 0.66,
      range[1]
    ];
  } else {
    // 4 ticks
    // tickvals = [
    //   range[0],
    //   range[0] * 0.33,
    //   range[1] * 0.33,
    //   range[1]
    // ];
    // 5 ticks
    tickvals = [
      range[0],
      range[0] * 0.5,
      0,
      range[1] * 0.5,
      range[1]
    ];
  }

  if (debug) console.log(tag, 'tickvals:', tickvals);
  range = [tickvals[0], tickvals[tickvals.length - 1]];
  if (debug) console.log(tag, 'range:', range);
  const ticktext: string[] = tickvals.map(v => v === 0 ? '0' : absoluteDecimal(v, scale, precision));
  if (debug) console.log(tag, 'ticktext:', ticktext);

  return {
    range,
    tickmode: 'array',
    tickvals,
    ticktext,
  };
}

export function createMainPlotTicks(range: number[], zeroPosition: ZeroPosition = ZeroPosition.Middle,
  scale: Scale = getScale(range), precision: number = YaxisRangeTickPrecision): any {
  const tag: string = `createMainPlotTicks`;
  const debug: boolean = false;

  if (debug) console.log(tag, 'old range:', range);
  if (debug) console.log(tag, 'zeroPosition:', ZeroPosition[zeroPosition]);

  const max: number = range[1];

  const tickCount: number = 10;
  const halfTickCount: number = Math.round(tickCount / 2);

  const tick: number = 0.1;
  const halfTick: number = tick / 2;

  let tickvals: number[];
  if (zeroPosition === ZeroPosition.Top) tickvals = array(tickCount).map((_, i) => -max * i * tick).reverse();
  else if (zeroPosition === ZeroPosition.Bottom) tickvals = array(tickCount).map((_, i) => max * i * tick);
  else {
    const step: number = max / halfTickCount;
    tickvals = [
      -max,
      ...array(halfTickCount).map((_, i) => i * -step).reverse(),
      ...array(halfTickCount).map((_, i) => i * step),
      max
    ];
  }
  if (debug) console.log(tag, 'tickvals:', tickvals);
  range = [tickvals[0], tickvals[tickvals.length - 1]];
  if (debug) console.log(tag, 'new range:', range);
  const ticktext: string[] = tickvals.map(v => v === 0 ? '0' : absoluteDecimal(v, scale, precision));
  if (debug) console.log(tag, 'ticktext:', ticktext);

  return {
    range,
    tickmode: 'array',
    tickvals,
    ticktext,
  };
}


export function createOverviewScatterYAxisRange(traces: any[], actualIndex: number, equal: boolean = false, actualValuesToShow: number = ActualValuesToShow): number[] {
  const tag: string = `createOverviewScatterYAxisRange()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'traces:', traces);
  if (debug) console.log(tag, 'actualIndex:', actualIndex);
  if (debug) console.log(tag, 'equal:', equal);
  if (debug) console.log(tag, 'actualValuesToShow:', actualValuesToShow);
  if (debug) console.log(tag, 'traces[actualIndex]:', traces[actualIndex]);

  const actualValues: number[] = getTraceValues([traces[actualIndex]]);
  if (debug) console.log(tag, 'actualValues:', actualValues);
  const actualCount: number = actualValues.length < actualValuesToShow ?
    actualValues.length : actualValuesToShow;
  if (debug) console.log(tag, 'actualCount:', actualCount);
  const visibleActualValues: number[] = actualValues.slice(actualValues.length - actualCount);
  if (debug) console.log(tag, 'visibleActualValues:', visibleActualValues);
  const notActualTraces: any[] = [
    ...traces.slice(0, actualIndex),
    ...traces.slice(actualIndex + 1)
  ];

  const notActualValues: any[] = getTraceValues(notActualTraces);
  if (debug) console.log(tag, 'notActualValues:', notActualValues);
  const values: any[] = [...visibleActualValues, ...notActualValues];
  if (debug) console.log(tag, 'values:', values);
  const yaxisRange: number[] = createLargerNumberRange(values, equal);
  if (debug) console.log(tag, 'yaxisRange:', yaxisRange);
  return yaxisRange;
}

export function createCompareScatterTrace({ kpi, name, color, markers = true }: {
  kpi: FormattedKpiValues, name: string, color: string, markers?: boolean
}): Trace {
  const tag: string = `createCompareScatterTrace()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpi:', kpi);
  if (debug) console.log(tag, 'name:', name);
  if (debug) console.log(tag, 'color:', color);
  if (debug) console.log(tag, 'markers:', markers);

  const machine: Trace = kpi[Forecast.Machine];
  const organization: Trace = kpi[Forecast.Organization];
  const adjustment: Trace = kpi[Forecast.Adjustment];

  if (debug) console.log(tag, 'machine:', machine);
  if (debug) console.log(tag, 'organization:', organization);
  if (debug) console.log(tag, 'adjustment:', adjustment);

  const isAdjusted: boolean[] = adjustment[Axis.Y].map(v => isNumber(v));
  if (debug) console.log(tag, 'isAdjusted:', isAdjusted);
  const trace: Trace = merge.recursive(Traces.Bar.Dfs({
    [Axis.X]: machine[Axis.X].slice(),
    [Axis.Y]: machine[Axis.Y].map((value, i) => isAdjusted[i] ? adjustment[Axis.Y][i] : organization[Axis.Y][i]),
    name,
    line: {
      color,
    },
    marker: {
      color,
    },
  }), markers ? Traces.Scatter.LinesMarkers : Traces.Scatter.Lines);

  return trace;
}

export function createDeltaCountryFcToModelFc({ kpi, tooltipPosition, scale }: {
  kpi: FormattedKpiValues, tooltipPosition?: Date, scale?: Scale
}): Plot {
  const tag: string = `createDeltaCountryFcToModelFc()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpi:', kpi);

  const actual: Trace = kpi[Forecast.Actual];
  if (debug) console.log(tag, 'actual:', actual);
  const adjustment: Trace = kpi[Forecast.Adjustment];
  if (debug) console.log(tag, 'adjustment:', adjustment);
  const organization: Trace = kpi[Forecast.Organization];
  if (debug) console.log(tag, 'organization:', organization);
  const machine: Trace = kpi[Forecast.Machine];
  if (debug) console.log(tag, 'machine:', machine);

  const isAdjusted: boolean[] = adjustment[Axis.Y].map(v => isNumber(v));
  if (debug) console.log(tag, 'isAdjusted:', isAdjusted);
  const deltaBars: Trace = Traces.Bar.Organization({
    [Axis.X]: machine[Axis.X].slice(),
    [Axis.Y]: machine[Axis.Y].map((value, i) => isAdjusted[i] ? adjustment[Axis.Y][i] - value :
      isNumber(organization[Axis.Y][i]) ? organization[Axis.Y][i] - value : null),
    marker: {
      color: isAdjusted.map(isAdjusted => isAdjusted ? Color.OrganizationAdjusted : Color.Organization),
      line: {
        color: array(machine[Axis.X].length, Color.Organization),
        dash: isAdjusted.map(isAdjusted => isAdjusted ? Dash : Solid),
        width: array(machine[Axis.X].length, LineWidth)
      },
      cornerroundness: {
        topleft: BarRounding,
        topright: BarRounding,
      }
    }
  });
  if (debug) console.log(tag, 'deltaBars:', deltaBars);

  let values: number[] = deltaBars[Axis.Y];
  if (debug) console.log(tag, 'values:', values);
  if (!scale) {
    scale = getScale(values);
    if (debug) console.log(tag, 'scale:', scale);
  }
  deltaBars[Axis.Y] = scaleArray(values, scale);
  values = deltaBars[Axis.Y];
  if (debug) console.log(tag, 'values:', values);

  // const deltaMarkers: any = merge.recursive({
  //   [date]: deltaBars[date].slice(),
  //   [value]: array(deltaBars[date].length),
  // }, Traces.Scatter.Markers, Traces.Scatter.Delta, Traces.Scatter.NoHover);
  // if (debug) console.log(tag, 'deltaMarkers:', deltaMarkers);

  const data: Trace[] = [deltaBars];

  const lastActual: Point = getFirstOrLastPointOfTrace(actual);
  if (debug) console.log(tag, 'lastActual:', lastActual);

  const xaxisRange: number[] = createDateRange(lastActual[Axis.X]);
  if (debug) console.log(tag, 'xaxisRange:', xaxisRange);

  // Unable to set range w/o organization value; with added scenarios have to reset the range.
  const yaxisRange: number[] = createLargerNumberRange(values, true);
  if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

  const deltaLine: Line = Shapes.Lines.HorizontalGridLine(0, {
    line: {
      color: Color.Machine
    },
  });
  const actualLine: Line = lastActual[Axis.X] ? Shapes.Lines.ActualLine(lastActual[Axis.X]) : null;
  const tooltipLine: Line = tooltipPosition ? Shapes.Lines.TooltipLine(tooltipPosition) : null;
  const shapes: any[] = [deltaLine, actualLine, tooltipLine].filter(isPresent);
  if (debug) console.log(tag, 'shapes:', shapes);

  return {
    scale,
    data,
    layout: {
      shapes,
      xaxis: {
        range: xaxisRange,
      },
      yaxis: {
        range: yaxisRange,
      }
    }
  } as any;
}

export function createCompareDeltaTrace({ kpi, name, color, }: {
  kpi: FormattedKpiValues, name: string, color: string,
}): Trace {
  const tag: string = `createCompareDeltaTrace()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpi:', kpi);
  if (debug) console.log(tag, 'name:', name);
  if (debug) console.log(tag, 'color:', color);

  const machine: Trace = kpi[Forecast.Machine];
  const organization: Trace = kpi[Forecast.Organization];

  if (debug) console.log(tag, 'machine:', machine);
  if (debug) console.log(tag, 'organization:', organization);

  const deltaBars: Trace = Traces.Bar.Dfs({
    [Axis.X]: machine[Axis.X].slice(),
    [Axis.Y]: machine[Axis.Y].map((value, i) => isNumber(organization[Axis.Y][i]) ? organization[Axis.Y][i] - value : null),
    name,
    marker: {
      color,
      line: {
        color: array(machine[Axis.X].length, color),
        dash: array(machine[Axis.X].length, Solid),
        width: array(machine[Axis.X].length, LineWidth)
      }
    }
  });

  return deltaBars;
}
