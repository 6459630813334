import { Component, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { PlotService } from 'src/app/services/plot.service';

@Component({
  selector: 'valueLabels',
  templateUrl: './valueLabels.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ValueLabelsComponent extends BaseComponent {
  private static readonly Tag: string = 'ValueLabelsComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    public readonly plot: PlotService,
  ) {
    super(cd, state);
    this.tag = ValueLabelsComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
  }
}
