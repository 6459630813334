export const Size = {
  GridSpan: {
    1: 6.66666,
    2: 13.33333,
    3: 19.99999,
    4: 26.66666,
    5: 33.33333,
    6: 39.99999,
    7: 46.66666,
    8: 53.33333,
    9: 59.99999,
    10: 66.66666,
    11: 73.33333,
    12: 79.99999,
    13: 86.66666,
    14: 93.33333,
    15: 100,
  },
  Font: {
    Plot: {
      Smaller: 12,
      Normal: 14,
      Larger: 16.38,
      Large: 18,
    }
  }
};

