import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { Page } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LogoutComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'LogoutComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly auth: AuthService,
    private readonly user: UserSettingsService,
    private readonly configuration: ConfigurationService
  ) {
    super(cd, state);
    this.tag = LogoutComponent.Tag;
  }

  public onLogoutTap(): void {
    const tag: string = `${this.tag}.onLogoutTap()`;
    this.user.authorized = false;
    this.auth.revoke().subscribe(() => {
      this.state.page = Page.Login;
    });
    window.location.href = `${this.configuration.backendBaseUrl}/api/oauth2/logout`;
  }
}
