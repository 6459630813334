import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UnsavedAdjustmentsText } from 'src/app/constants';
import { ConfigurationService } from 'src/app/services/configuration.service';
import {
  ConfirmationLayerComponent
} from 'src/app/components/common/elements/confirmationLayer/confirmationLayer.component';

@Injectable()
export class ModalService {
  private static readonly Tag: string = 'ModalService';
  private readonly tag: string = ModalService.Tag;
  private readonly debug: boolean = ConfigurationService.Debug && false;

  private static readonly ErrorMessages = {
    400: 'There was a problem with the sent data to the backend (400)',
    404: 'There was a problem retrieving the data from the backend (404)',
    500: 'There was a problem retrieving the data from the backend (500)',
  };

  // Set through confirmationLayer.component.
  public confirmationLayer: ConfirmationLayerComponent;

  // Set through outside services and components.
  public title: string;
  public text: string;
  public cancelBtnLabel: string;
  public continueBtnLabel: string;

  public action: Function;
  public cancel: Function;

  public open(): void {
    const tag: string = `${this.tag}.open()`;
    if (this.debug) console.log(tag);
    this.confirmationLayer && this.confirmationLayer.open();
  }

  public close(): void {
    const tag: string = `${this.tag}.close()`;
    if (this.debug) console.log(tag);
    this.confirmationLayer && this.confirmationLayer.close();
  }

  private onContinueTap(): void {
    const tag: string = `${this.tag}.onContinueTap()`;
    if (this.debug) console.log(tag);
    this.close();
    this.action && this.action();
  }

  private onCancelTap(): void {
    const tag: string = `${this.tag}.onCancelTap()`;
    if (this.debug) console.log(tag);
    this.close();
    this.cancel && this.cancel();
  }

  public async unsavedAdjustments(action: Function = () => null, cancel: Function = () => null): Promise<boolean> {
    const tag: string = `${this.tag}.unsavedAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    let resolve;
    const promise: Promise<boolean> = new Promise(r => resolve = r);

    this.setModal({
      title: 'Unsaved adjustments',
      text: UnsavedAdjustmentsText,
      cancelBtnLabel: 'Cancel',
      continueBtnLabel: 'Continue',
      action: () => { resolve(true); setTimeout(() => action()); },
      cancel: () => { cancel(); resolve(false); },
    });

    this.open();
    return promise;
  }

  public setModal({ title = '', text = '',
    cancelBtnLabel = '', continueBtnLabel = '',
    action = null, cancel = null }: {
      title?: string, text?: string,
      cancelBtnLabel?: string, continueBtnLabel?: string,
      action?: Function, cancel?: Function
    }): void {
    const tag: string = `${this.tag}.noAccess()`;
    const debug: boolean = this.debug || false;
    this.title = title;
    this.text = text;
    this.cancelBtnLabel = cancelBtnLabel;
    this.continueBtnLabel = continueBtnLabel;
    this.action = action;
    this.cancel = cancel;
  }

  public async noAccess(): Promise<boolean> {
    const tag: string = `${this.tag}.noAccess()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    let resolve;
    const promise: Promise<boolean> = new Promise(res => resolve = res);

    this.setModal({
      title: 'No Access',
      text: 'You do not have read or write access.',
      continueBtnLabel: 'Ok',
      action: () => { resolve(true); },
    });

    this.open();
    return promise;
  }

  public async submissionLocked(action: Function = () => null): Promise<boolean> {
    const tag: string = `${this.tag}.submissionLocked()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    let resolve;
    const promise: Promise<boolean> = new Promise(res => resolve = res);

    this.setModal({
      title: 'Submission window closed',
      text: `The deadline was reached. Adjustments are only possible until workday 10.
      If you have an urgent request please contact myforecast_support@daimler.com`,
      continueBtnLabel: 'Ok',
      action: () => { action(); resolve(true); },
    });

    this.open();
    return promise;
  }

  public async notifyError(err: HttpErrorResponse): Promise<boolean> {
    const tag: string = `${this.tag}.notifyError()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'err:', err);

    let resolve;
    const promise: Promise<boolean> = new Promise(res => resolve = res);

    this.setModal({
      title: ModalService.ErrorMessages[err.status] || 'A request error has occurred',
      text: err.statusText,
      continueBtnLabel: 'Ok',
      action: () => { resolve(true); },
    });

    this.open();
    return promise;
  }

  public async retryRequest(err: HttpErrorResponse, action: Function): Promise<boolean> {
    const tag: string = `${this.tag}.retryRequest()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'err:', err);

    let resolve;
    const promise: Promise<boolean> = new Promise(res => resolve = res);

    this.setModal({
      title: ModalService.ErrorMessages[err.status] || 'A request error has occurred',
      text: err.statusText,
      cancelBtnLabel: 'Ok',
      continueBtnLabel: 'Retry',
      action: async () => { action(); resolve(true); },
    });

    this.open();
    return promise;
  }
}
