import { Country } from './country';

export class LastModifiedContainer {
  lastModified: LastModified[];

  constructor(lastModified: LastModified[] = []) {
    this.lastModified = lastModified;
  }
}

export class LastModified {
  public id: number;
  public countryId: number;
  public country: Country;
  public modified: string;
  public username: string;

  constructor({ id, countryId, country, modified, username }: {
    id?: number, countryId?: number, country?: Country, modified?: string, username?: string
  }) {
    this.id = id;
    this.countryId = countryId;
    this.country = country;
    this.modified = modified;
    this.username = username;
  }
}
