import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { TopLevelKpi } from 'src/app/constants';

@Component({
  selector: 'workbenchFyForecast',
  templateUrl: './workbenchFyForecast.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WorkbenchFyForecastComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'WorkbenchFyForecastComponent';

  public portfolio: boolean = true;
  public ebit: boolean = true;
  public salesAndAcquisitions: boolean = true;

  constructor(
    private readonly workbench: WorkbenchDataService,
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService
  ) {
    super(cd, state);
    this.tag = WorkbenchFyForecastComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.topLevelKpi$.subscribe(this.onTopLevelKpiChange.bind(this)),
    ];
  }

  public onTopLevelKpiChange(topLevelKpi: TopLevelKpi): void {
    const tag: string = `${this.tag}.onTopLevelKpiChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'topLevelKpi:', topLevelKpi);
    this.portfolio = topLevelKpi === TopLevelKpi.Portfolio;
    this.ebit = topLevelKpi === TopLevelKpi.Ebit;
    this.salesAndAcquisitions = topLevelKpi === TopLevelKpi.SalesAndAcquisitions;
  }

  public onTopLevelKpiSelect(topLevelKpi: TopLevelKpi): void {
    const tag: string = `${this.tag}.onTopLevelKpiSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'topLevelKpi:', topLevelKpi);
    this.workbench.setTopLevelKpi(topLevelKpi);
  }

  public readonly TopLevelKpi = TopLevelKpi;
}
