import {
  Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { OrganizationEntity, StateService } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { Country } from 'src/app/models/common/country';
import { CurrencySplitContainer, ICurrencySplit, IKpiForecast } from 'src/app/models/common/currencySplit';
import { Adjustment, AdjustmentTab } from 'src/app/constants';
import { removeCommaSeparatorNum, removeExtraSigns, isNumber, createDate, dateToMonthPickerStr, sortKpiForecast, localeMonthFull, monthPickerStrToDate, incrementMonth } from 'src/app/utils';
import { browser } from 'src/app/app.component';

@Component({
  selector: 'currencySplit',
  templateUrl: 'currencySplit.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class CurrencySplitComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'CurrencySplitComponent';
  protected readonly tag: string = CurrencySplitComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  public firstCurrency: string;
  public secondCurrency: string;
  public values: ICurrencySplit[] = [];
  public adjustments: Adjustment[] = [];
  public firstMonth: string;
  public secondMonth: string;
  public thirdMonth: string;
  public disableWrite: boolean;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    if (this.debug) console.log(tag);

    this.subscriptions = [
      this.workbench.currencySplit$.subscribe(this.onCurrencySplitChange.bind(this)),
      this.state.country$.subscribe(this.onCountryChange.bind(this))
    ];
  }

  public onAdjustmentClick(firstFc: IKpiForecast, secondFc: IKpiForecast, value: number): void {
    const tag: string = `${this.tag}.onAdjustmentClick()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, 'firstFc:', firstFc);
    if (debug) console.log(tag, 'secondFc:', secondFc);
    if (debug) console.log(tag, 'value:', value);
  }

  public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
    const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, 'value:', value);

    event = event || window.event;
    const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
    const char: string = String.fromCharCode(charCode);
    if (debug) console.log(tag, 'char:', char);
    const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
    if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

    if (!isNumber(char) && !isValidSymbol) return false;
    return true;
  }

  public onAdjustmentKeyUp(firstFc: IKpiForecast, secondFc: IKpiForecast, value: string): void {
    const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, 'firstFc:', firstFc);
    if (debug) console.log(tag, 'secondFc:', secondFc);
    if (debug) console.log(tag, 'value:', value);

    if (!this.state.unsavedAdjustments) {
        const machine: string = firstFc.machineForecast === null ? '' : firstFc.machineForecast.toString();
        const isMachine: boolean = isNumber(machine);
        const editedMachine: boolean = isMachine && machine != value;

        const organization: string = firstFc.countryForecast === null ? '' : firstFc.countryForecast.toString();
        const isOrganization: boolean = isNumber(organization);
        const editedOrganization: boolean = isOrganization && organization != value;

        const newValue: boolean = !(editedMachine || editedOrganization);

        if (editedMachine || editedOrganization || newValue) {
            this.state.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi] = true;
        }
    }
    if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public async onKeyUpEnter(firstFc: IKpiForecast, secondFc: IKpiForecast, value: string): Promise<void> {
    const tag: string = `${this.tag}.onKeyUpEnter()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, 'firstFc:', firstFc);
    if (debug) console.log(tag, 'secondFc:', secondFc);
    if (debug) console.log(tag, 'value:', value);
    if (browser.name === 'ie') await this.onAdjustmentChange(firstFc, secondFc, value);
  }

  public async onAdjustmentChange(firstFc: IKpiForecast, secondFc: IKpiForecast, value: string = null): Promise<void> {
    const tag: string = `${this.tag}.onAdjustmentChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'firstFc:', firstFc);
    if (debug) console.log(tag, 'secondFc:', secondFc);
    if (debug) console.log(tag, 'value:', value);
    const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
    let firstValue = +formattedValue;
    if (firstValue > 100) firstValue = 100;
    else if (firstValue < 0) firstValue = 0;
    let secondValue = 100 - firstValue;
    if (debug) console.log(tag, 'formattedValue:', formattedValue);
    await this.workbench.postAdditionalKpiAdjustment({ id: firstFc.id, value: firstValue });
    await this.workbench.postAdditionalKpiAdjustment({ id: secondFc.id, value: secondValue });
    await this.reload();
    if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public getValue(fc: IKpiForecast): number {
    if (fc.adjustedValue != null) return fc.adjustedValue;
    return fc.countryForecast;
  }

  private onCurrencySplitChange(currencySplitContainer: CurrencySplitContainer): void {
    const tag: string = `${this.tag}.onCurrencySplitChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'currencySplitContainer', currencySplitContainer);
    if (!currencySplitContainer) return;
    this.values = currencySplitContainer.currencySplit;
    let forecasts: IKpiForecast[] = [];
    this.values.forEach(element => {
      forecasts = forecasts.concat(element.firstCurrencySplitValues, element.secondCurrencySplitValues);
    });
    this.adjustments = forecasts
    .filter((element) => element.adjustedValue != null && element.id)
    .sort(sortKpiForecast)
    .reverse()
    .map((element) => ({
        forecastId: element.id,
        asOf: dateToMonthPickerStr(createDate(element.asOfMonth)),
        perMonth: dateToMonthPickerStr(createDate(element.perMonth)),
        originalValue: removeCommaSeparatorNum(removeExtraSigns(element.countryForecast.toString())),
        adjustValue: removeCommaSeparatorNum(removeExtraSigns(element.adjustedValue.toString())),
    }));
    this.setUnsavedAdjustments();
    this.setLoadingStatus();
    this.firstMonth = localeMonthFull(monthPickerStrToDate(this.state.datePickerDateAsOf));
    this.secondMonth = localeMonthFull(incrementMonth(monthPickerStrToDate(this.state.datePickerDateAsOf), 1));
    this.thirdMonth = localeMonthFull(incrementMonth(monthPickerStrToDate(this.state.datePickerDateAsOf), 2));
  }

  private onCountryChange(country: Country): void {
    this.firstCurrency = country.currency.name;
    this.secondCurrency = country.secondCurrency.name;
    this.disableWrite = country.disableDataWrite && !this.state.userHasAdminAccess;
  }

  private clearAdjustments(): void {
    const tag: string = `${this.tag}.clearAdjustments()`;
    if (this.debug) console.log(tag);
    this.adjustments = [];
    this.setUnsavedAdjustments();
  }

  private async reload() {
    const tag: string = `${this.tag}.reload()`;
    if (this.debug) console.log(tag);
    await Promise.all([
        this.workbench.setUpCurrencySplit(),
    ]);
  }

  private setUnsavedAdjustments(): void {
    const tag: string = `${this.tag}.setUnsavedAdjustments()`;
    if (this.debug) console.log(tag);
    this.state.unsavedAdjustmentsMap[AdjustmentTab.CurrencySplit] = this.adjustments.length > 0;
  }
}
