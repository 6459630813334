import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { skip } from "rxjs/operators";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { StateService } from "src/app/services/state/state.service";
import { Page, TopLevelKpi } from "src/app/constants";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { INavigationKpiTree } from "src/app/models/workbench/navigation";
import { Country } from "src/app/models/common/country";

@Component({
  selector: "workbenchNavigation",
  templateUrl: "./workbenchNavigation.component.html",
  styleUrls: ['./workbenchNavigation.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "vclLayoutHorizontal myfcParentHeight myfcWorkbenchNavigation"
  }
})
export class WorkbenchNavigationComponent extends BaseComponent
  implements OnInit {
  private static readonly Tag: string = "WorkbenchNavigationComponent";
  protected readonly tag: string = WorkbenchNavigationComponent.Tag;
  protected readonly debug: boolean = false;

  @Input() public Pages: Page[];

  public navigation: INavigationKpiTree[] = [];
  public currencySplitAvailable: boolean = false;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    private readonly workbench: WorkbenchDataService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;

    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.kpi0$,
      this.state.kpi1$,
      this.state.kpi2$,
      this.state.kpi3$,
      this.state.kpi4$,
      this.state.kpi5$,
      this.state.organizationEntity$,
      this.state.datePickerDate$,
      this.state.country$
    ];

    this.subscriptions = [
      this.workbench.navigation$.subscribe(this.onNavigationChange.bind(this)),
      this.state.kpi0$.pipe(skip(1)).subscribe(this.onKpi0Select.bind(this)),
      this.workbench.isCurrencySplitVisible$.subscribe(this.onCountryChange.bind(this))
    ];
  }

  public onCountryChange(isCurrencySplitVisible: boolean) {
    this.currencySplitAvailable = isCurrencySplitVisible;
  }

  public onNavigationChange(navigation: INavigationKpiTree[]): void {
    const tag: string = `${this.tag}.onNavigationChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "navigation:", navigation);
    this.navigation = navigation;
    this.expandTopLevelKpi();
  }

  public onTopLevelKpiSelect(topLevelKpi: TopLevelKpi): void {
    const tag: string = `${this.tag}.onTopLevelKpiSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "topLevelKpi:", topLevelKpi);
    this.state.topLevelKpi = topLevelKpi;

    if (topLevelKpi === TopLevelKpi.AdditionalKpis || topLevelKpi === TopLevelKpi.CurrencySplit) {
      this.navigation.forEach(kpi => ((kpi as any).expanded = false));
    }
  }

  public onPageTap(page: Page): void {
    const tag: string = `${this.tag}.onPageTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "page:", page);
    this.state.page = page;
  }

  public onKpi0Select(
    kpi0: INavigationKpiTree,
    kpi1: INavigationKpiTree = null
  ): void {
    const tag: string = `${this.tag}.onKpi0Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi0:", kpi0);
    // if (debug) console.log(tag, 'kpi1:', kpi1);
    this.state.setKpi({ kpi0, kpi1 });
    this.expandTopLevelKpi();
  }

  public expandTopLevelKpi(): void {
    const tag: string = `${this.tag}.expandTopLevelKpi()`;
    const debug: boolean = this.debug || false;
    this.navigation.forEach(
      kpi =>
        ((kpi as any).expanded =
          this.state.kpi0 &&
          this.state.kpi0.kpi.kpiTypeId === kpi.kpi.kpiTypeId)
    );
    this.workbench.onNavigationChange(this.navigation);
    this.cd.detectChanges();
  }

  public onKpi1Select(
    kpi1: INavigationKpiTree,
    kpi0: INavigationKpiTree = this.state.kpi0
  ): void {
    const tag: string = `${this.tag}.onKpi1Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi1:", kpi1);
    // if (debug) console.log(tag, 'kpi0:', kpi0);
    this.onKpi0Select(kpi0, kpi1);
  }

  public onKpi2Select(
    kpi2: INavigationKpiTree,
    kpi1: INavigationKpiTree = this.state.kpi1,
    kpi0: INavigationKpiTree = this.state.kpi0
  ): void {
    const tag: string = `${this.tag}.onKpi2Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi2:", kpi2);
    // if (debug) console.log(tag, 'kpi1:', kpi1);
    // if (debug) console.log(tag, 'kpi0:', kpi0);
    this.state.setKpi({ kpi0, kpi1, kpi2 });
  }

  public onKpi3Select(
    kpi3: INavigationKpiTree,
    kpi2: INavigationKpiTree = this.state.kpi2,
    kpi1: INavigationKpiTree = this.state.kpi1,
    kpi0: INavigationKpiTree = this.state.kpi0
  ): void {
    const tag: string = `${this.tag}.onKpi3Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi3:", kpi3);
    // if (debug) console.log(tag, 'kpi2:', kpi2);
    // if (debug) console.log(tag, 'kpi1:', kpi1);
    // if (debug) console.log(tag, 'kpi0:', kpi0);
    this.state.setKpi({ kpi0, kpi1, kpi2, kpi3 });
  }

  public onKpi4Select(
    kpi4: INavigationKpiTree,
    kpi3: INavigationKpiTree = this.state.kpi3,
    kpi2: INavigationKpiTree = this.state.kpi2,
    kpi1: INavigationKpiTree = this.state.kpi1,
    kpi0: INavigationKpiTree = this.state.kpi0
  ): void {
    const tag: string = `${this.tag}.onKpi4Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi4:", kpi4);
    // if (debug) console.log(tag, 'kpi3:', kpi3);
    // if (debug) console.log(tag, 'kpi2:', kpi2);
    // if (debug) console.log(tag, 'kpi1:', kpi1);
    // if (debug) console.log(tag, 'kpi0:', kpi0);
    this.state.setKpi({ kpi0, kpi1, kpi2, kpi3, kpi4 });
  }

  public onKpi5Select(
    kpi5: INavigationKpiTree,
    kpi4: INavigationKpiTree = this.state.kpi4,
    kpi3: INavigationKpiTree = this.state.kpi3,
    kpi2: INavigationKpiTree = this.state.kpi2,
    kpi1: INavigationKpiTree = this.state.kpi1,
    kpi0: INavigationKpiTree = this.state.kpi0
  ): void {
    const tag: string = `${this.tag}.onKpi5Select()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi5:", kpi5);
    if (debug) console.log(tag, "kpi4:", kpi4);
    // if (debug) console.log(tag, 'kpi3:', kpi3);
    // if (debug) console.log(tag, 'kpi2:', kpi2);
    // if (debug) console.log(tag, 'kpi1:', kpi1);
    // if (debug) console.log(tag, 'kpi0:', kpi0);
    this.state.setKpi({ kpi0, kpi1, kpi2, kpi3, kpi4, kpi5 });
  }

  public toggleDropDown(kpi0: INavigationKpiTree): void {
    const tag: string = `${this.tag}.toggleDropDown()`;
    const debug: boolean = this.debug || false;
    (kpi0 as any).expanded = !(kpi0 as any).expanded;
  }

  public TopLevelKpi = TopLevelKpi;
}
