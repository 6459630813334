import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { TourService, TourComponent } from '@vcl/ng-vcl';

@Component({
  selector: 'bottomBar',
  templateUrl: './bottomBar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BottomBarComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'BottomBarComponent';

  // Taken from vclmyfctheme.
  private readonly CollapsedMenuWidth: string = '50px';
  private readonly ExpandedMenuWidth: string = '280px';

  private smallScreen: boolean = false;

  private bottomBarElIds: string[] = [
    '#scenarios',
    '#excelRequestBtn', '#excelUploadBtn',
    '#appliedAdjustments', '#submitBtn'
  ];

  private isBottomBarTargeted: boolean = false;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    public readonly tour: TourService,
  ) {
    super(cd, state);
    this.tag = BottomBarComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = false;

    this.subscriptions = [
      this.tour.tourComponent$.subscribe(this.onTourStepChange.bind(this))
    ];

    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.organizationEntity$,
    ];
  }

  private onTourStepChange(tourComponent: TourComponent): void {
    const tag: string = `${this.tag}.onTourStepChange()`;
    const debug: boolean = false;
    if (debug) console.log(tag, 'tourComponent:', tourComponent);
    this.isBottomBarTargeted = this.bottomBarElIds.includes(tourComponent.target as string);
    if (debug) console.log(tag, 'this.isBottomBarTargeted:', this.isBottomBarTargeted);
  }

  public getIndex(): string {
    if (this.isBottomBarTargeted) return `${this.tour.options.zIndex}`;
    return '';
  }
}
