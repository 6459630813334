import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoginGuard } from 'src/app/guards/login.guard';
import { PageGuard } from 'src/app/guards/page.guard';
import { InitializeGuard } from 'src/app/guards/initialize.guard';

@Injectable()
export class MasterGuard implements CanActivate {
  private static readonly Tag: string = 'LoginGuard';
  private readonly tag: string = MasterGuard.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    private readonly login: LoginGuard,
    private readonly page: PageGuard,
    private readonly initialize: InitializeGuard,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const tag: string = `${this.tag}.canActivate()`;
    const debug: boolean = this.debug || false;

    const canActivateLogin: boolean = this.login.canActivate();
    if (debug) console.log(tag, 'canActivateLogin:', canActivateLogin);
    if (!canActivateLogin) return false;

    const canActivatePage: boolean = await this.page.canActivate(route, state);
    if (debug) console.log(tag, 'canActivatePage:', canActivatePage);
    if (!canActivatePage) return false;

    const canActivateInitialize: boolean = await this.initialize.canActivate();
    if (debug) console.log(tag, 'canActivateInitialize:', canActivateInitialize);
    if (!canActivateInitialize) return false;

    return true;
  }
}
