import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgIdleModule } from "@ng-idle/core";
import { CurrencySplitComponent } from 'src/app/components/workbench/currencySplit/currencySplit.component';
import { CurrencySplitAdjustmentButtonsComponent } from 'src/app/components/workbench/currencySplitAdjustmentButtons/currencySplitAdjustmentButtons.component';
import { MoveReportingComponent } from 'src/app/components/workbench/moveReporting/moveReporting.component';
import { MoveReportingAdjustmentButtonsComponent } from 'src/app/components/workbench/moveReportingAdjustmentButtons/moveReportingAdjustmentButtons.component';
import { FocusReportingComponent } from 'src/app/components/workbench/focusReporting/focusReporting.component';
import { FocusReportingAdjustmentButtonsComponent } from 'src/app/components/workbench/focusReportingAdjustmentButtons/focusReportingAdjustmentButtons.component';
import { ItReportingComponent } from 'src/app/components/workbench/itReporting/itReporting.component';
import { ItReportingAdjustmentButtonsComponent } from 'src/app/components/workbench/itReportingAdjustmentButtons/itReportingAdjustmentButtons.component';


/*** Directives ***/
import { VarDirective } from "src/app/directives";
/*** Directives ***/

// import Pipes from './pipes';
/*** Pipes ***/
import { TimeAgoPipe } from "src/app/pipes/timeAgoPipe"; // 'time-ago-pipe';
import {
  KeysPipe,
  IsNumberPipe,
  ValuesPipe,
  ScaleNumberPipe,
  SplitPipe
} from "src/app/pipes";
import { NavigationPipe } from "src/app/pipes/navigation.pipe";
/*** Pipes ***/

import { Routing } from "src/app/app.routes";
import {
  VCLIconModule,
  VCLButtonModule,
  VCLSelectModule,
  VCLBusyIndicatorModule,
  VCLLayerModule,
  VCLPopoverModule,
  VCLCheckboxModule,
  VCLDateAdapterModule,
  VCLFormControlGroupModule,
  VCLOffClickModule,
  VCLFlipSwitchModule,
  VCLProgressBarModule,
  VCLTooltipModule,
  VCLTourModule,
  VCLRadioButtonModule,
  VCLFileInputModule,
  VCLIcogramModule,
  VCLNavigationModule,
  VCLFontAwesomeModule,
  VCLNotifierModule,
  VCLCalendarModule,
} from "@vcl/ng-vcl";

import { PlotlyModule } from "@n-fuse/ng-plotly";

// import Guards from './guards';
/*** Guards ***/
import { InitializeGuard } from "src/app/guards/initialize.guard";
import { LoginGuard } from "src/app/guards/login.guard";
import { UnsavedAdjustmentsGuard } from "src/app/guards/unsavedAdjustments.guard";
import { PageGuard } from "src/app/guards/page.guard";
import { MasterGuard } from "src/app/guards/master.guard";
/*** Guards ***/

// import Resolvers from './resolvers';
/*** Resolvers ***/
import { LoginResolver } from "src/app/resolvers/login.resolver";
import { WorkbenchResolver } from "src/app/resolvers/workbench.resolver";
import { LandingResolver } from "src/app/resolvers/landing.resolver";
import { PlanningResolver } from "src/app/resolvers/planning.resolver";
/*** Resolvers ***/

// import Services from './services';
/*** Services ***/
/** Data **/
import { CommonDataService } from "src/app/services/data/common.data.service";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { PlanningDataService } from "src/app/services/data/planning.data.service";
/** Data **/
import { StateService } from "src/app/services/state/state.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalService } from "src/app/services/modal.service";
import { NotificationService } from "src/app/services/notification.service";
import { RequestService } from "src/app/services/request.service";
import { RouterService } from "src/app/services/router.service";
import { StorageService } from "src/app/services/storage.service";
import { PlotService } from "src/app/services/plot.service";
import { UserSettingsService } from "src/app/services/userSettings.service";
import { WindowService } from "src/app/services/window.service";
import { AuthService } from "src/app/services/auth.service";
import { TooltipService } from "src/app/services/tooltip.service";
/*** Services ***/

// import Components from './components';
/*** Components ***/
import { AppComponent } from "src/app/app.component";

/** Workbench **/
import { WorkbenchComponent } from "src/app/components/workbench/workbench/workbench.component";
import { AdjustmentButtonsComponent } from "src/app/components/workbench/adjustmentButtons/adjustmentButtons.component";

/*** WorkbenchHeader */
import { CompareComponent } from "src/app/components/workbench/workbenchHeader/compare/compare.component";
import { ScenarioLabelsComponent } from "src/app/components/workbench/workbenchHeader/scenarioLabels/scenarioLabels.component";
import { ShareButtonComponent } from "src/app/components/workbench/workbenchHeader/shareButton/shareButton.component";
import { WorkbenchHeaderComponent } from "src/app/components/workbench/workbenchHeader/workbenchHeader/workbenchHeader.component";
import { ZoomOutButtonComponent } from "src/app/components/workbench/workbenchHeader/zoomOutButton/zoomOutButton.component";
/*** WorkbenchHeader */

import { DataTableComponent } from "src/app/components/workbench/dataTable/dataTable.component";
import { DataTableMonthsComponent } from "src/app/components/workbench/dataTableMonths/dataTableMonths.component";
import { DeltaCountryFcToModelFcComponent } from "src/app/components/workbench/deltaCountryFcToModelFc/deltaCountryFcToModelFc.component";
import { MonthlyForecastComponent } from "src/app/components/workbench/monthlyForecast/monthlyForecast.component";
import { ScatterFyComponentsComponent } from "src/app/components/workbench/scatterFyComponents/scatterFyComponents.component";
import { WorkbenchBreadcrumbsComponent } from "src/app/components/workbench/workbenchBreadcrumbs/workbenchBreadcrumbs.component";
import { WorkbenchTopLevelKpiFyForecastComponent } from "src/app/components/workbench/workbenchFyForecast/workbenchTopLevelKpiFyForecast/workbenchTopLevelKpiFyForecast.component";
import { WorkbenchSubKpiFyForecastComponent } from "src/app/components/workbench/workbenchFyForecast/workbenchSubKpiFyForecast/workbenchSubKpiFyForecast.component";
import { WorkbenchFyForecastComponent } from "src/app/components/workbench/workbenchFyForecast/workbenchFyForecast/workbenchFyForecast.component";

import { AdditionalKpiComponent } from "src/app/components/workbench/additionalKpi/additionalKpi.component";
import { AdditionalKpiAdjustmentButtonsComponent } from "src/app/components/workbench/additionalKpiAdjustmentButtons/additionalKpiAdjustmentButtons.component";
import { RatiosDisplayComponent } from "src/app/components/workbench/ratiosDisplay/ratiosDisplay.component";
/** Workbench **/

/** Planning */
import { PlanningComponent } from "src/app/components/planning/planning/planning.component";
import { PlanningAdjustmentButtonsComponent } from "src/app/components/planning/planningAdjustmentButtons/planningAdjustmentButtons.component";
import { FteComponent } from "src/app/components/planning/fte/fte.component";
import { InsuranceComponent } from "src/app/components/planning/insurance/insurance.component";
import { OpexComponent } from "src/app/components/planning/opex/opex.component";
import { MarketAndRiskComponent } from "src/app/components/planning/marketAndRisk/marketAndRisk.component";
import { ProjectFteCostsComponent } from "src/app/components/planning/projectFteCosts/projectFteCosts.component";
import { DigitalKpisComponent } from "src/app/components/planning/digitalKpis/digitalKpis.component";
import { EquityComponent } from "src/app/components/planning/equity/equity.component";
import { ItAndProjectsComponent} from "src/app/components/planning/itAndProjects/itAndProjects.component";
/** Planning */

/** Login **/
import { LoginComponent } from "src/app/components/login/login/login.component";
/** Login **/

/** Landing **/
import { LandingComponent } from "src/app/components/landing/landing/landing.component";
/** Landing **/

/** Common **/
import { BaseComponent } from "src/app/components/common/base/base.component";

/* Charts */
import { BarFyComponentsComponents } from "src/app/components/common/charts/barFyComponents/barFyComponents.component";
import { FyForecastComponent } from "src/app/components/common/charts/fyForecast/fyForecast.component";
import { WalkComponent } from "src/app/components/common/charts/walk/walk.component";
/* Charts */

/* Elements */
import { BottomBarComponent } from "src/app/components/common/elements/bottomBar/bottomBar/bottomBar.component";

// BottomBarMiddleBar
import { AppliedAdjustmentsComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/appliedAdjustments/appliedAdjustments.component";
import { BottomBarMiddleBarComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/bottomBarMiddleBar/bottomBarMiddleBar.component";
import { ExcelRequestButtonComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/excelRequestBtn/excelRequestBtn.component";
import { ExcelUploadButtonComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/excelUploadBtn/excelUploadBtn.component";
import { SubmitButtonComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/submitBtn/submitBtn.component";
import { SubmitExcelButtonComponent } from "src/app/components/common/elements/bottomBar/bottomBarMiddleBar/submitExcelBtn/submitExcelBtn.component";
// BottomBarMiddleBar

import { ScenariosComponent } from "src/app/components/common/elements/bottomBar/scenarios/scenarios.component";
import { ConfirmationLayerComponent } from "src/app/components/common/elements/confirmationLayer/confirmationLayer.component";
import { HamburgerButtonComponent } from "src/app/components/common/elements/hamburgerButton/hamburgerButton.component";
import { HomeButtonComponent } from "src/app/components/common/elements/homeButton/homeButton.component";
import { CollapsedNavigationComponent } from "src/app/components/common/elements/navigation/collapsedNavigation/collapsedNavigation.component";
import { ConfirmationDialogComponent } from "src/app/components/common/elements/confirmationDialog/confirmationDialog.component";
import { MyTooltipComponent } from "src/app/components/common/elements/tooltip/tooltip.component";
import { MonthPickerComponent } from "src/app/components/common/elements/monthPicker/monthPicker.component";

// Navigation
import { NavigationComponent } from "src/app/components/common/elements/navigation/navigation/navigation.component";
import { SimpleNavigationComponent } from "src/app/components/common/elements/navigation/simpleNavigation/simpleNavigation.component";
import { WorkbenchNavigationComponent } from "src/app/components/common/elements/navigation/workbenchNavigation/workbenchNavigation.component";
import { PlanningNavigationComponent } from "src/app/components/common/elements/navigation/planningNavigation/planningNavigation.component";
// Navigation

import { NotificationComponent } from "src/app/components/common/elements/notification/notification.component";

// TopBar
import { TopBarComponent } from "src/app/components/common/elements/topBar/topBar/topBar.component";
import { ColorLegendComponent } from "src/app/components/common/elements/topBar/colorLegend/colorLegend.component";
import { CountryPickerComponent } from "src/app/components/common/elements/topBar/countryPicker/countryPicker.component";
import { CurrencyComponent } from "src/app/components/common/elements/topBar/currency/currency.component";
import { DatePickerComponent } from "src/app/components/common/elements/topBar/datePicker/datePicker.component";
import { ForecastSelectorComponent } from "src/app/components/common/elements/topBar/forecastSelector/forecastSelector.component";
import { NotificationsComponent } from "src/app/components/common/elements/topBar/notifications/notifications.component";
import { TourComponent } from "src/app/components/common/elements/topBar/tour/tour.component";
import { StatusMessageComponent } from "src/app/components/common/elements/topBar/statusMessage/statusMessage.component";
import { ValueLabelsComponent } from "src/app/components/common/elements/topBar/valueLabels/valueLabels.component";
import { UserSettingsComponent } from "src/app/components/common/elements/topBar/userSettings/userSettings/userSettings.component";
import { CometButtonComponent } from "src/app/components/common/elements/topBar/cometButton/cometButton.component";
import { PlanPickerComponent } from "src/app/components/common/elements/topBar/planPicker/planPicker.component";
import { TypePickerComponent } from "src/app/components/common/elements/topBar/typePicker/typePicker.component";
// TopBar

// UserSettings //
import { LogoutComponent } from "src/app/components/common/elements/topBar/userSettings/logout/logout.component";
import { SettingsComponent } from "src/app/components/common/elements/topBar/userSettings/settings/settings.component";
// UserSettings //

import { VideoPopupComponent } from "src/app/components/common/elements/videoPopup/videoPopup.component";
/* Elements */
/** Common **/
/*** Components ***/

@NgModule({
  declarations: [
    AppComponent,
    /** Workbench **/
    WorkbenchComponent,
    AdjustmentButtonsComponent,
    DataTableComponent,
    DataTableMonthsComponent,
    DeltaCountryFcToModelFcComponent,
    MonthlyForecastComponent,
    ScatterFyComponentsComponent,
    WorkbenchTopLevelKpiFyForecastComponent,
    WorkbenchSubKpiFyForecastComponent,
    WorkbenchFyForecastComponent,
    CurrencySplitComponent,
    CurrencySplitAdjustmentButtonsComponent,
    MoveReportingComponent,
    MoveReportingAdjustmentButtonsComponent,
    FocusReportingComponent,
    FocusReportingAdjustmentButtonsComponent,
    ItReportingComponent,
    ItReportingAdjustmentButtonsComponent,
    /*** WorkbenchHeader ***/
    CompareComponent,
    ScenarioLabelsComponent,
    ShareButtonComponent,
    WorkbenchHeaderComponent,
    ZoomOutButtonComponent,
    /*** WorkbenchHeader ***/
    WorkbenchBreadcrumbsComponent,
    AdditionalKpiComponent,
    AdditionalKpiAdjustmentButtonsComponent,
    RatiosDisplayComponent,
    /** Workbench **/

    /** Planning **/
    PlanningComponent,
    PlanningAdjustmentButtonsComponent,
    FteComponent,
    InsuranceComponent,
    MarketAndRiskComponent,
    OpexComponent,
    ProjectFteCostsComponent,
    EquityComponent,
    DigitalKpisComponent,
    ItAndProjectsComponent,
    /** Planning **/

    /** Login **/
    LoginComponent,
    /** Login **/

    /** Landing **/
    LandingComponent,
    /** Landing **/

    /** Common **/
    BaseComponent,
    /** Common **/

    /* Charts */
    BarFyComponentsComponents,
    FyForecastComponent,
    WalkComponent,
    /* Charts */

    /* Elements */
    AppliedAdjustmentsComponent,
    BottomBarComponent,
    BottomBarMiddleBarComponent,
    ExcelRequestButtonComponent,
    ExcelUploadButtonComponent,
    SubmitButtonComponent,
    SubmitExcelButtonComponent,
    ScenariosComponent,
    ConfirmationLayerComponent,
    HamburgerButtonComponent,
    HomeButtonComponent,
    CollapsedNavigationComponent,
    ConfirmationDialogComponent,
    MyTooltipComponent,
    MonthPickerComponent,
    /* Elements */

    // Navigation
    NavigationComponent,
    SimpleNavigationComponent,
    WorkbenchNavigationComponent,
    PlanningNavigationComponent,
    // Navigation

    NotificationComponent,
    NotificationsComponent,

    // TopBar
    TopBarComponent,
    ColorLegendComponent,
    CountryPickerComponent,
    CurrencyComponent,
    DatePickerComponent,
    ForecastSelectorComponent,
    TourComponent,
    ValueLabelsComponent,
    UserSettingsComponent,
    CometButtonComponent,
    StatusMessageComponent,
    PlanPickerComponent,
    TypePickerComponent,
    // TopBar

    // UserSettings //
    SettingsComponent,
    LogoutComponent,
    VideoPopupComponent,

    /*** Directives ***/
    VarDirective,
    /*** Directives ***/

    /*** Pipes ***/
    KeysPipe,
    IsNumberPipe,
    ValuesPipe,
    ScaleNumberPipe,
    SplitPipe,
    TimeAgoPipe,
    NavigationPipe
    /*** Pipes ***/
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,

    /** ng-vcl */
    /** ng-plotly */
    PlotlyModule,
    /** ng-plotly */

    /** ng-vcl services */
    NgIdleModule.forRoot(),
    VCLDateAdapterModule.forRoot(),
    VCLLayerModule,
    VCLFontAwesomeModule,
    /** ng-vcl services */

    /** ng-vcl components */
    VCLPopoverModule,
    VCLIconModule,
    VCLButtonModule,
    VCLSelectModule,
    VCLBusyIndicatorModule,
    VCLCalendarModule,
    VCLCheckboxModule,
    VCLFormControlGroupModule,
    VCLOffClickModule,
    VCLFlipSwitchModule,
    VCLProgressBarModule,
    VCLTooltipModule,
    VCLTourModule,
    VCLRadioButtonModule,
    VCLFileInputModule,
    VCLIcogramModule,
    VCLNavigationModule,
    VCLNotifierModule,
    /** ng-vcl components*/
    /** ng-vcl */

    Routing
  ],
  providers: [
    /*** Guards ***/
    InitializeGuard,
    LoginGuard,
    UnsavedAdjustmentsGuard,
    PageGuard,
    MasterGuard,
    /*** Guards ***/

    /*** Resolvers ***/
    LoginResolver,
    LandingResolver,
    WorkbenchResolver,
    PlanningResolver,
    /*** Resolvers ***/

    /*** Services ***/
    /** Data **/
    CommonDataService,
    PlanningDataService,
    WorkbenchDataService,
    /** Data **/
    StateService,
    ConfigurationService,
    ModalService,
    NotificationService,
    RequestService,
    RouterService,
    StorageService,
    PlotService,
    UserSettingsService,
    WindowService,
    AuthService,
    TooltipService
    /*** Services ***/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
