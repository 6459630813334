import {
  ChangeDetectorRef,
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  OnInit
} from "@angular/core";
import { StateService } from "src/app/services/state/state.service";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { CommonDataService } from "src/app/services/data/common.data.service";
import { NotifierType } from 'src/app/constants';
import { NotificationService } from "src/app/services/notification.service";
import { ConfirmationDialogComponent } from "src/app/components/common/elements/confirmationDialog/confirmationDialog.component";
import { PlanningDataService } from "src/app/services/data/planning.data.service";

const GifDuration: number = 9000;

@Component({
  selector: "submitExcelBtn",
  templateUrl: "submitExcelBtn.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class SubmitExcelButtonComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "SubmitButtonComponent";
  @ViewChild("submitExcelBtnConfirmationDialog")
  private readonly submitExcelBtnConfirmationDialog: ConfirmationDialogComponent;
  protected readonly tag: string = SubmitExcelButtonComponent.Tag;
  protected readonly debug: boolean = false;

  public debugConfirmationDialog: boolean = false;
  public showConfirmationDialog: boolean = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
    public readonly state: StateService,
    private readonly notification: NotificationService,
    private readonly planning: PlanningDataService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;

    this.observables = [this.state.scenario$];
  }

  public async onSubmitExcelBtnTap(
    confirmDialog: ConfirmationDialogComponent
  ): Promise<void | boolean> {
    const tag: string = `${this.tag}.onSubmitExcelBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    const access: boolean = await this.data.hasApplicationAccess();
    if (!access) return;
    confirmDialog.Toggle();
  }

  public onConfirmationDialogCancelTap(): void {
    const tag: string = `${this.tag}.onCancelTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  public async onConfirmationDialogContinueTap(): Promise<void> {
    const tag: string = `${this.tag}.onConfirmationDialogContinueTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    try {
      await this.data.submitPlanningAdjustments();
    } catch (e) {
      this.notification.add({
        text: "Submitting planning data failed",
        type: NotifierType.Error
      });

      return;
    }

    await Promise.all([
      this.planning.setUpPlanning(),
      this.planning.setUpPlanningStatusMessage()
    ]);

    this.notification.add({
      text: `Planning data submitted successfully`,
      type: NotifierType.Success
    });
  }

  public get btnDisabled() {
    return (this.isLoading || this.state.organizationEntity.disableDataSubmit || this.state.type == 0 || this.state.type == 2);
  }
}
