import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { BaseComponent } from "src/app/components/common/base/base.component";
import {
  FormattedRegion,
  FormattedCountry,
  FormattedOrganization,
  Country
} from "src/app/models/common/country";
import { ConfigurationService } from "src/app/services/configuration.service";
import { StateService } from "src/app/services/state/state.service";
import { getValues, sortByString } from "src/app/utils";
import { Location } from "src/app/constants";
import { CommonDataService } from "src/app/services/data/common.data.service";
import { Flag } from "src/app/models/common/flag";

export enum OrganizationEntity {
  Region,
  Country,
  Organization
}

export type OrganizationEntityList =
  | FormattedRegion[]
  | FormattedCountry[]
  | FormattedOrganization[];

@Component({
  selector: "countryPicker",
  templateUrl: "countryPicker.component.html",
  styleUrls: ["countryPicker.styl"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CountryPickerComponent extends BaseComponent
  implements OnInit, AfterViewInit {
  private static readonly Tag: string = "CountryPickerComponent";

  private readonly titles = {
    [OrganizationEntity.Region]: "Regions",
    [OrganizationEntity.Country]: "Countries",
    [OrganizationEntity.Organization]: "Organizations"
  };

  public readonly debugPopover: boolean = false;

  public title: string;
  public backBtnTitle: string;

  public list: OrganizationEntityList = [];
  public region: any;
  public country: any;
  public organization: any;

  public popoverIsVisible: boolean = true;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    public readonly data: CommonDataService,
    private readonly configuration: ConfigurationService
  ) {
    super(cd, state);
    this.tag = CountryPickerComponent.Tag;
    this.debug = false;
    this.expanded = false;
    this.icon = this.collapsedIcon;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.subscriptions = [this.state.regions$.subscribe(this.reset.bind(this))];

    this.observables = [
      this.state.organizationEntity$,
      this.state.region$,
      this.state.country$,
      this.state.organization$
    ];
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    const tag: string = `${this.tag}.ngAfterViewInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  private setTitles(): void {
    const tag: string = `${this.tag}.setTitles()`;
    const debug: boolean = this.debug || false;
    // if (debug) console.log(tag, 'this.region:', this.region);
    // if (debug) console.log(tag, 'this.country:', this.country);
    // if (debug) console.log(tag, 'this.organization:', this.organization);
    if (!this.region) {
      this.title = this.titles[OrganizationEntity.Region];
      this.backBtnTitle = undefined;
    } else if (!this.country) {
      this.title = this.region.name;
      this.backBtnTitle = this.titles[OrganizationEntity.Region];
    } else if (!this.organization) {
      this.title = this.country.name;
      this.backBtnTitle = this.titles[OrganizationEntity.Country];
    }
    if (debug) console.log(tag, "this.title:", this.title);
    if (debug) console.log(tag, "this.backBtnTitle:", this.backBtnTitle);
  }

  private reset(): void {
    const tag: string = `${this.tag}.reset()`;
    const debug: boolean = this.debug || false;
    this.region = this.country = this.organization = undefined;
    if (debug) console.log(tag, "state.regions:", this.state.regions);
    const regions: FormattedRegion[] = getValues(this.state.regions.items);
    if (debug) console.log(tag, "regions:", regions);
    this.list = regions.sort((a, b) => sortByString(a, b, "name"));
    if (debug) console.log(tag, "this.list:", this.list);
    this.setTitles();
  }

  public onExpandTap(): void {
    const tag: string = `${this.tag}.onExpandTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.expanded) this.reset();
    this.expanded = !this.expanded;
    this.reset();
  }

  public onOffClick(): void {
    const tag: string = `${this.tag}.onOffClick()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.region:", this.region);
    if (debug) console.log(tag, "this.country:", this.country);
    if (debug) console.log(tag, "this.organization:", this.organization);
    this.expanded = false;

    if (this.region || this.country || this.organization) {
      this.reset();
    }
  }

  private onAggregatedItemTap(event: Event, location: Location): void {
    const tag: string = `${this.tag}.onAggregatedItemTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "location:", Location[location]);

    if (location === Location.Region) {
      this.country = this.organization = undefined;
    } else if (location === Location.Country) {
      this.organization = undefined;
    }

    if (debug) console.log(tag, "this.region:", this.region);
    if (debug) console.log(tag, "this.country:", this.country);
    if (debug) console.log(tag, "this.organization:", this.organization);

    this.state.setOrganizationEntity(
      this.region,
      this.country,
      this.organization
    );
    this.onExpandTap();
  }

  public onListItemTap(event: Event, item: any): void {
    const tag: string = `${this.tag}.onListTap()`;
    const debug: boolean = this.debug || false;
    // Avoid offClick event.
    event.stopPropagation();
    if (debug) console.log(tag, "item:", item);

    if (!this.region) this.region = item;
    else if (!this.country) this.country = item;
    else if (!this.organization) this.organization = item;

    const items: OrganizationEntityList =
      item && item.items && getValues(item.items);
    if (debug) console.log(tag, "items:", items);
    if (items && items.length) {
      // TODO: Be aware, FormattedOrganization currently not used.
      const firstItem:
        | FormattedRegion
        | FormattedCountry
        | FormattedOrganization = items[0];
      const isCountry: boolean = !!(firstItem as FormattedCountry).code;
      this.list = isCountry
        ? (items as FormattedCountry[]).sort((a, b) =>
            sortByString(a, b, "code")
          )
        : (items as FormattedRegion[]).sort((a, b) =>
            sortByString(a, b, "name")
          );
      if (this.debug) console.log(tag, "this.list:", this.list);
    } else {
      this.state.setOrganizationEntity(
        this.region,
        this.country,
        this.organization
      );
      this.onExpandTap();
    }
    this.resetPopover();
  }

  public onBackBtnTap(event: Event): void {
    const tag: string = `${this.tag}.onBackBtnTap()`;
    const debug: boolean = this.debug || false;
    // Avoid offClick event.
    event.stopImmediatePropagation();
    if (debug) console.log(tag, "this.region:", this.region);
    if (debug) console.log(tag, "this.country:", this.country);
    if (debug) console.log(tag, "this.organization:", this.organization);

    if (this.organization) {
      const organizations: FormattedOrganization[] = getValues(
        this.state.regions[this.region.id][this.country.id].items
      );
      if (debug) console.log("organizations:", organizations);
      this.organization = undefined;
      this.list = organizations.sort((a, b) => sortByString(a, b, "name"));
      if (debug) console.log("this.list:", this.list);
      this.setTitles();
    } else if (this.country) {
      const countries: FormattedCountry[] = getValues(
        this.state.regions[this.region.id].items
      );
      if (debug) console.log("countries:", countries);
      this.country = undefined;
      this.list = countries.sort((a, b) => sortByString(a, b, "code"));
      if (debug) console.log("this.list:", this.list);
      this.setTitles();
    } else if (this.region) {
      this.reset();
    }
    this.resetPopover();
  }

  // Redraw popover when box size is changed
  resetPopover() {
    this.popoverIsVisible = false;
    setTimeout(() => {
      this.popoverIsVisible = true;
    });
  }
  private readonly Location = Location;
}
