import { Kpi } from 'src/app/models/common/kpiValues';

export class KpiValuesYear {
  public year: number;
  public kpiValues: Kpi[];

  constructor({ year, kpiValues = [] }: {
    year?: number,
    kpiValues?: Kpi[],
  } = {}) {
    this.year = year;
    this.kpiValues = kpiValues;
  }
}
