import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {
  DataService,
  ParameterHandler
} from "src/app/services/data/data.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ModalService } from "src/app/services/modal.service";
import { StateService } from "src/app/services/state/state.service";
import {
  Api,
  Controller,
  Login,
  LastAvailable,
  LastAvailableForecast,
  Configuration,
  Countries,
  ConfigurationAppliedAdjustments,
  Adjust,
  KpiAggregated,
  Notification
} from "src/app/api";
import { RequestService } from "src/app/services/request.service";
import { Page } from "src/app/constants";
import {
  monthPickerStrNonZero,
  previousMonth,
  getValues,
  sortByString,
  nextMonth
} from "src/app/utils";
import { Currency } from "src/app/types";
import { AggregatedKpi } from "src/app/models/common/kpiAggregated";
import { Default } from "src/app/services/data/defaults";
import {
  Country,
  CountryContainer,
  FormattedRegions,
  FormattedRegion,
  FormattedCountry
} from "src/app/models/common/country";
import { Walk } from "src/app/models/results/walk";
import { AreaChartContainer } from "src/app/models/analysis/areaChart";
import { Flag } from "src/app/models/common/flag";
import { NotificationContainer } from "src/app/models/common/notification";
import { User } from "src/app/models/common/user";

export type FyForecast = AggregatedKpi; // | OneYearAggregatedKpi;
export interface FyForecastMap {
  [TopLevelKpi: string]: FyForecast[];
}

export type FyComponents = AggregatedKpi[];
export interface FyComponentMap {
  [TopLevelKpi: string]: FyComponents;
}

export interface AreaChartMap {
  [TopLevelKpi: string]: AreaChartContainer;
}

export interface WalkMap {
  [TopLevelKpi: string]: Walk;
}

export interface PageVisibility {
  [Page: string]: boolean;
}

export interface FlagMap {
  [id: number]: Flag;
}

@Injectable()
export class CommonDataService extends DataService {
  protected static readonly Tag: string = "CommonDataService";
  protected readonly tag: string = CommonDataService.Tag;
  protected readonly debug: boolean = false;
  protected readonly page: Page = null;

  private readonly handlers: ParameterHandler[] = [
    {
      name: "setUpOrganizationAccess",
      change: Api[Controller.Login].endpoints[Login.HasWriteAccess].parameters,
      requests: [this.setUpOrganizationAccess.bind(this)]
    },
    {
      name: "setUpLastAvailableMonth",
      change:
        Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast]
          .endpoints[LastAvailableForecast.PerMonth].parameters,
      requests: [this.setUpLastAvailableMonth.bind(this)]
    },
    {
      name: "setUpNotifications",
      change: Api[Controller.Notification].parameters,
      requests: [this.setUpNotifications.bind(this)]
    }
  ];

  constructor(
    protected readonly state: StateService,
    protected readonly modal: ModalService,
    protected readonly request: RequestService,
    protected readonly configuration: ConfigurationService
  ) {
    super(state, modal);
    this.setUpHandlers(this.handlers);
  }

  public flags: FlagMap = Default.Flags;

  private readonly _fyForecast$: BehaviorSubject<
    FyForecastMap
  > = new BehaviorSubject(Default.FyForecast);
  public readonly fyForecast$: Observable<
    FyForecastMap
  > = this._fyForecast$.asObservable();

  public readonly _fyComponents$: BehaviorSubject<
    FyComponentMap
  > = new BehaviorSubject<FyComponentMap>(Default.FyComponents);
  public readonly fyComponents$: Observable<
    FyComponentMap
  > = this._fyComponents$.asObservable();

  private readonly _walk$: BehaviorSubject<WalkMap> = new BehaviorSubject<
    WalkMap
  >(Default.Walk);
  public readonly walk$: Observable<WalkMap> = this._walk$.asObservable();

  private readonly _notifications$: BehaviorSubject<
    NotificationContainer
  > = new BehaviorSubject<NotificationContainer>(Default.Notifications);
  public readonly notifications$: Observable<
    NotificationContainer
  > = this._notifications$.asObservable();

  public async hasApplicationAccess(): Promise<boolean> {
    const tag: string = `${this.tag}.hasApplicationAccess()`;
    const debug: boolean = this.debug || false;
    return this.state.writeAccess;
  }

  /** Controller.Configuration */
  public async setUpConfiguration(): Promise<void> {
    const tag: string = `${this.tag}.setUpConfiguration()`;
    const debug: boolean = this.debug || false;
    const configuration = (await this.getConfiguration()).body;
    if (debug) console.log(tag, "configuration:", configuration);

    Object.assign(this.state, {
      allowWriteAccessToApplication:
        configuration.allowWriteAccessToApplication,
      appliedAdjustmentsEnabled: configuration.appliedAdjustments,
      dateRestrictionDisabled: configuration.dateRestrictionDisabled,
      excelImportEnabled: configuration.excelImport,
      pageVisibility: {
        [Page.Workbench]: configuration[Page.Workbench.toLowerCase()],
        [Page.Planning]: configuration[Page.Planning.toLowerCase()],
        [Page.Landing]: true
      },
      powerBiUrl: configuration.powerBiUrl
    });
  }
  private async getConfiguration(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getConfiguration()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Configuration].endpoints[Configuration.All].endpoint
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpWriteAccessToApplication(): Promise<void> {
    const tag: string = `${this.tag}.setUpWriteAccessToApplication()`;
    const debug: boolean = this.debug || false;
    const allowWriteAccessToApplication: boolean = (await this.getWriteAccessToApplication())
      .body;
    if (debug)
      console.log(
        tag,
        "allowWriteAccessToApplication:",
        allowWriteAccessToApplication
      );
    this.state.allowWriteAccessToApplication = allowWriteAccessToApplication;
  }
  // [HttpGet("IsWriteAble")]
  private async getWriteAccessToApplication(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getWriteAccessToApplication()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Configuration].endpoints[Configuration.IsWriteable]
        .endpoint
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpAppliedAdjustments(): Promise<void> {
    const tag: string = `${this.tag}.setUpAppliedAdjustments()`;
    const debug: boolean = this.debug || false;
    const appliedAdjustmentsEnabled: boolean = (await this.getAppliedAdjustments())
      .body;
    if (this.debug)
      console.log(tag, "appliedAdjustmentsEnabled:", appliedAdjustmentsEnabled);
    this.state.appliedAdjustmentsEnabled = appliedAdjustmentsEnabled;
  }
  private async getAppliedAdjustments(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getAppliedAdjustments()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Configuration].endpoints[Configuration.AppliedAdjustments]
        .endpoint
    }/${ConfigurationAppliedAdjustments.IsEnabled}`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpPageVisibility(): Promise<void> {
    const tag: string = `${this.tag}.setUpPageVisibility()`;
    const debug: boolean = this.debug || false;
    const pageVisibility = (await this.getPageVisibility()).body;
    this.state.pageVisibility = {
      [Page.Workbench]: pageVisibility[Page.Workbench.toLowerCase()],
      [Page.Planning]: pageVisibility[Page.Planning.toLowerCase()],
      [Page.Landing]: true
    };
    if (this.debug)
      console.log(tag, "state.pageVisibility:", this.state.pageVisibility);
  }
  private async getPageVisibility(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getPageVisibility()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Configuration].endpoints[Configuration.PageVisibility]
        .endpoint
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpDateRestriction(): Promise<void> {
    const tag: string = `${this.tag}.setUpDateRestriction`;
    const debug: boolean = this.debug || false;
    const dateRestrictionDisabled: boolean = (await this.getDateRestriction())
      .body;
    if (this.debug)
      console.log(tag, "dateRestrictionDisabled:", dateRestrictionDisabled);
    this.state.dateRestrictionDisabled = dateRestrictionDisabled;
  }
  // [HttpGet("IsDisableTimeStamp")]
  private async getDateRestriction(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getDateRestriction()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Configuration].endpoints[Configuration.IsDisableTimeStamp]
        .endpoint
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.Configuration */

  /** Controller.Login */
  public async setUpOrganizationAccess(): Promise<void> {
    const tag: string = `${this.tag}.setUpOrganizationAccess()`;
    const debug: boolean = this.debug || false;
    const hasWriteAccess: boolean = (await this.getWriteAccess()).body;
    if (debug) console.log(tag, "hasWriteAccess:", hasWriteAccess);
    this.state.userHasWriteAccess = hasWriteAccess;
  }
  // [HttpGet("hasWriteAccess/{countryId}")]
  private async getWriteAccess({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getWriteAccess()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Login].endpoints[Login.HasWriteAccess].endpoint
    }/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpUserAdminAccess(): Promise<void> {
    const tag: string = `${this.tag}.setUpUserAdminAccess()`;
    const debug: boolean = this.debug || false;
    const hasAdminAccess: boolean = (await this.getHasAdminAccess()).body;
    if (debug) console.log(tag, "hasAdminAccess:", hasAdminAccess);
    this.state.userHasAdminAccess = hasAdminAccess;
  }
  // [HttpGet("hasAdminAccess")]
  private async getHasAdminAccess(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getHasAdminAccess()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Login].endpoints[Login.HasAdminAccess].endpoint
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpAccess(): Promise<void> {
    const tag: string = `${this.tag}.getHasAdminAccess()`;
    const debug: boolean = this.debug || false;
    if (this.debug) console.log(tag);

    await Promise.all([
      this.setUpWriteAccessToApplication(),
      this.setUpOrganizationAccess(),
      this.setUpUserAdminAccess()
    ]);
  }

  public async setUpUser(): Promise<void> {
    const tag: string = `${this.tag}.setUpUser()`;
    const debug: boolean = this.debug || false;
    const user: User = (await this.getUser()).body;
    if (debug) console.log(tag, "user:", user);
    this.state.user = user;
  }
  // [HttpGet("username")]
  public async getUser({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getUser()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Login].endpoints[Login.User].endpoint
    }/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpWriteableCountries(): Promise<void> {
    const tag: string = `${this.tag}.setUpWriteableCountries()`;
    const debug: boolean = this.debug || false;
    const countries: Country[] = (await this.getWriteableCountries()).body.map(
      country => new Country(country)
    );
    const countryContainer: CountryContainer = new CountryContainer(countries);
    if (debug) console.log(tag, "countryContainer:", countryContainer);
    this.setOrganizationEntity(countryContainer);
    if (this.state.organizationEntity) {
      this.state.currency = this.state.organizationEntity.currencyId;
    }
  }
  // [HttpGet("listWriteableCountries")]
  public async getWriteableCountries(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getWriteableCountries()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Login].endpoints[Login.ListWriteableCountries].endpoint
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.Login */

  /** Controller.LastAvailable */
  public async setUpLastAvailableMonth({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<void> {
    const tag: string = `${this.tag}.setUpLastAvailableMonth()`;
    const debug: boolean = this.debug || false;
    const lastAvailableMonth: string = (await this.getLastAvailableAsOf({
      organization
    })).body;
    if (debug) console.log(tag, "lastAvailableMonth:", lastAvailableMonth);
    let organizationLastAvailableMonth: Date = previousMonth(lastAvailableMonth);
    organizationLastAvailableMonth = nextMonth(organizationLastAvailableMonth);

    if (debug)
      console.log(
        tag,
        "organizationLastAvailableMonth:",
        organizationLastAvailableMonth
      );
    this.state.datePickerDate = this.state.organizationLastAvailableMonth = organizationLastAvailableMonth;
  }
  // [HttpGet("forecast/asOf/{countryId}")]
  public async getLastAvailableAsOf({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getLastAvailableAsOf()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast].endpoints[
        LastAvailableForecast.AsOf
      ].endpoint
    }/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  // [HttpGet("forecast/perMonth/{countryId}")]
  public async getLastAvailablePerMonth({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getLastAvailablePerMonth()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast].endpoints[
        LastAvailableForecast.PerMonth
      ].endpoint
    }/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.LastAvailable */

  /** Controller.KpiAggregated */

  // [HttpGet("composer/fy/{typeId}/{countryId}/{currencyId}/{asOfMonth}/{scenarioId?}")]
  public async getKpiAggregated({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    kpi,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    kpi: number;
    asOf?: string;
    id?: number;
  }): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getKpiAggregated() `;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.KpiAggregated].endpoints[KpiAggregated.Fy].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.KpiAggregated */

  /** Controller.KpiValues */
  // [HttpGet("{typeId}/{countryId}/{currencyId}/{asOf}")]
  public async getKpiValues({
    id = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf
  }: {
    id?: number;
    organization?: number;
    currency?: Currency;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getKpiValues()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.KpiValues].endpoint
    }/${id}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.KpiValues */

  /** Controller.Countries */
  // [HttpPost("ToggleCountryVisibility/{country:int}/{show:bool}")]
  public async toggleOrganizationAdditionalKpis(
    id: number
  ): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.toggleOrganizationAdditionalKpis()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Countries].endpoints[Countries.ToggleCountryVisibility]
        .endpoint
    }/${id}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  // [HttpPost("ToggleCountryDataSubmit/{country:int}/{show:bool}")]
  public async toggleOrganizationSubmit(
    id: number
  ): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.toggleOrganizationSubmit()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Countries].endpoints[Countries.ToggleCountryDataSubmit]
        .endpoint
    }/${id}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  public async setUpReadableCountries(): Promise<void> {
    const tag: string = `${this.tag}.setUpReadableCountries()`;
    const debug: boolean = this.debug || false;
    const countries: Country[] = (await this.getReadableCountries()).body.map(
      country => new Country(country)
    );
    const countryContainer: CountryContainer = new CountryContainer(countries);
    if (debug) console.log(tag, "countryContainer:", countryContainer);
    const formattedRegions: FormattedRegions = countryContainer.format();
    if (debug) console.log(tag, "formattedRegions:", formattedRegions);
    this.state.regions = formattedRegions;
    this.setOrganizationEntity(countryContainer);
  }
  // [HttpGet]
  private async getReadableCountries(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getReadableCountries()`;
    const debug: boolean = this.debug || false;
    const url: string = `${Api[Controller.Countries].endpoint}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  private setOrganizationEntity(countryContainer: CountryContainer): void {
    const tag: string = `${this.tag}.setOrganizationEntity()`;
    const debug: boolean = this.debug || false;

    if (countryContainer.countries.length) {
      const firstCountry: Country = countryContainer.countries[0];
      if (debug) console.log(tag, "firstCountry:", firstCountry);
      const region: FormattedRegion = CountryContainer.formatRegion(
        firstCountry
      );
      const country: FormattedCountry = CountryContainer.formatCountry(
        firstCountry
      );
      this.state.setOrganizationEntity(region, country);
    } else {
      const firstRegion: FormattedRegion = getValues(
        this.state.regions.items
      ).sort((a, b) => sortByString(a, b, "name"))[0];
      if (debug) console.log(tag, "firstRegion:", firstRegion);
      if (firstRegion) {
        const firstCountry: FormattedCountry = getValues(
          firstRegion.items
        ).sort((a, b) => sortByString(a, b, "code"))[0];
        if (debug) console.log(tag, "firstCountry:", firstCountry);
        if (firstCountry) {
          this.state.setOrganizationEntity(firstRegion, firstCountry);
        }
      }
    }
  }
  /** Controller.Countries */

  /** Controller.Flags */
  public async setUpFlags(): Promise<void> {
    const tag: string = `${this.tag}.setUpFlags()`;
    const debug: boolean = this.debug || false;
    const flags: Flag[] = (await this.getFlags()).body;
    if (debug) console.log(tag, "flags:", flags);
    this.flags = flags.reduce((flags, flag) => {
      flags[flag.id] = flag;
      return flags;
    }, {});
    if (debug) console.log(tag, "this.flags:", this.flags);
  }
  // [HttpGet("composer/api/flags")]
  private async getFlags(): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getFlags()`;
    const debug: boolean = this.debug || false;
    const url: string = `${Api[Controller.Flags].endpoint}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.Flags */

  public async submitAdjustments({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.submitAdjustments()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.Export].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async submitPlanningAdjustments({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.submitPlanningAdjustments()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.ExportExcel].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async commonReload(): Promise<void> {
    const tag: string = `${this.tag}.commonReload()`;
    const debug: boolean = this.debug || true;
    await Promise.all([
      // this.setUpLastModified(),
    ]);
  }

  // [HttpGet("{countryId}/{asOf}")]
  public getExcelFileUrl({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: { organization?: number; asOf?: string } = {}): string {
    const tag: string = `${this.tag}.getExcel()`;
    const debug: boolean = this.debug || false;
    const url: string = `${this.configuration.backendBaseUrl}/${
      Api[Controller.Excel].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return url;
  }

  public async uploadExcelFile(
    file: FormData,
    {
      organization = this.state.organizationEntity.id,
      asOf = this.state.datePickerDateAsOf
    }: { organization?: number; asOf?: string } = {}
  ): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.uploadExcelFile()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Excel].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url, file, this.request.httpFileOptions);
  }

  /* Controller.Notification*/
  public async setUpNotifications(): Promise<void> {
    const tag: string = `${this.tag}.setUpNotifications()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    const notificationContainer: NotificationContainer = new NotificationContainer(
      {
        notifications: (await this.getNotifications()).body
      }
    );
    if (debug)
      console.log(tag, "notificationContainer:", notificationContainer);
    this._notifications$.next(notificationContainer);
  }

  // [HttpGet("{countryId}/{asOf}")]
  private async getNotifications({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getWorkbenchStatusMessage()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Notification].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url", url);
    return this.request.get(url);
  }

  public async hasReadNotification({
    user = this.state.user.user.name,
    id
  }: {
    user?: string;
    id?: number;
  }): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.hasReadNotification()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "user", user);
    if (debug) console.log(tag, "id", id);
    const url: string = `${
      Api[Controller.Notification].endpoints[Notification.HasRead].endpoint
    }/${user}/${id}`;
    if (debug) console.log(tag, "url", url);
    return this.request.put(url);
  }
  /* Controller.Notification*/
}
