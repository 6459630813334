import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription, } from 'rxjs';
import { skip } from 'rxjs/operators';
import { StateService } from 'src/app/services/state/state.service';
import { ModalService } from 'src/app/services/modal.service';
import { Parameter } from 'src/app/api';
import { getValues, flatten, unique, unsubscribe, lowerCase, clone, } from 'src/app/utils';
import { Page } from 'src/app/constants';

export interface ParameterHandler {
  name: string;
  change: string[];
  requests: Function[];
}

const Debug: boolean = false;

@Injectable()
export class DataService implements OnInit, OnDestroy {
  protected static readonly Tag: string = 'DataService';

  private readonly observableMappings = {
    organization: 'organizationEntity',
  };

  protected tag: string;
  protected debug: boolean = false;
  protected page: Page;

  private handlerSubscriptions: Subscription[];

  private _subscriptions: Subscription[];
  protected get subscriptions(): Subscription[] {
    return this._subscriptions;
  }
  protected set subscriptions(subscriptions: Subscription[]) {
    if (this._subscriptions) this._subscriptions.forEach(unsubscribe);
    this._subscriptions = subscriptions;
  }

  constructor(
    protected readonly state: StateService,
    protected readonly modal: ModalService,
  ) { }

  public ngOnInit(): void {
    this.tag = `${this.tag}`;
  }

  protected async setUpHandlers(handlers: ParameterHandler[]): Promise<void> {
    const tag: string = `${this.tag}.setUpHandlers()`;
    const debug: boolean = Debug || this.debug || false;
    if (this.handlerSubscriptions) {
      this.handlerSubscriptions.forEach(unsubscribe);
    }

    const parameters: Parameter[] = getValues(Parameter);
    const parametersLowerCased: string[] = parameters.map(lowerCase);
    const parameterHandlers = parameters.map(parameter => {
      if (debug) console.log(tag, 'parameter:', parameter);
      const parameterHandlers: ParameterHandler[] = this.getParameterHandlers(handlers, parameter);
      if (debug) console.log(tag, 'parameterHandlers:', parameterHandlers);
      const requests: Function[] = unique(flatten(parameterHandlers.map(handler => handler.requests)));
      // if (debug) console.log(tag, 'requests:', requests);
      const parameterRequests = (...args) => Promise.all(requests.map(request => request(...args)));
      const onParameterChange: (...args) => Promise<void> = (async (...args): Promise<void> => {
        const innerTag: string = `${tag}.on${parameter}Change()`;
        // TODO: With bringing pack the page check here, it's probably safe to remove the
        // page checks in each of the data.service setUp-calls.
        if (debug) console.log(innerTag, 'state.page:', this.state.page);
        if (debug) console.log(innerTag, 'state.initialized:', this.state.initialized);
        if (debug) console.log(innerTag, 'state.resolved:', this.state.resolved);
        const pageTriggerable: boolean = this.state.page === this.page || this.isCommonDataService();
        if (debug) console.log(innerTag, 'pageTriggerable:', pageTriggerable);
        const triggerHandlers: boolean = pageTriggerable && this.state.initialized && this.state.resolved;
        if (debug) console.log(innerTag, 'triggerHandlers:', triggerHandlers);
        if (!triggerHandlers) return;

        if (debug) console.log(innerTag, 'args:', args);
        await this.preHandler(parameter, ...args);
        await parameterRequests(...args);
        await this.postHandler(parameter, ...args);
      }).bind(this);
      return onParameterChange;
    });

    this.handlerSubscriptions = parameterHandlers.map((handler, i) => {
      const parameter: string = parametersLowerCased[i];
      const mapping: string = this.observableMappings[parameter] || parameter;
      // if (debug) console.log(tag, 'mapping:', mapping);
      const observable: string = `${mapping}$`;
      // if (debug) console.log(tag, 'observable:', observable);
      // if (debug) console.log(tag, 'handler:', handler);
      return this.state[observable].pipe(skip(1)).subscribe(handler);
    });
  }

  private isCommonDataService(): boolean {
    return this.page === null;
  }

  private getParameterHandlers(handlers: ParameterHandler[], parameter: Parameter): ParameterHandler[] {
    const tag: string = `${this.tag}.getParameterHandlers()`;
    const debug: boolean = Debug || this.debug || false;
    const parameterHandlers: ParameterHandler[] = handlers.filter(handler => handler.change.includes(parameter));
    return parameterHandlers;
  }

  protected async preHandler(parameter: Parameter, args?): Promise<void> {
    const tag: string = `${this.tag}.preHandler()`;
    const debug: boolean = Debug || this.debug || false;
    if (debug) console.log(tag, 'parameter:', parameter);
    if (debug) console.log(tag, 'args:', args);

    // Re-fetch and re-set the scenario before triggering other change handlers.
    // TODO: Change to parameterSet.includes (get it from api.ts).
    const setUpScenarios = parameter === Parameter.Organization &&
      (this as any).setUpScenarios && (this as any).setUpScenarios();

    await Promise.all([
      setUpScenarios,
    ]);
  }

  protected async postHandler(parameter: Parameter, args?): Promise<void> {
    const tag: string = `${this.tag}.postHandler()`;
    const debug: boolean = Debug || this.debug || false;
    if (debug) console.log(tag, 'parameter:', parameter);
    if (debug) console.log(tag, 'args:', args);
    // TODO: callCount++?
    await Promise.all([
      this.state.page === Page.Workbench ? this.state.refetchScenarios() : true,
    ]);
  }

  public ngOnDestroy(): void {
    const tag: string = `${this.tag}.ngOnDestroy()`;
    const debug: boolean = Debug || this.debug || false;
    if (debug) console.log(tag, 'this.subscriptions:', this.subscriptions);

    this.subscriptions.forEach(unsubscribe);
  }
}
