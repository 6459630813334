
import { IScenario } from 'src/app/models/workbench/scenario';
import { IOrganizationEntity } from 'src/app/models/common/country';
import { Unit, Scale, UnitToLabel } from 'src/app/constants';
import { Currency } from 'src/app/types';
import { normalizeDate, getFormatter, localeDateMonthShortYearShort, createDate, getMonthQuarter, localeMonthShortYearFull } from 'src/app/utils';

export class AdjustmentHistoryContainer {
  private static readonly Tag = 'AdjustmentHistoryContainer';
  private static readonly Debug: boolean = false;

  public adjustmentHistory: AdjustmentHistory[];

  constructor({ adjustmentHistory }: { adjustmentHistory?: AdjustmentHistory[] } = {}) {
    this.adjustmentHistory = (adjustmentHistory || []).map(ah => new AdjustmentHistory(ah));
  }

  public displayFormat(scale: Scale, currency: Currency): AdjustmentHistory[] {
    const tag: string = `${AdjustmentHistoryContainer.Tag}.displayFormat()`;
    const debug: boolean = AdjustmentHistoryContainer.Debug || false;
    const formatted: AdjustmentHistory[] = this.adjustmentHistory.map(adjustmentHistory =>
      adjustmentHistory.displayFormat(scale, currency));
    if (debug) console.log(tag, 'formatted:', formatted);
    return formatted;
  }
}

export class AdjustmentHistory {
  private static readonly Tag = 'AdjustmentHistory';
  private static readonly Debug: boolean = false;

  public id: number;
  public scenarioId: number;
  public scenario: IScenario;
  public typeId: number;
  public unit: Unit;
  public commentId: number;
  public comment: Comment;
  public countryId: number;
  public country: IOrganizationEntity;
  public adjustedTime: Date;
  public label: string;
  public asOf: Date;
  public adjustments: Adjustment[];

  constructor({
    id, scenarioId, scenario, typeId, unit, commentId, comment,
    countryId, country, adjustedTime, label, asOf, adjustments,
  }: {
      id?: number, scenarioId?: number, scenario?: IScenario,
      typeId?: number, unit?: Unit, commentId?: number,
      comment?: Comment, countryId?: number, country?: IOrganizationEntity,
      adjustedTime?: Date, label?: string, asOf?: Date,
      adjustments?: Adjustment[],
    } = {}) {
    this.id = id;
    this.scenarioId = scenarioId;
    this.scenario = scenario;
    this.typeId = typeId;
    this.unit = unit;
    this.commentId = commentId;
    this.comment = comment;
    this.countryId = countryId;
    this.country = country;
    this.adjustedTime = createDate(adjustedTime);
    this.label = label;
    this.asOf = normalizeDate(asOf);
    this.adjustments = (adjustments || []).map(a => new Adjustment(a));
  }

  public displayFormat(scale: Scale, currency: Currency): AdjustmentHistory {
    const tag: string = `${AdjustmentHistory.Tag}.displayFormat()`;
    const debug: boolean = AdjustmentHistory.Debug || false;

    const formattedAdjustmentHistory: AdjustmentHistory = new AdjustmentHistory(this);

    const format: Function = getFormatter(this.unit);
    if (debug) console.log(tag, 'format:', format);

    const displayUnit: string = `${
      this.unit === Unit.Currency ? scale || 'm' : ''}${
      this.unit === Unit.Currency ? Currency[currency] : UnitToLabel[this.unit]
      }`;
    if (debug) console.log(tag, 'displayUnit:', displayUnit);

    const formatted: AdjustmentHistory = Object.assign(formattedAdjustmentHistory, {
      displayUnit: (displayUnit === UnitToLabel[Unit.Percentage]) ? '' : displayUnit,
      displayDate: localeDateMonthShortYearShort(this.adjustedTime),
      adjustments: this.adjustments.map(adjustment => Object.assign({}, adjustment, {
        displayDate: (adjustment.isCurrencySplit) ? localeMonthShortYearFull(adjustment.perMonth) : `Q${getMonthQuarter(adjustment.perMonth)} - ${adjustment.perMonth.getFullYear()}`,
        previousValue: format(adjustment.previousValue, scale, null, debug),
        adjustedValue: format(adjustment.adjustedValue, scale, null, debug),
      })),
    });
    if (debug) console.log(tag, 'formatted:', formatted);
    return formatted;
  }
}

export class Adjustment {
  public perMonth: Date;
  public previousValue: number;
  public adjustedValue: number;
  public isCurrencySplit: boolean;

  constructor({
    previousValue, adjustedValue, perMonth, isCurrencySplit
  }: {
    perMonth?: Date, previousValue?: number, adjustedValue?: number, isCurrencySplit?: boolean,
    } = {}) {
    this.perMonth = normalizeDate(perMonth);
    this.previousValue = previousValue;
    this.adjustedValue = adjustedValue;
    this.isCurrencySplit = isCurrencySplit;
  }
}

export class Comment {
  id: number;
  createdOn: Date;
  message: string;
  username: string;
}
