import { Component, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'hamburgerButton',
  templateUrl: 'hamburgerButton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HamburgerButtonComponent extends BaseComponent {
  private static readonly Tag: string = 'HamburgerButtonComponent';

  constructor(
    public readonly state: StateService,
    private readonly window: WindowService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = HamburgerButtonComponent.Tag;
  }

  public onHamburgerBtnTap(): void {
    const tag: string = `${this.tag}.onHamburgerBtnTap()`;
    if (this.debug) console.log(tag);
    this.window.isMenuExpanded = true;
  }
}
