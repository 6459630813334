export enum Currency {
  LC = 1,
  EUR = 2,
}

export interface Organization {
  id: number;
  name: string;
  items: any;
}

export interface Month {
  date: string;
  label: string;
  available?: boolean;
  selected?: boolean;
  preselected?: boolean;
  color?: string;
}