import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';
import { Default } from 'src/app/services/state/defaults';
import { CommonDataService } from 'src/app/services/data/common.data.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RouterService } from 'src/app/services/router.service';
import { ModalService } from 'src/app/services/modal.service';
import { Page } from 'src/app/constants';
import { capital } from 'src/app/utils';

@Injectable()
export class PageGuard implements CanActivate {
  private static readonly Tag: string = 'PageGuard';
  private readonly tag: string = PageGuard.Tag;
  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    public readonly state: StateService,
    private readonly data: CommonDataService,
    private readonly router: RouterService,
    private readonly modal: ModalService,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const tag: string = `${this.tag}.canActivate()`;
    const debug: boolean = this.debug || false;
    const page: Page = capital(route.routeConfig.path) as Page;
    if (debug) console.log(tag, 'page:', page);

    if (page === Page.Login) return true;

    await this.data.setUpConfiguration();
    if (debug) console.log(tag, 'state.pageVisibility:', this.state.pageVisibility);
    const isPageVisible: boolean = this.state.pageVisibility[page];
    if (debug) console.log(tag, 'isPageVisible:', isPageVisible);
    if (isPageVisible) return true;

    const isDefaultPageVisible: boolean = this.state.pageVisibility[Default.Page];
    if (debug) console.log(tag, 'isDefaultPageVisible:', isDefaultPageVisible);
    if (isDefaultPageVisible) {
      this.router.goTo(Default.Page);
      return false;
    }

    const firstVisiblePage: Page = Object.keys(this.state.pageVisibility).find(page => this.state.pageVisibility[page]) as Page;
    if (debug) console.log(tag, 'firstVisiblePage:', firstVisiblePage);

    if (firstVisiblePage) {
      this.router.goTo(firstVisiblePage);
    } else {
      this.modal.setModal({
        title: 'No Pages Enabled',
        text: 'No pages for the application have been enabled. If you\'re the site\'s admin, head to the admin page.',
        continueBtnLabel: 'Ok',
      });
    }

    return false;
  }
}

