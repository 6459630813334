import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList
} from "@angular/core";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { Values, Kpi } from "src/app/models/common/kpiValues";
import { OrganizationEntity, StateService } from "src/app/services/state/state.service";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { WorkbenchResolver } from "src/app/resolvers/workbench.resolver";
import {
  KpiValuesQuarterContainer,
  KpiValuesQuarter
} from "src/app/models/workbench/kpiValuesQuarter";
import {
  localeMonthShortYearFull,
  isNumber,
  normalizeDate,
  ltDate,
  dateToMonthPickerStr,
  removeCommaSeparatorNum,
  removeExtraSigns,
  getUsedKpiValue,
  getFormatter,
  isDecember
} from "src/app/utils";
import {
  KpiValue,
  NoValue,
  AdjustmentTab,
  Unit,
  Precision,
  Scale,
  DataTableInputFieldCount,
  DataTableMonthsInputFieldCount,
  TopLevelKpi
} from "src/app/constants";
import { Adjustment } from "src/app/constants";
import { browser } from "src/app/app.component";
import { KpiValuesYear } from 'src/app/models/workbench/kpiValuesYear';
import { INavigationKpiTree } from "src/app/models/workbench/navigation";

@Component({
  selector: "dataTable",
  templateUrl: "dataTable.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class DataTableComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "DataTableComponent";
  protected readonly tag: string = DataTableComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  @ViewChildren("inputs") private readonly inputs: QueryList<HTMLElement>;

  public quarters: KpiValuesQuarter[] = [];
  public kpiValues: KpiValuesYear[] = [];
  public nextYear: KpiValuesQuarter;
  public isReadonly: boolean = true;
  public disableWrite: boolean;
  public salesDisable: boolean = false;
  public acquisitionsEnable: boolean = true;
  private lastFocus: number;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
    private readonly resolver: WorkbenchResolver
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.observables = [this.state.isReadonly$];

    this.subscriptions = [
      this.state.anyParameterChange$.subscribe(this.resetLastFocus.bind(this)),
      this.workbench.scenario$.subscribe(this.onScenarioChange.bind(this)),
      this.state.organizationEntity$.subscribe(this.onOrganizationChange.bind(this)),
      this.state.kpi1$.subscribe(this.onKpi1Change.bind(this)),
      this.state.kpi2$.subscribe(this.onKpi2Change.bind(this)),
    ];
  }

  private resetLastFocus(): void {
    const tag: string = `${this.tag}.resetLastFocus()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.lastFocus = null;
  }

  private reselectInput(): void {
    const tag: string = `${this.tag}.reselectInput()`;
    const debug: boolean = this.debug || false;
    if (!this.lastFocus) return;

    const el: HTMLElement = this.inputs.toArray()[this.lastFocus];
    if (debug) console.log(tag, "el:", el);
    if (!el) return;

    (el as any).nativeElement.select();
  }

  private onOrganizationChange(organization: OrganizationEntity): void {
    this.disableWrite = (organization.disableDataWrite && !this.state.userHasAdminAccess);
  } 

  private onKpi1Change(kpi1: INavigationKpiTree): void {
    var kpi1Label = kpi1 == null ? '' : kpi1?.kpi?.label;
    this.salesDisable = (this.state.kpi0?.kpi.label === 'Sales & Acquisitions' && kpi1Label === 'Sales') && !this.state.userHasAdminAccess;
  }

  private onKpi2Change(kpi2: INavigationKpiTree): void {
    var kpi1Label = this.state.kpi1 == null ? '' : this.state.kpi1?.kpi?.label;
    this.acquisitionsEnable = this.state.kpi0?.kpi.label === 'Sales & Acquisitions' && kpi1Label === 'Acquisitions' && kpi2 != null;
  }

  private onScenarioChange(quarterContainer: KpiValuesQuarterContainer): void {
    const tag: string = `${this.tag}.onScenarioChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    if (!quarterContainer) return;

    this.nextYear = this.formatQuarter(quarterContainer.nextYearValue);
    const quarters: KpiValuesQuarter[] = this.formatQuarters(quarterContainer);
    quarters.length = DataTableInputFieldCount;
    if (debug) console.log(tag, "quarters:", quarters);
    Object.assign(this, {
      quarters
    });
    this.kpiValues = [];
    while(this.kpiValues.length < 2) {
      this.kpiValues.push(new KpiValuesYear());
    }
    let index = 0;
    quarters.forEach(quarter => {
      quarter.kpiValues.forEach(kpiValue => {
        if (this.kpiValues[index].kpiValues.length >= DataTableMonthsInputFieldCount) {
          index += 1;
        }
        this.kpiValues[index].kpiValues.push(kpiValue);
        if(this.kpiValues[index].year == null) {
          this.kpiValues[index].year = normalizeDate(kpiValue.date).getFullYear();
        }
      });
    });
    this.setLoadingStatus();
    setTimeout(() => this.reselectInput());
  }

  private formatQuarters(
    quarterContainer: KpiValuesQuarterContainer
  ): KpiValuesQuarter[] {
    const tag: string = `${this.tag}.formatQuarters()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    const formatQuarter = this.formatQuarter.bind(this);
    const quarters: KpiValuesQuarter[] = [
      // Object.assign({}, quarterContainer.previousQuarter, {
      //   isActual: true,
      //   kpiValues: quarterContainer.previousQuarter.kpiValues.slice(-1),
      // }),
      ...quarterContainer.values
    ].map(formatQuarter);

    return quarters;
  }

  public formatQuarter(quarter: KpiValuesQuarter): KpiValuesQuarter {
    const tag: string = `${this.tag}.formatQuarter()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarter:", quarter);

    const lastKpi: Kpi = quarter.kpiValues[quarter.kpiValues.length - 1];
    const date: Date = (lastKpi.date && normalizeDate(lastKpi.date)) || null;

    const values: Values = quarter.quarterValue;

    const isActual: boolean = isNumber(values[KpiValue.Actual]);
    const isAdjusted: boolean = isNumber(values[KpiValue.Adjusted]);
    const isOrganization: boolean = isNumber(values[KpiValue.Organization]);
    const isMachine: boolean = isNumber(values[KpiValue.Machine]);
    const hasValue: boolean =
      isActual || isAdjusted || isOrganization || isMachine;

    const scale: Scale = null;
    if (debug) console.log(tag, "state.unit:", this.state.unit);
    const format: Function = getFormatter(this.state.unit, false);
    if (debug) console.log(tag, "format:", format);

    const precision: number = this.state.unit === Unit.Currency ? Precision : 0;
    if (debug) console.log(tag, "precision:", precision);

    const quarterValue = {
      [KpiValue.Actual]: isActual
        ? format(values[KpiValue.Actual], scale, precision)
        : null,
      [KpiValue.Adjusted]: isAdjusted
        ? format(values[KpiValue.Adjusted], scale, precision)
        : null,
      [KpiValue.Organization]: isOrganization
        ? format(values[KpiValue.Organization], scale, precision)
        : null,
      [KpiValue.Machine]: isMachine
        ? format(values[KpiValue.Machine], scale, precision)
        : null
    };
    if (debug) console.log(tag, "quarterValue:", quarterValue);

    const prevValue: string = isActual
      ? quarterValue[KpiValue.Actual]
      : isAdjusted
      ? quarterValue[KpiValue.Adjusted]
      : isOrganization
      ? quarterValue[KpiValue.Organization]
      : quarterValue[KpiValue.Machine];
    if (debug) console.log(tag, "prevValue:", prevValue);

    const formattedQuarter: KpiValuesQuarter = Object.assign({}, quarter, {
      // In case actual value is missing.
      isActual: isActual || (date && ltDate(date, this.state.datePickerDate)),
      isAdjusted,
      isOrganization: !isAdjusted && isOrganization,
      isMachine: !isAdjusted && !isOrganization, // && isMachine || !hasValue,
      hasValue,
      prevValue,
      quarterValue,
      kpiValues: quarter.kpiValues.map(kpi => {
        const date: Date = (kpi.date && normalizeDate(kpi.date)) || null;

        const values: Values = kpi.values;

        const isActual: boolean = isNumber(values[KpiValue.Actual]);
        const isAdjusted: boolean = isNumber(values[KpiValue.Adjusted]);
        const isOrganization: boolean = isNumber(values[KpiValue.Organization]);
        const isMachine: boolean = isNumber(values[KpiValue.Machine]);
        const hasValue: boolean =
          isActual || isAdjusted || isOrganization || isMachine;

        return Object.assign({}, kpi, {
          label: (date && localeMonthShortYearFull(date)) || "---",
          // In case actual value is missing.
          isActual:
            isActual || (date && ltDate(date, this.state.datePickerDate)),
          isAdjusted,
          isOrganization: !isAdjusted && isOrganization,
          isMachine: !isAdjusted && !isOrganization, // && isMachine || !hasValue,
          hasValue,
          values: {
            [KpiValue.Actual]: isActual
              ? format(values[KpiValue.Actual], scale, precision)
              : NoValue,
            [KpiValue.Adjusted]: isAdjusted
              ? format(values[KpiValue.Adjusted], scale, precision)
              : NoValue,
            [KpiValue.Organization]: isOrganization
              ? format(values[KpiValue.Organization], scale, precision)
              : NoValue,
            [KpiValue.Machine]: isMachine
              ? format(values[KpiValue.Machine], scale, precision)
              : NoValue
          }
        });
      })
    });
    return formattedQuarter;
  }

  public async onAdjustmentPaste(
    index: number,
    indexSecond: number,
    event: any
  ): Promise<void> {
    const tag: string = `${this.tag}.onAdjustmentPaste()`;
    const debug: boolean = this.debugInputEvents || false;
    event.preventDefault();

    const content: string = window["clipboardData"]
      ? window["clipboardData"].getData("text").toString()
      : event.clipboardData.getData("text/plain");
    if (debug) console.log(tag, "content:", content);

    const dataFromClipboard: string[] = content
      .replace(/\t/g, "\r\n")
      .split("\r\n")
      .filter(e => e !== "");
    if (debug) console.log(tag, "dataFromClipboard:", dataFromClipboard);

    let j = 0;
    for (let i = 0; dataFromClipboard.length > i; i++) {
      if (i + index >= DataTableMonthsInputFieldCount) {
        indexSecond += 1;
        index = 0;
        j = 0;
      }
      if (i > (DataTableInputFieldCount * 3)) {
        break;
      }

      const selectedMonth: Kpi = this.kpiValues[indexSecond].kpiValues[index + j];
      this.kpiValues[indexSecond].kpiValues[index + j].values[getUsedKpiValue(selectedMonth)] = dataFromClipboard[i];
      await this.workbench.postAdditionalKpiAdjustment(
        {
          id: this.kpiValues[indexSecond].kpiValues[index + j].id, 
          value: removeCommaSeparatorNum(removeExtraSigns(dataFromClipboard[i])) 
        });
      j++;
    }
    event.target.blur();
    if (!this.state.BulkAdjustment) {
      await this.resolver.load();
    }
  }

  public onAdjustmentClick(kpi: Kpi): void {
    const tag: string = `${this.tag}.onAdjustmentClick()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "kpi:", kpi);
  }

  public onAdjustmentFocus(index: number, secondIndex: number): void {
    const tag: string = `${this.tag}.onAdjustmentFocus()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "index:", index);
    let x = secondIndex > 0 ? 12 : 0;
    this.lastFocus = index + x;
  }

  public onAdjustmentKeyPress(value: string, index: number, secondIndex: number, event): boolean {
    const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "value:", value);

    event = event || window.event;
    const charCode =
      typeof event.which == "undefined" ? event.keyCode : event.which;
    const char: string = String.fromCharCode(charCode);
    if (debug) console.log(tag, "char:", char);
    const isValidSymbol: boolean = ["-", ",", "."].includes(char);
    if (debug) console.log(tag, "isValidSymbol:", isValidSymbol);
    let x = secondIndex > 0 ? 12 : 0;
    const input = (this.inputs.toArray()[index+x] as any).nativeElement;
    if (!isNumber(char) && !isValidSymbol) return false;
    return true;
  }

  public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
    const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "value:", value);
    if (debug) console.log(tag, "kpi:", kpi);

    if (!this.state.unsavedAdjustments) {
      const machine: string = kpi.values[KpiValue.Machine];
      const isMachine: boolean = isNumber(machine);
      const editedMachine: boolean = isMachine && machine != value;

      const organization: string = kpi.values[KpiValue.Organization];
      const isOrganization: boolean = isNumber(organization);
      const editedOrganization: boolean =
        isOrganization && organization != value;

      const newValue: boolean = !(editedMachine || editedOrganization);

      if (editedMachine || editedOrganization || newValue) {
        this.state.unsavedAdjustmentsMap[AdjustmentTab.Workbench] = true;
      }
    }
    if (debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  public async onAdjustmentChange(
    kpi: Kpi,
    value: string = null
  ): Promise<void> {
    const tag: string = `${this.tag}.onAdjustmentChange()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "value:", value);
    const date: Date = normalizeDate(kpi.date);
    if (debug) console.log(tag, "date:", date);
    const perMonth: string = dateToMonthPickerStr(date);
    if (debug) console.log(tag, "perMonth:", perMonth);

    const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
    if (debug) console.log(tag, "formattedValue:", formattedValue);

    let adjustment: any = { id: kpi.id, value: formattedValue };
    await this.workbench.postAdditionalKpiAdjustment(adjustment);
    if (!this.state.BulkAdjustment) {
      await this.resolver.load();
    }
    if (this.debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  public async onAdjustmentNextYearChange(
    kpi: KpiValuesQuarter,
    value: string = null
  ): Promise<void> {
    const tag: string = `${this.tag}.onAdjustmentNextYearChange()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "value:", value);
    const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
    if (debug) console.log(tag, "formattedValue:", formattedValue);

    if (this.state.topLevelKpi == TopLevelKpi.Portfolio) {
      let lastYear = this.kpiValues.find(k => k.year < kpi.year);
      let lastDec = lastYear.kpiValues.find(k => isDecember(normalizeDate(k.date)) == true )
      let lastDecValue = removeCommaSeparatorNum(removeExtraSigns(lastDec.values[getUsedKpiValue(lastDec)]));
      let delta = parseFloat(formattedValue.toString()) - parseFloat(lastDecValue.toString());
      let deltaSplit = delta / 12;
      let i = 1;
      kpi.kpiValues.forEach(async (element) => {
        let adjustment: any = { id: element.id, value: parseFloat(lastDecValue.toString()) + (deltaSplit * i) };
        i++;
        await this.workbench.postAdditionalKpiAdjustment(adjustment);
      });
    }
    else {
      kpi.kpiValues.forEach(async (element) => {
        let adjustment: any = { id: element.id, value: parseFloat(formattedValue.toString()) / 12 };
        await this.workbench.postAdditionalKpiAdjustment(adjustment);
      });
    }

    await this.resolver.load();
 
    if (this.debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  public async onKeyUpEnter(
    kpi: Kpi,
    oldValue: string,
    value: string
  ): Promise<void> {
    const tag: string = `${this.tag}.onKeyUpEnter()`;
    const debug: boolean = this.debugInputEvents || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "value:", value);
    if (browser.name === "ie") await this.onAdjustmentChange(kpi, value);
  }

  public onOffClick(): void {
    const tag: string = `${this.tag}.onOffClick()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.resetLastFocus();
  }

  public getTabIndex(index: number, secondIndex: number) {
    let x = secondIndex > 0 ? 12 : 0;
    return index + x;
  }

  public readonly getUsedKpiValue = getUsedKpiValue;
  public readonly Unit = Unit;
  public readonly DataTableMonthsInputFieldCount = DataTableMonthsInputFieldCount;
  public readonly normalizeDate = normalizeDate;
  public readonly TopLevelKpi = TopLevelKpi;
}
