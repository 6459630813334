import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { AggregatedKpi } from "src/app/models/common/kpiAggregated";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { FyForecastMap } from "src/app/services/data/common.data.service";
import { StateService } from "src/app/services/state/state.service";
import {
  getYearShort,
  localeMonthShortYearShort,
  isNumber,
  array,
  isAfterMarch,
  flatten,
  getScale,
  isJanuary,
  previousMonth
} from "src/app/utils";
import {
  TopLevelKpi,
  YearToDateAcronym,
  ForYearAcronym,
  Scale,
  Unit,
  ScaleCountries
} from "src/app/constants";

@Component({
  selector: "workbenchSubKpiFyForecast",
  templateUrl: "workbenchSubKpiFyForecast.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "vclLayoutFlex"
  }
})
export class WorkbenchSubKpiFyForecastComponent extends BaseComponent
  implements OnInit {
  private static readonly Tag: string = "WorkbenchSubKpiFyForecastComponent";
  protected readonly tag: string = WorkbenchSubKpiFyForecastComponent.Tag;
  protected readonly debug: boolean = false;

  @Input() public readonly topLevelKpi: TopLevelKpi = TopLevelKpi.Portfolio;

  public fyForecasts: AggregatedKpi[] = [];

  public isAfterMarch: boolean = false;
  public isJanuary: boolean = false;
  public yaxis: string[] = [];

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService
  ) {
    super(cd, state);
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.workbench.subKpiFyForecast$.subscribe(
        this.onSubKpiFyForecastChange.bind(this)
      ),
      this.state.datePickerDate$.subscribe(
        this.onDatePickerDateChange.bind(this)
      )
    ];
  }

  public onSubKpiFyForecastChange(fyForecastMap: FyForecastMap): void {
    const tag: string = `${this.tag}.onSubKpiFyForecastChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "fyForecastMap:", fyForecastMap);
    const FyForecastYears: number = 2;
    const SubKpiCount: number = 3;

    const fyForecast: AggregatedKpi[] = fyForecastMap[this.topLevelKpi];

    let scale: Scale = Scale.Million;
    if (
      ScaleCountries.some(country => this.state.country.name.includes(country))
    ) {
      const values: number[] = flatten(
        fyForecast
          .filter(f => f.unit === Unit.Currency)
          .map(f => f.listValues())
      );
      if (debug) console.log(tag, "values:", values);
      scale = getScale(values);
    }
    if (debug) console.log(tag, "scale:", scale);

    let fyForecasts: AggregatedKpi[] = fyForecast.map(fyForecast =>
      fyForecast.displayFormat(
        this.state.unitToLabel(fyForecast.unit),
        true,
        scale
      )
    );
    if (debug) console.log(tag, "fyForecasts:", fyForecasts);

    fyForecasts = [
      ...fyForecasts,
      ...(fyForecasts.length < SubKpiCount
        ? array(SubKpiCount - fyForecasts.length).map(
            _ => ({} as AggregatedKpi)
          )
        : [])
    ];
    if (debug) console.log(tag, "fyForecasts:", fyForecasts);

    const dateYearShort: number = getYearShort(
      previousMonth(this.state.datePickerDate)
    );
    const dateLocaleShort: string = localeMonthShortYearShort(
      previousMonth(this.state.datePickerDate)
    );
    const yaxis: string[] = [
      `${YearToDateAcronym} ${dateLocaleShort}`,
      ...array(FyForecastYears).map(
        (_, i) =>
          `${ForYearAcronym}${dateYearShort +
            i +
            (isJanuary(this.state.datePickerDate) ? 1 : 0)}`
      )
    ];
    if (debug) console.log(tag, "yaxis:", yaxis);

    Object.assign(this, {
      fyForecasts,
      yaxis
    });
    this.setLoadingStatus();
  }

  public onDatePickerDateChange(date: Date): void {
    const tag: string = `${this.tag}.onDatePickerDateChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "date:", date);
    const afterMarch: boolean = isAfterMarch(date);
    if (debug) console.log(tag, "isAfterMarch:", afterMarch);
    this.isAfterMarch = afterMarch;
    const isNowJanuary: boolean = isJanuary(date);
    if (debug) console.log(tag, "isJanuary:", isNowJanuary);
    this.isJanuary = isNowJanuary;
  }
}
