import * as merge from 'merge';
import { Font } from 'src/app/plotting/interfaces';
import { Color } from 'src/styles/color';
import { Size } from 'src/styles/size';

// TODO: Import font-sizes.
export class Fonts {
  static Dfs = ({ color = Color.Text }: Font = {}) => ({
    color,
    size: Size.Font.Plot.Normal,
    family: 'Daimler CS Regular',
  })

  static Smaller = (options?: Font): Font =>
    merge.recursive(Fonts.Dfs(options), {
      size: Size.Font.Plot.Smaller,
    })

  static Larger = (options?: Font): Font =>
    merge.recursive(Fonts.Dfs(options), {
      size: Size.Font.Plot.Larger,
    })

  static Large = (options?: Font): Font =>
    merge.recursive(Fonts.Dfs(options), {
      size: Size.Font.Plot.Large,
    })
}
