import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { ForYearAcronym, KpiValue, Adjustment, AdjustmentTab, Unit, Scale, Precision } from 'src/app/constants';
import {
  isNumber, removeCommaSeparatorNum, removeExtraSigns, getPlanningKpiValue, isAdjustedRemoveComma, dateToMonthPickerStr, createDate, sortAdjustments, getFormatter,
} from 'src/app/utils';
import { Kpi, Values } from 'src/app/models/common/kpiValues';
import { browser } from 'src/app/app.component';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';
import { IPlanning, IPlanEquity } from 'src/app/models/planning/planning';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';

@Component({
  selector: 'equity',
  templateUrl: 'equity.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class EquityComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'EquityComponent';
  protected readonly tag: string = EquityComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  public adjustments: Adjustment[] = [];
  public planningData: IPlanEquity = null;

  constructor(
      protected readonly cd: ChangeDetectorRef,
      public readonly state: StateService,
      private readonly planning: PlanningDataService,
      private readonly workbench: WorkbenchDataService,
  ) {
      super(cd, state);
  }

  public ngOnInit(): void {
      super.ngOnInit();

      const tag: string = `${this.tag}.ngOnInit()`;
      if (this.debug) console.log(tag);

      this.subscriptions = [
          this.planning.equity$.subscribe(this.onPlanningDataChange.bind(this))
      ];
  }

  private onPlanningDataChange(data: IPlanEquity): void {
      const tag: string = `${this.tag}.onPlanningDataChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'data', data);
      if (!data) return;
      let equity = data;
      equity.equity.forEach(kpi => kpi = this.displayFormat(kpi, Scale.Million, false));
      this.planningData = equity;
      this.adjustments = this.getPlanningKpiValues(equity, true)
      .filter(kpi => isAdjustedRemoveComma(kpi) && kpi.id)
      .sort(sortAdjustments)
      .reverse()
      .map(kpi => ({
          forecastId: kpi.id,
          perMonth: dateToMonthPickerStr(createDate(kpi.date)),
          adjustValue: removeCommaSeparatorNum(removeExtraSigns(kpi.values[KpiValue.Adjusted])),
      }));
      if (debug) console.log(tag, 'adjustments:', this.adjustments);
      this.setUnsavedAdjustments();
      this.setLoadingStatus();
  }

  public onAdjustmentClick(kpi: Kpi, value: number): void {
      const tag: string = `${this.tag}.onAdjustmentClick()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
  }

  public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
      const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'value:', value);

      event = event || window.event;
      const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
      const char: string = String.fromCharCode(charCode);
      if (debug) console.log(tag, 'char:', char);
      const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
      if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

      if (!isNumber(char) && !isValidSymbol) return false;
      return true;
  }

  public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
      const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);

      if (!this.state.unsavedAdjustments) {
          const machine: string = kpi.values[KpiValue.Machine];
          const isMachine: boolean = isNumber(machine);
          const editedMachine: boolean = isMachine && machine != value;

          const organization: string = kpi.values[KpiValue.Organization];
          const isOrganization: boolean = isNumber(organization);
          const editedOrganization: boolean = isOrganization && organization != value;

          const newValue: boolean = !(editedMachine || editedOrganization);

          if (editedMachine || editedOrganization || newValue) {
              this.state.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi] = true;
          }
      }
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public async onKeyUpEnter(kpi: Kpi, value: string): Promise<void> {
      const tag: string = `${this.tag}.onKeyUpEnter()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      if (browser.name === 'ie') await this.onAdjustmentChange(kpi, value);
  }

  public async onAdjustmentChange(kpi: Kpi, value: string = null, incorrectPercentage: boolean = false): Promise<void> {
    const tag: string = `${this.tag}.onAdjustmentChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'kpi:', kpi);
    if (debug) console.log(tag, 'value:', value);
    let formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
    if (incorrectPercentage && kpi.unit == Unit.Percentage) {
        formattedValue = Number(formattedValue) * 0.01;
    }
    if (debug) console.log(tag, 'formattedValue:', formattedValue);
    await this.workbench.postAdditionalKpiAdjustment({ id: kpi.id, value: formattedValue });
    await this.reload();
    if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public displayUnit(unit: Unit): string {
    switch (unit) {
        case Unit.Currency:
            return "mil (LC)";
        case Unit.Percentage:
            return "%";
        case Unit.Unit:
            return "u";
        case Unit.FullTimeEquivalent:
            return "fte";
        default:
            return "mil (LC)";
    }
  }

  private clearAdjustments(): void {
      const tag: string = `${this.tag}.clearAdjustments()`;
      if (this.debug) console.log(tag);
      this.adjustments = [];
      this.setUnsavedAdjustments();
  }

  private getPlanningKpiValues(kpis: IPlanEquity, onlyAdjustable: boolean = false): Kpi[] {
    const tag: string = `${this.tag}.getPlanningKpiValues()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'kpis:', kpis);
    if (debug) console.log(tag, 'onlyAdjustable:', onlyAdjustable);
    let result: Kpi[] = [];
    if (kpis.equity != null ) {
        kpis.equity.forEach(kpi => {
            if (!onlyAdjustable || (onlyAdjustable && kpi.isAdjustable)) {
                result.push(kpi.endOfYear);
            }
        });
    }
    return result;
  }

  private async reload() {
      const tag: string = `${this.tag}.reload()`;
      if (this.debug) console.log(tag);
      await Promise.all([
          this.planning.setUpEquity(),
      ]);
  }

  private setUnsavedAdjustments(): void {
      const tag: string = `${this.tag}.setUnsavedAdjustments()`;
      if (this.debug) console.log(tag);
      this.state.unsavedAdjustmentsMap[AdjustmentTab.Planning] = this.adjustments.length > 0;
  }

  private displayFormat(data: IPlanning, scale: Scale, correctPercentage: boolean = true): IPlanning {
    if (correctPercentage == false && data.isAdjustable == false) correctPercentage = true;
    const format: Function = getFormatter(data.unit, true, correctPercentage);
    const precision: number = data.unit === Unit.Currency || Unit.FullTimeEquivalent ? Precision : 0;
    let formattedData = new Object(data) as IPlanning;
    const values: Values = formattedData.endOfYear.values;
    const isActual: boolean = isNumber(values[KpiValue.Actual]);
    const isAdjusted: boolean = isNumber(values[KpiValue.Adjusted]);
    const isOrganization: boolean = isNumber(values[KpiValue.Organization]);
    const isMachine: boolean = isNumber(values[KpiValue.Machine]);
    const hasValue: boolean = isActual || isAdjusted || isOrganization || isMachine;
    Object.assign(formattedData.endOfYear.values, {
        isActual,
        isAdjusted,
        isOrganization: !isAdjusted && isOrganization,
        isMachine: !isAdjusted && !isOrganization,
        hasValue,
        values: {
            [KpiValue.Actual]: isActual ? format(values[KpiValue.Actual], scale, precision) : null,
            [KpiValue.Adjusted]: isAdjusted ? format(values[KpiValue.Adjusted], scale, precision) : null,
            [KpiValue.Organization]: isOrganization ? format(values[KpiValue.Organization], scale, precision) : null,
            [KpiValue.Machine]: isMachine ? format(values[KpiValue.Machine], scale, precision) : null
        }
    });
    return formattedData;
  }

  public readonly ForYearAcronym = ForYearAcronym;
  public readonly KpiValue = KpiValue;
  public readonly getPlanningKpiValue = getPlanningKpiValue;
}
