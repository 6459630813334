import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { Page } from 'src/app/constants';

@Component({
  selector: 'bottomBarMiddleBar',
  templateUrl: './bottomBarMiddleBar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'vclLayoutFlex'
  }
})
export class BottomBarMiddleBarComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'BottomBarMiddleBarComponent';

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = BottomBarMiddleBarComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.excelImportEnabled$,
    ];
  }

  public readonly Page = Page;
}
