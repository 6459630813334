import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { StateService } from '../services/state/state.service';
import { ConfigurationService } from '../services/configuration.service';

/** Currently not used. **/
@Injectable()
export class UnsavedAdjustmentsGuard implements CanDeactivate<any> {
  private static readonly Tag: string = 'UnsavedAdjustmentsGuard';
  private readonly tag: string = UnsavedAdjustmentsGuard.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    private readonly modal: ModalService,
    public readonly state: StateService,
  ) { }

  public async canDeactivate(component: any): Promise<boolean> {
    const tag: string = `${this.tag}.canDeactivate()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'state.unsavedAdjustments:', this.state.unsavedAdjustments);

    if (this.state.unsavedAdjustments) {
      return new Promise<boolean>(resolve =>
        this.modal.unsavedAdjustments(() => {

          // TODO: UndoAll adjustments.

          resolve(true);
        }, () => resolve(false)));
    }

    return true;
  }
}
