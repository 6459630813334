import { Kpi, Values } from 'src/app/models/common/kpiValues';
import { KpiValue, Unit, Scale, UnitToLabel, Precision } from 'src/app/constants';
import { isNumber, flatten, getScale, getFormatter } from 'src/app/utils';
import { Currency } from 'src/app/types';

export class AdditionalKpiContainer {
  private static readonly Tag = 'AdditionalKpiContainer';
  private static readonly Debug: boolean = false;

  public tag: string = AdditionalKpiContainer.Tag;
  public debug: boolean = AdditionalKpiContainer.Debug;

  public additionalKpis: IFormattedAdditionalKpis;

  constructor(additionalKpis: IAdditionalKpis) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'additionalKpis:', additionalKpis);

    this.additionalKpis = Object.keys(additionalKpis).reduce((formattedAdditionalKpis, k) => {
      // TODO: Remove when back-end has updated object.
      const additionalKpi: AdditionalKpi = Object.assign({}, additionalKpis[k]);
      additionalKpi.values = ['endOfYear', 'nextYear'/*, 'lastYear'*/].map(p => additionalKpi[p]);
      //
      formattedAdditionalKpis[k] = new AdditionalKpi(additionalKpi);
      return formattedAdditionalKpis;
    }, {} as IFormattedAdditionalKpis);
  }

  public displayFormat(scale: Scale, currency: Currency): IFormattedAdditionalKpis {
    const tag: string = `${this.tag}.displayFormat()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scale:', scale);
    if (debug) console.log(tag, 'currency:', currency);

    const formattedAdditionalKpis: IFormattedAdditionalKpis = Object.keys(this.additionalKpis).reduce((formattedAdditionalKpis, k) => {
      const additionalKpi: AdditionalKpi = this.additionalKpis[k];
      let usedScale: Scale = Scale.Million;

      // Don't scale additional kpis (like in the dataTable.component).

      // if (additionalKpi.unit !== Unit.Currency) {
      //   usedScale = Scale.Million;
      // } else if (!scale) {
      //   const values: number[] = additionalKpi.listValues();
      //   if (debug) console.log(tag, 'values:', values);
      //   usedScale = getScale(values);
      // }
      // if (debug) console.log(tag, 'usedScale:', usedScale);

      formattedAdditionalKpis[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedAdditionalKpis;
    }, {} as IFormattedAdditionalKpis);
    if (debug) console.log(tag, 'formattedAdditionalKpis', formattedAdditionalKpis);
    return formattedAdditionalKpis;
  }
}

export interface IFormattedAdditionalKpis {
  allOtherAssets: AdditionalKpi;
  totalRevenue: AdditionalKpi;
  insuranceIncome: AdditionalKpi;
  insuranceOpex: AdditionalKpi;
  ebitInsuranceBusiness: AdditionalKpi;
}

export class AdditionalKpi {
  private static readonly Tag = 'AdditionalKpi';
  private static readonly Debug: boolean = false;

  public name: string;
  public type: string;
  public unit: Unit;
  public values: Kpi[];
  public isAdjustable: boolean;

  constructor({ name, type, unit, values, isAdjustable }: {
    name?: string, type?: string, unit?: Unit,
    values?: Kpi[], isAdjustable?: boolean
  } = {}) {
    this.name = name;
    this.type = type;
    this.unit = unit || Unit.Currency;
    this.values = values.map(kpi => new Kpi(kpi));
    this.isAdjustable = isAdjustable;
  }

  public displayFormat(scale: Scale, currency: Currency): AdditionalKpi {
    const tag: string = `${AdditionalKpi.Tag}.displayFormat()`;
    const debug: boolean = AdditionalKpi.Debug || false;
    if (debug) console.log(tag, 'scale:', scale);
    if (debug) console.log(tag, 'currency:', currency);

    let displayUnit: string = `${
      this.unit === Unit.Currency ? scale : ''}${
      this.unit === Unit.Percentage && this.name.includes('%') ? '' :
        this.unit === Unit.Currency ? Currency[currency] : UnitToLabel[this.unit]
      }`;
    if (this.unit === Unit.FullTimeEquivalent) {
      displayUnit = 'fte';
    }
    if (debug) console.log(tag, 'displayUnit:', displayUnit);

    const format: Function = getFormatter(this.unit);
    if (debug) console.log(tag, 'format:', format);

    const precision: number = this.unit === Unit.Currency ? Precision : 0;
    if (debug) console.log(tag, 'precision:', precision);

    const formattedAdditionalKpi: AdditionalKpi = new AdditionalKpi(this);

    (formattedAdditionalKpi as any).displayUnit = displayUnit;
    formattedAdditionalKpi.values.forEach(kpi => {
      if (debug) console.log(tag, 'kpi:', kpi);
      const values: Values = kpi.values;
      if (debug) console.log(tag, 'values:', values);

      const isActual: boolean = isNumber(values[KpiValue.Actual]);
      const isAdjusted: boolean = isNumber(values[KpiValue.Adjusted]);
      const isOrganization: boolean = isNumber(values[KpiValue.Organization]);
      const isMachine: boolean = isNumber(values[KpiValue.Machine]);
      const hasValue: boolean = isActual || isAdjusted || isOrganization || isMachine;

      Object.assign(kpi, {
        isActual,
        isAdjusted,
        isOrganization: !isAdjusted && isOrganization,
        isMachine: !isAdjusted && !isOrganization,
        hasValue,
        values: {
          [KpiValue.Actual]: isActual ? format(values[KpiValue.Actual], scale, precision) : null,
          [KpiValue.Adjusted]: isAdjusted ? format(values[KpiValue.Adjusted], scale, precision) : null,
          [KpiValue.Organization]: isOrganization ? format(values[KpiValue.Organization], scale, precision) : null,
          [KpiValue.Machine]: isMachine ? format(values[KpiValue.Machine], scale, precision) : null
        }
      });
    });
    if (debug) console.log(tag, 'formattedAdditionalKpi:', formattedAdditionalKpi);
    return formattedAdditionalKpi;
  }

  public listValues(): number[] {
    return flatten(this.values.map(kpi => kpi.listValues()));
  }
}

export interface IAdditionalKpis {
  portfolio: IAdditionalKpi;
  fleetPortfolio: IAdditionalKpi;
  participation: IAdditionalKpi;
  allOtherAssets: IAdditionalKpi;
  fundingBankBranches: IAdditionalKpi;
  fleetBusiness: IAdditionalKpi;
  totalAssets: IAdditionalKpi;
  totalRevenue: IAdditionalKpi;
  insuranceIncome: IAdditionalKpi;
  insuranceOpex: IAdditionalKpi;
  ebitInsuranceBusiness: IAdditionalKpi;
}

export interface IAdditionalKpi {
  name: string;
  type: string;
  unit: Unit;
  endOfYear: Kpi;
  nextYear: Kpi;
  // lastYear: Kpi;
  isAdjustable: boolean;
}

export interface IAdditionalKpiLite {
  name: string;
  type: string;
  unit: Unit;
  endOfYear: Kpi;
  isAdjustable: boolean;
}
