import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges,
  SimpleChanges, OnInit, DoCheck, AfterContentInit,
  AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy,
} from '@angular/core';
import { Subscription, Observable, merge } from 'rxjs';
import { skip } from 'rxjs/operators';
import { unsubscribe } from 'src/app/utils';
import { StateService } from 'src/app/services/state/state.service';

const Tag: string = 'BaseComponent';
const Debug: boolean = false;

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BaseComponent implements OnChanges, OnInit, DoCheck, AfterContentInit,
  AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {

  protected tag: string = Tag;
  protected debug: boolean = Debug;

  public readonly expandedIcon: string = 'mdi mdi-chevron-up';
  public readonly collapsedIcon: string = 'mdi mdi-chevron-down';

  public expanded: boolean = true;
  public icon: string = this.expandedIcon;

  public isLoading: boolean = false;
  protected callsReceivedCount: number = 0;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    // cd.detach();
  }

  private _subscriptions: Subscription[] = [];
  protected get subscriptions(): Subscription[] {
    return this._subscriptions;
  }
  protected set subscriptions(subscriptions: Subscription[]) {
    // this.unsubscribeSubscriptions();
    this._subscriptions = [...this._subscriptions, ...subscriptions];
  }

  private unsubscribeSubscriptions(): void {
    const tag: string = `${Tag}.${this.tag}.unsubscribeSubscriptions()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag, 'subscriptions:', this._subscriptions);
    if (this._subscriptions) {
      this._subscriptions.forEach(unsubscribe);
    }
  }

  protected change$: Observable<any>;
  private changeSubscription: Subscription;

  protected set observables(observables: Observable<any>[]) {
    const tag: string = `${Tag}.${this.tag}.observables()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag, 'observables:', observables);
    this.unsubscribeChange();
    this.change$ = merge(...observables).pipe(skip(observables.length));
    this.changeSubscription = this.change$.subscribe(this.onChange.bind(this));
    if (debug) console.log(tag, 'changeSubscription:', this.changeSubscription);
  }

  private unsubscribeChange(): void {
    const tag: string = `${Tag}.${this.tag}.observables()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag, 'changeSubscription:', this.changeSubscription);
    if (this.changeSubscription) {
      unsubscribe(this.changeSubscription);
    }
  }

  private onChange(): void {
    const tag: string = `${Tag}.${this.tag}.onChange()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
    this.detectChanges();
  }

  protected detectChanges(): void {
    const tag: string = `${Tag}.${this.tag}.detectChanges()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  public onExpandTap(): void {
    const tag: string = `${Tag}.${this.tag}.onExpandTap()`;
    const debug: boolean = Debug && this.debug;
    if (this.expanded) {
      this.expanded = false;
      this.icon = this.collapsedIcon;
    } else {
      this.expanded = true;
      this.icon = this.expandedIcon;
    }
    if (debug) console.log(tag, 'expanded:', this.expanded);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const tag: string = `${Tag}.${this.tag}.ngOnChanges()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag, 'changes:', changes);
  }

  public ngOnInit(): void {
    const tag: string = `${Tag}.${this.tag}.ngOnInit()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.isLoading$.subscribe(this.onIsLoadingChange.bind(this)),
    ];
  }

  protected onIsLoadingChange(isLoading: boolean): void {
    const tag: string = `${Tag}.${this.tag}.onIsLoadingChange()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag, 'isLoading:', isLoading);
    this.isLoading = isLoading;
    this.callsReceivedCount = 0;
  }

  protected setLoadingStatus(): void {
    const tag: string = `${Tag}.${this.tag}.setLoadingStatus()`;
    const debug: boolean = Debug && this.debug;
    if (this.state.callCount === 0) {
      this.callsReceivedCount = 0;
      this.isLoading = false;
    } else {
      this.callsReceivedCount++;
      this.isLoading = !(this.callsReceivedCount === this.state.callCount);
    }
    if (debug) console.log(tag, 'isLoading:', this.isLoading);
  }

  public ngDoCheck(): void {
    const tag: string = `${Tag}.${this.tag}.ngDoCheck()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
  }

  public ngAfterContentInit(): void {
    const tag: string = `${Tag}.${this.tag}.ngAfterContentInit()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
  }

  public ngAfterContentChecked(): void {
    const tag: string = `${Tag}.${this.tag}.ngAfterContentChecked()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
  }

  public ngAfterViewInit(): void {
    const tag: string = `${Tag}.${this.tag}.ngAfterViewInit()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
  }

  public ngAfterViewChecked(): void {
    const tag: string = `${Tag}.${this.tag}.ngAfterViewChecked()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
  }

  public ngOnDestroy(): void {
    const tag: string = `${Tag}.${this.tag}.ngOnDestroy()`;
    const debug: boolean = Debug && this.debug;
    if (debug) console.log(tag);
    this.unsubscribeSubscriptions();
    this.unsubscribeChange();
  }
}
