import * as numberFormat from 'd3-format';
import * as dateFormat from 'd3-time-format';

import * as merge from 'merge';
import { Subscription } from 'rxjs';

import {
  Precision, NumberLocale, KpiValue, Unit,
  KpiValues, Scale, Quarter, NoValue, August, March, January, June, December, ForecastAcronym,
} from 'src/app/constants';
import { FormattedKpiValues, Kpi } from 'src/app/models/common/kpiValues';
import { Axis, Placement, PointData, } from 'src/app/plotting/constants';
import { Trace, ValueLabel } from 'src/app/plotting/interfaces';
import { DateFormat1 } from 'src/app/plotting/layout';
import { Scenario } from 'src/app/models/workbench/scenario';
import { KpiValuesQuarter } from 'src/app/models/workbench/kpiValuesQuarter';
import { Color } from 'src/styles/color';
import { IKpiForecast } from 'src/app/models/common/currencySplit';

const Debug: boolean = false;
const Tag: string = 'utils.ts';

export const XmlSerializer: XMLSerializer = new XMLSerializer();

export const clone = (v: any): any => !v ? v : JSON.parse(JSON.stringify((v)));

/** Type checking */
export const isNull = (v): boolean => v === null;
export const isDate = (v): boolean => v instanceof Date;
export const isArray = (v): boolean => Array.isArray(v);
export const isUndefined = (v): boolean => v === undefined;
export const isString = (v): boolean => typeof v === 'string';
export const isBoolean = (v): boolean => typeof v === 'boolean';
export const isFunction = (v): boolean => typeof v === 'function';
export const isPresent = (v): boolean => !isNull(v) && !isUndefined(v) && v !== '';
export const isNumber = (v): boolean => isPresent(v) && !isNaN(Number(v)) && isFinite(v);
export const isObject = (v): boolean => typeof v === 'object' && !isArray(v) && !isNull(v);
export const isPrimitive = (v): boolean => !isObject(v) && !isArray(v) && !isFunction(v);
/** Type checking */

/** Array operations */
export function array(size: number = 0, value?: any): any[] { return Array(size).fill(value); }
export const sortNumbers = (n1: number, n2: number): number => n1 - n2;
export const flatten = (arr: any[][]): any[] => [].concat.apply([], arr);
export const safeIncludes = (arr: any[], v: any): boolean => isArray(arr) && arr.includes(v);
export const unique = (arr: any[]): any[] => arr.reduce((a, b) => a.includes(b) ? a : [...a, b], []);
export const pluck = (arr: any[], value: any): any[] => arr.filter(v => v !== value);
export const toChunks = (arr: any[], chunk: number): any[] =>
  arr.reduce((chunks: any[][], v: any, i: number, arr: any[]) =>
    i % chunk ? chunks : [...chunks, arr.slice(i, i + chunk)], []);
export const hasPositiveNegativeValues = (arr: number[]): boolean => {
  let positive: boolean = false;
  let negative: boolean = false;

  arr.forEach(v => {
    positive = positive || v > 0;
    negative = negative || v < 0;
  });

  return positive && negative;
};

// items.sort(function(a, b) {
//   var nameA = a.name.toUpperCase(); // ignore upper and lowercase
//   var nameB = b.name.toUpperCase(); // ignore upper and lowercase
//   if (nameA < nameB) {
//     return -1;
//   }
//   if (nameA > nameB) {
//     return 1;
//   }

//   // names must be equal
//   return 0;
// });
/** Array operations */


/** String operations */
export const acronym = (str: string): string => str.split(' ').map(s => s[0]).join('');
export const ignoreCaseEqual = (str1: string, str2: string): boolean => str1.toUpperCase() === str2.toUpperCase();
export const capital = (str: string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const lowerCase = (str: string): string => `${str[0].toLowerCase()}${str.slice(1)}`;
/** String operations */


/** Enum operations */
export function getEnumKeys(e): number[] {
  return Object.keys(e).filter(isNumber).map(Number);
}

// export function getEnumValues(e): any[] {
//   return Object.keys(e).filter(isNumber).map(k => e[k]);
// }

export function getEnumStrings(e): string[] {
  return Object.keys(e).filter(k => !isNumber(k));
}
// export function getEnumStrings(e): string[] {
//   return Object.keys(e).map(k => e[k]);
// }
/** Enum operations */

export const weekInMilliseconds: number = 60 * 60 * 24 * 7 * 1000; // 604800000
export const dayInMilliseconds: number = 60 * 60 * 24 * 1000; // 86400000

/** Date operations */
export const isSameDay = (d1: Date, d2: Date) => d1 && d2 && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
export const eqDate = (d1: Date, d2: Date) => d1 && d2 && d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
export const gtDate = (d1: Date, d2: Date) => d1.getFullYear() > d2.getFullYear() || d1.getFullYear() === d2.getFullYear() && d1.getMonth() > d2.getMonth();
export const gtEqDate = (d1: Date, d2: Date) => gtDate(d1, d2) || eqDate(d1, d2);
export const ltDate = (d1: Date, d2: Date) => gtDate(d2, d1);
export const ltEqDate = (d1: Date, d2: Date) => gtEqDate(d2, d1);
export const daysBetween = (d1: Date, d2: Date) => Math.ceil((d2.getTime() - d1.getTime()) / dayInMilliseconds);
export const weeksBetween = (d1: Date, d2: Date) => Math.ceil((d2.getTime() - d1.getTime()) / weekInMilliseconds);
export const sortDate = (d1: Date, d2: Date): number => d1.getTime() - d2.getTime();

export function createDate(date: Date | string | number = new Date()): Date {
  if (!date) return date as Date;
  if (date instanceof Date) return new Date(date);
  // IE11 is picky with string dates.
  if (typeof date === 'string') return new Date((date as string).split(' ')[0]);
  return new Date(date);
}

export function normalizeDate(date: Date | string | number): Date {
  const tag: string = `normalizeDate()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'date:', date, typeof date);
  if (!date) return null;

  const d: Date = createDate(date);
  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setHours(0);
  d.setDate(1);
  return d;
}

export function formatDate(date: Date | string | number, format: string = DateFormat1): string {
  const tag: string = `formatDate()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'date:', date, typeof date);
  if (debug) console.log(tag, 'format:', format);
  if (!date) return null;

  const d: Date = createDate(date);
  const formatter: Function = dateFormat.timeFormat(format);
  const formatted: string = formatter(d);
  return formatted;
}

// Can add normalizeDate.
export function isDateInBounds(date: Date, min: Date, max: Date) {
  return date.getTime() >= min.getTime() && date.getTime() <= max.getTime();
}

export function getCurrentDate(): Date {
  return normalizeDate(Date.now());
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function getYearShort(date: Date = new Date()): number {
  return Number(String(date.getFullYear()).slice(2));
}

export function getTimeShort(date: Date = new Date()): string {
  return `${date.getHours()}:${date.getMinutes()}`;
}

export function incrementMonth(date: Date | string | number, months: number): Date {
  const d: Date = normalizeDate(date);
  d.setMonth(d.getMonth() + months);
  return d;
}

export const previousMonth = (date: Date | string | number = new Date()): Date =>
  date && incrementMonth(date, -1);
export const nextMonth = (date: Date | string | number = new Date()): Date =>
  date && incrementMonth(date, 1);

export const localeMonthFull = (d: Date): string =>
  d.toLocaleString('en-us', { month: 'long' });

export const localeMonthShort = (d: Date): string =>
  d.toLocaleString('en-us', { month: 'short' });

export const localeMonthFullYearFull = (d: Date): string =>
  `${localeMonthFull(d)} ${d.getFullYear()}`;

export const localeMonthShortYearFull = (d: Date): string =>
  `${localeMonthShort(d)} ${d.getFullYear()}`;

export const localeMonthShortYearShort = (d: Date): string =>
  `${localeMonthShort(d)} ${getYearShort(d)}`;

export const localeDateMonthShortYearShort = (d: Date = new Date()): string =>
  `${padLeadingZero(d.getDate())} ${localeMonthShort(d)} ${getYearShort(d)}`;

export const localeDateMonthShortYearShortTimeShort = (d: Date = new Date()): string =>
  `${padLeadingZero(d.getDate())} ${localeMonthShort(d)} ${getYearShort(d)} ${getTimeShort(d)}`;

export const padLeadingZero = (v: number) => v < 10 ? '0' + v : `${v}`;

export const nextFullYearAvailable = (date: Date): boolean => {
  const month: number = date.getMonth();
  // 'Edge-case' check for December as the first full year is already the next year,
  // and the second full year is the year after the next year.
  return month > June && month !== December;
};

export const monthPickerStrNonZero = (dateStr: string): string => {
  const split: string[] = dateStr.split('.');
  dateStr = `${split[0]}.${Number(split[1]) + 1}`;
  return dateStr;
};

export const dateToMonthPickerNonZero = (date: Date): string => {
  const dateStr = `${date.getFullYear()}.${date.getMonth() + 1}`;
  return dateStr;
};

export const monthPickerStrNonZeroCurrent = (dateStr: string): string => {
  const date: Date = monthPickerStrToDate(dateStr);
  if (date.getMonth() == 0) {
    dateStr = `${date.getFullYear() - 1}.${date.getMonth() + 12}`;
  } else {
    dateStr = `${date.getFullYear()}.${date.getMonth()}`;
  }
  return dateStr;
};

export const dateToPlotlyDateStr = (date: any): string =>
  new Date(date).toISOString().split('T').join(' ').split('Z').join('');

export const isAugust = (date: Date): boolean => date.getMonth() === August;
export const isAfterMarch = (date: Date): boolean => date.getMonth() > March;
export const isDecember = (date: Date): boolean => date.getMonth() === December;
export const isJanuary = (date: Date): boolean => date.getMonth() === January;

export const getMonthQuarter = (date: Date): number =>
  Math.ceil((date.getMonth() + 1) / Quarter);
/** Date operations */

/**  Promise operations*/
export const resolve = (v?: any) => new Promise(resolve => resolve(v));
/**  Promise operations*/

/** ng-month-picker operations */
export const dateToMonthPickerStr = (d: Date): string =>
  `${d.getFullYear()}.${d.getMonth()}`;

export const monthPickerStrNextMonth = (d: string): string =>
  dateToMonthPickerStr(nextMonth(monthPickerStrToDate(d)));

export function dateStrToDateObj(date: string, separator = '.'): { year: number, month: number } {
  const split: string[] = date.split(separator);
  const year: number = Number(split[0]);
  const month: number = Number(split[1]);

  return {
    year,
    month
  };
}

export function monthPickerStrToDate(d: string): Date {
  const dateObj: any = dateStrToDateObj(d);
  return new Date(dateObj.year, dateObj.month);
}

export function backendDateToMonthPickerStr(d: string): string {
  const dateObj: any = dateStrToDateObj(d, '-');
  dateObj.month -= 1;
  const date: Date = new Date(dateObj.year, dateObj.month);
  return dateToMonthPickerStr(date);
}

export function formatMonthPickerLabel(cm: any): string {
  const year: string = cm.date.slice(2, 4);
  return `${cm.label} ${year}`;
}

export function getForecastLabel(date: Date): string {
  return `${ForecastAcronym}${padLeadingZero(date.getMonth())} ${getYearShort(date)}`;
}

/** ng-month-picker operations */

export const eqCompareForecast = (s1: Scenario, s2: Scenario): boolean =>
  (s1.id && s2.id && s1.id === s2.id) || (s1.date && s2.date && eqDate(s1.date, s2.date));

/** Calculations */
export const getSign = (value: number): string => value === 0 ? '' : value < 0 ? '-' : '+';
// TODO: Apply everywhere and change to Math.floor (or just use toFixed) if Daimler wants to round down.
export const toPrecision = (value: number | string, p = 2): string => (Math.round(parseFloat(value as string) * 100) / 100).toFixed(p);
export const toPrecisionNum = (value: number | string, p = 2): number => parseFloat(toPrecision(value, p));
export const removeCommaSeparator = (value: string): string => value && value.split(',').join('');
export const removeCommaSeparatorNum = (value: string): number | string =>
  isNumber(value) ? value : value && parseFloat(removeCommaSeparator(value));
export const removeExtraSigns = (value: string): string => value && value.replace(/(-|\+)+(-)/g, '-').replace(/(-|\+)+(\+)/g, '+');

export function getMinMax(arr: number[]): number[] {
  arr = arr.filter(isNumber);

  let min = arr[0];
  let max = min;

  arr.forEach(v => {
    if (min > v) {
      min = v;
    }
    if (max < v) {
      max = v;
    }
  });

  return [min, max];
}

export function getMinMaxAbsolute(arr: number[]): number[] {
  if (!arr.length) return [null, null];

  let min = Math.abs(arr[0]);
  let max = min;

  arr.forEach(v => {
    v = Math.abs(v);
    if (min > v) {
      min = v;
    }
    if (max < v) {
      max = v;
    }
  });

  return [min, max];
}


export const isActualPoint = (point: SVGPathElement): boolean => isColorPoint(point, Color.Actual);
export const isMachinePoint = (point: SVGPathElement): boolean => isColorPoint(point, Color.Machine);
export const isOrganizationPoint = (point: SVGPathElement): boolean => isColorPoint(point, Color.Organization);
export const isAdjustmentPoint = (point: SVGPathElement): boolean => isColorPoint(point, 'fill-opacity: 0.25');
export function isColorPoint(point: SVGPathElement, color: string): boolean {
  const tag: string = 'isColorPoint()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'point:', point);
  if (debug) console.log(tag, 'color:', color);
  const html: string = XmlSerializer.serializeToString(point);
  return html.includes(color);
}

export const getTracePoints = (trace: Trace, points: SVGPathElement[]): SVGPathElement[] => {
  const tag: string = `getTracePoints()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'trace:', trace);
  if (debug) console.log(tag, 'points:', points);
  if (!trace || !points) return [];

  const color: string = trace.line && trace.line.color || trace.marker && trace.marker.color;
  if (debug) console.log(tag, 'color:', color);

  return points.filter(point => isColorPoint(point, color));
};

export const getTraceValues = (traces: Trace[], axis: Axis = Axis.Y, numbersOnly: boolean = true): number[] => {
  const values: number[] = flatten(traces.map(trace => trace[axis] as number[][]));
  return numbersOnly ? values.filter(isNumber) : values;
};

export function createScatterValueLabels({ actual, machine, organization, points, unit }: {
  actual: Trace, machine: Trace, organization: Trace, points: SVGPathElement[], unit: Unit
}): ValueLabel[] {
  const tag: string = `createScatterValueLabels()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'actual:', actual);
  if (debug) console.log(tag, 'machine:', machine);
  if (debug) console.log(tag, 'organization:', organization);
  // if (debug) console.log(tag, 'points:', points);
  if (debug) console.log(tag, 'unit:', unit);

  const actualPoints: SVGPathElement[] = points.filter(isActualPoint);
  const machinePoints: SVGPathElement[] = points.filter(isMachinePoint);
  const organizationPoints: SVGPathElement[] = points.filter(isOrganizationPoint);

  if (debug) console.log(tag, 'actualPoints:', actualPoints);
  if (debug) console.log(tag, 'machinePoints:', machinePoints);
  if (debug) console.log(tag, 'organizationPoints:', organizationPoints);

  const organizationDates: Date[] = organizationPoints.map(p => normalizeDate(p[PointData][Axis.X]));
  if (debug) console.log(tag, 'organizationDates:', organizationDates);

  const scale: Scale = null;
  const format: Function = getFormatter(unit);
  const precision: number = unit === Unit.Currency ? Precision : 0;
  const valueLabels: ValueLabel[] = [

    ...actualPoints.map(point => {
      const value: number | string = isNumber(point[PointData][Axis.Y]) ?
        format(point[PointData][Axis.Y], scale, precision) : NoValue;
      if (debug) console.log(tag, 'value:', value);
      return {
        point,
        value,
        class: 'myfcActualTooltip',
        placement: Placement.Top,
      };
    }),

    ...machinePoints.reduce((valueLabels, machinePoint, i) => {
      const mDate: Date = normalizeDate(machinePoint[PointData][Axis.X]);
      // if (debug) console.log(tag, 'mDate:', mDate);
      const index: number = organizationDates.findIndex(oDate => eqDate(oDate, mDate));
      // if (debug) console.log(tag, 'index:', index);
      const organizationPoint: SVGElement = index === -1 ? null : organizationPoints[index];

      if (debug) console.log(tag, 'machinePoints:', machinePoints);
      if (debug) console.log(tag, 'organizationPoint:', organizationPoint);

      const machineValue: number = machinePoint[PointData][Axis.Y];
      const organizationValue: number = organizationPoint && organizationPoint[PointData][Axis.Y];

      if (debug) console.log(tag, 'machineValue:', machineValue);
      if (debug) console.log(tag, 'organizationValue:', organizationValue);

      const machineIsNumber: boolean = isNumber(machineValue);
      const organizationIsNumber: boolean = isNumber(organizationValue);

      if (debug) console.log(tag, 'machineIsNumber:', machineIsNumber);
      if (debug) console.log(tag, 'organizationIsNumber:', organizationIsNumber);

      const machineOnTop: boolean = !organizationIsNumber || machineValue > organizationValue;
      if (debug) console.log(tag, 'machineOnTop:', machineOnTop);

      return [
        ...valueLabels,
        {
          point: machinePoint,
          value: machineIsNumber ? format(machineValue, scale, precision) : NoValue,
          class: 'myfcMachineTooltip',
          placement: machineOnTop ? Placement.Top : Placement.Bottom,
        },
        ...(organizationPoint ? [{
          point: organizationPoint,
          value: organizationIsNumber ? format(organizationValue, scale, precision) : NoValue,
          class: 'myfcOrganizationTooltip',
          placement: machineOnTop ? Placement.Bottom : Placement.Top,
        }] : [])
      ];
    }, [])
  ];
  if (debug) console.log(tag, 'valueLabels:', valueLabels);
  return valueLabels;
}


export function createDeltaValueLabels({ trace, points, unit }: {
  trace: Trace, points: SVGPathElement[], unit: Unit
}): ValueLabel[] {
  const tag: string = `createDeltaValueLabels()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'trace:', trace);
  if (debug) console.log(tag, 'points:', points);

  let organizationPoints: SVGPathElement[] = points.filter(isOrganizationPoint);
  const adjustmentPoints: SVGPathElement[] = organizationPoints.filter(isAdjustmentPoint);
  if (debug) console.log(tag, 'adjustmentPoints:', adjustmentPoints);
  organizationPoints = organizationPoints.filter(p => !adjustmentPoints.includes(p));
  if (debug) console.log(tag, 'organizationPoints:', organizationPoints);

  const scale: Scale = null;
  const format: Function = getFormatter(unit);
  const precision: number = unit === Unit.Currency ? Precision : 0;

  const valueLabels: ValueLabel[] =
    [
      ...organizationPoints.map(point => {
        const value: number = point[PointData][Axis.Y];
        // if (debug) console.log(tag, 'value:', value);
        return {
          point,
          value: isNumber(value) ? format(value, scale, precision) : NoValue,
          class: 'myfcOrganizationTooltip',
          placement: Placement.Top,
        };
      }),
      ...adjustmentPoints.map(point => {
        const value: number = point[PointData][Axis.Y];
        // if (debug) console.log(tag, 'value:', value);
        return {
          point,
          value: isNumber(value) ? format(value, scale, precision) : NoValue,
          class: 'myfcAdjustmentTooltip',
          placement: Placement.Top,
        };
      })
    ];

  if (debug) console.log(tag, 'valueLabels:', valueLabels);
  return valueLabels;
}

export function getPlanningKpiValue(kpi: Kpi): KpiValue {
  const tag: string = `getPlanningKpiValue()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpi:', kpi);
  if (kpi.values[KpiValue.Adjusted] != null) {
    return KpiValue.Adjusted;
  }
  else {
    return KpiValue.Organization;
  }
}

export function getUsedKpiValue(values: any): KpiValue {
  const tag: string = `getUsedKpiValue()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'values:', values);

  const kpiValue: KpiValue =
    values['isActual'] ? KpiValue.Actual :
      values['isAdjusted'] ? KpiValue.Adjusted :
        values['isOrganization'] ? KpiValue.Organization :
          KpiValue.Machine;
  if (debug) console.log(tag, 'kpiValue:', kpiValue);
  return kpiValue;
}

export function getFormattedKpiValues(formattedKpiValues: FormattedKpiValues): number[] {
  const traces: Trace[] = getValues(formattedKpiValues);
  const values: number[] = getTraceValues(traces);
  return values;
}

export const getFormattedKpiValueScale = (formattedKpiValues: FormattedKpiValues): Scale =>
  getScale(getFormattedKpiValues(formattedKpiValues));

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function roundUp(value: number, precision: number): number {
  return roundDown(value, precision) + precision;
}
export function roundDown(value: number, precision: number): number {
  const remainder: number = value % precision;
  return value - remainder;
}

// Not used anymore.
// export function getSIprefix(value: number): string {
//   const tag: string = `getSIprefix()`;
//   const debug: boolean = false;
//   if (debug) console.log(tag, 'v:', v);
//   const vStr: string = String(v);
//   const leadingZero: boolean = v >= 0 ? vStr[0] == '0' : vStr[1] == '0';
//   // Avoid numberFormat.format with si prefix from going haywire with a leading zero value.
//   if (leadingZero) return '';

//   const formattedValue: string = numberFormat.format(`.${Precision}s`)(v);
//   if (debug) console.log(tag, 'formattedValue:', formattedValue);
//   let symbol: string = formattedValue[formattedValue.length - 1];
//   // Check whether there actually is a SI prefix symbol.
//   symbol = isNumber(symbol) ? '' : symbol;
//   if (debug) console.log(tag, 'symbol:', symbol);
//   return symbol;
// };

const One: number = 1; // 1
const Thousand: number = 1000; // 1 000

// All the received values are already in millions.
const thousand: number = One / Thousand; // 0.001
const hundred: number = thousand / Thousand; // 0.000001

const million: number = One;
const billion: number = million * Thousand; // 1 000
const trillion: number = billion * Thousand; // 1 000 000
const quadrillion: number = trillion * Thousand; // 1 000 000 000
const quintillion: number = quadrillion * Thousand; // 1 000 000 000 000
export const isHundred = (value: number): boolean => value >= hundred;
export const isThousand = (value: number): boolean => value >= thousand;
export const isMillion = (value: number): boolean => value >= million;
export const isBillion = (value: number): boolean => value >= billion;
export const isTrillion = (value: number): boolean => value >= trillion;
export const isQuadrillion = (value: number): boolean => value >= quadrillion;
export const isQuintillion = (value: number): boolean => value >= quintillion;

export function getScale(value: number | number[]): Scale {
  const tag: string = 'getScale()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'value:', value);
  const max: number = isArray(value) ?
    getMinMaxAbsolute((value as number[]).filter(isNumber))[1] :
    Math.abs(value as number);
  if (debug) console.log(tag, 'max:', max);

  let scale: Scale;
  if (isQuintillion(max)) scale = Scale.Quintillion;
  else if (isQuadrillion(max)) scale = Scale.Quadrillion;
  else if (isTrillion(max)) scale = Scale.Trillion;
  else if (isBillion(max)) scale = Scale.Billion;
  else if (isMillion(max)) scale = Scale.Million;
  else if (isThousand(max)) scale = Scale.Thousand;
  else scale = Scale.Hundred;
  if (debug) console.log(tag, 'scale:', scale);
  return scale;
}

// export const isScaled = (value: number, scale: Scale): boolean =>
//   getScale(value) === scale;

const Factor = {
  [Scale.Quintillion]: quintillion,
  [Scale.Quadrillion]: quadrillion,
  [Scale.Trillion]: trillion,
  [Scale.Billion]: billion,
  [Scale.Million]: million,
  // Up-scale to thousands from millions.
  [Scale.Thousand]: thousand,
  // Up-scale to hundreds from millions.
  [Scale.Hundred]: hundred,
};
if (Debug) console.log(Tag, 'Factor:', Factor);

export function getFactor(scale: Scale): number {
  const tag: string = 'getFactor()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'scale:', scale);
  const factor: number = Factor[scale];
  if (debug) console.log(tag, 'factor:', factor);
  const result: number = factor || 1;
  if (debug) console.log(tag, 'result:', result);
  return result;
}

export function highestScale(scales: Scale[]): Scale {
  const tag: string = 'highestScale()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'scales:', scales);
  const highest: Scale = scales.reduce((highest, scale) =>
    isHigherScale(scale, highest) ? scale : highest, Scale.Hundred);
  if (debug) console.log(tag, 'highest:', highest);
  return highest;
}

export const isHigherScale = (higher: Scale, lower: Scale): boolean =>
  getFactor(higher) > getFactor(lower);

export const scaleKpi = (kpi: FormattedKpiValues, axis: Axis = Axis.Y, to: Scale, from: Scale = Scale.Million): FormattedKpiValues =>
  Object.keys(kpi).reduce((scaledKpi, k) => {
    scaledKpi[k] = scaleTrace(kpi[k], axis, to, from);
    return scaledKpi;
  }, {});

export const scaleTraces = (traces: Trace[], axis: string = Axis.Y, to: Scale, from: Scale = Scale.Million): Trace[] =>
  traces.map(trace => scaleTrace(trace, axis, to, from));

export const scaleTrace = (trace: Trace, axis: string = Axis.Y, to: Scale, from: Scale = Scale.Million): Trace => {
  const tag: string = 'scaleTrace()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'trace:', trace);
  if (debug) console.log(tag, 'axis:', axis);
  if (debug) console.log(tag, 'to:', to);
  if (debug) console.log(tag, 'from:', from);
  const scaledTrace: Trace = !trace[axis] || to === from ? trace : Object.assign({}, trace, {
    [axis]: scaleArray(trace[axis], to, from),
  });
  if (debug) console.log(tag, 'scaledTrace:', scaledTrace);
  return scaledTrace;
};

export function scaleArray(values: number[], to: Scale, from: Scale = Scale.Million): number[] {
  const tag: string = 'scaleArray()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'values:', values);
  if (debug) console.log(tag, 'to:', to);
  if (debug) console.log(tag, 'from:', from);
  const scaledArray: number[] = values.map(v => scaleNumber(v, to, from));
  if (debug) console.log(tag, 'scaledArray:', scaledArray);
  return scaledArray;
}

export const scaleNumber = (value: number, to: Scale, from: Scale = Scale.Million): number => {
  const tag: string = 'scaleNumber()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'value:', value);
  if (debug) console.log(tag, 'to:', to);
  if (debug) console.log(tag, 'from:', from);
  if (!isNumber(value) || to === from) return value;

  const toFactor: number = getFactor(to);
  if (debug) console.log(tag, 'toFactor:', toFactor);
  const fromFactor: number = getFactor(from);
  if (debug) console.log(tag, 'fromFactor:', fromFactor);
  const factor: number = fromFactor / toFactor;
  if (debug) console.log(tag, 'factor:', factor);
  const scaledNumber: number = value * factor;
  if (debug) console.log(tag, 'scaledNumber:', scaledNumber);
  return scaledNumber;
};

export function absoluteDecimal(value: number | string, scale: Scale = null, precision: number = Precision, debug: boolean = false): string {
  const tag: string = 'absoluteDecimal()';
  if (debug) console.log(tag, 'value:', value);
  if (debug) console.log(tag, 'scale:', scale);

  precision = isNumber(precision) ? precision : Precision;
  if (debug) console.log(tag, 'precision:', precision);

  value = parseFloat(value as string);

  const formatted: string = scaleNumber(value, scale).toLocaleString(NumberLocale, {
    useGrouping: true,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  if (debug) console.log(tag, 'formatted:', formatted);
  return formatted;
}

export function absoluteDecimalToNumber(value: string): number {
  const noThousandLocale: string = value.split(',').join('');
  return Number(noThousandLocale);
}

export function absolutePercentage(value: number | string, scale: Scale = null, precision?: number, debug: boolean = false): string {
  const tag: string = 'absolutePercentage()';
  // Use default precision.
  const formatted: string = absoluteDecimal(value, scale/*, precision */);
  if (debug) console.log(tag, 'formatted:', formatted);
  return formatted;

  // value = parseFloat(value as string);

  // const abs: number = Math.abs(value);
  // if (debug) console.log(tag, 'abs:', abs);
  // if (!isNumber(precision)) {
  //   if (abs > 100) precision = 0;
  //   else if (abs >= 10) precision = 1;
  //   else if (abs < 10) precision = 2;
  // }
  // precision = Precision;
  // if (debug) console.log(tag, 'precision:', precision);

  // const formatted: string = value.toFixed(precision);
  // if (debug) console.log(tag, 'formatted:', formatted);
  // return formatted;
}

export function absolutePercentageDisplay(value: number | string, scale: Scale = null, precision?: number, debug: boolean = false): string {
  const tag: string = 'absolutePercentageDisplay()';
  const formatted: string = `${absolutePercentage(value, scale, precision)}%`;
  if (debug) console.log(tag, 'formatted:', formatted);
  return formatted;
}

export function absolutePercentageIncorrectDisplay(value: number | string, scale: Scale = null, precision?: number, debug: boolean = false): string {
  const tag: string = 'absolutePercentageIncorrectDisplay()';
  const formatted: string = `${absolutePercentage(Number(value)*100, scale, precision)}%`;
  if (debug) console.log(tag, 'formatted:', formatted);
  return formatted;
}

export function absoluteDecimalInt(value: number | string, scale: Scale = null, precision?: number, debug: boolean = false): string {
  const tag: string = 'absoluteDecimalInt()';
  precision = 0;
  const formatted: string = absoluteDecimal(value, scale, precision);
  if (debug) console.log(tag, 'formatted:', formatted);
  return formatted;
}

export function addSign(value: number): string | number {
  if (value > 0) return `+${value}`;
  return value;
}
/** Calculations */


/** Object operations */
export const createMap = (): any => Object.create(null);

export function getValues(v): any[] {
  return isObject(v) ? Object.keys(v).map(k => v[k]) : [];
}

export function isValueInObj(v, obj): boolean {
  return !!Object.keys(obj).find(k => obj[k] === v);
}

export function indexOfValueInObj(v, obj): number {
  let index: number = -1;
  Object.keys(obj).find((k, i) => {
    if (obj[k] === v) {
      index = i;
      return true;
    }
  });

  return index;
}

export function sortByNumber(a: any, b: any, key?: string): number {
  const n1: number = Number(key ? a[key] : a);
  const n2: number = Number(key ? b[key] : b);
  if (n1 < n2) return -1;
  if (n1 > n2) return 1;
  return 0;
}

export function sortByString(a: any, b: any, key?: string): number {
  const s1: string = (key ? a[key] : a).toLowerCase();
  const s2: string = (key ? b[key] : b).toLowerCase();
  if (s1 < s2) return -1;
  if (s1 > s2) return 1;
  return 0;
}

export function sortByDate(a: any, b: any, key?: string, descending: boolean = false): number {
  const tag: string = 'sortByDate()';
  const debug: boolean = false;
  const t1 = (key ? a[key] : a);
  const t2 = (key ? b[key] : b);

  if (debug) console.log(tag, 't1:', t1);
  if (debug) console.log(tag, 't2:', t2);

  if (descending) {
    if (t1 < t2) return -1;
    if (t1 > t2) return 1;
  }

  if (t1 < t2) return 1;
  if (t1 > t2) return -1;
  return 0;
}

export function sortByUnderscore(a: any, b: any): number {
  const string1: string = a.toLowerCase();
  const string2: string = b.toLowerCase();

  const string1HasUnderscore: boolean = startsWithUnderscore(string1);
  const string2HasUnderscore: boolean = startsWithUnderscore(string2);

  if ((string1HasUnderscore && string2HasUnderscore)
    || (!string1HasUnderscore && !string2HasUnderscore)) {
    if (string1 < string2) return -1;
    if (string1 > string2) return 1;
    return 0;
  }
  else if (string1HasUnderscore) {
    return 1;
  }
  return -1;
}

export function sortAdjustments(a: any, b: any): number {
  const tag: string = 'sortAdjustments()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'a:', a);
  if (debug) console.log(tag, 'b:', b);

  const d1: Date = new Date(a.values[KpiValues.AdjustedTime]);
  const d2: Date = new Date(b.values[KpiValues.AdjustedTime]);

  return sortDate(d1, d2);
}

export function sortKpiForecast(a: IKpiForecast, b: IKpiForecast): number {
  const tag: string = 'sortAdjustments()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'a:', a);
  if (debug) console.log(tag, 'b:', b);
  const d1: Date = new Date(a.adjustedTime);
  const d2: Date = new Date(b.adjustedTime);
  return sortDate(d1, d2);
}

export function sortQuarterAdjustments(a: KpiValuesQuarter, b: KpiValuesQuarter): number {
  const tag: string = 'sortQuarterAdjustments()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'a:', a);
  if (debug) console.log(tag, 'b:', b);

  const d1: Date = new Date(a.quarterValue[KpiValues.AdjustedTime]);
  const d2: Date = new Date(b.quarterValue[KpiValues.AdjustedTime]);

  return sortDate(d1, d2);
}

export function startsWithUnderscore(string: string): boolean {
  return string.charAt(0) === '_';
}


/** Scenarios */
export function getScenarioId(scenario: Scenario): number | string {
  const tag: string = `getScenarioId()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'scenario:', scenario);
  const id: number | string = scenario.guid;
  if (debug) console.log(tag, 'id:', id);
  return id;
}

export function getScenario(scenarios: Scenario[], scenario: Scenario): Scenario {
  const tag: string = `getScenario()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'scenarios:', scenarios);
  if (debug) console.log(tag, 'scenario:', scenario);
  if (!scenario) return;

  const existingScenario: Scenario = scenarios.find(s => s.guid === scenario.guid);
  if (debug) console.log(tag, 'existingScenario:', existingScenario);
  return existingScenario;
}
/** Scenarios */


/** Object operations */

/** Kpi operations */
export const isForecast = (kpi): boolean => kpi && kpi.values && kpi.values.hasOwnProperty(KpiValue.Machine);
// isNumber(kpi.values[KpiValue.Adjusted]) or kpi.values.hasOwnProperty(KpiValues.AdjustedTime) can be used as well.
export const isActual = (kpi): boolean => isForecast(kpi) && isNumber(kpi.values[KpiValue.Actual]);
export const isAdjusted = (kpi): boolean => isForecast(kpi) && isNumber(kpi.values[KpiValue.Adjusted]);
export const isAdjustedRemoveComma = (kpi): boolean => isForecast(kpi) && isNumber(removeCommaSeparatorNum(kpi.values[KpiValue.Adjusted]));
export const isOrganization = (kpi): boolean => isForecast(kpi) && isNumber(kpi.values[KpiValue.Organization]);
export const isMachine = (kpi): boolean => isForecast(kpi) && isNumber(kpi.values[KpiValue.Machine]);

export const isQuarterAdjusted = (quarter: KpiValuesQuarter): boolean => isNumber(quarter.quarterValue[KpiValue.Adjusted]);

export const sum = (values: number[]): number => values.reduce((a, b) => a + b, 0);
// export const sum = (kpiValues: any, subTypes: string[], kpiValue: string) => {
//   const tag: string = `sum()`;
//   const debug: boolean = false;
//   if (debug) console.log(tag, 'kpiValues:', kpiValues);
//   if (debug) console.log(tag, 'subTypes:', subTypes);
//   if (debug) console.log(tag, 'kpiValue:', kpiValue);
//   const sum: number = subTypes.reduce((sum, kpiType) => {
//     if (debug) console.log(tag, 'sum:', sum);
//     if (debug) console.log(tag, 'kpiType:', kpiType);
//     return !isNumber(sum) || !isNumber(kpiValues[kpiType].values[kpiValue]) ?
//       0 : sum += Number(kpiValues[kpiType].values[kpiValue]);
//   }, 0);
//   if (debug) console.log(tag, 'sum:', sum);
//   return sum;
// };

export const sumAdjusted = (kpiValues: any, subTypes: string[]) => {
  const tag: string = `sumAdjusted()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpiValues:', kpiValues);
  if (debug) console.log(tag, 'subTypes:', subTypes);
  const sumAdjusted: number = !subTypes.some(kpiType => isAdjusted(kpiValues[kpiType])) ?
    null :
    subTypes.reduce((sum, kpiType) => {
      if (debug) console.log(tag, 'sum:', sum);
      if (debug) console.log(tag, 'kpiType:', kpiType);
      if (!isNumber(sum)) return null;
      const adjusted: number = isNumber(kpiValues[kpiType].values[KpiValue.Adjusted]) && Number(kpiValues[kpiType].values[KpiValue.Adjusted]) || null;
      const organization: number = isNumber(kpiValues[kpiType].values[KpiValue.Organization]) && Number(kpiValues[kpiType].values[KpiValue.Organization]) || null;
      return !isNumber(adjusted) && !isNumber(organization) ? null : sum += isNumber(adjusted) ? adjusted : organization;
    }, 0);
  if (debug) console.log(tag, 'sumAdjusted:', sumAdjusted);
  return sumAdjusted;
};

// export const calculateValues = (kpiValues: any, kpiType: string, subTypes: string[]) => {
//   const tag: string = `calculateValues()`;
//   const debug: boolean = false;
//   if (debug) console.log(tag, 'kpiValues:', kpiValues);
//   if (debug) console.log(tag, 'kpiType:', kpiType);
//   if (debug) console.log(tag, 'subTypes:', subTypes);
//   const values: any = Object.assign({}, kpiValues[kpiType], {
//     values: {
//       id: kpiValues[kpiType].id,
//       [KpiValue.Machine]: sum(kpiValues, subTypes, KpiValue.Machine),
//       [KpiValue.Organization]: sum(kpiValues, subTypes, KpiValue.Organization),
//       [KpiValue.Adjusted]: sumAdjusted(kpiValues, subTypes),
//     },
//   });
//   if (debug) console.log(tag, 'values:', values);
//   return values;
// };

export const diff = (kpiValues: any, subTypes: string[], kpiValue: string) => {
  const tag: string = `diff()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpiValues:', kpiValues);
  if (debug) console.log(tag, 'subTypes:', subTypes);
  if (debug) console.log(tag, 'kpiValue:', kpiValue);
  const diff: number = subTypes.slice(1).reduce((diff, kpiType) => {
    if (debug) console.log(tag, 'diff:', diff);
    if (debug) console.log(tag, 'kpiType:', kpiType);
    return !isNumber(diff) || !isNumber(kpiValues[kpiType].values[kpiValue]) ?
      null : diff -= kpiValues[kpiType].values[kpiValue];
  }, kpiValues[subTypes[0]].values[kpiValue]);
  if (debug) console.log(tag, 'diff:', diff);
  return diff;
};

export const diffAdjusted = (kpiValues: any, subTypes: string[]) => {
  const tag: string = `diffAdjusted()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpiValues:', kpiValues);
  if (debug) console.log(tag, 'subTypes:', subTypes);
  const startAdjusted: number = isNumber(kpiValues[subTypes[0]].values[KpiValue.Adjusted]) && Number(kpiValues[subTypes[0]].values[KpiValue.Adjusted]) || null;
  if (debug) console.log(tag, 'startAdjusted:', startAdjusted);
  const startOrganization: number = isNumber(kpiValues[subTypes[0]].values[KpiValue.Organization]) && Number(kpiValues[subTypes[0]].values[KpiValue.Organization]) || null;
  if (debug) console.log(tag, 'startOrganization:', startOrganization);
  const diffAdjusted: number = !subTypes.some(kpiType => isAdjusted(kpiValues[kpiType])) ?
    null :
    subTypes.slice(1).reduce((diff, kpiType) => {
      if (debug) console.log(tag, 'diff:', diff);
      if (debug) console.log(tag, 'kpiType:', kpiType);
      if (!isNumber(diff)) return null;
      const adjusted: number = isNumber(kpiValues[kpiType].values[KpiValue.Adjusted]) && Number(kpiValues[kpiType].values[KpiValue.Adjusted]) || null;
      const organization: number = isNumber(kpiValues[kpiType].values[KpiValue.Organization]) && Number(kpiValues[kpiType].values[KpiValue.Organization]) || null;
      return !isNumber(adjusted) && !isNumber(organization) ? null : diff -= isNumber(adjusted) ? adjusted : organization;
    }, !isNumber(startAdjusted) && !isNumber(startOrganization) ? null : isNumber(startAdjusted) ? startAdjusted : startOrganization);
  if (debug) console.log(tag, 'diffAdjusted:', diffAdjusted);
  return diffAdjusted;
};

export const roundValues = (kpiValues: any) => {
  const tag: string = `roundValues()`;
  const debug: boolean = false;
  if (debug) console.log(tag, 'kpiValues:', kpiValues);
  return Object.keys(kpiValues).reduce((roundedValues, kpiType) => {
    if (debug) console.log(tag, 'kpiType:', kpiType);
    const kpi: any = kpiValues[kpiType];
    if (debug) console.log(tag, 'kpi:', kpi);
    const roundedKpi: any = merge.recursive(true, kpi, {
      values: {
        [KpiValue.Machine]: isNumber(kpi.values[KpiValue.Machine]) && toPrecision(kpi.values[KpiValue.Machine]),
        [KpiValue.Organization]: isNumber(kpi.values[KpiValue.Organization]) && toPrecision(kpi.values[KpiValue.Organization]),
      }
    });

    if (isNumber(roundedKpi.values[KpiValue.Adjusted]))
      roundedKpi.values[KpiValue.Adjusted] = toPrecision(roundedKpi.values[KpiValue.Adjusted]);

    roundedValues[kpiType] = roundedKpi;
    return roundedValues;
  }, {});
};

export const identity = (v: any) => v;

export const getFormatter = (unit: Unit, display: boolean = true, correctPercentage = true, debug: boolean = false) => {
  const tag: string = 'getFormatter()';
  if (debug) console.log(tag, 'unit:', unit);
  switch (unit) {
    case Unit.Percentage: return correctPercentage? display ? absolutePercentageDisplay : absolutePercentage : absolutePercentageIncorrectDisplay;
    case Unit.Currency: return absoluteDecimal;
    case Unit.Headcount: return absoluteDecimalInt;
    case Unit.FullTimeEquivalent: return absoluteDecimal;
    case Unit.Unit: return absoluteDecimalInt;
    default: return identity;
  }
};

export const mapForecasts = (kpiValues: any, kpiType: string) => ({
  adjustEndOfYear: kpiValues[kpiType].values[KpiValue.Adjusted],
  machineEndOfYear: kpiValues[kpiType].values[KpiValue.Machine],
  organizationEndOfYear: kpiValues[kpiType].values[KpiValue.Organization],
});

/** Kpi operations */


/** HTML/JS */

export const getElement = (query: string): Element => document.querySelector(query);
export function getElementPosition(query: string): ClientRect {
  const el: Element = getElement(query);
  if (!el) return null;
  return el.getBoundingClientRect();
}

export const imageExists = async (url: string) => {
  const tag: string = 'imageExists()';
  const debug: boolean = false;
  if (debug) console.log(tag, 'url:', url);

  let resolve;
  const promise = new Promise(res => resolve = res);

  const img: HTMLImageElement = new Image();
  img.onload = () => resolve(true);
  img.onerror = () => resolve(false);
  img.src = url;

  return promise;
};

export function groupBy(items, keyFn) {
  const groups = {};

  items.forEach(item => {
    const key: any = keyFn(item);
    if (!groups[key]) groups[key] = [];
    groups[key].push(item);
  });

  return Object.keys(groups).map(key => ({
    key,
    values: groups[key]
  }));
}

export function triggerWindowResize(): void {
  const tag: string = 'triggerWindowResize()';
  const debug: boolean = false;
  if (debug) console.log(tag);
  const event: UIEvent = document.createEvent('UIEvents');
  //event.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(event);
  // window.dispatchEvent(new Event('resize'));
}
/** HTML/JS */


/** RxJs */
export function unsubscribe(subscription: Subscription): void {
  subscription.unsubscribe();
}
/** RxJs */


/** XHR */


export function xhrRequest(method: string, url: string) {
  const tag: string = 'xhrRequest()';
  const debug: boolean = false;

  return new Promise((resolve, reject) => {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = () => {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = () => {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.send();
  });
}

/** XHR */
