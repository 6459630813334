import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { Page } from 'src/app/constants';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';

@Component({
  selector: 'statusMessage',
  templateUrl: './statusMessage.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StatusMessageComponent extends BaseComponent {
  public static readonly Tag: string = 'StatusMessageComponent';
  protected tag: string = StatusMessageComponent.Tag;
  protected debug: boolean = false;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    public readonly workbench: WorkbenchDataService,
    public readonly planning: PlanningDataService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
  }

  public readonly Page = Page;
}
