import { Injectable } from '@angular/core';
import { NotifierType } from 'src/app/constants';
import { Subject, Observable } from 'rxjs';
import { INotification } from 'src/app/components/common/elements/notification/notification.component';

@Injectable()
export class NotificationService {
  private static readonly Tag: string = 'NotificationService';
  private readonly tag: string = NotificationService.Tag;
  private readonly debug: boolean = false;

  private get positioning() {
    return {
      positions: [{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      }]
    };
  }

  private readonly _notification$: Subject<INotification> = new Subject<INotification>();
  public readonly notification$: Observable<INotification> = this._notification$.asObservable();

  public readonly queue: INotification[] = [];

  public add(notification: INotification): void {
    const tag: string = `${this.tag}.push()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'notification:', notification);

    notification = Object.assign(this.positioning, notification, {
      visible: true,
      isSuccess: notification.type === NotifierType.Success,
      isWarning: notification.type === NotifierType.Warning,
      isInfo: notification.type === NotifierType.Info,
      isError: notification.type === NotifierType.Error,
    });

    notification.isDefault = !(
      notification.isSuccess || notification.isWarning ||
      notification.isInfo || notification.isError);

    this.queue.push(notification);
    this._notification$.next(notification);
  }
}
