import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'userSettings',
  templateUrl: 'userSettings.component.html',
  styleUrls: ['./userSettings.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserSettingsComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'UserSettingsComponent';

  public readonly debugPopover: boolean = false;

  public expanded: boolean = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = UserSettingsComponent.Tag;
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.observables = [
      this.state.userHasAdminAccess$
    ];
  }
}
