import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, AfterViewInit, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { TourService, TourOptions } from '@vcl/ng-vcl';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'tour',
  templateUrl: './tour.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TourComponent extends BaseComponent implements OnInit, AfterViewInit {
  private static readonly Tag: string = 'TourComponent';
  protected readonly tag: string = TourComponent.Tag;
  protected readonly debug: boolean = false;

  public static readonly TourCompleted: string = 'tourCompleted';

  private readonly debugPopover: boolean = false;

  public readonly offsetAttachment: number = 20;
  public readonly offsetAttachmentNeg: number = -this.offsetAttachment;


  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    public readonly tour: TourService,
    private readonly storage: StorageService,
  ) {
    super(cd, state);
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = false;

    this.subscriptions = [
      this.tour.end$.subscribe(this.onTourEnd.bind(this)),
    ];
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    const tag: string = `${this.tag}.ngAfterViewInit()`;
    const debug: boolean = false;
    const tourCompleted: boolean = this.storage.get(TourComponent.TourCompleted);
    if (debug) console.log(tag, 'tourCompleted:', tourCompleted);
    if (tourCompleted) return;
  }

  public onTourEnd(): void {
    const tag: string = `${this.tag}.onTourEnd()`;
    const debug: boolean = false;
    if (debug) console.log(tag);
    this.storage.set(TourComponent.TourCompleted, true);
  }

  public startTour(): void {
    const tag: string = `${this.tag}.startTour()`;
    const debug: boolean = false;
    if (debug) console.log(tag);
    let options = new TourOptions();
    options.useOrder = true;
    options.previousIcon = 'mdi mdi-chevron-left';
    options.nextIcon = 'mdi mdi-chevron-right';
    options.exitIcon = 'mdi mdi-close';
    options.buttonClass = 'vclTransparent';
    this.tour.initialize(options);
    this.tour.start();
  }
}
