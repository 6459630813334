import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { TopLevelKpi } from 'src/app/constants';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';

export const DebugPlots: boolean = false;

@Component({
  selector: 'planning',
  templateUrl: './planning.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'vclLayoutFlex'
  }
})
export class PlanningComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'PlanningComponent';
  protected readonly rag: string = PlanningComponent.Tag;
  protected readonly debug: boolean = false;


  public iframeUrl: SafeResourceUrl;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly planning: PlanningDataService,
    private readonly sanitizer: DomSanitizer
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  public readonly TopLevelKpi = TopLevelKpi;
}
