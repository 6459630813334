import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, Input, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { Page } from 'src/app/constants';

@Component({
  selector: 'collapsedNavigation',
  templateUrl: './collapsedNavigation.component.html',
  styleUrls: ['./collapsedNavigation.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // host: {
  //   class: 'vclLayoutFlex',
  // }
})
export class CollapsedNavigationComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'CollapsedNavigationComponent';

  @Input() public Pages: Page[];

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = CollapsedNavigationComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    this.observables = [
      this.state.page$,
      this.state.datePickerDate$,
    ];
  }

  private onPageTap(page: Page): void {
    const tag: string = `${this.tag}.onPageTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'page:', page);
    this.state.page = page;
  }
}
