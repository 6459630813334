import { Component, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'homeButton',
  templateUrl: 'homeButton.component.html',
  styleUrls: ['homeButton.component.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeButtonComponent extends BaseComponent {
  private static readonly Tag: string = 'HomeButtonComponent';
  protected readonly tag: string = HomeButtonComponent.Tag;
  protected readonly debug: boolean = false;

  constructor(
    public readonly state: StateService,
    private readonly window: WindowService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
  }

  public onHomeBtnTap(): void {
    const tag: string = `${this.tag}.onHomeBtnTap()`;
    if (this.debug) console.log(tag);
    this.state.app.refreshApplication();
  }

  public onCloseBtnTap(): void {
    const tag: string = `${this.tag}.onCloseBtnTap()`;
    if (this.debug) console.log(tag);
    this.window.isMenuExpanded = false;
  }
}
