import * as merge from 'merge';
import { Color } from 'src/styles/color';
import { Trace } from 'src/app/plotting/interfaces';
import { Axis } from 'src/app/plotting/constants';

// Used to round bar plot bars.
export const BarRounding: number = 0.25;

export class Bar {

  static Dfs(options: Trace = {}) {
    return merge.recursive({
      // [Axis.X]: [],
      // [Axis.Y]: [],
      type: 'bar',
      hoverinfo: 'none',
    }, options);
  }

  static get Horizontal() {
    return {
      orientation: 'h',
    };
  }

  static DfsHorizontal(options: Trace = {}): Trace {
    return merge.recursive(Bar.Dfs(options), Bar.Horizontal);
  }

  static Machine(options: Trace = {}): Trace {
    return merge.recursive({
      name: 'Model',
      marker: {
        color: Color.Machine,
      },
    }, Bar.Dfs(options));
  }

  static MachineHorizontal(options: Trace = {}): Trace {
    return merge.recursive(Bar.Horizontal, Bar.Machine(options));
  }

  static Organization(options: Trace = {}): Trace {
    return merge.recursive({
      name: 'Country',
      marker: {
        color: Color.Organization,
      },
    }, Bar.Dfs(options));
  }

  static OrganizationAdjusted(options: Trace = {}): Trace {
    return merge.recursive({
      marker: {
        color: Color.OrganizationAdjusted,
        line: {
          width: 2,
          color: Color.Organization,
        }
      },
    }, Bar.Organization(options));
  }

  static OrganizationHorizontal(options: Trace = {}): Trace {
    return merge.recursive(Bar.Horizontal, Bar.Organization(options));
  }

  static OrganizationEditedHorizontal(options: Trace = {}): Trace {
    return merge.recursive(Bar.Horizontal, Bar.OrganizationAdjusted(options));
  }

  static Actual(options: Trace = {}): Trace {
    return merge.recursive({
      name: 'Actual',
      marker: {
        color: Color.Actual,
      },
    }, Bar.Dfs(options));
  }

  static ActualHorizontal(options: Trace = {}): Trace {
    return merge.recursive(Bar.Horizontal, Bar.Actual(options));
  }

  static Invisible(options: Trace = {}): Trace {
    return merge.recursive({
      marker: {
        color: Color.Transparent
      },
    }, Bar.Dfs(options));
  }

  static Waterfall(options: Trace = {}): Trace {
    return Bar.Dfs(options);
    // Feature not yet implemented? https://github.com/plotly/plotly.js/pull/1159
    // textposition: 'outside',
  }

  static StackedBar(options: Trace = {}): Trace {
    return Bar.Dfs(options);
  }
}
