import * as merge from 'merge';
import {
  Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChildren, QueryList
} from '@angular/core';
import { skip } from 'rxjs/operators';
import { PlotlyComponent, PlotlyEvent } from '@n-fuse/ng-plotly';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { KpiValues, FormattedKpiValues, KpiValuesChild } from 'src/app/models/common/kpiValues';
import { Plot, Trace, PlotlyEventMap } from 'src/app/plotting/interfaces';
import { Configuration } from 'src/app/plotting/configuration';
import { Layout } from 'src/app/plotting/layout';
import { createScatter, createLargerNumberRange, createZoomOut } from 'src/app/plotting/data';
import { getValues, flatten, getScale, scaleKpi, toChunks, getTraceValues, getFormattedKpiValues } from 'src/app/utils';
import { Axis, Forecast } from 'src/app/plotting/constants';
import { Scale, Unit, UnitToLabel } from 'src/app/constants';
import { PlotService, Transition } from 'src/app/services/plot.service';

@Component({
  selector: 'scatterFyComponents',
  templateUrl: 'scatterFyComponents.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ScatterFyComponentsComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ScatterFyComponentsComponent';
  private readonly MaxPlotsPerRow: number = 4;

  @ViewChildren('vclPlotly') private readonly vclPlotly: QueryList<PlotlyComponent>;

  public readonly debugPlot: boolean = false;
  public readonly width: number = 100;
  public readonly height: number = 100;

  public readonly plotId: string = ScatterFyComponentsComponent.Tag;
  public readonly plotClass: string = 'myfcComponent myfcScatterFyComponent';

  public plotRows: Plot[][];
  private rangeZoomIn: Plot;
  private rangeZoomOut: Plot;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
    private readonly plotService: PlotService,
  ) {
    super(cd, state);
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${ScatterFyComponentsComponent.Tag}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.workbench.scatterFyComponents$.subscribe(this.onScatterFyComponentsChange.bind(this)),
      this.plotService.isZoomedOut$.pipe(skip(1)).subscribe(this.onIsZoomedOutChange.bind(this)),
    ];
  }

  private onScatterFyComponentsChange(scatterFyComponents: KpiValuesChild[]): void {
    const tag: string = `${this.tag}.onScatterFyComponentsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scatterFyComponents:', scatterFyComponents);
    if (!scatterFyComponents) return;

    const plots: Plot[] = scatterFyComponents.map((fyComponent, i) => Object.assign({
      plotId: `${this.tag}.${i}`,
      plotClass: this.plotClass,
    }, this.createPlot(fyComponent)));

    const plotRows: Plot[][] = toChunks(plots, this.MaxPlotsPerRow);
    if (debug) console.log(tag, 'plotRows:', plotRows);
    Object.assign(this, {
      plotRows,
      rangeZoomIn: {
        layout: merge(true, Layout.Scatter, {
          xaxis: { range: plotRows[0] && plotRows[0][0] && plotRows[0][0].layout.xaxis.range.slice() },
          // yaxis: { range: plot.layout.yaxis.range.slice() },
        })
      }
    });
    this.setLoadingStatus();
  }

  private createPlot(fyComponent: KpiValuesChild): Plot {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyComponent:', fyComponent);

    let formattedKpi: FormattedKpiValues = fyComponent.format();
    if (debug) console.log(tag, 'formattedKpi:', formattedKpi);

    const unit: string = fyComponent.unit === Unit.Currency ? this.state.unitLabel : UnitToLabel[fyComponent.unit];
    if (debug) console.log(tag, 'unit:', unit);

    let scale: Scale;
    if (fyComponent.unit === Unit.Currency) {
      const values: number[] = getFormattedKpiValues(formattedKpi);
      if (debug) console.log(tag, 'values:', values);
      scale = getScale(values);
    }
    if (debug) console.log(tag, 'scale:', scale);
    if (scale) {
      formattedKpi = scaleKpi(formattedKpi, Axis.Y, scale);
    }
    if (debug) console.log(tag, 'formattedKpi:', formattedKpi);

    // const yaxisRange: number[] = [];

    const plot: Plot = merge.recursive({
      configuration: Configuration.Dfs,
      layout: Layout.Scatter,
      label: fyComponent.label,
      scale,
      unit,
    }, createScatter({
      kpi: formattedKpi,
      markers: false,
      // yaxisRange
    }));

    return plot;
  }

  private async onIsZoomedOutChange(isZoomedOut: boolean): Promise<void> {
    const tag: string = `${this.tag}.onIsZoomedOutChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'isZoomedOut:', isZoomedOut);
    if (isZoomedOut) return this.zoomOut();
    else return this.zoomIn();
  }

  private async zoomIn(): Promise<void> {
    const tag: string = `${this.tag}.zoomIn()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'this.rangeZoomIn:', this.rangeZoomIn);
    if (!this.rangeZoomIn) return;

    return this.vclPlotly.forEach(vclPlotly => vclPlotly.animate(this.rangeZoomIn, Transition));
  }

  private async zoomOut(): Promise<void> {
    const tag: string = `${this.tag}.zoomOut()`;
    const debug: boolean = this.debug || false;
    if (!this.rangeZoomOut) {
      this.rangeZoomIn.layout.yaxis = { range: this.plotRows[0][0].layout.yaxis.range.slice() };

      const traces: Trace[] = this.plotRows[0][0].data;
      if (debug) console.log(tag, 'traces:', traces);
      const values: number[] = getTraceValues(traces);
      if (debug) console.log(tag, 'values:', values);
      this.rangeZoomOut = {
        layout: merge.recursive(true, Layout.MonthlyForecast,
          createZoomOut(this.state.datePickerDate, values).layout, {
            xaxis: {
              dtick: 'M6',
            }
          }),
      };
    }
    // delete this.rangeZoomOut.layout.xaxis.dtick;
    if (debug) console.log(tag, 'this.rangeZoomOut:', this.rangeZoomOut);
    return this.vclPlotly.forEach(vclPlotly => vclPlotly.animate(this.rangeZoomOut, Transition));
  }
}
