import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { Page } from 'src/app/constants';

@Component({
  selector: 'topBar',
  templateUrl: './topBar.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TopBarComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'TopBarComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = TopBarComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.organizationEntity$,
    ];
  }

  public readonly Page = Page;
}
