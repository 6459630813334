import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';
import { CommonDataService } from 'src/app/services/data/common.data.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Page } from 'src/app/constants';

@Injectable()
export class WorkbenchResolver implements Resolve<void> {
  private static readonly Tag: string = 'WorkbenchResolver';
  private readonly tag: string = WorkbenchResolver.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    public readonly state: StateService,
    private readonly data: CommonDataService,
    private readonly workbench: WorkbenchDataService,
  ) { }

  public async resolve(): Promise<void> {
    const tag: string = `${this.tag}.resolve()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'state.pageVisibility:', this.state.pageVisibility);
    if (!this.state.pageVisibility[Page.Workbench]) return;

    this.state.page = Page.Workbench;
    if (debug) console.log(tag, 'state.page:', this.state.page);
    this.load();
  }

  public async load(): Promise<void> {
    const tag: string = `${this.tag}.load()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    await Promise.all([
      this.workbench.setUpWorkbenchStatusMessage(),
      this.workbench.setUpTopLevelKpiFyForecast(),
      this.workbench.setUpSubKpiFyForecast(),
      this.workbench.setUpScenario(),
      this.workbench.setUpAdjustmentHistory(),
      this.workbench.setUpAdditionalKpis(),
      this.workbench.setUpMoveReporting(),
      this.workbench.setUpFocusReporting(),
      this.workbench.setUpItReporting(),
      this.workbench.setUpRatios(),
      this.workbench.setUpComponents(),
      this.workbench.setUpSalesAndAcquisitionUnits(),
      this.workbench.setUpIsCurrencySplitVisible(),
      this.workbench.setUpCurrencySplit()
    ]);
  }
}
