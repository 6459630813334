import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService, OrganizationEntity } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { ForYearAcronym, KpiValue, Adjustment, AdjustmentTab } from 'src/app/constants';
import {
  isNumber, sortAdjustments, isAdjustedRemoveComma, dateToMonthPickerStr,
  createDate, removeCommaSeparatorNum, removeExtraSigns, getUsedKpiValue, getYearShort, isAfterMarch
} from 'src/app/utils';
import { Kpi } from 'src/app/models/common/kpiValues';
import { browser } from 'src/app/app.component';
import { IFormattedMoveReporting, MoveReportingContainer } from 'src/app/models/moveReporting/moveReporting';
import { AdditionalKpi } from 'src/app/models/additionalKpis/additionalKpis';

@Component({
  selector: 'moveReporting',
  templateUrl: 'moveReporting.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class MoveReportingComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'MoveReportingComponent';
  protected readonly tag: string = MoveReportingComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  public disableWrite: boolean;
  public adjustments: Adjustment[] = [];
  public opexGa: AdditionalKpi[] = [];
  public fteGa: AdditionalKpi[] = [];
  public opexBf: AdditionalKpi[] = [];
  public fte: AdditionalKpi[] = [];
  public opexMisc: AdditionalKpi[] = [];
  public fteMisc: AdditionalKpi[] = [];

  public years: number[];

  constructor(
      protected readonly cd: ChangeDetectorRef,
      public readonly state: StateService,
      private readonly workbench: WorkbenchDataService,
  ) {
      super(cd, state);
  }

  public ngOnInit(): void {
      super.ngOnInit();

      const tag: string = `${this.tag}.ngOnInit()`;
      if (this.debug) console.log(tag);

      this.subscriptions = [
          this.workbench.moveReporting$.subscribe(this.onMoveReportingChange.bind(this)),
          this.state.organizationEntity$.subscribe(this.onOrganizationChange.bind(this))
      ];
  }

  private onMoveReportingChange(moveReportingContainer: MoveReportingContainer): void {
      const tag: string = `${this.tag}.onMoveReportingChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'moveReportingContainer', moveReportingContainer);
      if (!moveReportingContainer) return;

      const moveReporting: IFormattedMoveReporting = moveReportingContainer.displayFormat(null, this.state.currency);
      if (debug) console.log(tag, 'moveReporting', moveReporting);

      const years: number[] = moveReporting.opexGa[0].values.map(kpi => getYearShort(createDate(kpi.date)));
      if (debug) console.log(tag, 'years', years);

      const opexGa: AdditionalKpi[] = [];
      const fteGa: AdditionalKpi[] = [];
      const opexBf: AdditionalKpi[] = [];
      const fteBf: AdditionalKpi[] = [];
      const opexMisc: AdditionalKpi[] = [];
      const fteMisc: AdditionalKpi[] = [];

      Object.keys(moveReporting.opexGa).map(function(key){  
        opexGa.push(moveReporting.opexGa[key])  
        return opexGa;  
      });
      Object.keys(moveReporting.fteGa).map(function(key){  
        fteGa.push(moveReporting.fteGa[key])  
        return fteGa;  
      });
      Object.keys(moveReporting.opexBf).map(function(key){  
        opexBf.push(moveReporting.opexBf[key])  
        return opexBf;  
      });
      Object.keys(moveReporting.fteBf).map(function(key){  
        fteBf.push(moveReporting.fteBf[key])  
        return fteBf;  
      });   
      Object.keys(moveReporting.opexMisc).map(function(key){  
        opexMisc.push(moveReporting.opexMisc[key])  
        return opexMisc;  
      });
      Object.keys(moveReporting.fteMisc).map(function(key){  
        fteMisc.push(moveReporting.fteMisc[key])  
        return fteMisc;  
      });      
      
      const adjustments = this.getMoveReportingValues(moveReporting)
          .filter(kpi => isAdjustedRemoveComma(kpi) && kpi.id)
          .sort(sortAdjustments)
          .reverse()
          .map(kpi => ({
              forecastId: kpi.id,
              perMonth: dateToMonthPickerStr(createDate(kpi.date)),
              adjustValue: removeCommaSeparatorNum(removeExtraSigns(kpi.values[KpiValue.Adjusted])),
          }));
      if (debug) console.log(tag, 'adjustments:', adjustments);

      Object.assign(this, {
          years,
          opexGa,
          fteGa,
          opexBf,
          fteBf,
          opexMisc,
          fteMisc,
          adjustments,
      });

      this.setUnsavedAdjustments();
      this.setLoadingStatus();
  }

  private getMoveReportingValues(moveReporting: IFormattedMoveReporting,): Kpi[] {
      const tag: string = `${this.tag}.getMoveReportingValues()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'moveReporting:', moveReporting);

      const kpis1: Kpi[] = Object.keys(moveReporting.opexGa).reduce((kpis, k) =>
          [...kpis, ...moveReporting.opexGa[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis1);
      const kpis2: Kpi[] = Object.keys(moveReporting.fteGa).reduce((kpis, k) =>
      [...kpis, ...moveReporting.fteGa[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis2);
      const kpis3: Kpi[] = Object.keys(moveReporting.opexBf).reduce((kpis, k) =>
      [...kpis, ...moveReporting.opexBf[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis1);
      const kpis4: Kpi[] = Object.keys(moveReporting.fteBf).reduce((kpis, k) =>
      [...kpis, ...moveReporting.fteBf[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis2);
      const kpis5: Kpi[] = Object.keys(moveReporting.opexMisc).reduce((kpis, k) =>
      [...kpis, ...moveReporting.opexMisc[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis1);
      const kpis6: Kpi[] = Object.keys(moveReporting.fteMisc).reduce((kpis, k) =>
      [...kpis, ...moveReporting.fteMisc[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis2);

      return kpis1.concat(kpis2).concat(kpis3).concat(kpis4).concat(kpis5).concat(kpis6);
  }

  public onAdjustmentClick(kpi: Kpi, value: number): void {
      const tag: string = `${this.tag}.onAdjustmentClick()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
  }

  public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
      const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'value:', value);

      event = event || window.event;
      const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
      const char: string = String.fromCharCode(charCode);
      if (debug) console.log(tag, 'char:', char);
      const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
      if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

      if (!isNumber(char) && !isValidSymbol) return false;
      return true;
  }

  public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
      const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);

      if (!this.state.unsavedAdjustments) {
          const machine: string = kpi.values[KpiValue.Machine];
          const isMachine: boolean = isNumber(machine);
          const editedMachine: boolean = isMachine && machine != value;

          const organization: string = kpi.values[KpiValue.Organization];
          const isOrganization: boolean = isNumber(organization);
          const editedOrganization: boolean = isOrganization && organization != value;

          const newValue: boolean = !(editedMachine || editedOrganization);

          if (editedMachine || editedOrganization || newValue) {
              this.state.unsavedAdjustmentsMap[AdjustmentTab.MoveReporting] = true;
          }
      }
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public async onKeyUpEnter(kpi: Kpi, value: string): Promise<void> {
      const tag: string = `${this.tag}.onKeyUpEnter()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      if (browser.name === 'ie') await this.onAdjustmentChange(kpi, value);
  }

  public async onAdjustmentChange(kpi: Kpi, value: string = null): Promise<void> {
      const tag: string = `${this.tag}.onAdjustmentChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
      if (debug) console.log(tag, 'formattedValue:', formattedValue);
      await this.workbench.postAdditionalKpiAdjustment({ id: kpi.id, value: formattedValue });
      await this.reload();
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  private onOrganizationChange(organization: OrganizationEntity): void {
      this.disableWrite = (organization.disableAdditionalKpiWrite || organization.disableDataWrite) && !this.state.userHasAdminAccess;
  }

  private clearAdjustments(): void {
      const tag: string = `${this.tag}.clearAdjustments()`;
      if (this.debug) console.log(tag);
      this.adjustments = [];
      this.setUnsavedAdjustments();
  }

  private async reload() {
      const tag: string = `${this.tag}.reload()`;
      if (this.debug) console.log(tag);
      await Promise.all([
          this.workbench.setUpMoveReporting(),
      ]);
  }

  private setUnsavedAdjustments(): void {
      const tag: string = `${this.tag}.setUnsavedAdjustments()`;
      if (this.debug) console.log(tag);
      this.state.unsavedAdjustmentsMap[AdjustmentTab.MoveReporting] = this.adjustments.length > 0;
  }

  public readonly ForYearAcronym = ForYearAcronym;
  public readonly KpiValue = KpiValue;
  public readonly getUsedKpiValue = getUsedKpiValue;
}
