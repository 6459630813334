import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { LayerRef } from '@vcl/ng-vcl';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { UserSettingsService, UnsavedAdjustment } from 'src/app/services/userSettings.service';
import { StateService } from 'src/app/services/state/state.service';
import { RequestService } from 'src/app/services/request.service';
import { Api, Controller, Cache } from 'src/app/api';
import { getEnumKeys } from 'src/app/utils';
import { AdjustmentButton, AdjustmentButtons } from 'src/app/constants';

@Component({
  selector: 'settings',
  templateUrl: 'settings.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./settings.styl'],
})
export class SettingsComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'SettingsComponent';

  @ViewChild('settingsLayer') private readonly settingsLayer: LayerRef;

  public readonly adjustmentButtons: number[] = getEnumKeys(AdjustmentButton);

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly request: RequestService,
    public readonly user: UserSettingsService,
  ) {
    super(cd, state);
    this.tag = SettingsComponent.Tag;
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  // private async onClearCacheTap(): Promise<void> {
  //   const tag: string = `${this.tag}.onClearCacheTap()`;
  //   await this.clearCache();
  // }
  // private async clearCache(): Promise<Response> {
  //   const tag: string = `${this.tag}.clearCache()`;
  //   const url: string = `${Api[Controller.Cache].endpoints[Cache.Clear].endpoint}`;
  //   if (this.debug) console.log(tag, 'url:', url);
  //   return this.request.get(url);
  // }

  public readonly AdjustmentButton = AdjustmentButton;
  public readonly AdjustmentButtons = AdjustmentButtons;
  public readonly UnsavedAdjustment = UnsavedAdjustment;
}
