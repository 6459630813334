import { Component, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';

export enum Forecast {
  CountryFcModelFc,
  ModelFcCountryFc
}

@Component({
  selector: 'forecastSelector',
  templateUrl: './forecastSelector.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ForecastSelectorComponent extends BaseComponent {
  private static readonly Tag: string = 'ForecastSelectorComponent';

  public forecast: Forecast = Forecast.CountryFcModelFc;
  public forecasts = [
    {
      label: 'Country FC vs Model FC',
      value: Forecast.CountryFcModelFc,
      selected: this.forecast === Forecast.CountryFcModelFc,
    },
    {
      label: 'Model FC vs Country FC',
      value: Forecast.ModelFcCountryFc,
      selected: this.forecast === Forecast.ModelFcCountryFc,
    }
  ];

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = ForecastSelectorComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
  }

  public onForecastSelect(forecast: Forecast): void {
    const tag: string = `${this.tag}.onSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'forecast:', Forecast[forecast]);
    this.forecast = forecast;
    this.forecasts.forEach(forecast => forecast.selected = forecast.value === this.forecast);
  }
}
