import { ForYearAcronym, } from 'src/app/constants';
import { Forecast, } from 'src/app/plotting/constants';
import { Color } from 'src/styles/color';
import { nextFullYearAvailable, getYearShort } from 'src/app/utils';

export interface WalkBar {
  base: number;
  value: number;
  label: string;
  color: string;
  subtype?: boolean;
}

export class Walk {
  private static readonly Tag = 'Walk';

  public tag: string = Walk.Tag;
  public debug: boolean = false;

  public actual: number;
  public endOfYearOrganization: number;
  public nextYearOrganization: number;
  public lastAvailableDate: string;
  public subtypes: WalkSubtype[];

  constructor({ actual, endOfYearOrganization, nextYearOrganization,
    lastAvailableDate, subtypes = [] }: {
      actual?: number, endOfYearOrganization?: number, nextYearOrganization?: number,
      lastAvailableDate?: string, subtypes?: WalkSubtype[]
    } = {}) {
    this.actual = actual;
    this.endOfYearOrganization = endOfYearOrganization;
    this.nextYearOrganization = nextYearOrganization;
    this.lastAvailableDate = lastAvailableDate;
    this.subtypes = subtypes.map(subtype => new WalkSubtype(subtype));
  }

  public format(date: Date, forecast: Forecast): WalkBar[] {
    const tag: string = `${this.tag}.formatWalk()`;
    const debug: boolean = this.debug || false;

    const color: string = forecast === Forecast.Organization ? Color.Organization : Color.Machine;
    const endOfYear: string = forecast === Forecast.Organization ? 'endOfYearOrganization' : 'endOfYearMachine';
    const nextYear: string = forecast === Forecast.Organization ? 'nextYearOrganization' : 'nextYearMachine';

    const yearShort: number = getYearShort(date);

    const actualYtd: WalkBar = {
      base: 0,
      value: this.actual || null,
      label: `${ForYearAcronym} ${yearShort - 1}`,
      color: Color.Actual,
    };
    if (debug) console.log(tag, 'actualYtd:', actualYtd);

    let base: number = actualYtd.value;
    const endOfYearSubKpis: WalkBar[] = this.subtypes.map((kpi, i) => {
      const value: number = kpi[endOfYear] || null;
      const walkBar: WalkBar = {
        base,
        value,
        label: kpi.label,
        color: value < 0 ? Color.Decreasing : Color.Increasing,
        subtype: true,
      };
      base += value;
      return walkBar;
    });
    if (debug) console.log(tag, 'endOfYearSubKpis:', endOfYearSubKpis);

    const endOfYearBar: WalkBar = {
      base: 0,
      value: this[endOfYear] || null,
      label: `${ForYearAcronym} ${yearShort}`,
      color,
    };
    if (debug) console.log(tag, 'endOfYearBar:', endOfYearBar);

    const bars: WalkBar[] = [
      actualYtd,
      ...endOfYearSubKpis,
      endOfYearBar,
    ];

    const nextYearHidden: boolean = !nextFullYearAvailable(date);
    if (debug) console.log(tag, 'nextYearHidden:', nextYearHidden);

    if (!nextYearHidden) {
      base = endOfYearBar.value;
      const nextYearSubKpis: WalkBar[] = this.subtypes.map((kpi, i) => {
        const value: number = kpi[nextYear] || null;
        const walkBar: WalkBar = {
          base,
          value,
          // Add a space to make the labels unique and show up.
          label: `${kpi.label} `,
          color: value < 0 ? Color.Decreasing : Color.Increasing,
          subtype: true,
        };
        base += value;
        return walkBar;
      });
      if (debug) console.log(tag, 'nextYearSubKpis:', nextYearSubKpis);

      const nextYearBar: WalkBar = {
        base: 0,
        value: this[nextYear] || null,
        label: `${ForYearAcronym} ${yearShort + 1}`,
        color,
      };
      if (debug) console.log(tag, 'nextYearBar:', nextYearBar);

      bars.push(...nextYearSubKpis, nextYearBar);
    }

    if (debug) console.log(tag, 'bars:', bars);
    return bars;
  }
}

export class WalkSubtype {
  public label: string;
  public endOfYearOrganization: number;
  public nextYearOrganization: number;

  constructor({ label, endOfYearOrganization, nextYearOrganization }: {
    label?: string, endOfYearOrganization?: number, nextYearOrganization?: number
  } = {}) {
    this.label = label;
    this.endOfYearOrganization = endOfYearOrganization;
    this.nextYearOrganization = nextYearOrganization;
  }
}
