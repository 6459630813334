import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { isAfterMarch } from 'src/app/utils';

@Component({
    selector: 'ratiosDisplay',
    templateUrl: 'ratiosDisplay.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class RatiosDisplayComponent extends BaseComponent {
    private static readonly Tag: string = 'RatiosDisplayComponent';
    protected readonly tag: string = RatiosDisplayComponent.Tag;
    protected readonly debug: boolean = false;

    @Input() public title: string;
    @Input() public showActuals: boolean;
    @Input() public ratios: any;

    constructor(
        protected readonly cd: ChangeDetectorRef,
        public readonly state: StateService) {
        super(cd, state);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        const tag: string = `${this.tag}.ngOnInit()`;
        const debug: boolean = this.debug || false;
    }

    public readonly isAfterMarch = isAfterMarch;
}
