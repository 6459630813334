import { Scale } from 'src/app/constants';
import { Currency } from 'src/app/types';
import { AdditionalKpi, IAdditionalKpiLite } from '../additionalKpis/additionalKpis';

export class ItReportingContainer {
  private static readonly Tag = 'ItReportingContainer';
  private static readonly Debug: boolean = false;

  public tag: string = ItReportingContainer.Tag;
  public debug: boolean = ItReportingContainer.Debug;

  public itReporting: AdditionalKpi[];

  constructor(itReporting: IAdditionalKpiLite[]) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'itReporting:', itReporting);

    var opex = Object.keys(itReporting).reduce((formattedItReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, itReporting[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = itReporting[0].endOfYear.unit;
      formattedItReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedItReporting;
    }, {} as AdditionalKpi[]);
    this.itReporting = opex as AdditionalKpi[] ;
  }

  public displayFormat(scale: Scale, currency: Currency): AdditionalKpi[]  {
    const tag: string = `${this.tag}.displayFormat()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scale:', scale);
    if (debug) console.log(tag, 'currency:', currency);

    const opex: AdditionalKpi[] = Object.keys(this.itReporting).reduce((formattedItReporting, k) => {
      const additionalKpi: AdditionalKpi = this.itReporting[k];
      let usedScale: Scale = Scale.Million;

      formattedItReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedItReporting;
    }, {} as AdditionalKpi[]);


    const formattedItReporting: AdditionalKpi[] = opex;
    if (debug) console.log(tag, 'formattedItReporting', formattedItReporting);
    return formattedItReporting;
  }
}
