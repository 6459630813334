import { Unit, Scale, Precision, NoValue } from 'src/app/constants';
import { isNumber, getScale, getFormatter, absoluteDecimal } from 'src/app/utils';

export interface OneYearAggregatedKpi {
  actual: number;

  endOfYearOrganization: number;
  endOfYearMachine: number;
  endOfYearAdjust: number;

  label: string;
  middleLabel: string;
  shortLabel: string;

  unit: Unit;
}

export class OneYearAggregatedKpi implements OneYearAggregatedKpi {
  public actual: number;

  public endOfYearAdjust: number;
  public endOfYearMachine: number;
  public endOfYearOrganization: number;

  public label: string;
  public middleLabel: string;
  public shortLabel: string;

  public unit: Unit;

  constructor({
    actual,
    endOfYearAdjust, endOfYearMachine, endOfYearOrganization,
    label, middleLabel, shortLabel,
    unit }: {
      actual?: number,
      endOfYearAdjust?: number, endOfYearMachine?: number, endOfYearOrganization?: number,
      label?: string, middleLabel?: string, shortLabel?: string,
      unit?: Unit
    }) {
    this.actual = actual;

    this.endOfYearOrganization = endOfYearOrganization;
    this.endOfYearMachine = endOfYearMachine;
    this.endOfYearAdjust = endOfYearAdjust;

    this.label = label;
    this.middleLabel = middleLabel;
    this.shortLabel = shortLabel;

    this.unit = unit;
  }

  public listValues(includeOrganization: boolean = true, includeAdjustment: boolean = true): number[] {
    return [
      this.actual,
      this.endOfYearMachine,
      includeOrganization ? this.endOfYearOrganization : null,
      includeAdjustment ? this.endOfYearAdjust : null,
    ].filter(isNumber);
  }
}

export interface TwoYearAggregatedKpi extends OneYearAggregatedKpi {
  description: string;

  id: number;

  lastAvailableDate: string;

  name: string;

  nextYearOrganization: number;
  nextYearMachine: number;
  nextYearAdjust: number;
}

export class AggregatedKpi extends OneYearAggregatedKpi implements TwoYearAggregatedKpi {
  private static readonly Tag: string = 'AggregatedKpi';
  private static readonly Debug: boolean = false;

  public description: string;

  public id: number;

  public lastAvailableDate: string;

  public name: string;

  public nextYearAdjust: number;
  public nextYearMachine: number;
  public nextYearOrganization: number;

  constructor({
    actual = null,
    endOfYearAdjust = null, endOfYearMachine = null, endOfYearOrganization = null,
    label, middleLabel, shortLabel,
    unit,

    description,
    id,
    lastAvailableDate,
    name,
    nextYearAdjust = null, nextYearMachine = null, nextYearOrganization = null }: {
      actual?: number,
      endOfYearAdjust?: number, endOfYearMachine?: number, endOfYearOrganization?: number,
      label?: string, middleLabel?: string, shortLabel?: string,
      unit?: Unit,

      description?: string,
      id?: number,
      lastAvailableDate?: string,
      name?: string,
      nextYearOrganization?: number, nextYearMachine?: number, nextYearAdjust?: number,
    } = {}, ) {
    super({ actual, endOfYearAdjust, endOfYearMachine, endOfYearOrganization, label, middleLabel, shortLabel, unit });
    const tag: string = `${AggregatedKpi.Tag}.constructor();`;
    const debug: boolean = false;

    this.lastAvailableDate = lastAvailableDate;

    this.nextYearOrganization = nextYearOrganization;
    this.nextYearMachine = nextYearMachine;
    this.nextYearAdjust = nextYearAdjust;

    if (debug) console.log(tag, 'this:', this);
  }

  public listValues(includeOrganization: boolean = true, includeAdjustment: boolean = true): number[] {
    return [
      this.actual,
      this.endOfYearMachine,
      this.nextYearMachine,
      ...(includeOrganization ? [
        this.endOfYearOrganization,
        this.nextYearOrganization
      ] : []),
      ...(includeAdjustment ? [
        this.endOfYearAdjust,
        this.nextYearAdjust
      ] : []),
    ].filter(isNumber);
  }

  public displayFormat(unitLabel: string, useMachineFallback: boolean, scale?: Scale): AggregatedKpi {
    const tag: string = `${AggregatedKpi.Tag}.format()`;
    const debug: boolean = AggregatedKpi.Debug || false;
    if (debug) console.log(tag, 'unitLabel:', unitLabel);
    if (debug) console.log(tag, 'scale:', scale);

    if (this.unit !== Unit.Currency) {
      scale = Scale.Million;
    } else if (!scale) {
      const values: number[] = this.listValues();
      if (debug) console.log(tag, 'values:', values);
      scale = getScale(values);
    }
    if (debug) console.log(tag, 'scale:', scale);

    const label: string = this.shortLabel || this.middleLabel || this.label;
    const displayUnit: string = `${
      this.unit === Unit.Currency ? scale : ''}${
      this.unit === Unit.Percentage && label.includes('%') ?
        '' : unitLabel
      }`;
    if (debug) console.log(tag, 'displayUnit:', displayUnit);

    const format: Function = getFormatter(this.unit);
    if (debug) console.log(tag, 'format:', format);

    const precision: number = this.unit === Unit.Currency ? Precision : 0;
    if (debug) console.log(tag, 'precision:', precision);

    const isEndYearAdjusted: boolean = isNumber(this.endOfYearAdjust);
    const isEndYearOrganization: boolean = isNumber(this.endOfYearOrganization);

    const isNextYearAdjusted: boolean = isNumber(this.nextYearAdjust);
    const isNextYearOrganization: boolean = isNumber(this.nextYearOrganization);

    const subKpiFyForecast: any = {
      label,

      unit: this.unit,
      displayUnit,

      actual: isNumber(this.actual) ? format(this.actual, scale, precision) : NoValue,

      isEndYearAdjusted,
      isEndYearOrganization,

      isNextYearAdjusted,
      isNextYearOrganization,

      endOfYearMachine: isNumber(this.endOfYearMachine) ? format(this.endOfYearMachine, scale, precision) : NoValue,
      nextYearMachine: isNumber(this.nextYearMachine) ? format(this.nextYearMachine, scale, precision) : NoValue,
    };

    if (isEndYearAdjusted) subKpiFyForecast.endOfYearAdjust = format(this.endOfYearAdjust, scale, precision);
    else if (isEndYearOrganization) subKpiFyForecast.endOfYearOrganization = format(this.endOfYearOrganization, scale, precision);
    else subKpiFyForecast.endOfYearOrganization = useMachineFallback ? subKpiFyForecast.endOfYearMachine : NoValue;

    if (isNextYearAdjusted) subKpiFyForecast.nextYearAdjust = format(this.nextYearAdjust, scale, precision);
    else if (isNextYearOrganization) subKpiFyForecast.nextYearOrganization = format(this.nextYearOrganization, scale, precision);
    else subKpiFyForecast.nextYearOrganization = useMachineFallback ? subKpiFyForecast.nextYearMachine : NoValue;

    return subKpiFyForecast;
  }
}
