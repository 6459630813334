import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { WindowService } from 'src/app/services/window.service';
import { Page } from 'src/app/constants';

// Taken from vclmyfctheme.
export const ExpandedMenuWidth: string = '280px';
export const CollapsedMenuWidth: string = '50px';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'NavigationComponent';

  private smallScreen: boolean = false;

  public NavigationPages;

  constructor(
    public readonly state: StateService,
    public readonly window: WindowService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = NavigationComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.organizationEntity$,
      this.window.isMenuExpanded$,
      this.window.isSmallScreen$,
    ];

    this.subscriptions = [
      this.state.page$.subscribe(this.onPageChange.bind(this))
    ];
  }

  public onPageChange(page: Page) {
    if (page === Page.Planning) {
      this.NavigationPages = [{
        page: Page.Planning,
        icon: 'mdi mdi-calendar-multiple-check'
      }];
    }
    else {
      this.NavigationPages = [  {
        page: Page.Workbench,
        icon: 'mdi mdi-wrench'
      }];
    }
  }

  public readonly Page = Page;
  public readonly ExpandedMenuWidth = ExpandedMenuWidth;
  public readonly CollapsedMenuWidth = CollapsedMenuWidth;
}
