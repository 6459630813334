import { ChangeDetectorRef, Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state/state.service';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { CommonDataService } from 'src/app/services/data/common.data.service';

@Component({
  selector: 'excelRequestBtn',
  templateUrl: 'excelRequestBtn.component.html',
  styleUrls: ['excelRequestBtn.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExcelRequestButtonComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ExcelRequestButtonComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = ExcelRequestButtonComponent.Tag;
    this.debug = false;
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
  }

  public async onRequestBtnTap(): Promise<void> {
    const tag: string = `${this.tag}.onRequestBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    window.open(this.data.getExcelFileUrl());
  }
}
