import * as merge from 'merge';
import { Color } from 'src/styles/color';
import { Axis } from 'src/app/plotting/constants';
import { Trace } from 'src/app/plotting/interfaces';

export class Heatmap {
  static Dfs(options: Trace): Trace {
    return merge.recursive(options, {
      // [Axis.X]: [],
      // [Axis.Y]: [],
      // [Axis.Z]: [],
      // hoverinfo: 'none',
      type: 'heatMap',
      // xgap: 1,
      ygap: 10,
      connectgaps: true,
      showscale: false,
      colorscale: [
        [0, 'rgb(255,155,0)'],
        [0.2, 'rgb(255,135,0)'],
        [0.5, 'rgb(255,75,0)'],
        [0.7, 'rgb(255,25,0)'],
        [1, 'rgb(255,0,0)']
      ],
    });
  }
}
