import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CommonDataService } from 'src/app/services//data/common.data.service';
import { ModalService } from 'src/app/services/modal.service';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RouterService } from 'src/app/services/router.service';
import { UserSettingsService } from 'src/app/services/userSettings.service';
import { LoginGuard } from 'src/app/guards/login.guard';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';

@Injectable()
export class InitializeGuard implements CanActivate {
  private static readonly Tag: string = 'InitializeGuard';
  private readonly tag: string = InitializeGuard.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    private readonly loginGuard: LoginGuard,
    private readonly data: CommonDataService,
    private readonly modal: ModalService,
    public readonly state: StateService,
    private readonly router: RouterService,
    private readonly user: UserSettingsService,
    private readonly configuration: ConfigurationService,
    private readonly workbench: WorkbenchDataService,
  ) { }

  public async canActivate(): Promise<boolean> {
    const tag: string = `${this.tag}.canActivate()`;
    const debug: boolean = this.debug || false;

    if (debug) console.log(tag, 'state.initialized:', this.state.initialized);
    if (this.state.initialized) return true;

    await this.data.setUpReadableCountries();
    if (debug) console.log(tag, 'state.organizationEntity:', this.state.organizationEntity);
    if (this.state.organizationEntity) {
      if (debug) console.log(tag, 'User has read access to a country.');
    }

    await this.data.setUpWriteableCountries();
    if (debug) console.log(tag, 'state.organizationEntity:', this.state.organizationEntity);
    if (this.state.organizationEntity) {
      if (debug) console.log(tag, 'User has write access to a country.');
    } else {
      this.modal.noAccess();
      return false;
    }

    await this.data.setUpUser();
    this.setUpAnalytics();

    await this.data.setUpLastAvailableMonth();
    await this.workbench.setUpScenarios();

    await Promise.all([
      Promise.all([
        this.data.setUpOrganizationAccess(),
        this.data.setUpUserAdminAccess()
      ]).then(),
      this.data.setUpFlags(),
      this.data.setUpNotifications(),
      this.workbench.setUpNavigation(),
    ]);

    this.state.initialized = true;

    return true;
  }

  public setUpAnalytics(): void {
    const tag: string = `${this.tag}.setUpAnalytics()`;
    const debug: boolean = this.debug && false;

    if (debug) console.log(tag, 'ConfigurationService.Production:', ConfigurationService.Production);
    if (ConfigurationService.Development) return;

    const digitalData = {
      page: {
        pageInfo: {
          solutionName: 'myForecast'
        }
      },
      user: {
        profile: {
          attributes: {
            function: 'FC',
            userID: this.state.user.user.identity,
            regionID: this.state.user.regionID,
            plantID: this.state.user.plantID,
          }
        }
      },
    };
    if (debug) console.log(tag, 'digitalData:', digitalData);
    (window as any).digitalData = digitalData;

    const script: HTMLScriptElement = document.createElement('script');
    script.onload = () => {
      const satellite = (window as any)._satellite;
      satellite.setDebug(debug);
      if (debug) console.log(tag, 'satellite:', satellite);
      satellite.pageBottom();
    };

    if (debug) console.log(tag, 'ConfigurationService.Production:', ConfigurationService.Production);
    script.src = ConfigurationService.Production ?
      ConfigurationService.AnalyticsUrlProduction :
      ConfigurationService.AnalyticsUrlStaging;

    document.head.appendChild(script);
  }
}
