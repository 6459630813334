import { StrEnum, DefinedBelow } from './constants';
import { Currency } from './models/common/currency';

export const Controller = StrEnum([
  'Accuracy',
  'AdditionalKpi',
  'Adjust',
  'AdjustmentHistory',
  'Audit',
  'Cache',
  'Comments',
  'Comparison',
  'Configuration',
  'Countries',
  'CurrencySplit',
  'Export',
  'Flags',
  'HeatMap',
  'Import',
  'KpiAggregated',
  'KpiDescription',
  'KpiForecasts',
  'KpiInformation',
  'KpiTypes',
  'KpiValues',
  'LastAvailable',
  'LastModified',
  'Login',
  'Notification',
  'OutputKpi',
  'Ratios',
  'Scenario',
  'Scientists',
  'Submit',
  'Walk',
  'Excel',
  'Navigation',
  'Planning',
]);
export type Controller = keyof typeof Controller;

export const AdditionalKpi = StrEnum([
  'Opex',
  'Personnel',
  'ProfitAndLossBalanceSheet',
  'SalesAndAcquisitions',
  'Composer',
  'MoveReporting',
  'FocusReporting',
  'ItReporting'
]);
export type AdditionalKpi = keyof typeof AdditionalKpi;

export const Planning = StrEnum([
  'Composer',
  'MarketAndRiskData',
  'PlAndBalanceSheetData',
  'EquityAndInsurance',
  'OpexAndIt',
  'FteAndPersonnel',
  'Equity',
  'ItAndProjects',
  'DigitalKpis',
  'Plans',
  'Types',
  'Latest',
]);
export type Planning = keyof typeof Planning;

export const CurrencySplit = StrEnum([
  'Composer',
]);
export type CurrencySplit = keyof typeof CurrencySplit;

export const Adjust = StrEnum([
  'Comment',
  'CreateForecasts',
  'CreateWorkbenchForecasts',
  'DeleteComment',
  'SaveAll',
  'SubmitAll',
  'Undo',
  'UndoAll',
  'ResetAll',
  'SaveAllAdditionalKpi',
  'SubmitAllAdditionalKpi',
  'ResetAllAdditionalKpi',
  'Export',
  'ResetLastSubmission',
  'SubmitAllWithComment',
  'ExportExcel'
]);
export type Adjust = keyof typeof Adjust;


export const AdjustmentHistory = StrEnum([]);
export type AdjustmentHistory = keyof typeof AdjustmentHistory;


export const Audit = StrEnum([
  'FindMissingForecastsMessage',
  'FindMissingActualsMessage',
  'FindDuplicatesMessage',
  'GetSubmittedCountries',
]);
export type Audit = keyof typeof Audit;


export const Cache = StrEnum([
  'Clear'
]);
export type Cache = keyof typeof Cache;


export const Comparison = StrEnum([
  'LastMonth'
]);
export type Comparison = keyof typeof Comparison;


export const Configuration = StrEnum([
  'All',
  'IsWriteable',
  'SetWriteable',
  'AdditionalKpis',
  'Comments',
  'AppliedAdjustments',
  'IsDisableTimeStamp',
  'DisableTimeStamp',
  'IsPlanningVisible',
  'IsWorkbenchVisible',
  'PageVisibility',
  'PowerBiUrl',
]);
export type Configuration = keyof typeof Configuration;

export const ConfigurationAdditionalKpis = StrEnum([
  'IsEnabled',
  'SetEnabled',
]);
export type ConfigurationAdditionalKpis = keyof typeof ConfigurationAdditionalKpis;

export const ConfigurationComments = StrEnum([
  'IsEnabled',
  'SetEnabled',
]);
export type ConfigurationComments = keyof typeof ConfigurationComments;

export const ConfigurationAppliedAdjustments = StrEnum([
  'IsEnabled',
  'SetEnabled',
]);
export type ConfigurationAppliedAdjustments = keyof typeof ConfigurationAppliedAdjustments;


export const Comments = StrEnum([
  'Type',
  'Forecast',
  'Country'
]);
export type Comments = keyof typeof Comments;


export const Countries = StrEnum([
  'ToggleCountryVisibility',
  'ToggleCountryDataSubmit',
  'IsCurrencySplit'
]);
export type Countries = keyof typeof Countries;


export const Export = StrEnum([
  'Server'
]);
export type Export = keyof typeof Export;


export const Import = StrEnum([
  'GetImportNames'
]);
export type Import = keyof typeof Import;


export const KpiValuesApi = StrEnum([
  'Children',
  'Composer'
]);
export type KpiValuesApi = keyof typeof KpiValuesApi;


export const Ratios = StrEnum([
  'AvgAssets',
  'AvgPortfolio',
  'AvgPortfolioToCoCR',
  'CoCRPercent',
  'IrPercentPortfolio',
  'OperatingRatio',
  'SumCoCR',
  'SumIR',
  'SumOpex'
]);
export type Ratios = keyof typeof Ratios;


export const LastAvailable = StrEnum([
  'Forecast'
]);
export type LastAvailable = keyof typeof LastAvailable;

export const LastAvailableForecast = StrEnum([
  'AsOf',
  'PerMonth'
]);
export type LastAvailableForecast = keyof typeof LastAvailableForecast;


export const Notification = StrEnum([
  'StatusMessage',
  'PlanningStatusMessage',
  'HasRead',
]);
export type Notification = keyof typeof Notification;


export const Login = StrEnum([
  'User',
  'HasAdminAccess',
  'HasWriteAccess',
  'ListWriteableCountries'
]);
export type Login = keyof typeof Login;

export const OutputKpi = StrEnum([
  'Ratios',
  'SalesAndAcquisitionUnits',
]);
export type OutputKpi = keyof typeof OutputKpi;


export const ScenarioApi = StrEnum([
  'Save',
  'List',
]);
export type ScenarioApi = keyof typeof ScenarioApi;

export const ScenarioSave = StrEnum([
  'WithModel',
]);
export type ScenarioSave = keyof typeof ScenarioSave;

export const KpiAggregated = StrEnum([
  'Fy',
]);
export type KpiAggregated = keyof typeof KpiAggregated;


export const Parameter = StrEnum([
  'Kpi',
  'Organization',
  'Currency',
  'DatePickerDate',
  'Scenario',
  'Type'
]);
export type Parameter = keyof typeof Parameter;

export const Parameters = {
  Set3: [Parameter.Kpi, Parameter.Organization, Parameter.DatePickerDate],
  Set1: [Parameter.Kpi, Parameter.Organization, Parameter.DatePickerDate, Parameter.Currency],
  Set2: [Parameter.Kpi, Parameter.Organization, Parameter.DatePickerDate, Parameter.Currency, Parameter.Scenario],
  Set7: [Parameter.Organization],
  Set8: [Parameter.Organization, Parameter.Scenario],
  Set9: [Parameter.Organization, Parameter.Currency],
  Set10: [Parameter.Organization, Parameter.DatePickerDate],
  Set11: [Parameter.Organization, Parameter.Type],
  Set4: [Parameter.Organization, Parameter.Currency, Parameter.DatePickerDate],
  Set5: [Parameter.Organization, Parameter.Currency, Parameter.DatePickerDate, Parameter.Scenario],
  Set6: [Parameter.Organization, Parameter.Currency, Parameter.Currency, Parameter.DatePickerDate, Parameter.Scenario]
};

export const ApiBase: string = 'Api';
export const Composer: string = 'Composer';

const Api: any = {
  [Controller.Accuracy]: {
    endpoint: `${ApiBase}/${Controller.Accuracy}`,
    parameters: Parameters.Set2,
  },
  [Controller.Adjust]: {
    endpoint: `${ApiBase}/${Controller.Adjust}`,
    endpoints: DefinedBelow,
  },
  [Controller.AdjustmentHistory]: {
    endpoint: `${ApiBase}/${Controller.AdjustmentHistory}/${Composer}`,
    parameters: [Parameter.Organization],
  },
  [Controller.Audit]: {
    endpoint: `${ApiBase}/${Controller.Audit}`,
    endpoints: DefinedBelow,
  },
  [Controller.Comparison]: {
    endpoint: `${ApiBase}/${Controller.Comparison}`,
    parameters: Parameters.Set2,
    endpoints: DefinedBelow,
  },
  [Controller.Cache]: {
    endpoint: `${ApiBase}/${Controller.Cache}`,
    endpoints: DefinedBelow,
  },
  [Controller.Countries]: {
    endpoint: `${ApiBase}/${Controller.Countries}`,
    endpoints: DefinedBelow,
  },
  [Controller.Configuration]: {
    endpoint: `${ApiBase}/${Controller.Configuration}`,
    endpoints: DefinedBelow,
  },
  [Controller.Comments]: {
    endpoint: `${ApiBase}/${Controller.Comments}`,
    endpoints: DefinedBelow,
  },
  [Controller.Export]: {
    endpoint: `${ApiBase}/${Controller.Export}`,
  },
  [Controller.Flags]: {
    endpoint: `${ApiBase}/${Controller.Flags}`,
  },
  [Controller.Excel]: {
    endpoint: `${ApiBase}/${Controller.Excel}`,
  },
  [Controller.HeatMap]: {
    endpoint: `${ApiBase}/${Controller.HeatMap}`,
    parameters: Parameters.Set2,
  },
  [Controller.Import]: {
    endpoint: `${ApiBase}/${Controller.Import}`,
    endpoints: DefinedBelow,
  },
  [Controller.KpiAggregated]: {
    endpoint: `${ApiBase}/${Controller.KpiAggregated}`,
    parameters: Parameters.Set2,
    endpoints: DefinedBelow,
  },
  [Controller.KpiDescription]: {
    endpoint: `${ApiBase}/${Controller.KpiDescription}`,
    parameters: Parameters.Set3,
  },
  [Controller.KpiForecasts]: {
    endpoint: `${ApiBase}/${Controller.KpiForecasts}`,
    parameters: Parameters.Set2,
  },
  [Controller.KpiInformation]: {
    endpoint: `${ApiBase}/${Controller.KpiInformation}`,
    parameters: Parameters.Set1,
  },
  [Controller.KpiTypes]: {
    endpoint: `${ApiBase}/${Controller.KpiTypes}`,
    endpoints: DefinedBelow,
  },
  [Controller.KpiValues]: {
    endpoint: `${ApiBase}/${Controller.KpiValues}`,
    parameters: Parameters.Set2,
    endpoints: DefinedBelow,
  },
  [Controller.Ratios]: {
    endpoint: `${ApiBase}/${Controller.Ratios}`,
    endpoints: DefinedBelow,
  },
  [Controller.Notification]: {
    endpoint: `${ApiBase}/${Controller.Notification}`,
    parameters: Parameters.Set4,
    endpoints: DefinedBelow,
  },
  [Controller.Scientists]: {
    parameters: [Parameter.Organization],
  },
  [Controller.Walk]: {
    endpoint: `${ApiBase}/${Controller.Walk}`,
    parameters: Parameters.Set2,
  },
  [Controller.Login]: {
    endpoint: `${ApiBase}/${Controller.Login}`,
    endpoints: DefinedBelow,
  },
  [Controller.LastAvailable]: {
    endpoint: `${ApiBase}/${Controller.LastAvailable}`,
    endpoints: DefinedBelow,
  },
  [Controller.LastModified]: {
    endpoint: `${ApiBase}/${Controller.LastModified}`,
    parameters: [Parameter.Organization],
    endpoints: DefinedBelow,
  },
  [Controller.AdditionalKpi]: {
    endpoint: `${ApiBase}/${Controller.AdditionalKpi}`,
    endpoints: DefinedBelow,
  },
  [Controller.CurrencySplit]: {
    endpoint: `${ApiBase}/${Controller.CurrencySplit}`,
    endpoints: DefinedBelow
  },
  [Controller.OutputKpi]: {
    endpoint: `${ApiBase}/${Controller.OutputKpi}`,
    parameters: Parameters.Set2,
    endpoints: DefinedBelow,
  },
  [Controller.Scenario]: {
    endpoint: `${ApiBase}/${Controller.Scenario}`,
    endpoints: DefinedBelow,
  },
  [Controller.Navigation]: {
    endpoint: `${ApiBase}/${Controller.Navigation}`,
    parameters: [Parameter.Organization],
    endpoints: DefinedBelow,
  },
  [Controller.Planning]: {
    endpoint: `${ApiBase}/${Controller.Planning}`,
    endpoints: DefinedBelow,
  },
};

Api[Controller.Adjust].endpoints = {
  [Adjust.CreateForecasts]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.CreateForecasts}`,
  },
  [Adjust.CreateWorkbenchForecasts]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.CreateWorkbenchForecasts}`,
  },
  [Adjust.Comment]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.Comment}`,
  },
  [Adjust.DeleteComment]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.DeleteComment}`,
  },
  [Adjust.SaveAll]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.SaveAll}`,
  },
  [Adjust.SubmitAll]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.SubmitAll}`,
  },
  [Adjust.SubmitAllWithComment]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.SubmitAllWithComment}`,
  },
  [Adjust.Undo]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.Undo}`,
  },
  [Adjust.UndoAll]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.UndoAll}`,
  },
  [Adjust.ResetAll]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.ResetAll}`,
  },
  [Adjust.SaveAllAdditionalKpi]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.SaveAll}`,
  },
  [Adjust.SubmitAllAdditionalKpi]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.SubmitAll}`,
  },
  [Adjust.ResetAllAdditionalKpi]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Adjust.ResetAll}`,
  },
  [Adjust.Export]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.Export}`,
  },
  [Adjust.ExportExcel]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.ExportExcel}`,
  },
  [Adjust.ResetLastSubmission]: {
    endpoint: `${Api[Controller.Adjust].endpoint}/${Composer}/${Adjust.ResetLastSubmission}`,
  },
};

Api[Controller.Audit].endpoints = {
  [Audit.FindMissingActualsMessage]: {
    endpoint: `${Api[Controller.Audit].endpoint}/${Audit.FindMissingActualsMessage}`,
  },
  [Audit.FindMissingForecastsMessage]: {
    endpoint: `${Api[Controller.Audit].endpoint}/${Audit.FindMissingForecastsMessage}`,
  },
  [Audit.FindDuplicatesMessage]: {
    endpoint: `${Api[Controller.Audit].endpoint}/${Audit.FindDuplicatesMessage}`,
  },
  [Audit.GetSubmittedCountries]: {
    endpoint: `${Api[Controller.Audit].endpoint}/${Audit.GetSubmittedCountries}`,
  },
};

Api[Controller.Cache].endpoints = {
  [Cache.Clear]: {
    endpoint: `${Api[Controller.Cache].endpoint}/${Cache.Clear}`,
  },
};

Api[Controller.Comparison].endpoints = {
  [Comparison.LastMonth]: {
    endpoint: `${Api[Controller.Comparison].endpoint}/${Comparison.LastMonth}`,
    parameters: Parameters.Set2,
  },
};

Api[Controller.Countries].endpoints = {
  [Countries.ToggleCountryVisibility]: {
    endpoint: `${Api[Controller.Countries].endpoint}/${Countries.ToggleCountryVisibility}`,
  },
  [Countries.ToggleCountryDataSubmit]: {
    endpoint: `${Api[Controller.Countries].endpoint}/${Countries.ToggleCountryDataSubmit}`,
  },
  [Countries.IsCurrencySplit]: {
    endpoint: `${Api[Controller.Countries].endpoint}/${Countries.IsCurrencySplit}`,
    parameters: Parameters.Set7,
  },
};

Api[Controller.Configuration].endpoints = {
  [Configuration.All]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.All}`,
  },
  [Configuration.IsWriteable]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.IsWriteable}`,
  },
  [Configuration.SetWriteable]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.SetWriteable}`,
  },
  [Configuration.AdditionalKpis]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.AdditionalKpis}`,
    endpoints: DefinedBelow,
  },
  [Configuration.Comments]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.Comments}`,
    endpoints: DefinedBelow,
  },
  [Configuration.AppliedAdjustments]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.AppliedAdjustments}`,
    endpoints: DefinedBelow,
  },
  [Configuration.IsDisableTimeStamp]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.IsDisableTimeStamp}`
  },
  [Configuration.DisableTimeStamp]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.DisableTimeStamp}`
  },
  [Configuration.IsPlanningVisible]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.IsPlanningVisible}`
  },
  [Configuration.IsWorkbenchVisible]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.IsWorkbenchVisible}`
  },
  [Configuration.PageVisibility]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.PageVisibility}`
  },
  [Configuration.PowerBiUrl]: {
    endpoint: `${Api[Controller.Configuration].endpoint}/${Configuration.PowerBiUrl}`
  },
};

Api[Controller.Configuration].endpoints[Configuration.AdditionalKpis].endpoints = {
  [ConfigurationAdditionalKpis.IsEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.AdditionalKpis].endpoint}/${ConfigurationAdditionalKpis.IsEnabled}`,
  },
  [ConfigurationAdditionalKpis.SetEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.AdditionalKpis].endpoint}/${ConfigurationAdditionalKpis.SetEnabled}`,
  },
};

Api[Controller.Configuration].endpoints[Configuration.Comments].endpoints = {
  [ConfigurationComments.IsEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.Comments].endpoint}/${ConfigurationComments.IsEnabled}`,
  },
  [ConfigurationComments.SetEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.Comments].endpoint}/${ConfigurationComments.SetEnabled}`,
  },
};

Api[Controller.Configuration].endpoints[Configuration.AppliedAdjustments].endpoints = {
  [ConfigurationAppliedAdjustments.IsEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.AppliedAdjustments].endpoint}/${ConfigurationAppliedAdjustments.IsEnabled}`,
  },
  [ConfigurationAppliedAdjustments.SetEnabled]: {
    endpoint: `${Api[Controller.Configuration].endpoints[Configuration.AppliedAdjustments].endpoint}/${ConfigurationAppliedAdjustments.SetEnabled}`,
  },
};

Api[Controller.Comments].endpoints = {
  [Comments.Forecast]: {
    endpoint: `${Api[Controller.Comments].endpoint}/${Comments.Forecast}`,
  },
  [Comments.Type]: {
    endpoint: `${Api[Controller.Comments].endpoint}/${Comments.Type}`,
  },
  [Comments.Country]: {
    endpoint: `${Api[Controller.Comments].endpoint}/${Comments.Country}`,
    parameters: [Parameter.Organization],
  },
};

Api[Controller.Export].endpoints = {
  [Export.Server]: {
    endpoint: `${Api[Controller.Export].endpoint}/${Export.Server}`,
  },
};

Api[Controller.KpiValues].endpoints = {
  [KpiValuesApi.Children]: {
    endpoint: `${Api[Controller.KpiValues].endpoint}/${KpiValuesApi.Children}`,
    parameters: Parameters.Set2,
  },
  [KpiValuesApi.Composer]: {
    endpoint: `${Api[Controller.KpiValues].endpoint}/${Composer}`,
    parameters: Parameters.Set2,
  },
};

Api[Controller.Ratios].endpoints = {
  [Ratios.AvgAssets]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.AvgAssets}`,
  },
  [Ratios.AvgPortfolio]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.AvgPortfolio}`,
  },
  [Ratios.AvgPortfolioToCoCR]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.AvgPortfolioToCoCR}`,
  },
  [Ratios.CoCRPercent]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.CoCRPercent}`,
  },
  [Ratios.IrPercentPortfolio]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.IrPercentPortfolio}`,
  },
  [Ratios.OperatingRatio]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.OperatingRatio}`,
  },
  [Ratios.SumCoCR]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.SumCoCR}`,
  },
  [Ratios.SumIR]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.SumIR}`,
  },
  [Ratios.SumOpex]: {
    endpoint: `${Api[Controller.Ratios].endpoint}/${Ratios.SumOpex}`,
  },
};

Api[Controller.Login].endpoints = {
  [Login.User]: {
    endpoint: `${Api[Controller.Login].endpoint}/${Login.User}`,
  },
  [Login.HasAdminAccess]: {
    endpoint: `${Api[Controller.Login].endpoint}/${Login.HasAdminAccess}`,
  },
  [Login.HasWriteAccess]: {
    endpoint: `${Api[Controller.Login].endpoint}/${Login.HasWriteAccess}`,
    parameters: [Parameter.Organization],
  },
  [Login.ListWriteableCountries]: {
    endpoint: `${Api[Controller.Login].endpoint}/${Login.ListWriteableCountries}`,
  },
};

Api[Controller.LastAvailable].endpoints = {
  [LastAvailable.Forecast]: {
    endpoint: `${Api[Controller.LastAvailable].endpoint}/${LastAvailable.Forecast}`,
    endpoints: DefinedBelow,
  }
};

Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast].endpoints = {
  [LastAvailableForecast.AsOf]: {
    endpoint: `${Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast].endpoint}/${LastAvailableForecast.AsOf}`,
    parameters: [Parameter.Organization],
  },
  [LastAvailableForecast.PerMonth]: {
    endpoint: `${Api[Controller.LastAvailable].endpoints[LastAvailable.Forecast].endpoint}/${LastAvailableForecast.PerMonth}`,
    parameters: [Parameter.Organization],
  },
};

Api[Controller.CurrencySplit].endpoints = {
  [CurrencySplit.Composer]: {
    endpoint: `${Api[Controller.CurrencySplit].endpoint}/${CurrencySplit.Composer}`,
    parameters: Parameters.Set6,
  }
};

Api[Controller.AdditionalKpi].endpoints = {
  [AdditionalKpi.SalesAndAcquisitions]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.SalesAndAcquisitions}`,
    parameters: Parameters.Set4,
  },
  [AdditionalKpi.ProfitAndLossBalanceSheet]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.ProfitAndLossBalanceSheet}`,
    parameters: Parameters.Set4,
  },
  [AdditionalKpi.Personnel]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Personnel}`,
    parameters: Parameters.Set4,
  },
  [AdditionalKpi.Opex]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Opex}`,
    parameters: Parameters.Set4,
  },
  [AdditionalKpi.Composer]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Composer}`,
    parameters: Parameters.Set5,
  },
  [AdditionalKpi.MoveReporting]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Composer}/${AdditionalKpi.MoveReporting}`,
    parameters: Parameters.Set5
  },
  [AdditionalKpi.FocusReporting]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Composer}/${AdditionalKpi.FocusReporting}`,
    parameters: Parameters.Set5
  },
  [AdditionalKpi.ItReporting]: {
    endpoint: `${Api[Controller.AdditionalKpi].endpoint}/${AdditionalKpi.Composer}/${AdditionalKpi.ItReporting}`,
    parameters: Parameters.Set5
  }
};

Api[Controller.Planning].endpoints = {
  [Planning.Composer]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.Composer}`,
    parameters: Parameters.Set10,
  },
  [Planning.Latest]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.Latest}`,
    parameters: Parameters.Set11,
  },
  [Planning.MarketAndRiskData]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.MarketAndRiskData}`,
    parameters: Parameters.Set10,
  },
  [Planning.PlAndBalanceSheetData]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.PlAndBalanceSheetData}`,
    parameters: Parameters.Set10,
  },
  [Planning.EquityAndInsurance]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.EquityAndInsurance}`,
    parameters: Parameters.Set10,
  },
  [Planning.OpexAndIt]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.OpexAndIt}`,
    parameters: Parameters.Set10,
  },
  [Planning.FteAndPersonnel]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.FteAndPersonnel}`,
    parameters: Parameters.Set10,
  },
  [Planning.DigitalKpis]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.DigitalKpis}`,
    parameters: Parameters.Set10,
  },
  [Planning.ItAndProjects]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.ItAndProjects}`,
    parameters: Parameters.Set10,
  },
  [Planning.Equity]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.Equity}`,
    parameters: Parameters.Set10,
  },
  [Planning.Plans]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.Plans}`,
    parameters: Parameters.Set11,
  },
  [Planning.Types]: {
    endpoint: `${Api[Controller.Planning].endpoint}/${Planning.Types}`,
    parameters: Parameters.Set7,
  }
};

Api[Controller.Import].endpoints = {
  [Import.GetImportNames]: {
    endpoint: `${Api[Controller.Import].endpoint}/${Import.GetImportNames}`,
  }
};

Api[Controller.Notification].endpoints = {
  [Notification.StatusMessage]: {
    endpoint: `${Api[Controller.Notification].endpoint}/${Notification.StatusMessage}`,
    parameters: [Parameter.Organization, Parameter.DatePickerDate]
  },
  [Notification.PlanningStatusMessage]: {
    endpoint: `${Api[Controller.Notification].endpoint}/${Notification.PlanningStatusMessage}`,
    parameters: [Parameter.Organization, Parameter.DatePickerDate]
  },
  [Notification.HasRead]: {
    endpoint: `${Api[Controller.Notification].endpoint}/${Notification.HasRead}`,
  }
};

Api[Controller.OutputKpi].endpoints = {
  [OutputKpi.Ratios]: {
    endpoint: `${Api[Controller.OutputKpi].endpoint}/${OutputKpi.Ratios}`,
    parameters: [Parameter.Organization, Parameter.DatePickerDate, Parameter.Scenario]
  },
  [OutputKpi.SalesAndAcquisitionUnits]: {
    endpoint: `${Api[Controller.OutputKpi].endpoint}/${OutputKpi.SalesAndAcquisitionUnits}`,
    parameters: [Parameter.Organization, Parameter.DatePickerDate, Parameter.Scenario]
  }
};

Api[Controller.Scenario].endpoints = {
  [ScenarioApi.Save]: {
    endpoint: `${Api[Controller.Scenario].endpoint}/${ScenarioApi.Save}`,
    endpoints: DefinedBelow,
  },
  [ScenarioApi.List]: {
    endpoint: `${Api[Controller.Scenario].endpoint}/${ScenarioApi.List}`,
    parameters: Parameters.Set9,
  },
};

Api[Controller.Scenario].endpoints[ScenarioApi.Save].endpoints = {
  [ScenarioSave.WithModel]: {
    endpoint: `${Api[Controller.Scenario].endpoints[ScenarioApi.Save].endpoint}/${ScenarioSave.WithModel}`,
  }
};

Api[Controller.KpiAggregated].endpoints = {
  [KpiAggregated.Fy]: {
    endpoint: `${Api[Controller.KpiAggregated].endpoint}/${Composer}/${KpiAggregated.Fy}`,
  }
};

const PlotApi = {
  FyForecast: {
    endpoint: `${Api[Controller.KpiAggregated].endpoint}/${Composer}`,
    parameters: Api[Controller.KpiAggregated].parameters,
  },
  AreaChart: {
    endpoint: `${ApiBase}/${Controller.KpiValues}/${Composer}/AreaChart`,
    parameters: Api[Controller.KpiValues].parameters,
  },
  FcAccuracy: {
    endpoint: `${Api[Controller.Accuracy].endpoint}/${Composer}`,
    parameters: Api[Controller.Accuracy].parameters,
  },
  ActualEffect: {
    endpoint: `${Api[Controller.Comparison].endpoint}/${Composer}`,
    parameters: Api[Controller.Comparison].parameters,
  },
  FyDevelopment: {
    endpoint: `${ApiBase}/${Controller.KpiForecasts}/${Composer}/FyDevelopment`,
    parameters: Parameters.Set2,
  },
  FyComponents: {
    endpoint: `${ApiBase}/${Controller.KpiAggregated}/${Composer}`,
    parameters: Api[Controller.KpiAggregated].parameters,
  },
};

export {
  Api,
  PlotApi,
};
