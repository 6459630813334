import * as merge from 'merge';
import { Precision } from 'src/app/constants';
import { Color } from 'src/styles/color';
import { Fonts } from 'src/app/plotting/font';

export const DateFormat1: string = '%b %y';
export const DateFormat2: string = 'as of %b %y';
export const DateFormat3: string = 'as of %b';

export const NumberFormat1: string = '.2f';
// Signed (positive and negative) numbers.
export const NumberFormat2: string = `+.2s`;

export class Layout {
  static get Dfs() {
    return {
      autosize: true,
      font: Fonts.Dfs(),
      showlegend: false,
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent',
      yaxis: {
        side: 'right',
        showline: false,
        linecolor: Color.Line,
        showgrid: true,
        gridcolor: Color.Line,
        zeroline: true,
        zerolinecolor: Color.Line,
        zerolinewidth: 1,
      },
      xaxis: {
        showline: false,
        linecolor: Color.Line,
        zeroline: false,
        zerolinecolor: Color.Line,
        showgrid: true,
        gridcolor: Color.Line,
      },
      margin: {
        l: 0,
        t: 0,
        r: 0,
        b: 0,
      },
    };
  }

  static get NoAction() {
    return merge.recursive({
      xaxis: {
        fixedrange: true,
      },
      yaxis: {
        fixedrange: true,
      }
    });
  }

  static get Scatter() {
    return merge.recursive(Layout.Dfs, {
      dragmode: 'pan',
      xaxis: {
        type: 'date',
        // dtick: 'M3',
        tickangle: '0',
        tickformat: DateFormat1,
      },
      yaxis: {
        // tickformat: '.s',
        // fixedrange: true,
        separatethousands: true,
      },
      margin: {
        r: 50,
        b: 20,
      },
    });
  }

  static get MonthlyForecast() {
    return merge.recursive(Layout.Scatter, {
      xaxis: {
        dtick: 'M1'
      }
    });
  }

  static get FyForecast() {
    return merge.recursive(Layout.Dfs, {
      // barmode: 'stack',
      bargap: 0.25,
      yaxis: {
        showgrid: false,
        // zeroline: false,
      },
      xaxis: {
        showgrid: false,
      },
    });
  }

  static get WorkbenchFyForecast() {
    return merge.recursive(Layout.Dfs, {
      font: Fonts.Smaller(),
      xaxis: {
        showgrid: false,
      },
      yaxis: {
        showgrid: false,
      },
      margin: {
        b: 20,
      }
    });
  }

  static get PortfolioFyForecast() {
    return merge.recursive(Layout.Dfs, {
      yaxis: {
        showgrid: false,
        zeroline: false,
      },
      xaxis: {
        showgrid: false,
      },
    });
  }

  static get FcAccuracy() {
    return merge.recursive(Layout.Dfs, Layout.NoAction, {
      bargap: 0.6,
      xaxis: {
        zeroline: true,
        showgrid: false,
        fixedrange: true,
      },
      yaxis: {
        visible: false,
      },
      margin: {
        r: 20,
        b: 20,
      }
    });
  }

  static get FyDevelopment() {
    return merge.recursive(Layout.Scatter, Layout.NoAction, {
      yaxis: {
        zeroline: false,
      },
      xaxis: {
        tickformat: DateFormat2,
      },
      // margin: {
      //   r: 20,
      // },
    });
  }

  static get Walk() {
    return merge.recursive(Layout.Dfs, {
      // Defined in createWalk().
      // bargap:
      barmode: 'stack',
      margin: {
        r: 50,
        b: 20,
      },
      xaxis: {
        showgrid: false,
      }
    });
  }

  static get FyComponents() {
    return merge.recursive(Layout.Dfs, Layout.NoAction, {
      barmode: 'group',
      bargap: 0.4,
      bargroupgap: 0.35,
      xaxis: {
        showgrid: false,
        autorange: false,
      },
      yaxis: {
        visible: false,
      },
      margin: {
        l: 10,
        r: 10,
        b: 20,
      },
    });
  }

  static get ActualEffect() {
    return merge.recursive(Layout.FyComponents, {
      bargap: 0.5,
      bargroupgap: 0.15,
      xaxis: {
        tickformat: DateFormat3,
      },
      margin: {
        l: 0,
        r: 0,
      },
    });
  }

  static get DeltaCountryFcToModelFc() {
    return merge.recursive(Layout.Scatter, {
      bargap: 0.5,
      xaxis: {
        dtick: 'M1'
      },
      yaxis: {
        tickformat: NumberFormat2,
        separatethousands: true,
      },
    });
  }

  static get AreaChart() {
    return merge.recursive(Layout.Scatter, Layout.NoAction, {
      yaxis: {
        tickformat: NumberFormat1,
      }
    });
  }

  static get FyRatio() {
    return merge.recursive(Layout.Dfs, {
      width: 300,
      height: 165,
      margin: {
        b: 20,
      },
      bargap: 0.55,
      barmode: 'group',
      bargroupgap: 0.3,
      xaxis: {
        showgrid: false,
      },
      yaxis: {
        visible: false,
      }
    });
  }

  static get StackedBarPlot() {
    return merge.recursive(Layout.Dfs, {
      barmode: 'relative',
      xaxis: {
        fixedrange: true,
        position: 1,
        tickangle: '0',
        showline: false,
      },
      yaxis: {
        fixedrange: true,
        showline: false,
        zeroline: false,
        showgrid: false,
      },
    });
  }

  static get StackedPlot() {
    return merge.recursive(Layout.Dfs, {
      paper_bgcolor: Color.Black,
      xaxis: {
        // Used for testing when setting the x-axis to numbers from 0 to 17.
        // dtick: 1,
        // tickformat: '%Y',
        showline: true,
        // range: [0, PreviousFcMachineFcYearGap * 2],
      },
      yaxis: {
        // tickmode: 'array',
        side: 'left',
        zeroline: false,
        showgrid: false,
        showline: false,
        // range: [0, PreviousFcMachineFcPlotCount],
      },
    });
  }
}
