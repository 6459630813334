import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { StateService } from './state/state.service';
import { ConfigurationService } from '../services/configuration.service';
import { Page } from 'src/app/constants';

@Injectable()
export class RouterService {
  private static readonly Tag: string = 'RouterService';
  private readonly tag: string = RouterService.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  private subscriptions: Subscription[];

  constructor(
    private readonly router: Router,
    public readonly state: StateService,
  ) {
    this.subscriptions = [
      this.state.page$.pipe(skip(1)).subscribe(this.onPageChange.bind(this))
    ];
  }

  public async onPageChange(page: Page): Promise<boolean> {
    const tag: string = `${this.tag}.onPageChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'page:', page);
    return this.goTo(page);
  }

  public async goTo(route: string, skipLocationChange: boolean = false): Promise<boolean> {
    const tag: string = `${this.tag}.goTo()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'route:', route);
    if (debug) console.log(tag, 'skipLocationChange:', skipLocationChange);

    const success: boolean = await this.router.navigate([route.toLowerCase()], {
      skipLocationChange,
    });
    if (debug) console.log(tag, 'success:', success);
    return success;
  }
}

