import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService, OrganizationEntity } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { ForYearAcronym, KpiValue, Adjustment, AdjustmentTab } from 'src/app/constants';
import {
  isNumber, sortAdjustments, isAdjustedRemoveComma, dateToMonthPickerStr,
  createDate, removeCommaSeparatorNum, removeExtraSigns, getUsedKpiValue, getYearShort, isAfterMarch
} from 'src/app/utils';
import { Kpi } from 'src/app/models/common/kpiValues';
import { browser } from 'src/app/app.component';
import { ItReportingContainer } from 'src/app/models/itReporting/itReporting';
import { AdditionalKpi } from 'src/app/models/additionalKpis/additionalKpis';

@Component({
  selector: 'itReporting',
  templateUrl: 'itReporting.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ItReportingComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ItReportingComponent';
  protected readonly tag: string = ItReportingComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  public disableWrite: boolean;
  public adjustments: Adjustment[] = [];
  public opex: AdditionalKpi[] = [];

  public years: number[];

  constructor(
      protected readonly cd: ChangeDetectorRef,
      public readonly state: StateService,
      private readonly workbench: WorkbenchDataService,
  ) {
      super(cd, state);
  }

  public ngOnInit(): void {
      super.ngOnInit();

      const tag: string = `${this.tag}.ngOnInit()`;
      if (this.debug) console.log(tag);

      this.subscriptions = [
          this.workbench.itReporting$.subscribe(this.onItReportingChange.bind(this)),
          this.state.organizationEntity$.subscribe(this.onOrganizationChange.bind(this))
      ];
  }

  private onItReportingChange(itReportingContainer: ItReportingContainer): void {
      const tag: string = `${this.tag}.onItReportingChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'itReportingContainer', itReportingContainer);
      if (!itReportingContainer) return;

      const itReporting: AdditionalKpi[] = itReportingContainer.displayFormat(null, this.state.currency);
      if (debug) console.log(tag, 'itReporting', itReporting);

      const years: number[] = itReporting[0].values.map(kpi => getYearShort(createDate(kpi.date)));
      if (debug) console.log(tag, 'years', years);

      const opex: AdditionalKpi[] = [];
      Object.keys(itReporting).map(function(key){  
        opex.push(itReporting[key])  
        return opex;  
      });
      
      const adjustments = this.getItReportingValues(itReporting)
          .filter(kpi => isAdjustedRemoveComma(kpi) && kpi.id)
          .sort(sortAdjustments)
          .reverse()
          .map(kpi => ({
              forecastId: kpi.id,
              perMonth: dateToMonthPickerStr(createDate(kpi.date)),
              adjustValue: removeCommaSeparatorNum(removeExtraSigns(kpi.values[KpiValue.Adjusted])),
          }));
      if (debug) console.log(tag, 'adjustments:', adjustments);

      Object.assign(this, {
          years,
          opex,
          adjustments,
      });

      this.setUnsavedAdjustments();
      this.setLoadingStatus();
  }

  private getItReportingValues(itReporting: AdditionalKpi[]): Kpi[] {
      const tag: string = `${this.tag}.getItReportingValues()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'itReporting:', itReporting);

      const kpis: Kpi[] = Object.keys(itReporting).reduce((kpis, k) =>
          [...kpis, ...itReporting[k].values.filter(kpi => kpi.id > 0)], []);
      if (debug) console.log(tag, 'kpis:', kpis);
      return kpis;
  }

  public onAdjustmentClick(kpi: Kpi, value: number): void {
      const tag: string = `${this.tag}.onAdjustmentClick()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
  }

  public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
      const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'value:', value);

      event = event || window.event;
      const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
      const char: string = String.fromCharCode(charCode);
      if (debug) console.log(tag, 'char:', char);
      const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
      if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

      if (!isNumber(char) && !isValidSymbol) return false;
      return true;
  }

  public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
      const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);

      if (!this.state.unsavedAdjustments) {
          const machine: string = kpi.values[KpiValue.Machine];
          const isMachine: boolean = isNumber(machine);
          const editedMachine: boolean = isMachine && machine != value;

          const organization: string = kpi.values[KpiValue.Organization];
          const isOrganization: boolean = isNumber(organization);
          const editedOrganization: boolean = isOrganization && organization != value;

          const newValue: boolean = !(editedMachine || editedOrganization);

          if (editedMachine || editedOrganization || newValue) {
              this.state.unsavedAdjustmentsMap[AdjustmentTab.ItReporting] = true;
          }
      }
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public async onKeyUpEnter(kpi: Kpi, value: string): Promise<void> {
      const tag: string = `${this.tag}.onKeyUpEnter()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      if (browser.name === 'ie') await this.onAdjustmentChange(kpi, value);
  }

  public async onAdjustmentChange(kpi: Kpi, value: string = null): Promise<void> {
      const tag: string = `${this.tag}.onAdjustmentChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
      if (debug) console.log(tag, 'formattedValue:', formattedValue);
      await this.workbench.postAdditionalKpiAdjustment({ id: kpi.id, value: formattedValue });
      await this.reload();
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  private onOrganizationChange(organization: OrganizationEntity): void {
      this.disableWrite = (organization.disableAdditionalKpiWrite || organization.disableDataWrite) && !this.state.userHasAdminAccess;
  }

  private clearAdjustments(): void {
      const tag: string = `${this.tag}.clearAdjustments()`;
      if (this.debug) console.log(tag);
      this.adjustments = [];
      this.setUnsavedAdjustments();
  }

  private async reload() {
      const tag: string = `${this.tag}.reload()`;
      if (this.debug) console.log(tag);
      await Promise.all([
          this.workbench.setUpItReporting(),
      ]);
  }

  private setUnsavedAdjustments(): void {
      const tag: string = `${this.tag}.setUnsavedAdjustments()`;
      if (this.debug) console.log(tag);
      this.state.unsavedAdjustmentsMap[AdjustmentTab.ItReporting] = this.adjustments.length > 0;
  }

  public readonly ForYearAcronym = ForYearAcronym;
  public readonly KpiValue = KpiValue;
  public readonly getUsedKpiValue = getUsedKpiValue;
}
