import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { StateService } from "src/app/services/state/state.service";
import { Page, TopLevelKpi } from "src/app/constants";
import { PlanningDataService } from "src/app/services/data/planning.data.service";

@Component({
  selector: "planningNavigation",
  templateUrl: "./planningNavigation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "vclLayoutHorizontal myfcParentHeight myfcWorkbenchNavigation"
  }
})
export class PlanningNavigationComponent extends BaseComponent
  implements OnInit {
  private static readonly Tag: string = "PlanningNavigationComponent";
  protected readonly tag: string = PlanningNavigationComponent.Tag;
  protected readonly debug: boolean = false;

  @Input() public Pages: Page[];

  public currencySplitAvailable: boolean = false;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    private readonly planning: PlanningDataService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;

    this.observables = [
      this.state.initialized$,
      this.state.page$,
      this.state.organizationEntity$,
      this.state.datePickerDate$,
      this.state.country$
    ];
  }

  public onTopLevelKpiSelect(topLevelKpi: TopLevelKpi): void {
    const tag: string = `${this.tag}.onTopLevelKpiSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "topLevelKpi:", topLevelKpi);
    this.state.topLevelKpi = topLevelKpi;
  }

  public onPageTap(page: Page): void {
    const tag: string = `${this.tag}.onPageTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "page:", page);
    this.state.page = page;
  }

  public TopLevelKpi = TopLevelKpi;
}
