import * as merge from 'merge';
import {
  Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit,
  OnChanges, AfterViewInit, Input
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { AggregatedKpi } from 'src/app/models/common/kpiAggregated';
import { WorkbenchDataService, AggregatedKpiMap } from 'src/app/services/data/workbench.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { Configuration } from 'src/app/plotting/configuration';
import { Plot, Trace } from 'src/app/plotting/interfaces';
import { Layout } from 'src/app/plotting/layout';
import { Axis, Solid, Dash, LineWidth } from 'src/app/plotting/constants';
import * as Traces from 'src/app/plotting/traces';
import { createWorkbenchFyForecast } from 'src/app/plotting/data';
import { Color } from 'src/styles/color';
import {
  getYearShort, localeMonthShortYearShort,
  getScale, array, isNumber, scaleTrace, isAfterMarch, isPresent, previousMonth, isJanuary
} from 'src/app/utils';
import {
  TopLevelKpi, YearToDateAcronym, NoValue, ForYearAcronym, Scale, ScaleCountries
} from 'src/app/constants';

@Component({
  selector: 'workbenchTopLevelKpiFyForecast',
  templateUrl: 'workbenchTopLevelKpiFyForecast.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'vclLayoutHorizontal myfcParentHeight myfcRelative myfcWorkbenchTopLevelKpiFyForecast',
  }
})
export class WorkbenchTopLevelKpiFyForecastComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  private static readonly Tag: string = 'WorkbenchTopLevelKpiFyForecastComponent';
  protected readonly debug: boolean = false;
  public readonly debugPlot: boolean = false;

  @Input() public topLevelKpi: TopLevelKpi;
  public scale: Scale;
  public label: string;

  public plotId: string;
  public plotClass: string; // 'myfcWorkbenchTopLevelKpiFyForecast';

  public plot: Plot;
  public xaxis: string[] = [];

  public width: number = 100;
  public height: number = 100;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${WorkbenchTopLevelKpiFyForecastComponent.Tag}.${TopLevelKpi[this.topLevelKpi]}`;
    this.plotId = this.tag;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.workbench.topLevelKpiFyForecast$.subscribe(this.onTopLevelKpiFyForecastChange.bind(this)),
    ];
  }

  public onTopLevelKpiFyForecastChange(fyForecast: AggregatedKpiMap): void {
    const tag: string = `${this.tag}.onTopLevelKpiFyForecastChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecast:', fyForecast);
    const FyForecastYears: number = isAfterMarch(this.state.datePickerDate) ? 2 : 1;
    this.label = this.topLevelKpi == TopLevelKpi.SalesAndAcquisitions ? "Acq. Volume" : this.topLevelKpi;
    if (debug) console.log(tag, 'fyForecast:', fyForecast);
    const topLevelKpifyForecast: AggregatedKpi = fyForecast[this.topLevelKpi];
    if (debug) console.log(tag, 'topLevelKpifyForecast:', topLevelKpifyForecast);
    let traces: Trace[] = this.formatData(topLevelKpifyForecast);
    if (debug) console.log(tag, 'traces:', traces);

    // Removed the return so a plot change is visible / N/A's are displayed (albeit could look nicer).
    // if (!values.length) values = [0]; // return;

    let scale: Scale = Scale.Million;
    if (ScaleCountries.some(country => this.state.country.name.includes(country))) {
      let values: number[] = topLevelKpifyForecast.listValues();
      values = values.length ? values : [0];
      if (debug) console.log(tag, 'values:', values);
      scale = getScale(values);
    }
    if (debug) console.log(tag, 'scale:', scale);

    traces = traces.map(trace => scaleTrace(trace, Axis.Y, scale));
    if (debug) console.log(tag, 'traces:', traces);

    // (A) See this.createPlot() -> createWorkbenchFyForecast().
    // values = flatten(traces.map(trace => trace[Axis.Y]));
    // const yaxisRange: number[] = createLargerNumberRange(values);
    // if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

    const dateYearShort: number = getYearShort(previousMonth(this.state.datePickerDate));
    const dateLocaleShort: string = localeMonthShortYearShort(previousMonth(this.state.datePickerDate));
    const xaxis: string[] = [
      `${YearToDateAcronym} ${dateLocaleShort}`,
      ...array(FyForecastYears).map((_, i) => `${ForYearAcronym}${dateYearShort + i + (isJanuary(this.state.datePickerDate) ? 1 : 0)}`)
    ];
    if (debug) console.log(tag, 'xaxis:', xaxis);

    const plot: Plot = this.createPlot({
      traces,
      xaxis,
      // yaxisRange,
    });
    if (debug) console.log(tag, 'plot:', plot);

    Object.assign(this, {
      scale,
      xaxis,
      plot,
    });
    this.setLoadingStatus();
  }

  private createPlot({ traces, xaxis, yaxisRange }: {
    traces: Trace[], xaxis: string[], yaxisRange?: number[],
  }): Plot {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;

    const plot: Plot = merge.recursive({
      layout: Layout.WorkbenchFyForecast,
      configuration: Configuration.Static,
    }, createWorkbenchFyForecast({
      traces,
      xaxis,
      yaxisRange,
    }));

    return plot;
  }

  private formatData(fyForecast: AggregatedKpi): Trace[] {
    const tag: string = `${this.tag}.formatData()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecast:', fyForecast);

    const endOfYearOrganization: boolean = isNumber(fyForecast.endOfYearOrganization);
    const nextYearOrganization: boolean = isNumber(fyForecast.nextYearOrganization);

    const endOfYearAdjusted: boolean = isNumber(fyForecast.endOfYearAdjust);
    const nextYearAdjusted: boolean = isNumber(fyForecast.nextYearAdjust);

    if (debug) console.log(tag, 'endOfYearAdjusted:', endOfYearAdjusted);
    if (debug) console.log(tag, 'nextYearAdjusted:', nextYearAdjusted);

    if (debug) console.log(tag, 'this.state.datePickerDate:', this.state.datePickerDate);
    const afterMarch: boolean = isAfterMarch(this.state.datePickerDate);
    if (debug) console.log(tag, 'afterMarch:', afterMarch);
    const width: number = 0.8; // afterMarch ? 0.8 : 0.8;

    const traces: Trace[] = [
      Traces.Bar.Dfs({
        [Axis.X]: [0],
        [Axis.Y]: [fyForecast.actual],
        width: [width],
        marker: {
          color: [Color.Actual],
          line: {
            color: [
              Color.Actual,
            ],
            dash: [
              Solid,
            ],
            width: [LineWidth],
          }
        },
      }),
      Traces.Bar.Dfs({
        [Axis.X]: [2, 3],
        [Axis.Y]: [
          endOfYearAdjusted ?
            fyForecast.endOfYearAdjust :
            endOfYearOrganization ? fyForecast.endOfYearOrganization :
              fyForecast.endOfYearMachine,
          fyForecast.endOfYearMachine
        ],
        width: [width, width],
        marker: {
          color: [
            endOfYearAdjusted ? Color.OrganizationAdjusted : Color.Organization,
            Color.Machine
          ],
          line: {
            color: [
              Color.Organization,
              Color.Machine,
            ],
            dash: [
              endOfYearAdjusted ? Dash : Solid,
              Solid,
            ],
            width: [LineWidth, LineWidth],
          }
        },
      }),
      afterMarch ? Traces.Bar.Dfs({
        [Axis.X]: [5, 6],
        [Axis.Y]: [
          nextYearAdjusted ?
            fyForecast.nextYearAdjust :
            nextYearOrganization ? fyForecast.nextYearOrganization :
              fyForecast.nextYearMachine,
          fyForecast.nextYearMachine
        ],
        width: [width, width],
        marker: {
          color: [
            nextYearAdjusted ? Color.OrganizationAdjusted : Color.Organization,
            Color.Machine
          ],
          line: {
            color: [
              Color.Organization,
              Color.Machine,
            ],
            dash: [
              nextYearAdjusted ? Dash : Solid,
              Solid,
            ],
            width: [LineWidth, LineWidth],
          }
        },
      }) : null,
    ];

    return traces.filter(isPresent);
  }

  public readonly TopLevelKpi = TopLevelKpi;

  public readonly NoValue = NoValue;
  public readonly ForYearAcronym = ForYearAcronym;
  public readonly YearToDateAcronym = YearToDateAcronym;

  public readonly localeMonthShortYearShort = localeMonthShortYearShort;
}
