import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';
import { IPlan } from 'src/app/models/planning/planning';

@Component({
  selector: 'planPicker',
  templateUrl: './planPicker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PlanPickerComponent extends BaseComponent {
  public static readonly Tag: string = 'PlanPickerComponent';
  protected tag: string = PlanPickerComponent.Tag;
  protected debug: boolean = false;

  public planningData: IPlan[] = [];
  public index: number = 0;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    public readonly planning: PlanningDataService,
  ) {
    super(cd, state);
  }

  public async ngOnInit() {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.datePickerDate$
    ];

    this.subscriptions = [
      this.planning.plans$.subscribe(this.onPlansChange.bind(this)),
    ];
  }

  public onPlanSelect(planDate: Date): void {
    const tag: string = `${this.tag}.onSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'planDate:', planDate);
    this.state.datePickerDate = planDate;
    for (let i = 0; i < this.planningData.length; i++) {
      if (this.planningData[i].date == planDate) {
        this.index = i;
        break;
      }
    }
  }

  private onPlansChange(data: IPlan[]) {
    const tag: string = `${this.tag}.onPlansChange`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'data', data);
    if (!data) return;
    this.planningData = data;
    this.index = 0;
  }
}
