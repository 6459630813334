import * as merge from 'merge';
import {
  Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit,
  OnChanges, Input, ViewChildren, QueryList,
} from '@angular/core';
import { PlotlyComponent, PlotlyEvent, } from '@n-fuse/ng-plotly';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import {
  CommonDataService,
  FyComponentMap, FyComponents,
} from 'src/app/services/data/common.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { Plot, Trace } from 'src/app/plotting/interfaces';
import { Configuration } from 'src/app/plotting/configuration';
import { Axis, MagicNumber } from 'src/app/plotting/constants';
import { createFyComponent, } from 'src/app/plotting/data';
import * as Traces from 'src/app/plotting/traces';
import { Layout } from 'src/app/plotting/layout';
import {
   flatten, getScale, getTraceValues
} from 'src/app/utils';
import {
  TopLevelKpi, ForYearAcronym, Scale, Page
} from 'src/app/constants';

@Component({
  selector: 'barFyComponents',
  templateUrl: './barFyComponents.component.html',
  styleUrls: ['./barFyComponents.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BarFyComponentsComponents extends BaseComponent implements OnInit, OnChanges {
  private static readonly Tag: string = 'BarFyComponentsComponents';
  protected tag: string = BarFyComponentsComponents.Tag;
  protected debug: boolean = false;
  public debugPlot: boolean = false;

  @Input() private topLevelKpi: TopLevelKpi;
  @Input() private page: Page;

  @ViewChildren('vclPlotly') private readonly vclPlotly: QueryList<PlotlyComponent>;

  private plotId: string;
  private plotClass: string = 'myfcFyComponents';
  private events: any;

  // private readonly PlotCount: number = [this.state.yearShort, this.state.nextYearShort].length;
  public width: number = 100; // 100 / this.PlotCount;

  public plots: Plot[] = [];

  public scale: Scale;
  public kpis: string[];

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly data: CommonDataService,
  ) {
    super(cd, state);
    this.debugPlot = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.plotId = `${BarFyComponentsComponents.Tag}.${TopLevelKpi[this.topLevelKpi]}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.events = {
      [PlotlyEvent.Hover]: (data) => {
        const tag: string = `${this.tag}.${PlotlyEvent.Hover}()`;
        const debug: boolean = this.debug && false;
        // if (debug) console.log(tag, 'data:', data);
        const point = data.points[0];
        const y: number = point.y;
        if (debug) console.log(tag, 'y:', y);
        this.plots.forEach(plot => (plot.layout && plot.layout.annotations || []).forEach(annotation => {
          // if (debug) console.log(tag, 'annotation:', annotation);
          const y1: number = Math.round(annotation.y - MagicNumber.FcAccuracySideAnnotationOrganizationPadding);
          const y2: number = Math.round(annotation.y - MagicNumber.FcAccuracySideAnnotationMachinePadding);
          if (debug) console.log(tag, 'y1:', y1);
          if (debug) console.log(tag, 'y2:', y2);
          annotation.visible = y === y1 || y === y2;
          if (annotation.visible) if (debug) console.log(tag, 'annotation:', annotation);
        }));

        this.vclPlotly.forEach(vclPlotly => vclPlotly.relayout());
      },
      [PlotlyEvent.Unhover]: (data) => {
        const tag: string = `${this.tag}.${PlotlyEvent.Unhover}()`;
        const debug: boolean = this.debug && false;
        // if (debug) console.log(tag, 'data:', data);
        this.plots.forEach(plot => (plot.layout && plot.layout.annotations || [])
          .forEach(annotation => annotation.visible = false));
        this.vclPlotly.forEach(vclPlotly => vclPlotly.relayout());
      }
    };

    this.subscriptions = [
      this.data.fyComponents$.subscribe(this.onFyComponentsChange.bind(this)),
    ];
  }

  public onFyComponentsChange(fyComponentsMap: FyComponentMap): void {
    const tag: string = `${this.tag}.onForecastChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyComponentsMap:', fyComponentsMap);
    // Slice off top level kpi (Portfolio / EbIT).
    const fyComponents: FyComponents = fyComponentsMap[this.topLevelKpi].slice(1);
    this.createPlot(fyComponents);
  }

  private createPlot(fyComponents: FyComponents): void {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyComponents:', fyComponents);

    const tracesArrays: Trace[][] = this.formatData(fyComponents);
    if (debug) console.log(tag, 'tracesArrays:', tracesArrays);

    const values: number[] = flatten(tracesArrays.map(traceArray => getTraceValues(traceArray, Axis.X)));
    if (debug) console.log(tag, 'values:', values);
    if (!values.length) return;

    const scale: Scale = getScale(values);
    if (debug) console.log(tag, 'scale:', scale);

    const plots: Plot[] = tracesArrays.map((traces, i) => merge.recursive({
      // title: i === 0 ? `${ForYearAcronym} ${this.state.yearShort}` : `${ForYearAcronym} ${this.state.nextYearShort}`,
      plotId: `${this.tag}.${i}`,
      layout: Layout.FyComponents,
      configuration: Configuration.Dfs,
    }, createFyComponent({
      traces,
      scale,
    })));
    if (debug) console.log(tag, 'plots:', plots);

    this.scale = scale;
    this.plots = plots;
    this.setUpLayout(fyComponents);
  }

  private setUpLayout(fyComponents: FyComponents): void {
    const tag: string = `${this.tag}.setUpLayout()`;
    if (this.debug) console.log(tag, 'fyComponents:', fyComponents);
    this.kpis = fyComponents.map(fyComponent => fyComponent.label);
  }

  private formatData(fyComponents: FyComponents): Trace[][] {
    const tag: string = `${this.tag}.formatData()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyComponents:', fyComponents);

    const endOfYearMachineValues = fyComponents.map(fyComponent => fyComponent.endOfYearMachine);
    const endOfYearOrganizationValues = fyComponents.map(fyComponent => fyComponent.endOfYearOrganization);

    const nextYearMachineValues = fyComponents.map(fyComponent => fyComponent.nextYearMachine);
    const nextYearOrganizationValues = fyComponents.map(fyComponent => fyComponent.nextYearOrganization);

    const endOfYearMachineTrace: Trace = Traces.Bar.MachineHorizontal({
      [Axis.X]: endOfYearMachineValues,
    });

    const endOfYearOrganizationTrace = Traces.Bar.OrganizationHorizontal({
      [Axis.X]: endOfYearOrganizationValues,
    });

    const endOfYearTraces: Trace[] = [
      endOfYearMachineTrace,
      endOfYearOrganizationTrace,
    ];
    if (debug) console.log(tag, 'endOfYearTraces:', endOfYearTraces);

    const nextYearMachineTrace: Trace = Traces.Bar.MachineHorizontal({
      [Axis.X]: nextYearMachineValues,
    });

    const nextYearOrganizationTrace = Traces.Bar.OrganizationHorizontal({
      [Axis.X]: nextYearOrganizationValues,
    });

    const nextYearTraces: Trace[] = [
      nextYearMachineTrace,
      nextYearOrganizationTrace,
    ];
    if (debug) console.log(tag, 'nextYearTraces:', nextYearTraces);

    return [endOfYearTraces, nextYearTraces];
  }

  public readonly Page = Page;
  public readonly ForYearAcronym = ForYearAcronym;
}
