import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnInit
} from '@angular/core';
import { MonthPickerComponent } from 'src/app/components/common/elements/monthPicker/monthPicker.component';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { normalizeDate, getForecastLabel } from 'src/app/utils';
import { NoMonthsAvailable, MonthPickerAllowedYearsBack } from 'src/app/constants';

const MaxDate: Date = new Date(9000000000000);
const MinDate: Date = new Date(0);

@Component({
  selector: 'datePicker',
  templateUrl: 'datePicker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DatePickerComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'DatePickerComponent';
  protected readonly tag: string = DatePickerComponent.Tag;
  protected readonly debug: boolean = false;

  public readonly debugPopover: boolean = false;
  public readonly debugMonthPicker: boolean = false;

  @ViewChild('datePicker') private readonly datePicker: MonthPickerComponent;

  public label: string;
  public appIcon: string = this.collapsedIcon;
  public disabled: boolean = false;
  public selectedYear: number;
  public minDate: Date;
  public maxDate: Date;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);

    this.icon = this.collapsedIcon;

    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;

    this.minDate = normalizeDate(this.state.minMonthPickerDate);
    this.maxDate = normalizeDate(this.state.maxMonthPickerDate);

    if (debug) console.log(tag, 'this.minDate:', this.minDate);
    if (debug) console.log(tag, 'this.maxDate:', this.maxDate);

    this.selectedYear = this.state.datePickerDate.getFullYear();
    this.setLabel(this.state.datePickerDate);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    if (this.debug) console.log(tag);
    this.state.datePicker = this.datePicker;

    this.observables = [
      this.state.datePickerDate$
    ];

    this.subscriptions = [
      this.state.datePickerDate$.subscribe(this.onDatePickerDateChange.bind(this)),
      this.state.organizationLastAvailableMonth$.subscribe(this.onLastAvailableMonthChange.bind(this))
    ];
  }

  private onDatePickerDateChange(date: Date): void {
    const tag: string = `${this.tag}.onDatePickerDateChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'date:', date);
    this.setLabel(date);
  }

  private onLastAvailableMonthChange(lastAvailableMonth: Date): void {
    const tag: string = `${this.tag}.onLastAvailableMonthChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'lastAvailableMonth:', lastAvailableMonth);
    if (!lastAvailableMonth) return;

    if (debug) console.log(tag, 'this.state.dateRestrictionDisabled:', this.state.dateRestrictionDisabled);
    this.minDate = normalizeDate(this.state.dateRestrictionDisabled ? MinDate :
      new Date(lastAvailableMonth.getFullYear() - MonthPickerAllowedYearsBack, lastAvailableMonth.getMonth()));
    this.maxDate = normalizeDate(this.state.dateRestrictionDisabled ? MaxDate : lastAvailableMonth);

    if (debug) console.log(tag, 'this.minDate:', this.minDate);
    if (debug) console.log(tag, 'this.maxDate:', this.maxDate);
  }

  private disable(): void {
    const tag: string = `${this.tag}.disable()`;
    if (this.debug) console.log(tag);
    this.label = NoMonthsAvailable;
    this.disabled = true;
  }

  private enable(): void {
    const tag: string = `${this.tag}.enable()`;
    if (this.debug) console.log(tag);
    this.setLabel(this.state.datePickerDate);
    this.disabled = false;
  }

  private setLabel(date: Date): void {
    const tag: string = `${this.tag}.setLabel()`;
    if (this.debug) console.log(tag, 'date:', date);
    this.label = getForecastLabel(date);
    if (this.debug) console.log(tag, 'this.label:', this.label);
  }

  public onSelectedDateChange(date: Date): void {
    const tag: string = `${this.tag}.onSelectedDateChange()`;
    if (this.debug) console.log(tag, 'date:', date);
    this.state.datePickerDate = date;
  }
}
