import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { LayerRef } from '@vcl/ng-vcl';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'videoPopup',
  templateUrl: './videoPopup.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VideoPopupComponent extends BaseComponent {
  private static readonly Tag: string = 'VideoPopupComponent';

  @ViewChild('videoLayer') private readonly videoLayer: LayerRef;

  @Input() public src: string;
  @Input() public title: string;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = VideoPopupComponent.Tag;
  }

  public open(): void {
    const tag: string = `${this.tag}.open()`;
    this.videoLayer.open();
  }
}
