import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { KpiValuesChild } from "src/app/models/common/kpiValues";
import {
  Page,
  TopLevelKpi,
  Adjustment,
  Portfolio,
  Ebit,
  SalesAndAcquisitions,
  Dividends,
  Capital
} from "src/app/constants";
import {
  Api,
  Controller,
  Parameters,
  Composer,
  Adjust,
  KpiValuesApi,
  ScenarioApi,
  Notification,
  AdditionalKpi,
  ScenarioSave,
  Parameter,
  OutputKpi,
  CurrencySplit,
  Countries
} from "src/app/api";
import { Currency } from "src/app/types";
import {
  monthPickerStrNonZero,
  dateToMonthPickerStr,
  sortByDate,
  eqDate,
  normalizeDate
} from "src/app/utils";
import { StateService } from "src/app/services/state/state.service";
import { RequestService } from "src/app/services/request.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { DataService, ParameterHandler } from "./data.service";
import { Default } from "src/app/services/data/defaults";
import {
  CommonDataService,
  FyForecastMap
} from "src/app/services/data/common.data.service";
import {
  KpiValuesQuarterContainer,
  KpiValuesQuarter
} from "src/app/models/workbench/kpiValuesQuarter";
import { AggregatedKpi } from "src/app/models/common/kpiAggregated";
import { ModalService } from "src/app/services/modal.service";
import {
  AdjustmentHistoryContainer,
  AdjustmentHistory
} from "src/app/models/common/adjustment";
import {
  ScenarioContainer,
  ScenarioMonth,
  Scenario,
  IScenarioMonth,
  ICreateScenario
} from "src/app/models/workbench/scenario";
import { Color } from "src/styles/color";
import { AdditionalKpiContainer } from "src/app/models/additionalKpis/additionalKpis";
import { IStatusMessage } from "src/app/models/common/sideBar";
import { INavigationKpiTree } from "src/app/models/workbench/navigation";
import { CurrencySplitContainer } from "src/app/models/common/currencySplit";
import { MoveReportingContainer } from 'src/app/models/moveReporting/moveReporting';
import { FocusReportingContainer } from "src/app/models/focusReporting/focusReporting";
import { ItReportingContainer } from "src/app/models/itReporting/itReporting";

export interface AggregatedKpiMap {
  [topLevelKpiEnum: string]: AggregatedKpi;
}

@Injectable()
export class WorkbenchDataService extends DataService {
  protected static readonly Tag: string = "WorkbenchDataService";
  protected readonly tag: string = WorkbenchDataService.Tag;
  protected readonly debug: boolean = false;
  protected readonly page: Page = Page.Workbench;

  public bulkAdjustments: any[] = [];

  private readonly handlers: ParameterHandler[] = [
    {
      name: "setUpWorkbenchStatusMessage",
      change:
        Api[Controller.Notification].endpoints[Notification.StatusMessage]
          .parameters,
      requests: [this.setUpWorkbenchStatusMessage.bind(this)]
    },
    {
      name: "setUpTopLevelKpiFyForecast",
      // Use KpiAggregatedController parameters except for the selected kpi.
      change: Parameters.Set5,
      requests: [this.setUpTopLevelKpiFyForecast.bind(this)]
    },
    {
      name: "setUpSubKpiFyForecast",
      // Use KpiAggregatedController parameters except for the selected kpi.
      change: Parameters.Set5,
      requests: [this.setUpSubKpiFyForecast.bind(this)]
    },
    {
      name: "setUpScenario",
      change:
        Api[Controller.KpiValues].endpoints[KpiValuesApi.Composer].parameters,
      requests: [this.setUpScenario.bind(this)]
    },
    // Done in data.service preHandler().
    // {
    //   name: 'setUpScenarios',
    //   change: Api[Controller.Scenario].endpoints[ScenarioApi.List].parameters,
    //   requests: [this.setUpScenarios.bind(this)],
    // },
    // TODO: Un-comment when needed.
    // {
    //   name: 'setUpScatterFyComponents',
    //   change: Api[Controller.KpiValues].endpoints[KpiValuesApi.Children].parameters,
    //   requests: [this.setUpScatterFyComponents.bind(this)],
    // },
    {
      name: "setUpAdjustmentHistory",
      change: Api[Controller.AdjustmentHistory].parameters,
      requests: [this.setUpAdjustmentHistory.bind(this)]
    },
    {
      name: "setUpAdditionalKpis",
      change: Api[Controller.AdditionalKpi].endpoints[Composer].parameters,
      requests: [this.setUpAdditionalKpis.bind(this)]
    },
    {
      name: "setUpMoveReporting",
      change: Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.MoveReporting].parameters,
      requests: [this.setUpMoveReporting.bind(this)]
    },
    {
      name: "setUpItReporting",
      change: Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.ItReporting].parameters,
      requests: [this.setUpItReporting.bind(this)]
    },
    {
      name: "setUpFocusReporting",
      change: Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.FocusReporting].parameters,
      requests: [this.setUpFocusReporting.bind(this)]
    },
    {
      name: "setUpRatios",
      change: Api[Controller.OutputKpi].endpoints[OutputKpi.Ratios].parameters,
      requests: [this.setUpRatios.bind(this)]
    },
    {
      name: "setUpComponents",
      change: Api[Controller.OutputKpi].parameters,
      requests: [this.setUpComponents.bind(this)]
    },
    {
      name: "setUpSalesAndAcquisitionUnits",
      change:
        Api[Controller.OutputKpi].endpoints[OutputKpi.SalesAndAcquisitionUnits]
          .parameters,
      requests: [this.setUpSalesAndAcquisitionUnits.bind(this)]
    },
    {
      name: "setUpNavigation",
      change: Api[Controller.Navigation].parameters,
      requests: [this.setUpNavigation.bind(this)]
    },
    {
      name: "setUpIsCurrencySplitVisible",
      change: Api[Controller.Countries].endpoints[Countries.IsCurrencySplit].parameters,
      requests: [this.setUpIsCurrencySplitVisible.bind(this)]
    },
    {
      name: "setUpCurrencySplit",
      change:
        Api[Controller.CurrencySplit].endpoints[CurrencySplit.Composer]
          .parameters,
      requests: [this.setUpCurrencySplit.bind(this)]
    }
  ];

  private readonly _workbenchStatusMessage$: BehaviorSubject<
    IStatusMessage
  > = new BehaviorSubject<IStatusMessage>(Default.StatusMessage);
  public readonly workbenchStatusMessage$: Observable<
    IStatusMessage
  > = this._workbenchStatusMessage$.asObservable();

  private readonly _topLevelKpiFyForecast$: BehaviorSubject<
    AggregatedKpiMap
  > = new BehaviorSubject<AggregatedKpiMap>(Default.TopLevelKpiFyForecast);
  public readonly topLevelKpiFyForecast$: Observable<
    AggregatedKpiMap
  > = this._topLevelKpiFyForecast$.asObservable();

  private readonly _subKpiFyForecast$: BehaviorSubject<
    FyForecastMap
  > = new BehaviorSubject(Default.SubKpiFyForecast);
  public readonly subKpiFyForecast$: Observable<
    FyForecastMap
  > = this._subKpiFyForecast$.asObservable();

  private readonly _scenario$: BehaviorSubject<
    KpiValuesQuarterContainer
  > = new BehaviorSubject<KpiValuesQuarterContainer>(Default.Scenario);
  public readonly scenario$: Observable<
    KpiValuesQuarterContainer
  > = this._scenario$.asObservable();

  public get scenario(): KpiValuesQuarterContainer {
    return this._scenario$.value;
  }

  private readonly _scatterFyComponents$: BehaviorSubject<
    KpiValuesChild[]
  > = new BehaviorSubject<KpiValuesChild[]>(Default.ScatterFyComponents);
  public readonly scatterFyComponents$: Observable<
    KpiValuesChild[]
  > = this._scatterFyComponents$.asObservable();

  private readonly _scenarios$: BehaviorSubject<
    ScenarioContainer
  > = new BehaviorSubject<ScenarioContainer>(Default.Scenarios);
  public readonly scenarios$: Observable<
    ScenarioContainer
  > = this._scenarios$.asObservable();
  public scenarioMonths: ScenarioMonth[] = [];

  private readonly _adjustmentHistory$: BehaviorSubject<
    AdjustmentHistoryContainer
  > = new BehaviorSubject<AdjustmentHistoryContainer>(
    Default.AdjustmentHistory
  );
  public readonly adjustmentHistory$: Observable<
    AdjustmentHistoryContainer
  > = this._adjustmentHistory$.asObservable();

  private readonly _addScenario$: Subject<
    KpiValuesQuarterContainer
  > = new Subject<KpiValuesQuarterContainer>();
  public readonly addScenario$: Observable<
    KpiValuesQuarterContainer
  > = this._addScenario$.asObservable();

  private readonly _additionalKpis$: BehaviorSubject<
    AdditionalKpiContainer
  > = new BehaviorSubject<AdditionalKpiContainer>(null);
  public readonly additionalKpis$: Observable<
    AdditionalKpiContainer
  > = this._additionalKpis$.asObservable();

  private readonly _moveReporting$: BehaviorSubject<
    MoveReportingContainer
  > = new BehaviorSubject<MoveReportingContainer>(null);
  public readonly moveReporting$: Observable<
    MoveReportingContainer
  > = this._moveReporting$.asObservable();

  private readonly _itReporting$: BehaviorSubject<
  ItReportingContainer
  > = new BehaviorSubject<ItReportingContainer>(null);
  public readonly itReporting$: Observable<
    ItReportingContainer
  > = this._itReporting$.asObservable();

  private readonly _focusReporting$: BehaviorSubject<
  FocusReportingContainer
  > = new BehaviorSubject<FocusReportingContainer>(null);
  public readonly focusReporting$: Observable<
    FocusReportingContainer
  > = this._focusReporting$.asObservable();

  private readonly _currencySplit$: BehaviorSubject<
    CurrencySplitContainer
  > = new BehaviorSubject<CurrencySplitContainer>(null);
  public readonly currencySplit$: Observable<
    CurrencySplitContainer
  > = this._currencySplit$.asObservable();

  private readonly _isCurrencySplitVisible$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(null);
  public readonly isCurrencySplitVisible$: Observable<
    boolean
  > = this._isCurrencySplitVisible$.asObservable();

  private readonly _ratios$: BehaviorSubject<
    AggregatedKpi[]
  > = new BehaviorSubject<AggregatedKpi[]>(Default.Ratios);
  public readonly ratios$: Observable<
    AggregatedKpi[]
  > = this._ratios$.asObservable();

  private readonly _components$: BehaviorSubject<
    AggregatedKpi[]
  > = new BehaviorSubject<AggregatedKpi[]>(Default.Components);
  public readonly components$: Observable<
    AggregatedKpi[]
  > = this._components$.asObservable();

  private readonly _salesAndAcquisitionUnits$: BehaviorSubject<
    AggregatedKpi[]
  > = new BehaviorSubject<AggregatedKpi[]>(Default.Components);
  public readonly salesAndAcquisitionUnits$: Observable<
    AggregatedKpi[]
  > = this._salesAndAcquisitionUnits$.asObservable();

  private readonly _navigation$: BehaviorSubject<
    INavigationKpiTree[]
  > = new BehaviorSubject<INavigationKpiTree[]>(Default.Navigation);
  public readonly navigation$: Observable<
    INavigationKpiTree[]
  > = this._navigation$.asObservable();

  public navigation: INavigationKpiTree[] = [];

  public readonly navigationChange: Subject<INavigationKpiTree[]> = new Subject<
    INavigationKpiTree[]
  >();

  constructor(
    protected readonly state: StateService,
    protected readonly modal: ModalService,
    private readonly data: CommonDataService,
    private readonly request: RequestService,
    private readonly configuration: ConfigurationService
  ) {
    super(state, modal);
    this.setUpHandlers(this.handlers);

    this.subscriptions = [
      state.addScenario$.subscribe(this.onAddScenario.bind(this))
    ];
  }

  public onNavigationChange(nav: INavigationKpiTree[]): void {
    this.navigationChange.next(nav);
  }

  /* Controller.Notification*/
  public async setUpWorkbenchStatusMessage(): Promise<void> {
    const tag: string = `${this.tag}.setUpWorkbenchStatusMessage()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const workbenchStatusMessage: IStatusMessage = (await this.getWorkbenchStatusMessage())
      .body;
    if (debug)
      console.log(tag, "workbenchStatusMessage:", workbenchStatusMessage);
    this._workbenchStatusMessage$.next(workbenchStatusMessage);
  }
  // [HttpGet("notification/statusMessage/{countryId}/{asOf}")]
  private async getWorkbenchStatusMessage({
    organization = this.state.organizationEntity.id,
    asOfMonth = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOfMonth?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getWorkbenchStatusMessage()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Notification].endpoints[Notification.StatusMessage]
        .endpoint
    }/${organization}/${monthPickerStrNonZero(asOfMonth)}`;
    if (debug) console.log(tag, "url", url);
    return this.request.get(url);
  }
  /* Controller.Notification*/

  /* Controller.KpiAggergated */
  public async setUpTopLevelKpiFyForecast(): Promise<void> {
    const tag: string = `${this.tag}.setUpTopLevelKpiFyForecast()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const transformAggregatedKpi = aggregatedKpi => {
      try {
        return new AggregatedKpi(aggregatedKpi.body);
      } catch (ex) {
        console.warn(ex);
        return null;
      }
    };

    const [portfolio, ebit, salesAndAcquisitions] = await Promise.all(
      [
        Portfolio.kpi.kpiTypeId,
        Ebit.kpi.kpiTypeId,
        SalesAndAcquisitions.kpi.kpiTypeId
      ].map(kpi =>
        this.data.getKpiAggregated({ kpi }).then(transformAggregatedKpi)
      )
    );

    if (debug) console.log(tag, "portfolio:", portfolio);
    if (debug) console.log(tag, "ebit:", ebit);

    const fyForecast: AggregatedKpiMap = {
      [TopLevelKpi.Portfolio]: portfolio,
      [TopLevelKpi.Ebit]: ebit,
      [TopLevelKpi.SalesAndAcquisitions]: salesAndAcquisitions
    };

    this._topLevelKpiFyForecast$.next(fyForecast);
  }
  /* Controller.KpiAggergated */

  /* Controller.OutputKpi */
  public async setUpSubKpiFyForecast(): Promise<void> {
    const tag: string = `${this.tag}.setUpSubKpiFyForecast()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const kpi: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "kpi:", kpi);
    if (!kpi) return;

    const [portfolio, ebit, salesAndAcquisitions] = await Promise.all(
      [
        Portfolio.kpi.kpiTypeId,
        Ebit.kpi.kpiTypeId,
        SalesAndAcquisitions.kpi.kpiTypeId
      ].map(kpi =>
        this.getOutputKpiById({ kpi }).then(fyForecasts =>
          fyForecasts.body.map(fyForecast => new AggregatedKpi(fyForecast))
        )
      )
    );
    if (debug) console.log(tag, "portfolio:", portfolio);
    if (debug) console.log(tag, "ebit:", ebit);

    const fyForecast: FyForecastMap = {
      [TopLevelKpi.Portfolio]: portfolio,
      [TopLevelKpi.Ebit]: ebit,
      [TopLevelKpi.SalesAndAcquisitions]: salesAndAcquisitions,
    };

    this._subKpiFyForecast$.next(fyForecast);
  }
  // [HttpGet("OutputKpi/{countryId}/{currencyId}/{typeId}/{asOfMonth}/{scenarioId?}")]
  public async getOutputKpiById({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    kpi = this.state.kpiTypeId,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    kpi?: number;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getOutputKpiById()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.OutputKpi].endpoint
    }/${organization}/${currency}/${kpi}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  // [HttpGet("OutputKpi/Ratios/{countryId}/{asOfMonth}/{scenarioId?}")]
  public async getOutputKpiRatios({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getOutputKpiRatios()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.OutputKpi].endpoints[OutputKpi.Ratios].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpRatios(): Promise<void> {
    const tag: string = `${this.tag}.setUpRatios()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const kpi: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "kpi:", kpi);
    if (!kpi) return;

    const ratios: AggregatedKpi[] = (await this.getOutputKpiRatios()).body.map(
      ratio => new AggregatedKpi(ratio)
    );
    if (debug) console.log(tag, "ratios:", ratios);
    this._ratios$.next(ratios);
  }

  // [HttpGet("OutputKpi/SalesAndAcquisitionUnits/{countryId}/{asOfMonth}/{scenarioId?}")]
  public async getOutputKpiSalesAndAcquisitionUnits({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getOutputKpiSalesAndAcquisitionUnits()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.OutputKpi].endpoints[OutputKpi.SalesAndAcquisitionUnits]
        .endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpSalesAndAcquisitionUnits(): Promise<void> {
    const tag: string = `${this.tag}.setUpSalesAndAcquisitionUnits()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const kpi: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "kpi:", kpi);
    if (!kpi) return;

    const salesAndAcquisitionUnits: AggregatedKpi[] = (await this.getOutputKpiSalesAndAcquisitionUnits()).body.map(
      salesAndAcquisitionUnits => new AggregatedKpi(salesAndAcquisitionUnits)
    );
    if (debug)
      console.log(tag, "salesAndAcquisitionUnits:", salesAndAcquisitionUnits);
    this._salesAndAcquisitionUnits$.next(salesAndAcquisitionUnits);
  }

  public async setUpComponents(): Promise<void> {
    const tag: string = `${this.tag}.setUpComponents()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const kpi: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "kpi:", kpi);
    if (!kpi) return;

    const components: AggregatedKpi[] = (await this.getOutputKpiById()).body.map(
      component => new AggregatedKpi(component)
    );
    if (debug) console.log(tag, "components:", components);
    this._components$.next(components);
  }
  /* Controller.OutputKpi */

  /* Controller.KpiValues */
  // [HttpGet("composer/{mapping}/{countryId}/{currencyId}/{asOf}/{version}/{isSimulation?}")]
  public async getKpiValuesQuarter({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    kpi?: number;
    organization?: number;
    currency?: Currency;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getKpiValuesQuarter()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.KpiValues].endpoints[KpiValuesApi.Composer].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpScatterFyComponents(): Promise<void> {
    const tag: string = `${this.tag}.setUpScatterFyComponents()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    const kpi: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "kpi:", kpi);
    if (!kpi) return;

    const scatterFyComponents: KpiValuesChild[] = await this.getChildrenKpiValues(
      { kpi }
    ).then(kpis => kpis.body.map(kpi => new KpiValuesChild(kpi)));
    if (debug) console.log(tag, "scatterFyComponents:", scatterFyComponents);

    this._scatterFyComponents$.next(scatterFyComponents);
  }
  // [HttpGet("KpiValues/children/{typeId}/{countryId}/{currencyId}/{asOf}")]
  public async getChildrenKpiValues({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    currency?: Currency;
    kpi?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getChildrenKpiValues()`;
    const url: string = `${
      Api[Controller.KpiValues].endpoints[KpiValuesApi.Children].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpScenario(
    parameter: Parameter = null,
    scenario: Scenario = this.state.scenario,
    resetScenario: boolean = true
  ): Promise<void> {
    const tag: string = `${this.tag}.setUpScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "parameter:", parameter);
    if (debug) console.log(tag, "scenario:", scenario);
    if (debug) console.log(tag, "resetScenario:", resetScenario);
    // if (!scenario) return;

    const typeId: number = this.state.kpiTypeId;
    if (debug) console.log(tag, "typeId:", typeId);
    if (!typeId) return;

    if (debug) console.log(tag, "state.scenario:", this.state.scenario);
    const sameScenario: boolean =
      scenario &&
      this.state.scenario &&
      scenario.guid === this.state.scenario.guid;
    if (debug) console.log(tag, "sameScenario:", sameScenario);
    if (sameScenario && !resetScenario) return;

    const quarterContainerResponse: HttpResponse<
      any
    > = await this.getKpiValuesQuarter({
      id: scenario && scenario.id
    });

    if (debug) console.log(tag, "state.scenario:", this.state.scenario);
    const changedScenario: boolean =
      scenario &&
      this.state.scenario &&
      scenario.guid !== this.state.scenario.guid;
    if (debug) console.log(tag, "changedScenario:", changedScenario);
    if (changedScenario) return;

    const quarterContainer: KpiValuesQuarterContainer = new KpiValuesQuarterContainer(
      quarterContainerResponse.body
    );
    Object.assign(quarterContainer, scenario, {
      color: Color.Organization
    });
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    this.state.unit = quarterContainer.unit;
    this._scenario$.next(quarterContainer);
  }

  private async onAddScenario(scenario: Scenario): Promise<void> {
    const tag: string = `${this.tag}.onAddScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenario:", scenario);
    if (this.state.page !== Page.Workbench) return;

    const quarterContainerResponse: HttpResponse<
      any
    > = await this.getKpiValuesQuarter({
      id: scenario.id,
      asOf: dateToMonthPickerStr(scenario.date)
    });

    if (debug) console.log(tag, "state.scenarios:", this.state.scenarios);
    const removedCompareForecast: boolean = !this.state.scenarios.some(
      s => s.guid === scenario.guid
    );
    if (removedCompareForecast) {
      if (debug)
        console.log(
          tag,
          `scenario ${scenario.title} (#${scenario.id}) was removed while requesting it`
        );
      return;
    }

    const quarterContainer: KpiValuesQuarterContainer = new KpiValuesQuarterContainer(
      quarterContainerResponse.body
    );
    Object.assign(quarterContainer, scenario);
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    this._addScenario$.next(quarterContainer);
  }
  /* Controller.KpiValues */

  /** Controller.Adjust */
  // [HttpPost("composer/{typeId}/{countryId}/{currencyId}/{asOf}/{perMonth}/{originalValue}/{adjustValue}/{scenarioId?}")]
  public async postAdjustment({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    perMonth,
    originalValue,
    value,
    id = this.state.scenario && this.state.scenario.id
  }: {
    kpi?: number;
    organization?: number;
    currency?: number;
    asOf?: string;
    perMonth: string;
    originalValue: number | string;
    value: number | string;
    id?: number;
  }): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.postAdjustment()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    if (debug) console.log(tag, "asOf:", asOf);
    if (debug) console.log(tag, "perMonth:", perMonth);
    if (debug) console.log(tag, "value:", value);

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoint
    }/${Composer}/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(
      asOf
    )}/${monthPickerStrNonZero(perMonth)}/${originalValue}/${value}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  // TODO: Check whether the previously used (?) sync functionality is necessary.
  // [HttpPost("composer/saveAll/{typeId}/{countryId}/{currencyId}/{asOf}/${scenarioId}")]
  public async postAdjustments({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id,
    adjustments = [],
    sync = false
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
    adjustments: Adjustment[];
    sync?: boolean;
  }): Promise<HttpResponse<any> | XMLHttpRequest | void> {
    const tag: string = `${this.tag}.postAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    if (debug) console.log(tag, "sync:", sync);
    if (debug) console.log(tag, "adjustments:", adjustments);
    if (!adjustments.length) return;

    adjustments = adjustments.map(adjustment =>
      Object.assign({}, adjustment, {
        asOf: monthPickerStrNonZero(adjustment.asOf),
        perMonth: monthPickerStrNonZero(adjustment.perMonth)
      })
    );

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.SaveAll].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return sync
      ? this.request.postSync(url, adjustments)
      : this.request.post(url, adjustments);
  }

  // [HttpPost("composer/undo/{typeId}/{countryId}/{currencyId}/{asOf}/{perMonth}/{scenarioId}")]
  public async postUndoAdjustment({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    perMonth,
    id = this.state.scenario && this.state.scenario.id
  }: {
    kpi?: number;
    organization?: number;
    currency?: number;
    asOf?: string;
    perMonth: string;
    id?: number;
  }): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.postUndoAdjustment()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    if (debug) console.log(tag, "asOf:", asOf);
    if (debug) console.log(tag, "perMonth:", perMonth);

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.Undo].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(
      asOf
    )}/${monthPickerStrNonZero(perMonth)}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  // TODO: Check whether the previously used (?) sync functionality is necessary.
  // [HttpPost("composer/undoAll/{typeId}/{countryId}/{currencyId}/{asOf}")]
  public async postUndoAdjustments({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id,
    adjustments = [],
    sync = false
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
    adjustments: Adjustment[];
    sync?: boolean;
  }): Promise<HttpResponse<any> | XMLHttpRequest | void> {
    const tag: string = `${this.tag}.postAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    if (debug) console.log(tag, "sync:", sync);
    if (debug) console.log(tag, "adjustments:", adjustments);
    if (!adjustments.length) return;

    adjustments = adjustments.map(adjustment =>
      Object.assign({}, adjustment, {
        asOf: monthPickerStrNonZero(adjustment.asOf),
        perMonth: monthPickerStrNonZero(adjustment.perMonth)
      })
    );

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.UndoAll].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return sync
      ? this.request.postSync(url, adjustments)
      : this.request.post(url, adjustments);
  }

  // [HttpPost("composer/submitAll/{typeId}/{countryId}/{currencyId}/{asOf}/{label}/{scenarioId?}")]
  public async saveAdjustments({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    label = this.state.generateKpiPath(" > "),
    id = this.state.scenario && this.state.scenario.id,
    quarterAdjustments
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
    quarterAdjustments: KpiValuesQuarter[];
    comment?: string;
    label?: string;
  }): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.saveAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (debug) console.log(tag, "label:", label);
    if (!kpi) return;

    if (debug) console.log(tag, "quarterAdjustments:", quarterAdjustments);
    if (!quarterAdjustments.length) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.SubmitAll].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(
      asOf
    )}/${label}/${id ? id : ""}`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url, quarterAdjustments);
  }

  // [HttpPost("composer/submitAllWithComment/{typeId}/{countryId}/{currencyId}/{asOf}/{message}/{label}/{scenarioId?}")]
  public async saveAdjustmentsWithComment({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    label = this.state.generateKpiPath(" > "),
    id = this.state.scenario && this.state.scenario.id,
    quarterAdjustments,
    comment = ""
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
    quarterAdjustments: KpiValuesQuarter[];
    comment?: string;
    label?: string;
  }): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.saveAdjustmentsWithComment()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (debug) console.log(tag, "label:", label);
    if (!kpi) return;

    if (debug) console.log(tag, "comment:", comment);
    if (true) console.log(tag, "quarterAdjustments:", quarterAdjustments);
    if (!quarterAdjustments.length) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.SubmitAllWithComment].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      !!comment ? comment + "/" : ""
    }${label}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url, quarterAdjustments);
  }

  // [HttpGet("composer/resetAll/{typeId}/{countryId}/{currencyId}/{asOf}/{scenarioId?}")]
  public async setMachineValues({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
  } = {}): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.setMachineValues()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.ResetAll].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  // [HttpPost("resetAll/{asOf}/{countryId}/{scenarioId?}")]
  public async setMachineValuesAdditionalKpis({
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.setMachineValuesAdditionalKpis()`;
    const debug: boolean = this.debug || false;

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.ResetAllAdditionalKpi].endpoint
    }/${this.state.organizationEntity.id}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  // [HttpGet("composer/resetLastSubmission/{typeId}/{countryId}/{currencyId}/{asOf}/{scenarioId?}")]
  public async setLastSubmittedValues({
    kpi = this.state.kpiTypeId,
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    kpi?: number;
    organization?: number;
    asOf?: string;
    currency?: number;
    id?: number;
  } = {}): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.setLastSubmittedValues()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    if (debug) console.log(tag, "id:", id);
    if (!kpi) return;

    // const access: boolean = await this.data.hasApplicationAccess();
    // if (!access) return;

    const url: string = `${
      Api[Controller.Adjust].endpoints[Adjust.ResetLastSubmission].endpoint
    }/${kpi}/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.Adjust */

  // [HttpPost("{forecastId}/{adjustValue}")]
  public async postAdditionalKpiAdjustment({
    id,
    value
  }: {
    id: number;
    value: number | string;
  }): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.postAdjustment()`;
    const url: string = `${Api[Controller.Adjust].endpoint}/${id}/${value}`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }

  public async undoAllAdjustments(
    adjustments: Adjustment[],
    sync: boolean = false
  ): Promise<HttpResponse<any> | XMLHttpRequest> {
    const tag: string = `${this.tag}.undoAllAdjustments()`;
    if (this.debug) console.log(tag, "adjustments:", adjustments);
    if (this.debug) console.log(tag, "sync:", sync);
    if (this.debug)
      console.log(tag, "state.writeAccess:", this.state.writeAccess);
    if (!this.state.writeAccess) return;
    adjustments.forEach(element => {
      element.adjustValue = null;
      element.forecastId = element.forecastId;
    });
    const url: string = `${Api[Controller.Adjust].endpoints[Adjust.SaveAllAdditionalKpi].endpoint}`;
    if (this.debug) console.log(tag, "url:", url);
    return sync
      ? this.request.postSync(url, adjustments)
      : this.request.post(url, adjustments);
  }

  public async undoLastAdjustment(
    adjustments: Adjustment[],
    lastAdjustment: Adjustment
  ): Promise<HttpResponse<any> | void> {
    let tag: string = `${this.tag}.undoLastAction()`;
    if (this.debug) console.log(tag, "adjustments:", adjustments);
    if (this.debug) console.log(tag, "lastAdjustment:", lastAdjustment);
    if (!lastAdjustment) return;
    lastAdjustment.adjustValue = null;
    const url: string = `${Api[Controller.Adjust].endpoints[Adjust.SaveAllAdditionalKpi].endpoint}`;
    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url, [lastAdjustment]);
  }

  // [HttpPost("submitAll")]
  public async applyAdjustments(
    adjustments: Adjustment[],
    message?: string
  ): Promise<HttpResponse<any> | void> {
    const tag: string = `${this.tag}.applyAdjustments()`;
    if (this.debug) console.log(tag, "adjustments:", adjustments);
    if (!adjustments.length) return;

    const url: string = `${adjustments.reduce(
      (url, b, i) =>
        url + `ids=${b.forecastId}${i < adjustments.length - 1 ? "&" : ""}`,
      `${Api[Controller.Adjust].endpoints[Adjust.SubmitAllAdditionalKpi].endpoint}?`
    )} ${message ? "&message=" + message : ""}`;

    if (this.debug) console.log(tag, "url:", url);
    return this.request.post(url);
  }
  /** Controller.Adjust */

  /** Controller.AdjustmentHistory */
  public async setUpAdjustmentHistory(): Promise<void> {
    const tag: string = `${this.tag}.setUpAdjustmentHistory()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Workbench) return;

    const adjustmentHistory: AdjustmentHistory[] = (await this.getAdjustmentHistory())
      .body;
    const adjustmentHistoryContainer: AdjustmentHistoryContainer = new AdjustmentHistoryContainer(
      { adjustmentHistory }
    );
    if (debug)
      console.log(
        tag,
        "adjustmentHistoryContainer:",
        adjustmentHistoryContainer
      );
    this._adjustmentHistory$.next(adjustmentHistoryContainer);
  }
  // [HttpGet]
  private async getAdjustmentHistory({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getAdjustments()`;
    const debug: boolean = this.debug || false;
    const url: string = `${Api[Controller.AdjustmentHistory].endpoint}/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.AdjustmentHistory */

  /** Controller.Scenario */
  public async setUpScenarios(
    parameter: Parameter = null,
    resetScenario: boolean = true
  ): Promise<void> {
    const tag: string = `${this.tag}.setUpScenarios()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "parameter:", parameter);
    if (debug) console.log(tag, "resetScenario:", resetScenario);

    // const to: number = this.state.datePickerDate.getFullYear();
    // if (debug) console.log(tag, 'to:', to);
    // const years: number[] = getCompareYears(to);
    // if (debug) console.log(tag, 'years:', years);
    // const asOfFrom: Date = new Date(years[0]);
    // if (debug) console.log(tag, 'asOfFrom:', asOfFrom);
    // const asOfTo: Date = new Date(years[years.length - 1]);
    // if (debug) console.log(tag, 'asOfTo:', asOfTo);

    const response: HttpResponse<any> | void = await this.getScenarios({
      // asOfFrom: dateToMonthPickerStr(asOfFrom),
      // asOfTo: dateToMonthPickerStr(asOfTo)
    });
    if (debug) console.log(tag, "response:", response);
    if (!response) {
      if (debug) console.log(tag, "Back-end has no forecasts.");
      return;
    }

    const scenarioMonths: IScenarioMonth[] = response.body;
    if (debug) console.log(tag, "scenarioMonths:", scenarioMonths);
    const scenarioContainer: ScenarioContainer = new ScenarioContainer(
      scenarioMonths
    );
    if (debug) console.log(tag, "scenarioContainer:", scenarioContainer);
    this.scenarioMonths = scenarioContainer
      .format(this.state.user.user.name)
      .map(m => {
        const generatedScenario: Scenario = m.scenarios.find(s => !s.id);
        const scenarioMonth: ScenarioMonth = Object.assign({}, m, {
          scenarios: m.scenarios
            .filter(s => s !== generatedScenario)
            .sort((a, b) => sortByDate(a, b, "createdOn"))
        });
        if (generatedScenario) scenarioMonth.scenarios.push(generatedScenario);
        return scenarioMonth;
      });
    if (debug) console.log(tag, "this.scenarioMonths:", this.scenarioMonths);

    if (debug)
      console.log(tag, "state.datePickerDate:", this.state.datePickerDate);
    const currentMonth: ScenarioMonth = this.scenarioMonths.find(month =>
      eqDate(month.date, this.state.datePickerDate)
    );
    if (debug) console.log(tag, "currentMonth:", currentMonth);
    if (currentMonth) {
      /* currentMonth.scenarios.find(s => !s.readOnly || !!s.submitted) || */
      const scenario: Scenario =
        (currentMonth && currentMonth.scenarios[0]) || null;
      if (debug) console.log(tag, "scenario:", scenario);
      if (resetScenario) {
        this.state.scenario = scenario;
        if (debug) console.log(tag, "state.scenario:", this.state.scenario);
      }

      const lastMonth: ScenarioMonth = this.scenarioMonths[0];
      if (debug) console.log(tag, "lastMonth:", lastMonth);
      this.state.scenarioLastAvailableMonth =
        lastMonth && normalizeDate(lastMonth.date);
      if (debug)
        console.log(
          tag,
          "state.scenarioLastAvailableMonth:",
          this.state.scenarioLastAvailableMonth
        );
    } else {
      this.setUpScenario();
    }
    this._scenarios$.next(scenarioContainer);
  }
  // [HttpGet("list/{countryId}")]
  private async getScenarios({
    organization = this.state.organizationEntity.id /*, asOfFrom, asOfTo*/
  }: {
    organization?: number /* asOfFrom: string, asOfTo: string, */;
  }): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getScenarios()`;
    const debug: boolean = this.debug || false;
    const url: string = `${Api[Controller.Scenario].endpoints[ScenarioApi.List].endpoint}/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  public async postScenario({
    scenario,
    id
  }: {
    scenario: ICreateScenario;
    id?: number;
  }): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.postScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenario:", scenario);
    const url: string = `${
      Api[Controller.Scenario].endpoints[ScenarioApi.Save].endpoint
    }/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url, scenario);
  }
  public async postMachineScenario({
    scenario,
    id
  }: {
    scenario: ICreateScenario;
    id?: number;
  }): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.postScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenario:", scenario);
    const url: string = `${
      Api[Controller.Scenario].endpoints[ScenarioApi.Save].endpoints[
        ScenarioSave.WithModel
      ].endpoint
    }/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.post(url, scenario);
  }
  /** Controller.Scenario */

  /** Controller.Export */
  // [HttpGet("{countryId}/{asOf}/{removeDisabled}/{scenarioId?}/{amount?}")]
  public getExportFileUrl({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id,
    removeDisabled = false
  }: {
    organization?: number;
    asOf?: string;
    id?: number;
    removeDisabled?: boolean;
  } = {}): string {
    const tag: string = `${this.tag}.getExportFile()`;
    const debug: boolean = this.debug || false;
    const url: string = `${this.configuration.backendBaseUrl}/${
      Api[Controller.Export].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}/${removeDisabled}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return url;
  }
  /** Controller.Export */

  /** Controller.AdditionalKpi */
  public async setUpAdditionalKpis(): Promise<void> {
    const tag: string = `${this.tag}.setUpAdditionalKpis()`;
    const debug: boolean = this.debug || false;
    // If so, then setUpAdditionalKpis() has to be called in the additionalKpis.component.
    // if (debug) console.log(tag, 'this.state.topLevelKpi:', TopLevelKpi[this.state.topLevelKpi]);
    // if (this.state.topLevelKpi !== TopLevelKpi.AdditionalKpis) return;

    const additionalKpis: AdditionalKpiContainer = new AdditionalKpiContainer(
      (await this.getAdditionalKpis()).body
    );
    if (debug) console.log(tag, "additionalKpis:", additionalKpis);
    this._additionalKpis$.next(additionalKpis);
  }

  // [HttpGet("Composer/{countryId}/{currencyId}/{asOfS}/{scenarioId?}")]
  private async getAdditionalKpis({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getAdditionalKpis()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.Composer].endpoint
    }/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpMoveReporting(): Promise<void> {
    const tag: string = `${this.tag}.setUpMoveReporting()`;
    const debug: boolean = this.debug || false;
    const moveReporting: MoveReportingContainer = new MoveReportingContainer(
      (await this.getMoveReporting()).body
    );
    if (debug) console.log(tag, "moveReporting:", moveReporting);
    this._moveReporting$.next(moveReporting);
  }

  // [HttpGet("Composer/MoveReporting/{countryId}/{currencyId}/{asOfS}/{scenarioId?}")]
  private async getMoveReporting({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getMoveReporting()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.MoveReporting].endpoint
    }/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpItReporting(): Promise<void> {
    const tag: string = `${this.tag}.setUpItReporting()`;
    const debug: boolean = this.debug || false;
    const itReporting: ItReportingContainer = new ItReportingContainer(
      (await this.getItReporting()).body
    );
    if (debug) console.log(tag, "itReporting:", itReporting);
    this._itReporting$.next(itReporting);
  }

  // [HttpGet("Composer/ItReporting/{countryId}/{currencyId}/{asOfS}/{scenarioId?}")]
  private async getItReporting({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getItReporting()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.ItReporting].endpoint
    }/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }


  public async setUpFocusReporting(): Promise<void> {
    const tag: string = `${this.tag}.setUpFocusReporting()`;
    const debug: boolean = this.debug || false;
    const focusReporting: FocusReportingContainer = new FocusReportingContainer(
      (await this.getFocusReporting()).body
    );
    if (debug) console.log(tag, "focusReporting:", focusReporting);
    this._focusReporting$.next(focusReporting);
  }

  // [HttpGet("Composer/FocusReporting/{countryId}/{currencyId}/{asOfS}/{scenarioId?}")]
  private async getFocusReporting({
    organization = this.state.organizationEntity.id,
    currency = this.state.currency,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currency?: Currency;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getFocusReporting()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.AdditionalKpi].endpoints[AdditionalKpi.FocusReporting].endpoint
    }/${organization}/${currency}/${monthPickerStrNonZero(asOf)}/${
      id ? id : ""
    }`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.AdditionalKpi */

  /** Controller.CurrencySplit */
  public async setUpCurrencySplit(): Promise<void> {
    const tag: string = `${this.tag}.setUpCurrencySplit()`;
    const debug: boolean = this.debug || false;
    const currencySplit: CurrencySplitContainer = new CurrencySplitContainer(
      (await this.getCurrencySplit()).body
    );
    if (debug) console.log(tag, "currencySplit:", currencySplit);
    this._currencySplit$.next(currencySplit);
  }

  // [HttpGet("Composer/{countryId}/{currencyId}/{secondCurrencyId}/{asOfS}/{scenarioId?}")]
  private async getCurrencySplit({
    organization = this.state.organizationEntity.id,
    currencyId = this.state.organizationEntity.currencyId,
    secondCurrencyId = this.state.organizationEntity.secondCurrencyId,
    asOf = this.state.datePickerDateAsOf,
    id = this.state.scenario && this.state.scenario.id
  }: {
    organization?: number;
    currencyId?: number;
    secondCurrencyId?: number;
    asOf?: string;
    id?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getCurrencySplit()`;
    const debug: boolean = this.debug || false;
    if (secondCurrencyId == null) secondCurrencyId = 0;
    const url: string = `${
      Api[Controller.CurrencySplit].endpoints[CurrencySplit.Composer].endpoint
    }/${organization}/${currencyId}/${secondCurrencyId}/${monthPickerStrNonZero(
      asOf
    )}/${id ? id : ""}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.CurrencySplit */

  /** Controller.Navigation */
  public async setUpNavigation(): Promise<void> {
    const tag: string = `${this.tag}.setUpNavigation()`;
    const debug: boolean = this.debug || false;
    const navigation: INavigationKpiTree[] = (await this.getNavigation()).body;
    if (debug) console.log(tag, "navigation:", navigation);
    this.navigation = navigation;
    this._navigation$.next(navigation);
    this.setPortfolio();
  }

  // [HttpGet("{countryId)]
  private async getNavigation({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getNavigation()`;
    const debug: boolean = this.debug || false;
    const url: string = `${Api[Controller.Navigation].endpoint}/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public setTopLevelKpi(topLevelKpi: TopLevelKpi): void {
    const tag: string = `${this.tag}.setTopLevelKpi()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "topLevelKpi:", topLevelKpi);
    switch (topLevelKpi) {
      case TopLevelKpi.Portfolio:
        return this.setPortfolio();
      case TopLevelKpi.Ebit:
        return this.setEbit();
      case TopLevelKpi.SalesAndAcquisitions:
        return this.setSalesAndAcquisitions();
      case TopLevelKpi.Dividends:
        return this.setDividends();
      case TopLevelKpi.Capital:
        return this.setCapital();
    }
  }

  public setPortfolio(isFallback: boolean = false): void {
    const tag: string = `${this.tag}.setPortfolio()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "isFallback:", isFallback);
    const portfolio: INavigationKpiTree = this.getPortfolio();
    if (debug) console.log(tag, "portfolio:", portfolio);
    if (portfolio) return this.state.setKpi({ kpi0: portfolio });

    if (isFallback) return;

    this.setEbit(true);
  }

  public getPortfolio(): INavigationKpiTree {
    const tag: string = `${this.tag}.getPortfolio()`;
    const debug: boolean = this.debug && false;
    const portfolio: INavigationKpiTree = this.navigation.find(
      kpi => kpi.kpi.kpiTypeId === Portfolio.kpi.kpiTypeId
    );
    if (debug) console.log(tag, "portfolio:", portfolio);
    return portfolio;
  }

  public setEbit(isFallback: boolean = false): void {
    const tag: string = `${this.tag}.setEbit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "isFallback:", isFallback);
    const ebit: INavigationKpiTree = this.getEbit();
    if (debug) console.log(tag, "ebit:", ebit);
    if (ebit) return this.state.setKpi({ kpi0: ebit });

    if (isFallback) return;

    this.setPortfolio(true);
  }

  public setSalesAndAcquisitions(isFallback: boolean = false): void {
    const tag: string = `${this.tag}.setSalesAndAcquisitions()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "isFallback:", isFallback);
    const salesAndAcquisitions: INavigationKpiTree = this.getSalesAndAcquisitions();
    if (debug) console.log(tag, "salesAndAcquisitions:", salesAndAcquisitions);
    if (salesAndAcquisitions)
      return this.state.setKpi({ kpi0: salesAndAcquisitions });

    if (isFallback) return;

    this.setPortfolio(true);
  }

  public getSalesAndAcquisitions(): INavigationKpiTree {
    const tag: string = `${this.tag}.getSalesAndAcquisitions()`;
    const debug: boolean = this.debug && false;
    const salesAndAcquisitions: INavigationKpiTree = this.navigation.find(
      kpi => kpi.kpi.kpiTypeId === SalesAndAcquisitions.kpi.kpiTypeId
    );
    if (debug) console.log(tag, "salesAndAcquisitions:", salesAndAcquisitions);
    return salesAndAcquisitions;
  }

  public setDividends(isFallback: boolean = false): void {
    const tag: string = `${this.tag}.setDividends()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "isFallback:", isFallback);
    const dividends: INavigationKpiTree = this.getDividends();
    if (debug) console.log(tag, "dividends:", dividends);
    if (dividends)
      return this.state.setKpi({ kpi0: dividends });

    if (isFallback) return;

    this.setPortfolio(true);
  }

  public getDividends(): INavigationKpiTree {
    const tag: string = `${this.tag}.getDividends()`;
    const debug: boolean = this.debug && false;
    const dividends: INavigationKpiTree = this.navigation.find(
      kpi => kpi.kpi.kpiTypeId === Dividends.kpi.kpiTypeId
    );
    if (debug) console.log(tag, "dividends:", dividends);
    return dividends;
  }

  public setCapital(isFallback: boolean = false): void {
    const tag: string = `${this.tag}.setCapital()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "isFallback:", isFallback);
    const capital: INavigationKpiTree = this.getCapital();
    if (debug) console.log(tag, "capital:", capital);
    if (capital)
      return this.state.setKpi({ kpi0: capital });

    if (isFallback) return;

    this.setPortfolio(true);
  }

  public getCapital(): INavigationKpiTree {
    const tag: string = `${this.tag}.getCapital()`;
    const debug: boolean = this.debug && false;
    const capital: INavigationKpiTree = this.navigation.find(
      kpi => kpi.kpi.kpiTypeId === Capital.kpi.kpiTypeId
    );
    if (debug) console.log(tag, "capital:", capital);
    return capital;
  }

  public getEbit(): INavigationKpiTree {
    const tag: string = `${this.tag}.getEbit()`;
    const debug: boolean = this.debug && false;
    const ebit: INavigationKpiTree = this.navigation.find(
      kpi => kpi.kpi.kpiTypeId === Ebit.kpi.kpiTypeId
    );
    if (debug) console.log(tag, "ebit:", ebit);
    return ebit;
  }

  public async OnAdjustment(data: any) {
    if (this.state.BulkAdjustment) {
      this.bulkAdjustments.push(data);
    } else {
      this.bulkAdjustments = [];
      await this.postAdjustment(data);
    }
  }

  public async OnAdjustments(adjustments: Adjustment[]) {
    if (this.state.BulkAdjustment) {
      adjustments.forEach(adjustment => {
        this.bulkAdjustments.push({
          perMonth: adjustment.perMonth,
          originalValue: adjustment.originalValue,
          value: adjustment.adjustValue
        });
      });
    } else {
      this.bulkAdjustments = [];
      await this.postAdjustments({ adjustments });
    }
  }

  /** Controller.Navigation */

  /** Controller.Countries */
  public async setUpIsCurrencySplitVisible() {
    const tag: string = `${this.tag}.setUpIsCurrencySplitVisible()`;
    const debug: boolean = this.debug || false;
    const isCurrencySplitVisible: boolean = (await this.getIsCurrencySplitVisible()).body;
    if (debug) console.log(tag, "isCurrencySplitVisible:", isCurrencySplitVisible);
    this._isCurrencySplitVisible$.next(isCurrencySplitVisible);
  }

  // [HttpGet("Countries/{countryId}")]
  private async getIsCurrencySplitVisible({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getIsCurrencySplitVisible()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Countries].endpoints[Countries.IsCurrencySplit].endpoint}/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }
  /** Controller.Countries */
}
