import { formatDate } from 'src/app/plotting/data';
import { Axis, Forecast, } from 'src/app/plotting/constants';
import { isNumber, getEnumKeys, getValues, } from 'src/app/utils';
import { KpiValue, Unit, } from 'src/app/constants';
import { Trace } from 'src/app/plotting/interfaces';

export class KpiValues {
  protected static readonly Tag: string = 'KpiValues';

  public tag: string = KpiValues.Tag;
  public debug: boolean = false;

  public values: Kpi[];

  constructor({ values = [] }: {
    values?: Kpi[]
  } = {}) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    this.values = values.map(value => new Kpi(value));
    if (debug) console.log(tag, 'this:', this);
  }

  public format(): FormattedKpiValues {
    const tag: string = `${this.tag}.format()`;
    const debug: boolean = this.debug || false;

    const date: string = Axis.X;
    const value: string = Axis.Y;

    // Match formatted KPIs to the Forecast enum.
    const kpi: FormattedKpiValues = getEnumKeys(Forecast).reduce((kpi, forecast) => {
      kpi[forecast] = {
        [date]: [],
        [value]: [],
      };
      return kpi;
    }, {});
    const machineTrace: Trace = kpi[Forecast.Machine];
    const organizationTrace: Trace = kpi[Forecast.Organization];
    const adjustedTrace: Trace = kpi[Forecast.Adjustment];
    const actualTrace: Trace = kpi[Forecast.Actual];
    //

    if (debug) console.log(tag, 'this.values:', this.values);
    this.values.forEach((kpi: Kpi) => {
      const values: Values = kpi.values;
      const formattedDate: Date = formatDate(kpi.date);
      const isActual: boolean = values.hasOwnProperty(KpiValue.Actual);
      if (isActual) {
        const actual: string = values[KpiValue.Actual];
        actualTrace[date].push(formattedDate);
        actualTrace[value].push(isNumber(actual) ? parseFloat(actual) : null);
      } else {
        const machine: string = values[KpiValue.Machine];
        const organization: string = values[KpiValue.Organization];
        const adjusted: string = values[KpiValue.Adjusted];

        machineTrace[date].push(formattedDate);
        machineTrace[value].push(isNumber(machine) ? parseFloat(machine) : null);

        organizationTrace[date].push(formattedDate);
        organizationTrace[value].push(isNumber(organization) ? parseFloat(organization) : null);

        adjustedTrace[date].push(formattedDate);
        adjustedTrace[value].push(isNumber(adjusted) ? parseFloat(adjusted) : null);
      }
    });
    if (debug) console.log(tag, 'kpi:', kpi);
    return kpi;
  }
}

export class KpiValuesChild extends KpiValues {
  protected static readonly Tag: string = 'KpiValuesChild';

  public tag: string = KpiValues.Tag;

  public label: string;
  public unit: Unit;

  constructor({ label, unit, values = [] }: {
    label?: string, unit?: Unit, values?: Kpi[]
  } = {}) {
    super({ values });
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    this.label = label;
    this.unit = unit;
  }
}

export class Kpi {
  public id: number;
  public date: string;
  public values: Values;
  public unit?: Unit;

  constructor({ id, date, values }: {
    id?: number, date?: string, values?: Values
  } = {}) {
    this.id = id;
    this.date = date;
    this.values = values;
  }

  public listValues(includeOrganization: boolean = true, includeAdjustment: boolean = true): number[] {
    return [
      KpiValue.Actual,
      KpiValue.Adjusted,
      KpiValue.Machine,
      KpiValue.Organization
    ].map(k => this.values[k]).filter(isNumber).map(Number);
  }
}

export interface Values {
  [KpiValue: string]: string;
}

export interface FormattedKpiValues {
  [Forecast: number]: Trace;
}
