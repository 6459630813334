import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';
import { IType } from 'src/app/models/planning/planning';

@Component({
  selector: 'typePicker',
  templateUrl: './typePicker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TypePickerComponent extends BaseComponent {
  public static readonly Tag: string = 'TypePickerComponent';
  protected tag: string = TypePickerComponent.Tag;
  protected debug: boolean = false;

  public typeData: IType[] = [];

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    public readonly planning: PlanningDataService,
  ) {
    super(cd, state);
  }

  public async ngOnInit() {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.type$
    ];

    this.subscriptions = [
      this.planning.types$.subscribe(this.onTypeChange.bind(this)),
    ];
  }

  public onTypeSelect(typeId: number): void {
    const tag: string = `${this.tag}.onSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'typeId:', typeId);
    this.state.type = typeId;
  }

  private onTypeChange(data: IType[]) {
    const tag: string = `${this.tag}.onTypeChange`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'data', data);
    if (!data) return;
    this.typeData = data;
  }
}
