import * as merge from 'merge';
import { Fonts } from './font';
import { Annotation, Font } from 'src/app/plotting/interfaces';

export class Annotations {
  static Dfs = (options: Annotation): Annotation =>
    merge.recursive({
      // captureevents: false,
      // text,
      xanchor: 'center',
      xref: 'x',
      // x,
      yref: 'y',
      // y,
      showarrow: false,
    }, options, {
        font: Fonts.Dfs(options.font)
      })

  static Larger = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Dfs(options), {
      font: Fonts.Larger(options.font)
    })

  static Large = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Dfs(options), {
      font: Fonts.Large(options.font)
    })

  static Above = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Dfs({
      yanchor: 'bottom',
    }), options)

  static AboveLarger = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Above(options), Annotations.Larger(options))

  static AboveLarge = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Above(options), Annotations.Large(options))

  static Below = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Dfs({
      yanchor: 'top',
    }), options)

    static BelowLarger = (options: Annotation): Annotation =>
    merge.recursive({
      yanchor: 'top',
    }, Annotations.Below(options), Annotations.Larger(options))

  static BelowLarge = (options: Annotation): Annotation =>
    merge.recursive({
      yanchor: 'top',
    }, Annotations.Below(options), Annotations.Large(options))

  static Top = (options: Annotation): Annotation =>
    merge.recursive(Annotations.Dfs({
      yref: 'paper',
      yanchor: 'top',
    }), options)
}
