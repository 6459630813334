import { Country } from "src/app/models/common/country";
import { createDate, normalizeDate } from "src/app/utils";

export class NotificationContainer {
  public notifications: Notification[];

  constructor({ notifications = [] }: {
    notifications?: INotification[]
  } = {}) {
    this.notifications = notifications.map(n => new Notification(n));
  }
}

export class Notification {
  public id: number;
  public createdOn: Date;
  public title: string;
  public isRead: boolean;
  public countryId: number;
  public country: Country;
  public asOf: Date;
  public html: string;

  constructor({ id, createdOn, title, isRead, countryId, country, asOfMonth, html,
  }: {
      id?: number, createdOn?: string, title?: string,
      isRead?: boolean, countryId?: number,
      country?: Country, asOfMonth?: string, html?: string,
    } = {}) {
    this.id = id;
    this.createdOn = createDate(createdOn);
    this.title = title;
    this.isRead = isRead;
    this.countryId = countryId;
    this.country = new Country(country);
    this.asOf = normalizeDate(asOfMonth);
    this.html = html;
  }
}

export interface INotification {
  id: number;
  createdOn: string;
  title: string;
  isRead: boolean;
  countryId: number;
  country: Country;
  asOfMonth: string;
  html: string;
}
