import * as merge from "merge";
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Input,
  ElementRef
} from "@angular/core";
import { NotifierType } from "src/app/constants";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { Kpi } from "src/app/models/common/kpiValues";
import {
  KpiValuesQuarterContainer,
  KpiValuesQuarter
} from "src/app/models/workbench/kpiValuesQuarter";
import { StateService } from "src/app/services/state/state.service";
import { CommonDataService } from "src/app/services/data/common.data.service";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { UserSettingsService } from "src/app/services/userSettings.service";
import { ModalService } from "src/app/services/modal.service";
import { NotificationService } from "src/app/services/notification.service";
import { WorkbenchResolver } from "src/app/resolvers/workbench.resolver";
import {
  KpiValue,
  Adjustment,
  AdjustmentButton,
  AdjustmentButtons,
  AdjustmentTab,
  TopLevelKpi,
} from "src/app/constants";
import {
  isAdjusted,
  isForecast,
  createDate,
  dateToMonthPickerStr,
  isQuarterAdjusted,
  sortQuarterAdjustments,
  flatten,
  removeCommaSeparatorNum,
  removeExtraSigns,
  sortAdjustments,
  normalizeDate
} from "src/app/utils";
import { ConfirmationDialogComponent } from "src/app/components/common/elements/confirmationDialog/confirmationDialog.component";
@Component({
  selector: "adjustmentButtons",
  templateUrl: "adjustmentButtons.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class AdjustmentButtonsComponent extends BaseComponent
  implements OnInit {
  private static readonly Tag: string = "AdjustmentButtonsComponent";
  protected readonly tag: string = AdjustmentButtonsComponent.Tag;
  protected readonly debug: boolean = false;
  public ShowLastSubmissionBtn: boolean = false;

  public readonly AdjustmentButtons = merge.recursive(true, AdjustmentButtons, {
    [AdjustmentButton.LastSubmitted]: {
      action: this.lastSubmittedAction
    },
    [AdjustmentButton.AddAll]: {
      action: this.modelFcAction
    },
    [AdjustmentButton.UndoAll]: {
      action: this.undoAllAction
    },
    [AdjustmentButton.UndoLast]: {
      action: this.undoLastAction
    },
    [AdjustmentButton.Save]: {
      action: this.saveAction
    },
    [AdjustmentButton.BulkSave]: {
      action: this.SaveBulkAction
    }
  });

  @ViewChild("adjustmentConfirmationDialog")
  private readonly confirmationDialog: ConfirmationDialogComponent;

  @ViewChild("adjustmentLastSubmittedBtn")
  adjustmentLastSubmittedBtnEl: ElementRef;
  @ViewChild("adjustmentAddAllBtn") adjustmentAddAllBtnEl: ElementRef;
  @ViewChild("adjustUndoAllBtn") adjustUndoAllBtnEl: ElementRef;
  @ViewChild("adjustmentUndoLastBtn") adjustmentUndoLastBtnEl: ElementRef;
  @ViewChild("adjustmentBulkSaveBtn") adjustmentBulkSaveBtnEl: ElementRef;
  @ViewChild("adjustmentSaveBtn") adjustmentSaveBtnEl: ElementRef;

  public readonly debugConfirmationDialog: boolean = false;

  public quarterContainer: KpiValuesQuarterContainer;
  public adjustedQuarters: KpiValuesQuarter[] = [];

  @Input() public comment: string;
  @Input() public undoMonth: boolean = false;

  public button: AdjustmentButton;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly data: CommonDataService,
    public readonly workbench: WorkbenchDataService,
    private readonly resolver: WorkbenchResolver,
    private readonly modal: ModalService,
    public readonly user: UserSettingsService,
    private readonly notification: NotificationService
  ) {
    super(cd, state);
  }

  private get adjustedKpis(): Kpi[] {
    const adjustedKpisArr: Kpi[][] = this.adjustedQuarters.map(
      quarter => quarter.kpiValues
    );
    return flatten(adjustedKpisArr).filter(isAdjusted);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.workbench.scenario$.subscribe(this.onScenarioChange.bind(this)),
      this.workbench.navigationChange.subscribe(
        this.onNavigationChange.bind(this)
      )
    ];
  }

  public ngAfterViewInit(): void {
    const tag: string = `${this.tag}.ngAfterViewInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.state.adjustments = this;
  }

  // For Top level kpis (Portfolio and Ebits) last submission btn show be hidden
  private onNavigationChange(navigation: any[]): void {
    this.ShowLastSubmissionBtn = !!this.state.kpi1;
  }

  private onScenarioChange(quarterContainer: KpiValuesQuarterContainer): void {
    const tag: string = `${this.tag}.onScenarioChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    if (!quarterContainer) return;

    const adjustedQuarters: KpiValuesQuarter[] = quarterContainer.values
      .filter(isQuarterAdjusted)
      .sort(sortQuarterAdjustments)
      .reverse();
    if (debug) console.log(tag, "adjustedQuarters:", adjustedQuarters);

    Object.assign(this, {
      quarterContainer,
      adjustedQuarters
    });

    this.setUnsavedAdjustments();
    this.setLoadingStatus();
  }

  private setUnsavedAdjustments(): void {
    const tag: string = `${this.tag}.setUnsavedAdjustments()`;
    if (this.debug) console.log(tag);
    this.state.unsavedAdjustmentsMap[AdjustmentTab.Workbench] =
      this.adjustedQuarters.length > 0;
  }

  public async onBtnTap(button: AdjustmentButton): Promise<void> {
    const tag: string = `${this.tag}.onBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "button:", AdjustmentButton[button]);
    this.button = button;
    if (debug)
      console.log(tag, " this.confirmationDialog:", this.confirmationDialog);
    switch (button) {
      case AdjustmentButton.LastSubmitted:
        this.confirmationDialog.target = this.adjustmentLastSubmittedBtnEl;
        break;
      case AdjustmentButton.AddAll:
        this.confirmationDialog.target = this.adjustmentAddAllBtnEl;
        break;
      case AdjustmentButton.UndoAll:
        this.confirmationDialog.target = this.adjustUndoAllBtnEl;
        break;
      case AdjustmentButton.UndoLast:
        this.confirmationDialog.target = this.adjustmentUndoLastBtnEl;
        break;
      case AdjustmentButton.Save:
        this.confirmationDialog.target = this.adjustmentSaveBtnEl;
        break;
      case AdjustmentButton.BulkSave:
        this.confirmationDialog.target = this.adjustmentBulkSaveBtnEl;
    }
    this.confirmationDialog.title = AdjustmentButtons[button].title;
    this.confirmationDialog.text = AdjustmentButtons[button].confirmationText;

    const skipConfirmationDialog: boolean = this.user.getSkipConfirmationDialog(
      AdjustmentButton[button]
    );
    if (debug)
      console.log(tag, "skipConfirmationDialog:", skipConfirmationDialog);
    if (skipConfirmationDialog) {
      this.onConfirmationDialogContinueTap();
    } else {
      this.confirmationDialog.Toggle();
    }
  }

  public onConfirmationDialogContinueTap(): void {
    const tag: string = `${this.tag}.onConfirmationDialogContinueTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.AdjustmentButtons[this.button].action.call(this);
    this.button = null;
  }

  public onConfirmationDialogCancelTap(): void {
    const tag: string = `${this.tag}.onConfirmationDialogCancelTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.button = null;
  }

  public getAdjustments(kpiValue: KpiValue): Adjustment[] {
    const tag: string = `${this.tag}.getAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug)
      console.log(tag, "this.quarterContainer:", this.quarterContainer);
    const adjustments: Adjustment[] = this.quarterContainer.kpiValues.values
      .filter(isForecast)
      .map(kpi => ({
        asOf: this.state.datePickerDateAsOf,
        perMonth: dateToMonthPickerStr(createDate(kpi.date)),
        adjustValue: removeCommaSeparatorNum(
          removeExtraSigns(kpi.values[kpiValue])
        )
      }));
    if (debug) console.log(tag, "adjustments:", adjustments);
    return adjustments;
  }

  public getMachineAdjustments(): Adjustment[] {
    const tag: string = `${this.tag}.getMachineAdjustments()`;
    const debug: boolean = this.debug || false;
    const adjustments: Adjustment[] = this.getAdjustments(KpiValue.Machine);
    if (debug) console.log(tag, "adjustments:", adjustments);
    return adjustments;
  }

  // TODO: Check whether the previously used (?) sync functionality is necessary.
  public async undoAllAction(
    sync: boolean = false,
    resolve: boolean = true
  ): Promise<any[] | void> {
    const tag: string = `${this.tag}.undoAllAction()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "sync:", sync);
    if (debug) console.log(tag, "resolve:", resolve);
    if (debug)
      console.log(tag, "this.adjustedQuarters:", this.adjustedQuarters);
    const adjustments: Adjustment[] = this.adjustedKpis.map(kpi => ({
      asOf: this.state.datePickerDateAsOf,
      perMonth: dateToMonthPickerStr(createDate(kpi.date)),
      adjustValue: null
    }));
    if (debug) console.log(tag, "adjustments:", adjustments);
    if (!adjustments.length) return;

    try {
      await this.workbench.postUndoAdjustments({ adjustments, sync });
    } catch (e) {
      this.notification.add({
        type: NotifierType.Error,
        text: "Resetting adjustments failed"
      });

      return;
    }

    this.notification.add({
      type: NotifierType.Success,
      text: "Adjustments have been reset"
    });

    if (resolve) await this.resolver.load();
    if (debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  private  async undoLastAction(): Promise<void> {
    if (this.undoMonth) {
      await this.undoLastMonthAction();
    }
    else {
      await this.undoLastQuarterAction();
    }
  }

  private async undoLastQuarterAction(): Promise<void> {
    const tag: string = `${this.tag}.undoLastAction()`;
    const debug: boolean = this.debug || false;
    if (debug)
      console.log(tag, "this.adjustedQuarters:", this.adjustedQuarters);
    const lastAdjustedQuarter: KpiValuesQuarter = this.adjustedQuarters[0];
    if (debug) console.log(tag, "lastAdjustedQuarter:", lastAdjustedQuarter);
    if (!lastAdjustedQuarter) return;

    const firstKpi: Kpi = lastAdjustedQuarter.kpiValues.filter(isForecast)[0];
    if (debug) console.log(tag, "firstKpi:", firstKpi);
    if (!firstKpi) return;

    await this.workbench.postUndoAdjustment({
      asOf: this.state.datePickerDateAsOf,
      perMonth: dateToMonthPickerStr(createDate(firstKpi.date))
    });
    await this.reload();
    if (this.debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  private async undoLastMonthAction(): Promise<void> {
    const tag: string = `${this.tag}.undoLastMonthAction()`;
    const debug: boolean = this.debug || false;
    if (debug)
      console.log(tag, "this.adjustedQuarters:", this.adjustedQuarters);
    let kpis: Kpi[] = [];
    this.adjustedQuarters.forEach(quarter => {
      quarter.kpiValues.forEach(kpi => {
        if (kpi.values[KpiValue.Adjusted] != null) {
          kpis.push(kpi)
        }
      });
    });
    const firstKpi: Kpi = kpis.sort(sortAdjustments).reverse()[0];
    const sorted: Kpi[] = kpis.sort(sortAdjustments).reverse();
    if (debug) console.log(tag, "firstKpi:", firstKpi);
    if (!firstKpi) return;

    if (this.state.datePickerDate.getMonth() < 8 && normalizeDate(firstKpi.date).getFullYear() > this.state.datePickerDate.getFullYear() && !(this.state.topLevelKpi === TopLevelKpi.Dividends || this.state.topLevelKpi === TopLevelKpi.Capital)) {
      for (let i = 0; i < 12; i++) {
        if (normalizeDate(firstKpi.date).getFullYear() == normalizeDate(sorted[i].date).getFullYear()) {
          await this.workbench.undoLastAdjustment(null, { forecastId: sorted[i].id, adjustValue: null, perMonth: dateToMonthPickerStr(createDate(sorted[i].date))})
        }
      }
    }
    else {
      await this.workbench.undoLastAdjustment(null, { forecastId: firstKpi.id, adjustValue: null, perMonth: dateToMonthPickerStr(createDate(firstKpi.date))})
    }
    await this.reload();
    if (this.debug)
      console.log(
        tag,
        "state.unsavedAdjustmentsMap:",
        this.state.unsavedAdjustmentsMap
      );
  }

  private async SaveBulkAction(): Promise<void> {
    for (let value of this.workbench.bulkAdjustments) {
      await this.workbench.postAdjustment(value);
    }
    this.workbench.bulkAdjustments = [];
    await this.resolver.load();
  }

  private async saveAction(): Promise<void> {
    const tag: string = `${this.tag}.saveAction()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.adjustedQuarters", this.adjustedQuarters);
    if (debug) console.log(tag, "this.comment:", this.comment);

    try {
      await (this.comment
        ? this.workbench.saveAdjustmentsWithComment({
            quarterAdjustments: this.adjustedQuarters,
            comment: this.comment
          })
        : this.workbench.saveAdjustments({
            quarterAdjustments: this.adjustedQuarters
          }));

      this.clearComment();
    } catch (e) {
      this.notification.add({
        type: NotifierType.Error,
        text: "Saving adjustments failed"
      });

      return;
    }

    this.notification.add({
      type: NotifierType.Success,
      text: "Adjustments saved successfully"
    });

    await Promise.all([this.reload(), this.workbench.setUpAdjustmentHistory()]);
  }

  private async lastSubmittedAction(): Promise<void> {
    const tag: string = `${this.tag}.lastSubmittedAction()`;
    const debug: boolean = this.debug || false;

    await this.workbench.setLastSubmittedValues();
    await this.reload();
  }

  private async modelFcAction(): Promise<void> {
    const tag: string = `${this.tag}.modelFcAction()`;
    const debug: boolean = this.debug || false;

    await this.workbench.setMachineValues();

    this.clearAdjustments();

    await Promise.all([this.resolver.load(), this.data.commonReload()]);
  }

  private clearAdjustments(): void {
    const tag: string = `${this.tag}.clearAdjustments()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.adjustedQuarters = [];
    this.setUnsavedAdjustments();
  }

  private clearComment(): void {
    const tag: string = `${this.tag}.clearComment()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.comment = "";
  }

  private async reload(): Promise<void> {
    const tag: string = `${this.tag}.reload()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    await Promise.all([this.data.commonReload(), this.resolver.load()]);
  }
  // TODO: Check if necessary. // Added as an extra caution, but should be dealt with in app.component already. (Isn' t yet.)
  public async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();
    const tag: string = `${this.tag}.ngOnDestroy()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    await this.undoAllAction(true);
  }

  public readonly AdjustmentButton = AdjustmentButton;
}
