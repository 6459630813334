import { Unit } from "src/app/constants";

export class CurrencySplitContainer {
  private static readonly Tag = 'CurrencySplitContainer';
  private static readonly Debug: boolean = false;

  public currencySplit: ICurrencySplit[];

  constructor(currencySplit: ICurrencySplit[]) {
    this.currencySplit = currencySplit;
  }
}

export interface ICurrencySplit {
  kpiType: IKpiType;
  firstCurrencySplitValues: IKpiForecast[];
  secondCurrencySplitValues: IKpiForecast[];
}

export interface IKpiType {
  id: number;
  name: string;
  label: string;
  labelShort: string;
  labelMiddle: string;
  description: string;
  formula: string;
  atomic?: boolean;
  adjustable?: boolean;
  fromExcel?: boolean;
  dcfsAcc: string;
  dfsProd: string;
  dfsSeg: string;
  unit: Unit;
  mapping: string;
  aggregationType: string;
  additionalKpi: boolean;
  includeInExport: boolean;
  currencySplit: boolean;
}

export interface IKpiForecast {
  id: number;
  typeId: number;
  countryId: number;
  currencyId: number;
  asOfMonth: Date;
  perMonth: Date;
  machineForecast: number;
  countryForecast: number;
  username: string;
  adjustedValue?: number;
  submitted?: boolean;
  adjustedTime?: Date;
  adjustedMessage: string;
  scenarioId?: number;
}
