
        var result = require("!!../../../../../../../node_modules/css-loader/index.js??ref--8-1!../../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../../../node_modules/mini-css-extract-plugin/dist/loader.js!../../../../../../../node_modules/css-loader/index.js??ref--9-1!../../../../../../../node_modules/postcss-loader/src/index.js??postcss!./simpleNavigation.component.styl");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    