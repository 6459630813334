import {
  Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef,
  SimpleChanges, forwardRef, OnInit, OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MONTH_COUNT = 12;

export interface Month {
  date: string;
  label: string;
  available?: boolean;
  selected?: boolean;
  preselected?: boolean;
  color?: string;
}

@Component({
  selector: 'month-picker',
  templateUrl: 'monthPicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthPickerComponent),
      multi: true
    }
  ]
})
export class MonthPickerComponent implements ControlValueAccessor, OnInit, OnChanges {
  readonly now: Date = new Date();

  months: string[];

  yearMeta: any = {};

  currentMeta: any[];

  availableColors: boolean[];

  @Input() popover: any;

  @Input() currentYear: number = this.now.getFullYear();
  @Output() currentYearChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() prevYearBtnTap = new EventEmitter();
  @Output() nextYearBtnTap = new EventEmitter();

  @Output() select = new EventEmitter<string>();
  @Output() deselect = new EventEmitter<string>();

  // Customization
  @Input() tabindex = 0;
  @Input() monthsPerRow = 3;

  @Input() colors: string[];
  @Input() locales: string | string[] = 'en-US';
  @Input() dateOptions: Intl.DateTimeFormatOptions = { month: 'short' };

  @Input() expandable = false;
  @Input() prevYearAvailable = false;
  @Input() nextYearAvailable = false;
  @Input() useAvailableMonths = false;

  @Input() maxSelectableMonths: number;
  @Input() minSelectableMonths = 0;
  @Input() minYear: number = Number.MIN_SAFE_INTEGER;
  @Input() maxYear: number = Number.MAX_SAFE_INTEGER;
  @Output() change = new EventEmitter<Date | Array<Date | undefined>>();

  minValue: Date;

  @Input('min')
  set min(value: Date) {
    if (!value) {
      return;
    }
    this.minValue = value;
    if (!this.maxValue || !this.months) {
      return;
    }

    this.setAvailableMonths();
  }

  maxValue: Date;

  @Input('max')
  set max(value: Date) {
    if (!value) {
      return;
    }

    this.maxValue = value;
    if (!this.minValue || !this.months) {
      return;
    }

    this.setAvailableMonths();
  }

  setAvailableMonths(): void {
    this.useAvailableMonths = true;
    this.removeAllAvailableMonths();
    this.addAvailableMonthRange();
  }

  onModelChange(value) {
    this.onChange && this.onChange(value);
  }

  writeValue(value: Date): void {
    if (value) {
      this.selectMonth(value.getFullYear(), value.getMonth());
      this.ref.markForCheck();
    }
  }

  private onChange: (_: any) => void = () => {};
  private onTouched: () => any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor(
    private readonly ref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // Create month labels.
    const date: Date = new Date(this.now.getFullYear(), 0);
    this.months = Array(MONTH_COUNT).fill(0).map(_ => {
      const month: string = date.toLocaleString(this.locales, this.dateOptions);
      date.setMonth(date.getMonth() + 1);
      return month;
    });

    if (!this.maxSelectableMonths) {
      this.maxSelectableMonths = this.colors && this.colors.length || 1;
    }

    this.availableColors = this.colors ? this.colors.map(color => true) : [];

    this.addAvailableMonthRange();
    this.setYearMeta(this.currentYear);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentYear && !changes.currentYear.isFirstChange()) { this.setYearMeta(changes.currentYear.currentValue); }
  }

  setYearMeta(year: number): void {
    if (!this.yearMeta[year]) {
      this.yearMeta[year] = this.createYearMeta(year);
    }
    setTimeout(() => {
      this.currentMeta = this.yearMeta[year];
      this.ref.markForCheck();
    }, 0);
  }

  createYearMeta(year: number): any[] {
    return this.months.map(_ => ({}));
  }

  isSelected(idx) {
    return this.currentMeta && (this.currentMeta[idx].selected || this.currentMeta[idx].preselected);
  }

  selectMonth(year: number, month: number): void {
    if (!this.isMonthAvailable(year, month) || this.isMonthPreselected(year, month)) {
      return;
    }

    const monthMeta: any = this.getYearMeta(year)[month];

    if (monthMeta.selected) {
      if (this.getSelectedDates().length <= this.minSelectableMonths) {
        return;
      }
      return this.deselectMonth(year, month);
    }

    if (this.maxSelectableMonths === 1) {
      this.iterateMonthMetas((_year, _month, mMeta) => {
        mMeta.selected = mMeta === monthMeta;
      });
    }

    if (this.getSelectedDates().length < this.maxSelectableMonths) {
      monthMeta.selected = true;
    }

    if (monthMeta.selected) {
      this.setMonthBackgroundColor(year, month);
      this.notifySelect(`${year}.${month}`);
      this.onModelChange(new Date(year, month));
    }
  }

  preselectMonth(year: number, month: number, color: string): void {
    const monthMeta: any = this.getYearMeta(year)[month];
    if (monthMeta.selected) {
      this.deselectMonth(year, month);
    }
    monthMeta.preselected = true;
    monthMeta.color = color;
    this.ref.markForCheck();
  }

  dePreselectMonth(year: number, month: number): void {
    if (!this.isMonthPreselected(year, month)) { return; }

    const monthMeta: any = this.getYearMeta(year)[month];
    monthMeta.preselected = false;
    delete monthMeta.color;
    this.ref.markForCheck();
  }

  isMonthAvailable(year: number, month: number): boolean {
    return this.isDateInBounds(year, month) && (!this.useAvailableMonths ||
      this.yearMeta[year] && this.yearMeta[year][month].available);
  }

  isDateInBounds(year: number, month: number): boolean {
    return this.isMonthInBounds(month) && this.isYearInBounds(year);
  }

  isMonthInBounds(month: number): boolean {
    return month > -1 && month < MONTH_COUNT;
  }

  isYearInBounds(year: number): boolean {
    return year > this.minYear && year < this.maxYear;
  }

  isMonthPreselected(year: number, month: number): boolean {
    const isMonthPreselected: boolean = !!(this.isDateInBounds(year, month) &&
      this.yearMeta[year] && this.yearMeta[year][month].preselected);
    return isMonthPreselected;
  }

  getYearMeta(year: number): any[] {
    if (!this.yearMeta[year]) {
      this.yearMeta[year] = this.createYearMeta(year);
    }
    return this.yearMeta[year];
  }

  iterateMonthMetas(cb) {
    Object.keys(this.yearMeta).forEach(year => {
      this.yearMeta[year].forEach((monthMeta, month) => {
        cb(Number(year), month, monthMeta);
      });
    });
  }

  getSelectedDates(): string[] {
    const selectedDates: string[] = [];
    this.iterateMonthMetas((year, month, monthMeta) => {
      if (monthMeta.selected) {
        selectedDates.push(`${year}.${month}`);
      }
    });
    return selectedDates;
  }

  setMonthBackgroundColor(year: number, month: number): void {
    const color: string | undefined = this.getMonthBackgroundColor();
    if (color) {
      const monthMeta: any = this.getYearMeta(year)[month];
      monthMeta.color = color;
    }
  }

  getMonthBackgroundColor(): string | undefined {
    const index: number = this.availableColors.findIndex(available => available);
    if (index !== -1) {
      this.availableColors[index] = false;
      return this.colors[index];
    }
  }

  deselectMonth(year: number, month: number): void {
    if (this.isMonthSelected(year, month)) {
      const monthMeta: any = this.getYearMeta(year)[month];
      monthMeta.selected = false;
      this.clearMonthBackgroundColor(year, month);
      this.notifyDeselect(`${year}.${month}`);
    }
  }

  isMonthSelected(year: number, month: number): boolean {
    return this.isDateInBounds(year, month) &&
      this.yearMeta[year] && this.yearMeta[year][month].selected;
  }

  clearMonthBackgroundColor(year: number, month: number): void {
    if (this.availableColors) {
      const monthMeta: any = this.getYearMeta(year)[month];
      if (monthMeta.color) {
        const index: number = this.colors.indexOf(monthMeta.color);
        this.availableColors[index] = true;
        delete monthMeta.color;
      }
    }
  }

  deselectAllMonths(): void {
    this.iterateMonthMetas(this.deselectMonth);
  }

  addAvailableMonthRange(min: Date = this.minValue, max: Date = this.maxValue): void {
    for (const i: Date = new Date(min); i <= max; i.setMonth(i.getMonth() + 1)) {
      this.addAvailableMonth(i.getFullYear(), i.getMonth());
    }
  }

  addAvailableMonth(year: number, month: number): void {
    if (this.isDateInBounds(year, month)) {
      this.getYearMeta(year)[month].available = true;
      this.ref.markForCheck();
    }
  }

  removeAvailableMonth(year: number, month: number): void {
    if (this.isDateInBounds(year, month) && this.yearMeta[year]) {
      this.yearMeta[year][month].available = false;
    }
  }

  removeAllAvailableMonths(): void {
    this.iterateMonthMetas((year, month) => {
      this.dePreselectMonth(year, month);
      this.deselectMonth(year, month);
      this.removeAvailableMonth(year, month);
    });
    this.ref.markForCheck();
  }

  onPrevYearTap(): void {
    if (this.prevYearAvailable) {
      this.currentYear--;
      this.setYearMeta(this.currentYear);
      this.currentYearChange.emit(this.currentYear);
      this.prevYearBtnTap.emit();
    }
  }

  onNextYearTap(): void {
    if (this.nextYearAvailable) {
      this.currentYear++;
      this.setYearMeta(this.currentYear);
      this.currentYearChange.emit(this.currentYear);
      this.nextYearBtnTap.emit();
    }
  }

  onCloseBtnTap(): void {
    if (this.expandable) {
      this.popover.toggle();
    }
  }

  notifySelect(date: string): void {
    this.select.emit(date);
  }

  notifyDeselect(date: string): void {
    this.deselect.emit(date);
  }

  isCurrentMonth(year: number, month?: number): boolean {
    return this.now.getFullYear() === year && this.now.getMonth() === month;
  }

  getMonth(year: number, month: number): any {
    if (this.isDateInBounds(year, month)) {
      return Object.assign({
        date: `${year}.${month}`,
        label: this.months[month]
      }, this.getYearMeta(year)[month]);
    }
  }
}