import { Rect } from "src/app/plotting/interfaces";

export class Rects {
  static Vertical = (x0, x1, y0 = 0, y1 = 1): Rect => {
    return {
      type: 'rect',
      x0,
      x1,
      xref: 'x',
      y0,
      y1,
      yref: 'paper',
    };
  }

  static Horizontal = (y0, y1, x0 = 0, x1 = 1): Rect => {
    return {
      type: 'rect',
      x0,
      x1,
      xref: 'paper',
      y0,
      y1,
      yref: 'y',
    };
  }
}
