import { ChangeDetectorRef, Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { LayerRef } from '@vcl/ng-vcl';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { AdjustmentHistoryContainer, AdjustmentHistory } from 'src/app/models/common/adjustment';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'appliedAdjustments',
  templateUrl: 'appliedAdjustments.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppliedAdjustmentsComponent extends BaseComponent {
  public static readonly Tag: string = 'AppliedAdjustmentsComponent';
  protected readonly tag: string = AppliedAdjustmentsComponent.Tag;
  protected readonly debug: boolean = false;

  @ViewChild('adjustmentLayer') private readonly layer: LayerRef;

  private _adjustmentHistory: AdjustmentHistory[] = [];
  public adjustmentHistory: AdjustmentHistory[] = [];

  public searchValue: string;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly workbench: WorkbenchDataService,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = AppliedAdjustmentsComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;

    this.subscriptions = [
      this.workbench.adjustmentHistory$.subscribe(this.onAdjustmentsChange.bind(this))
    ];
  }

  private onAdjustmentsChange(adjustmentHistoryContainer: AdjustmentHistoryContainer) {
    const tag: string = `${this.tag}.onAdjustmentsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'adjustmentHistoryContainer:', adjustmentHistoryContainer);
    if (!adjustmentHistoryContainer) return;

    const adjustmentHistory: AdjustmentHistory[] = adjustmentHistoryContainer.displayFormat(null, this.state.currency);
    if (debug) console.log(tag, 'adjustmentHistory:', adjustmentHistory);

    Object.assign(this, {
      searchValue: '',
      _adjustmentHistory: adjustmentHistory,
      adjustmentHistory,
    });
  }

  public onSearchValueChange(value: string): void {
    const tag: string = `${this.tag}.onSearchValueChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'value:', value);

    if (!value) {
      this.adjustmentHistory = this._adjustmentHistory;
      return;
    }

    const terms: string[] = value.split(',').map(term => term.trim()).filter(term => term).map(term => term.toUpperCase());
    if (debug) console.log(tag, 'terms:', terms);

    this.adjustmentHistory = this._adjustmentHistory.filter(ah =>
      terms.some(term =>
        ah.comment.username.toUpperCase().includes(term) ||
        ah.label.toUpperCase().includes(term) ||
        ah.comment.message.toUpperCase().includes(term) ||
        ah.adjustments.some(a => (a as any).displayDate.toUpperCase().includes(term)) ||
        (ah as any).displayDate.toUpperCase().includes(term)
      ));
    if (debug) console.log(tag, 'this.adjustmentHistory:', this.adjustmentHistory);
  }

  public close(): void {
    const tag: string = `${this.tag}.close()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.layer.close();
  }

  public open(): void {
    const tag: string = `${this.tag}.open()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.layer.open();
  }

  public onSearchBtnTap(): void {
    const tag: string = `${this.tag}.onSearchBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  public onCloseBtnTap(): void {
    const tag: string = `${this.tag}.onCloseBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.close();
  }

  // private onMakeChangesTap(kpiType: number | string): void {
  //   const tag: string = `${this.tag}.onGoToAdjustmentTap()`;
  //   if (this.debug) console.log(tag, 'kpiType:', kpiType);
  //   this.navigation.navigateToAdjustment(kpiType);
  // }

  public readonly NoComment = 'No comment';
}

