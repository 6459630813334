import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { TopLevelKpi, Unit, YearToDateAcronym, ForYearAcronym, Kpi1 } from 'src/app/constants';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { AggregatedKpi } from 'src/app/models/common/kpiAggregated';
import { getYearShort, localeMonthShortYearShort } from 'src/app/utils';

export const DebugPlots: boolean = false;

@Component({
  selector: 'workbench',
  templateUrl: './workbench.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'vclLayoutFlex'
  }
})
export class WorkbenchComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'WorkbenchComponent';
  protected readonly rag: string = WorkbenchComponent.Tag;
  protected readonly debug: boolean = false;

  public isAdditionalKpi: boolean = false;
  public isMoveReporting: boolean = false;
  public isIt: boolean = false;
  public isCurrencySplit: boolean = false;
  public isFocusReporting: boolean = false;
  public isPowerBiDashboard: boolean = false;
  public isDividends: boolean = false;
  public isCapital: boolean = false;
  public isBasic: boolean = true;

  public ratios: AggregatedKpi[] = [];
  public ratiosTitle: string = 'Ratios';
  public showRatiosActuals: boolean = false;

  public components: AggregatedKpi[] = [];
  public componentsTitle: string = 'Components';
  public showComponentsActuals: boolean = false;

  public salesAndAcquisitionUnits: AggregatedKpi[] = [];
  public salesAndAcquisitionUnitsTitle: string = 'Sales & Acquisition Units';
  public showSalesAndAcquisitionUnitsActuals: boolean = false;

  public iframeUrl: SafeResourceUrl;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
    private readonly sanitizer: DomSanitizer
  ) {
    super(cd, state);

    this.subscriptions = [
      this.state.topLevelKpi$.subscribe(this.onTopLevelKpiChange.bind(this)),
      this.state.powerBiUrl$.subscribe(this.onPowerBiUrlChange.bind(this)),
      this.workbench.ratios$.subscribe(this.onRatiosComponentsChange.bind(this)),
      this.workbench.components$.subscribe(this.onComponentsComponentsChange.bind(this)),
      this.workbench.salesAndAcquisitionUnits$.subscribe(this.onSalesAndAcquisitionUnitsComponentsChange.bind(this))
    ];
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  public onTopLevelKpiChange(topLevelKpi: TopLevelKpi): void {
    this.isAdditionalKpi = topLevelKpi === TopLevelKpi.AdditionalKpis;
    this.isMoveReporting = topLevelKpi === TopLevelKpi.MoveReporting;
    this.isIt = topLevelKpi === TopLevelKpi.ItAndProjects;
    this.isFocusReporting = topLevelKpi === TopLevelKpi.FocusReporting;
    this.isCurrencySplit = topLevelKpi === TopLevelKpi.CurrencySplit;
    this.isPowerBiDashboard = topLevelKpi === TopLevelKpi.PowerBI;
    this.isDividends = topLevelKpi === TopLevelKpi.Dividends;
    this.isCapital = topLevelKpi === TopLevelKpi.Capital;
    this.isBasic = !this.isAdditionalKpi && !this.isCurrencySplit && !this.isPowerBiDashboard && !this.isDividends 
      && !this.isCapital  && !this.isMoveReporting && !this.isFocusReporting && !this.isIt
  }

  public onPowerBiUrlChange(url: string): void {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private onRatiosComponentsChange(ratios: AggregatedKpi[]) {
    const tag: string = `${this.tag}.onRatiosComponentsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'ratios:', ratios);
    const formattedRatios: AggregatedKpi[] = ratios.map(ratio =>
      ratio.displayFormat(this.state.unitToLabel(ratio.unit), false));
    if (debug) console.log(tag, 'formattedRatios:', formattedRatios);
    this.addDates(formattedRatios);
    this.ratios = formattedRatios;
    this.setLoadingStatus();
  }

  private onComponentsComponentsChange(components: AggregatedKpi[]) {
    const tag: string = `${this.tag}.onComponentsComponentsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'components:', components);
    const formattedComponents: AggregatedKpi[] = components.map(component =>
      component.displayFormat(this.state.unitToLabel(component.unit), false));
    if (debug) console.log(tag, 'formattedComponents:', formattedComponents);
    this.addDates(formattedComponents);
    this.components = formattedComponents;
    this.setLoadingStatus();
  }

  private onSalesAndAcquisitionUnitsComponentsChange(salesAndAcquisitionUnits: AggregatedKpi[]) {
    const tag: string = `${this.tag}.onSalesAndAcquisitionUnitsComponentsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'salesAndAcquisitionUnits:', salesAndAcquisitionUnits);
    const formattedSalesAndAcquisitionUnits: AggregatedKpi[] = salesAndAcquisitionUnits.map(salesAndAcquisitionUnit =>
      salesAndAcquisitionUnit.displayFormat(this.state.unitToLabel(salesAndAcquisitionUnit.unit), false));
    if (debug) console.log(tag, 'formattedSalesAndAcquisitionUnits:', formattedSalesAndAcquisitionUnits);
    this.addDates(formattedSalesAndAcquisitionUnits);
    this.salesAndAcquisitionUnits = formattedSalesAndAcquisitionUnits;
    this.setLoadingStatus();
  }

  private addDates(forecasts: AggregatedKpi[]): void {
    const tag: string = `${this.tag}.addDates()`;
    const debug: boolean = this.debug || false;
    const dateYearShort: number = getYearShort(this.state.datePickerDate);
    const dateLocaleShort: string = localeMonthShortYearShort(this.state.datePickerDate);

    forecasts.forEach(f => Object.assign(f, {
      actualDate: `${YearToDateAcronym} ${dateLocaleShort}`,
      endOfYearDate: `${ForYearAcronym}${dateYearShort}`,
      nextYearDate: `${ForYearAcronym}${dateYearShort + 1}`,
    }));
  }

  public readonly Kpi1 = Kpi1;
}
