import { Component, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { PlotService } from 'src/app/services/plot.service';

@Component({
    selector: 'zoomOutButton',
    templateUrl: './zoomOutButton.component.html',
})
export class ZoomOutButtonComponent extends BaseComponent {
    private static readonly Tag: string = 'ZoomOutButtonComponent';

    constructor(
        protected readonly cd: ChangeDetectorRef,
        public readonly state: StateService,
        private readonly plot: PlotService) {
        super(cd, state);
    }

    public onZoomOutBtnTap(): void {
        const tag: string = `${this.tag}.onZoomOutBtnTap()`;
        const debug: boolean = this.debug || false;
        this.plot.isZoomedOut = !this.plot.isZoomedOut;
    }
}
