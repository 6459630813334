import { ChangeDetectorRef, Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NotifierType } from 'src/app/constants';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { CommonDataService } from 'src/app/services/data/common.data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';

@Component({
  selector: 'excelUploadBtn',
  templateUrl: 'excelUploadBtn.component.html',
  styleUrls: ['excelUploadBtn.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExcelUploadButtonComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ExcelUploadButtonComponent';

  protected show: boolean = true;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
    private readonly plans: PlanningDataService,
    public readonly state: StateService,
    private readonly notification: NotificationService,
  ) {
    super(cd, state);
    this.tag = ExcelUploadButtonComponent.Tag;
    this.debug = false;
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
  }

  public async onFileChange(file: Blob[]): Promise<void | boolean> {
    const tag: string = `${this.tag}.onFileUpload()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'file:', file);
    let fileToUpload = new FormData();
    fileToUpload.append("file", file[0]);

    try {   
      await this.data.uploadExcelFile(fileToUpload);
      this.show = false;
      await this.plans.reload(this.state.type);

      this.notification.add({
        text: 'Excel has been uploaded successfully',
        type: NotifierType.Success,
      });
      
    } catch (e) {
      this.notification.add({
        text: 'Uploading excel failed',
        type: NotifierType.Error,
      });
      return;
    }
    this.show = true;
  }
}
