import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { AdjustmentButton } from "src/app/constants";

// Currently not used.
export type userSetting =
  | "authorized"
  | "skipAdjustmentsCancelDialog"
  | "skipAdjustmentsAddAllDialog"
  | "skipAdjustmentsUndoAllDialog"
  | "skipAdjustmentsUndoLastDialog"
  | "skipAdjustmentsApplyDialog"
  | "skipLastSubmissionDialog";

// export const UserSetting = {
//   authorized: 'authorized' as userSetting,
//   skipAdjustmentsCancelDialog: 'skipAdjustmentsCancelDialog' as userSetting,
//   skipAdjustmentsAddAllDialog: 'skipAdjustmentsAddAllDialog' as userSetting,
//   skipAdjustmentsUndoAllDialog: 'skipAdjustmentsUndoAllDialog' as userSetting,
//   skipAdjustmentsUndoLastDialog: 'skipAdjustmentsUndoLastDialog' as userSetting,
//   skipAdjustmentsApplyDialog: 'skipAdjustmentsApplyDialog' as userSetting,
//   skipLastSubmissionDialog: 'skipLastSubmissionDialog' as userSetting,
// };
// //

export const UnsavedAdjustment: string = "UnsavedAdjustment";

@Injectable()
export class UserSettingsService extends StorageService {
  protected static readonly Tag: string = "UserSettingsService";
  private readonly tag: string = UserSettingsService.Tag;

  private readonly debug: boolean = false;

  // // Currently not used.
  // get(k: userSetting) {
  //   super.get(k);
  // }

  // set(k: userSetting, v) {
  //   super.set(k, v);
  // }
  // //

  get authorized(): boolean {
    return super.get("authorized");
  }

  set authorized(v: boolean) {
    super.set("authorized", v);
  }

  get skipLastSubmissionDialog(): boolean {
    return super.get("skipLastSubmissionDialog");
  }

  set skipLastSubmissionDialog(v: boolean) {
    super.set("skipLastSubmissionDialog", v);
  }

  public getSkipConfirmationDialog(str: string): boolean {
    return super.get(this.strToConfirmationDialogStr(str));
  }
  public setSkipConfirmationDialog(str: string, v: boolean): void {
    const tag: string = `${this.tag}.ngOnChanges()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "str:", str);
    if (debug) console.log(tag, "v:", v);
    super.set(this.strToConfirmationDialogStr(str), v);
  }
  private strToConfirmationDialogStr(str: string): string {
    return `skip${str}ConfirmationDialog`;
  }
}

// // @Injectable()
// export class UserSettingsService extends StorageService {
//   private static readonly Tag: string = 'UserSettingsService';
//   private static readonly tag: string = UserSettingsService.Tag;

//   private static readonly debug: boolean = false;

//   public static get authorized(): boolean {
//     return StorageService.get('authorized');
//   }

//   public static set authorized(v: boolean) {
//     StorageService.set('authorized', v);
//   }

//   public static get skipLastSubmissionDialog(): boolean {
//     return StorageService.get('skipLastSubmissionDialog');
//   }

//   public static set skipLastSubmissionDialog(v: boolean) {
//     StorageService.set('skipLastSubmissionDialog', v);
//   }

//   public static getSkipConfirmationDialog(str: string): boolean {
//     return StorageService.get(this.adjustmentButtonToStr(button));
//   }
//   public static setSkipConfirmationDialog(str: string, v: boolean): void {
//     const tag: string = `${this.tag}.ngOnChanges()`;
//     const debug: boolean = this.debug || false;
//     if (debug) console.log(tag, 'button:', AdjustmentButton[button]);
//     if (debug) console.log(tag, 'v:', v);
//     StorageService.set(this.adjustmentButtonToStr(button), v);
//   }
//   private static adjustmentButtonToStr(str: string): string {
//     return `skip${AdjustmentButton[button]}ConfirmationDialog`;
//   }

//   public static getPageDisplayed(page: Page): boolean {
//     return StorageService.get(this.pageToStr(page));
//   }
//   public static setPageDisplayed(page: Page, v: boolean): void {
//     const tag: string = `${this.tag}.ngOnChanges()`;
//     const debug: boolean = this.debug || false;
//     if (debug) console.log(tag, 'page:', page);
//     if (debug) console.log(tag, 'v:', v);
//     StorageService.set(this.pageToStr(page), v);
//   }
//   private static pageToStr(page: Page): string {
//     return `display${page}`;
//   }
// }
