import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'currency',
  templateUrl: './currency.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CurrencyComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'CurrencyComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = CurrencyComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.currency$,
    ];
  }
}
