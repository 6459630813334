import * as merge from 'merge';
import { Color } from 'src/styles/color';
import { Axis } from 'src/app/plotting/constants';
import { Trace } from 'src/app/plotting/interfaces';

export class Scatter {

  static get Dfs(): Trace {
    return {
      // [Axis.X]: [],
      // [Axis.Y]: [],
      type: 'scatter',
      hoverinfo: 'none',
    };
  }


  static get Lines(): Trace {
    return merge.recursive(Scatter.Dfs, {
      mode: 'lines',
      line: {
        width: 2,
      },
    });
  }

  static get Markers(): Trace {
    return merge.recursive(Scatter.Dfs, {
      mode: 'markers',
      marker: {
        symbol: 'circle-open',
      },
    });
  }

  static get LinesMarkers(): Trace {
    return merge.recursive(Scatter.Lines, Scatter.Markers, {
      mode: 'lines+markers',
    });
  }


  static get DfsFilled(): Trace {
    return merge.recursive(Scatter.Dfs, {
      mode: 'none',
    });
  }

  static get Filled(): Trace {
    return merge.recursive(Scatter.DfsFilled, {
      fill: 'tonexty',
    });
  }

  static get FirstFilled(): Trace {
    return merge.recursive(Scatter.DfsFilled, {
      fill: 'tozeroy',
    });
  }


  static get Actual(): Trace {
    return {
      name: 'Actual',
      line: {
        color: Color.Actual,
      },
    };
  }

  static get Organization(): Trace {
    return {
      name: 'Country',
      line: {
        color: Color.Organization,
      },
      marker: {
        color: Color.Organization,
      }
    };
  }

  static get Machine(): Trace {
    return {
      name: 'Model',
      line: {
        color: Color.Machine,
      },
      marker: {
        color: Color.Machine,
      }
    };
  }

  static get Adjustment()/*: Trace */ {
    return {
      name: 'Adjustment',
      line: {
        color: Color.Adjustment,
        dash: 4,
      },
    };
  }

  static get Visible(): Trace {
    return {
      visible: true,
    };
  }

  static get Invisible(): Trace {
    return {
      visible: false,
    };
  }
}


// static get HiddenMarkers() : Trace {
//   return merge.recursive(Scatter.Lines, Scatter.Markers, {
//     mode: 'lines+markers',
//   });
// }

// static get FilledX() : Trace {
//   return merge.recursive(Scatter.DfsFilled, {
//     fill: 'tonextx',
//   });
// }

// static get SelfFilled() : Trace {
//   return merge.recursive(Scatter.DfsFilled, {
//     fill: 'toself',
//   });
// }
