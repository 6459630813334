import { Scale } from 'src/app/constants';
import { Currency } from 'src/app/types';
import { AdditionalKpi, IAdditionalKpiLite } from '../additionalKpis/additionalKpis';

export class FocusReportingContainer {
  private static readonly Tag = 'FocusReportingContainer';
  private static readonly Debug: boolean = false;

  public tag: string = FocusReportingContainer.Tag;
  public debug: boolean = FocusReportingContainer.Debug;

  public focusReporting: AdditionalKpi[];

  constructor(focusReporting: IAdditionalKpiLite[]) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'focusReporting:', focusReporting);

    var opex = Object.keys(focusReporting).reduce((formattedFocusReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, focusReporting[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = focusReporting[0].endOfYear.unit;
      formattedFocusReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedFocusReporting;
    }, {} as AdditionalKpi[]);
    this.focusReporting = opex as AdditionalKpi[] ;
  }

  public displayFormat(scale: Scale, currency: Currency): AdditionalKpi[]  {
    const tag: string = `${this.tag}.displayFormat()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scale:', scale);
    if (debug) console.log(tag, 'currency:', currency);

    const opex: AdditionalKpi[] = Object.keys(this.focusReporting).reduce((formattedFocusReporting, k) => {
      const additionalKpi: AdditionalKpi = this.focusReporting[k];
      let usedScale: Scale = Scale.Million;

      formattedFocusReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedFocusReporting;
    }, {} as AdditionalKpi[]);


    const formattedFocusReporting: AdditionalKpi[] = opex;
    if (debug) console.log(tag, 'formattedFocusReporting', formattedFocusReporting);
    return formattedFocusReporting;
  }
}
