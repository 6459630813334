import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/components/common/elements/confirmationDialog/confirmationDialog.component';
import { CometButton, IButton } from 'src/app/constants';
import { StateService } from 'src/app/services/state/state.service';

const CometUrl = 'https://comet.app.corpintra.net/comet/#/';

@Component({
  selector: 'cometButton',
  templateUrl: 'cometButton.component.html'
})

export class CometButtonComponent extends BaseComponent {
  private static readonly Tag: string = 'CometButtonComponent';
  protected tag: string = CometButtonComponent.Tag;
  protected debug: boolean = false;

  @ViewChild('cometConfirmationDialog') private readonly confirmationDialog: ConfirmationDialogComponent;

  public readonly button: IButton = CometButton;
  public showDialog: boolean = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
  }

  public onCometBtnTap(): void {
    const tag: string = `${this.tag}.onCometBtnTap()`;
    const debug: boolean = false;
    this.confirmationDialog.popover.open();
  }

  public async onContinueTap(): Promise<void> {
    const tag: string = `${this.tag}.onContinueTap()`;
    const debug: boolean = false;
    const win: Window = window.open(CometUrl, '_blank');
    win.focus();
  }

  public onCancelTap(): void {
    const tag: string = `${this.tag}.onCancelTap()`;
    const debug: boolean = false;
  }
}
