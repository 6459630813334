import * as merge from 'merge';
import {
  Component, ChangeDetectionStrategy, OnInit,
  ChangeDetectorRef, Input, ViewChild
} from '@angular/core';
import { PlotlyComponent } from '@n-fuse/ng-plotly';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { Walk, WalkBar } from 'src/app/models/results/walk';
import { CommonDataService, WalkMap } from 'src/app/services/data/common.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { Configuration } from 'src/app/plotting/configuration';
import { Forecast } from 'src/app/plotting/constants';
import { createWalk } from 'src/app/plotting/data';
import { Plot } from 'src/app/plotting/interfaces';
import { Layout } from 'src/app/plotting/layout';
import { getScale } from 'src/app/utils';
import {
  TopLevelKpi, Scale,
  ForecastName, ForecastAcronym, Page
} from 'src/app/constants';

@Component({
  selector: 'walk',
  templateUrl: './walk.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WalkComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'WalkComponent';
  public debugPlot: boolean;

  @ViewChild('vclPlotly') private readonly vclPlotly: PlotlyComponent;

  @Input() public topLevelKpi: TopLevelKpi;
  @Input() private page: Page;

  private walk: Walk;

  public plotId: string;
  public plotClass: string = 'myfcWalkChart';
  public width: number = 100;
  public plot: Plot;

  public scale: Scale;

  public forecast: Forecast = Forecast.Organization;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.debug = false;
    this.debugPlot = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.plotId = `${WalkComponent.Tag}.${TopLevelKpi[this.topLevelKpi]}`;
    this.tag = this.plotId;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.data.walk$.subscribe(this.onWalkChange.bind(this)),
    ];
  }

  private onWalkChange(walkMap: WalkMap): void {
    const tag: string = `${this.tag}.onWalkChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log('walkMap:', walkMap);
    const walk: Walk = walkMap[this.topLevelKpi];
    this.walk = walk;
    this.createPlot(walk);
  }

  private createPlot(walk: Walk): void {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'walk:', walk);

    const formattedWalk: WalkBar[] = walk.format(this.state.datePickerDate, this.forecast);
    if (debug) console.log(tag, 'formattedWalk:', formattedWalk);

    const values: number[] = formattedWalk.map(bar => bar.base + bar.value);
    if (debug) console.log(tag, 'values:', values);
    if (!values.length) return;

    const scale: Scale = getScale(values);
    if (debug) console.log(tag, 'scale:', scale);

    const plot: Plot = merge.recursive({
      layout: Layout.Walk,
      configuration: Configuration.Static,
    }, createWalk(formattedWalk, scale));
    if (debug) console.log(tag, 'plot:', plot);

    Object.assign(this, {
      scale, plot
    });
  }

  public onCheckedChange(forecast: Forecast): void {
    const tag: string = `${this.tag}.onCheckedChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'forecast:', `${Forecast[forecast]}`);
    this.forecast = forecast;
    this.recreatePlot();
  }

  public recreatePlot(): void {
    const tag: string = `${this.tag}.recreatePlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (!this.expanded) return;

    this.createPlot(this.walk);
  }

  public onMouseEnter(event: MouseEvent): void {
    const tag: string = `${this.tag}.onMouseEnter()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'event:', event);
    this.plot.layout.annotations.forEach(annotation => annotation.visible = true);
    this.vclPlotly.relayout();
  }

  public onMouseLeave(event: MouseEvent) {
    const tag: string = `${this.tag}.onMouseLeave()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'event:', event);
    // if (debug) return;
    this.plot.layout.annotations.forEach(annotation => annotation.visible = !annotation.subtype);
    this.vclPlotly.relayout();
  }

  public onExpandTap(): void {
    const tag: string = `${this.tag}.onExpandTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    super.onExpandTap();
    this.recreatePlot();
  }

  public readonly Page = Page;
  public readonly Forecast = Forecast;
  public readonly ForecastName = ForecastName;
  public readonly ForecastAcronym = ForecastAcronym;
}
