import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { Kpi1 } from 'src/app/constants';
import { INavigationKpiTree } from 'src/app/models/workbench/navigation';

@Component({
  selector: 'workbenchBreadcrumbs',
  templateUrl: 'workbenchBreadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WorkbenchBreadcrumbsComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'WorkbenchBreadcrumbsComponent';

  public kpis: INavigationKpiTree[] = [];

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${WorkbenchBreadcrumbsComponent.Tag}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.kpi$.subscribe(this.onKpiChange.bind(this)),
    ];
  }

  private onKpiChange(): void {
    const tag: string = `${this.tag}.onKpiChange()`;
    const debug: boolean = this.debug || false;
    const kpis: INavigationKpiTree[] = this.state.listKpis();
    if (debug) console.log(tag, 'kpis:', kpis);
    this.kpis = kpis;
  }

    public readonly Kpi1 = Kpi1;
}
