import { sortByUnderscore, startsWithUnderscore } from '../../utils';

export class ImportData {
  private static readonly Tag = 'ImportData';

  public tag: string = ImportData.Tag;
  public debug: boolean = false;

  public names: string[];

  constructor(names: string[] = []) {
    this.names = names;
  }

  public getSortedNamesWithoutUnderscore(names: string[] = this.names): string[] {
    const tag: string = `${this.tag}.getSortedNamesWithoutUnderscore()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'names:', names);

    names = [...names];
    names.sort(sortByUnderscore);
    if (debug) console.log(tag, 'names:', names);

    const index: number = names.findIndex(startsWithUnderscore);
    if (debug) console.log(tag, 'index:', index);
    if (index !== -1) return names.slice(0, index);
    return names;
  }

  public getSortedNamesWithUnderscore(names: string[] = this.names): string[] {
    const tag: string = `${this.tag}.getSortedNamesWithUnderscore()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'names:', names);

    names = [...names];
    names.sort(sortByUnderscore);
    if (debug) console.log(tag, 'names:', names);

    const index: number = names.findIndex(startsWithUnderscore);
    if (debug) console.log(tag, 'index:', index);
    if (index !== -1) return names.slice(index);
    return names;
  }
}
