import { Component, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
    selector: 'shareButton',
    templateUrl: './shareButton.component.html',
})
export class ShareButtonComponent extends BaseComponent {
    private static readonly Tag: string = 'ShareButtonComponent';
    protected tag: string = ShareButtonComponent.Tag;
    protected debug: boolean = false;

    constructor(
        protected readonly cd: ChangeDetectorRef,
        public readonly state: StateService,
        private readonly workbench: WorkbenchDataService) {
        super(cd, state);
    }

    public onShareButtonTap(): void {
        const tag: string = `${this.tag}.onShareButtonTap()`;
        const debug: boolean = this.debug || false;
        const url: string = this.workbench.getExportFileUrl();
        if (debug) console.log(tag, 'url:', url);
        window.open(url);
    }
}
