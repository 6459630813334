import * as merge from 'merge';
import { Line } from "src/app/plotting/interfaces";
import { Color } from 'src/styles/color';

export class Lines {

  public static get Dfs(): Line {
    return {
      type: 'line',
      layer: 'above',
      line: {
        width: 1,
      },
    };
  }

  public static Vertical = (x, options: Line = {}): Line =>
    merge.recursive(Lines.Dfs, {
      xref: 'x',
      x0: x,
      x1: x,
      yref: 'paper',
      y0: 0,
      y1: 1,
    }, options)

  public static Horizontal = (y: any, options: Line = {}): Line =>
    merge.recursive(Lines.Dfs, {
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: y,
      y1: y,
    }, options)

  public static ActualLine = (x: Date, options: Line = {}): Line =>
    Lines.Vertical(x.getTime(), merge.recursive({
      line: {
        color: Color.Actual,
      }
    }, options))

  public static TooltipLine = (x: Date, options: Line = {}): Line =>
    Lines.Vertical(x.getTime(), merge.recursive({
      line: {
        color: Color.Black,
      }
    }, options))

  public static VerticalGridLine = (x: any, options: Line = {}): Line =>
    Lines.Vertical(x, merge.recursive({
      line: {
        color: Color.Line,
      }
    }, options))

  public static HorizontalGridLine = (y: any, options: Line = {}): Line =>
    Lines.Horizontal(y, merge.recursive({
      line: {
        color: Color.Line,
      }
    }, options))
}
