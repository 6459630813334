import {
  Page, AdjustmentTab, MonthPickerAllowedYearsBack, Scale, Unit, TopLevelKpi, Portfolio,
} from 'src/app/constants';
import { Currency } from 'src/app/types';
import { getCurrentDate, getValues, } from 'src/app/utils';
import {
  FormattedRegions, FormattedRegionMap, FormattedRegion,
  FormattedCountry, FormattedOrganization,
} from 'src/app/models/common/country';
import { ImportData } from 'src/app/models/common/importData';
import { OrganizationEntity } from 'src/app/services/state/state.service';
import { PageVisibility } from 'src/app/services/data/common.data.service';
import { Scenario } from 'src/app/models/workbench/scenario';
import { INavigationKpiTree } from 'src/app/models/workbench/navigation';

export interface AdjustmentMap {
  [adjustmentTabEnum: number]: boolean;
}

export const Default = {
  Initialized: false,
  HasWriteAccess: false,
  UserHasAdminAccess: false,
  UserHasWriteAccess: false,
  AllowWriteAccessToApplication: false,
  DateRestrictionDisabled: false,
  AppliedAdjustmentsEnabled: false,
  IsLoading: false,
  ValueLabels: false,
  IsMenuExpanded: true,
  IsSmallScreen: false,
  CallCount: 0,
  IsReadonly: true,
  ExcelImportEnabled: false,
  PowerBiUrl: "",
  Type: 0,

  Scale: Scale.Hundred,
  Page: Page.Workbench,
  Currency: Currency.LC,
  Unit: Unit.Currency,
  TopLevelKpi: TopLevelKpi.Portfolio,

  Kpi0: Portfolio as INavigationKpiTree, // null as INavigationKpiTree,
  Kpi1: null as INavigationKpiTree,
  Kpi2: null as INavigationKpiTree,
  Kpi3: null as INavigationKpiTree,
  Kpi4: null as INavigationKpiTree,
  Kpi5: null as INavigationKpiTree,
  get Kpi(): INavigationKpiTree {
    return null; // Default.Kpi0;
  },

  get Regions() {
    return {
      items: {} as FormattedRegionMap,
    } as FormattedRegions;
  },
  Region: undefined as FormattedRegion,
  get Country(): FormattedCountry {
    return;
  },
  Organization: undefined as FormattedOrganization,
  OrganizationEntity: undefined as OrganizationEntity,
  OrganizationLastAvailableMonth: undefined,

  get Now() {
    return getCurrentDate();
  },
  get DatePickerDate() {
    return Default.Now;
  },
  get MaxMonthPickerDate() {
    return Default.DatePickerDate;
  },
  get MinMonthPickerDate() {
    const minMonthPickerDate: Date = new Date(Default.MaxMonthPickerDate);
    minMonthPickerDate.setFullYear(minMonthPickerDate.getFullYear() - MonthPickerAllowedYearsBack);
    return minMonthPickerDate;
  },

  get UnsavedAdjustmentsMap() {
    return {
      [AdjustmentTab.AdditionalKpi]: false,
      [AdjustmentTab.Workbench]: false,
      [AdjustmentTab.CurrencySplit]: false,
      [AdjustmentTab.Planning]: false
    };
  },
  get UnsavedAdjustments() {
    return getValues(Default.UnsavedAdjustmentsMap).some(v => v);
  },

  get ImportData() {
    return new ImportData();
  },

  get PageVisibility(): PageVisibility {
    return {
      [Page.Planning]: true,
      [Page.Workbench]: true,
      [Page.Landing]: true,
    } as PageVisibility;
  },
  // Workbench
  get Scenario(): Scenario {
    return null;
  },
  get Scenarios(): Scenario[] {
    return [] as Scenario[];
  },
  // Workbench
};
