import * as merge from "merge";
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ViewChildren,
  QueryList
} from "@angular/core";
import { skip } from "rxjs/operators";
import { TooltipComponent } from "@vcl/ng-vcl";
import { AnimationState } from "src/app/types/animationState";
import { PlotlyComponent, PlotlyEvent } from "@n-fuse/ng-plotly";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { StateService } from "src/app/services/state/state.service";
import { PlotService, Transition } from "src/app/services/plot.service";
import { KpiValues, FormattedKpiValues } from "src/app/models/common/kpiValues";
import {
  Plot,
  Trace,
  PlotlyEventMap,
  Point,
  ValueLabel,
  Shape
} from "src/app/plotting/interfaces";
import { Configuration } from "src/app/plotting/configuration";
import { Layout } from "src/app/plotting/layout";
import {
  createScatter,
  createZoomOut,
  dateToPoint,
  GetZoomInRangeAlignment
} from "src/app/plotting/data";
import {
  getValues,
  flatten,
  getScale,
  scaleKpi,
  array,
  getScenarioId,
  previousMonth,
  gtDate,
  createScatterValueLabels,
  nextMonth,
  normalizeDate,
  isActualPoint,
  gtEqDate,
  ltEqDate,
  eqDate,
  XmlSerializer,
  isPresent,
  isHigherScale,
  scaleTraces,
  getTraceValues,
  getFormattedKpiValues
} from "src/app/utils";
import { Axis, Forecast, PointData } from "src/app/plotting/constants";
import { Scale, Unit, TopLevelKpi } from "src/app/constants";
import { KpiValuesQuarterContainer } from "src/app/models/workbench/kpiValuesQuarter";
import { createCompareScatterTrace } from "src/app/plotting/workbench/data";
import { Scenario } from "src/app/models/workbench/scenario";
import { DebugPlots } from "src/app/components/workbench/workbench/workbench.component";
import * as Shapes from "src/app/plotting/shapes";

@Component({
  selector: "monthlyForecast",
  templateUrl: "monthlyForecast.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class MonthlyForecastComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "MonthlyForecastComponent";
  protected readonly tag: string = MonthlyForecastComponent.Tag;
  private static readonly PointSelector: string =
    "g.cartesianlayer > g.subplot.xy > g.plot > g.scatterlayer > g.trace.scatter > g.points > path.point";
  @ViewChildren("valueLabels") private readonly valueLabelComponents: QueryList<
    TooltipComponent
  >;

  public readonly debugPlot: boolean = DebugPlots || false;
  public readonly debugPlotEvents: boolean = false;
  protected readonly debug: boolean = false;

  public readonly width: number = 100;
  public readonly height: number = 100;

  @ViewChild("vclPlotly") private readonly vclPlotly: PlotlyComponent;

  public readonly plotId: string = MonthlyForecastComponent.Tag;
  public readonly plotClass: string =
    "myfcMonthlyForecast myfcScatterCircleMarkers";

  public scale: Scale;
  public plot: Plot;
  private rangeZoomIn: Plot;
  private rangeZoomOut: Plot;

  private formattedKpis: FormattedKpiValues[] = [];
  private scenarios: (number | string)[] = [];
  private removeScenarioQueue: Scenario[] = [];
  private removingScenario: boolean = false;

  public animationState: AnimationState = "hidden";
  public valueLabels: ValueLabel[];
  private hoverPosition: Date;
  private tooltipPosition: Date;
  private didPlotResize: boolean = false;
  private settingUpLabels: boolean = false;
  private tooltipIndex: number;
  private clicked: number = 0;
  private ignoreScenarioChange: boolean = false;

  public readonly events: PlotlyEventMap = {
    [PlotlyEvent.Relayout]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.Relayout}()`;
      const debug: boolean = this.debugPlotEvents || false;
      if (debug) console.log(tag, "this.clicked:", this.clicked);
      if (this.clicked > 0) {
        this.clicked--;
        // Reset actual point references.
        const actualPoints: SVGPathElement[] = this.getFilteredPoints().filter(
          isActualPoint
        );
        this.valueLabels
          .filter(label => isActualPoint(label.point))
          .forEach((label, i) => (label.point = actualPoints[i]));

        if (debug) console.log(tag, "skipping setUpValueLabels()");
        return;
      }

      if (debug) console.log(tag, "this.didPlotResize:", this.didPlotResize);
      if (!this.didPlotResize) {
        this.didPlotResize = true;
        if (debug) console.log(tag, "skipping setUpValueLabels()");
        return;
      }
      this.setUpValueLabels(plot);
    },
    [PlotlyEvent.Redraw]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.Redraw}()`;
      const debug: boolean = this.debugPlotEvents || false;
      if (debug) console.log(tag);
      this.setUpValueLabels(plot);
    },
    [PlotlyEvent.Click]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.Click}()`;
      const debug: boolean = false; // this.debugPlotEvents || false;
      const date: Date = normalizeDate(data.points[0][Axis.X]);
      if (debug) console.log(tag, "date:", date);
      this.plotService.tooltipPosition = eqDate(
        date,
        this.plotService.tooltipPosition
      )
        ? null
        : date;
    },
    // [PlotlyEvent.DoubleClick]: (data, event: Event, plot: PlotlyComponent, Plotly) => {
    //   const tag: string = `${this.tag}.${PlotlyEvent.DoubleClick}()`;
    //   const debug: boolean = this.debugPlotEvents || false;
    //   if (debug) console.log(tag);
    //   this.plotService.isZoomedOut = !this.plotService.isZoomedOut;
    // },
    [PlotlyEvent.AfterPlot]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.AfterPlot}()`;
      const debug: boolean = this.debugPlotEvents || false;
      if (debug) console.log(tag, "this.clicked:", this.clicked);
      if (this.clicked > 0) {
        this.clicked--;
        return;
      }

      this.setUpValueLabels(plot);
    },
    [PlotlyEvent.Hover]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.Hover}()`;
      const debug: boolean = false; // this.debugPlotEvents || false;
      const date: Date = normalizeDate(data.points[0][Axis.X]);
      if (debug)
        console.log(
          tag,
          "this.plotService.showValues:",
          this.plotService.showValues
        );
      if (this.plotService.showValues) return;

      if (debug) console.log(tag, "date:", date);
      this.plotService.hoverPosition = date;
    },
    [PlotlyEvent.Unhover]: (
      data,
      event: Event,
      plot: PlotlyComponent,
      Plotly
    ) => {
      const tag: string = `${this.tag}.${PlotlyEvent.Unhover}()`;
      const debug: boolean = false; // this.debugPlotEvents || false;
      if (debug)
        console.log(
          tag,
          "this.plotService.showValues:",
          this.plotService.showValues
        );
      if (this.plotService.showValues) return;

      this.plotService.hoverPosition = null;
    }
  };

  private setUpValueLabels(plot: PlotlyComponent, range?: Date[]): void {
    const tag: string = `${this.tag}.setUpValueLabels()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag, "this.settingUpLabels:", this.settingUpLabels);
    if (this.settingUpLabels) return;
    this.settingUpLabels = true;
    // this.isLoading = true;

    this.purgeValueLabels();
    setTimeout(() => {
      // Hide value labels till new ones are created.
      this.animationState = "hidden";
      this.hoverPosition = null;
      this.tooltipPosition = null;

      const valueLabels: ValueLabel[] = createScatterValueLabels({
        actual: this.plot.data[Forecast.Actual],
        machine: this.plot.data[Forecast.Machine],
        organization: this.plot.data[Forecast.Organization],
        points: this.getFilteredPoints(plot, range),
        unit: this.state.unit
      });
      if (debug) console.log(tag, "valueLabels:", valueLabels);
      this.valueLabels = valueLabels;
      setTimeout(() => {
        this.animationState = this.plotService.showValues
          ? "shown"
          : "hidden";
        this.hoverPosition = this.plotService.hoverPosition;
        this.tooltipPosition = this.plotService.tooltipPosition;
        this.settingUpLabels = false;
      });
    });
  }

  private getFilteredPoints(
    plot: PlotlyComponent = this.vclPlotly,
    range?: Date[]
  ): SVGPathElement[] {
    const tag: string = `${this.tag}.getFilteredPoints()`;
    const debug: boolean = this.debugPlotEvents || false;

    if (debug) console.log(tag, "range:", range);
    const plotRange: string[] = plot.plot.layout.xaxis.range;
    if (debug) console.log(tag, "plotRange:", plotRange);
    const usedRange: Date[] = range || [
      nextMonth(plotRange[0] as any),
      previousMonth(plotRange[1] as any)
    ];
    if (debug) console.log(tag, "usedRange:", usedRange);

    const pointNodeList: SVGPathElement[] = plot.plot.querySelectorAll(
      MonthlyForecastComponent.PointSelector
    );
    const points: SVGPathElement[] = Array.from(pointNodeList);
    // if (debug) console.log(tag, 'points:', points);

    // const lastActualDate: Date = previousMonth(this.state.datePickerDate);
    // if (debug) console.log(tag, 'lastActualDate:', lastActualDate);
    // const lastActual: SVGPathElement = points.filter(isActualPoint).find(point => {
    //   const date: Date = normalizeDate(point[PointData][Axis.X]);
    //   return eqDate(date, lastActualDate);
    // });

    const lastActual: SVGPathElement = points.filter(isActualPoint)[0];
    if (debug) console.log(tag, "lastActual:", lastActual);
    const lastActualDate: Date =
      lastActual && normalizeDate(lastActual[PointData][Axis.X]);
    if (debug) console.log(tag, "lastActualDate:", lastActualDate);

    const filteredPoints: SVGPathElement[] = points.filter(point => {
      const date: Date = normalizeDate(point[PointData][Axis.X]);
      // if (debug) console.log(tag, 'date:', date);
      const inRange: boolean =
        gtEqDate(date, usedRange[0]) && ltEqDate(date, usedRange[1]);
      const isLastActualDate: boolean =
        lastActualDate && eqDate(date, lastActualDate);
      return (
        inRange &&
        (!isLastActualDate || (isActualPoint(point) && isLastActualDate))
      );
    });
    if (debug) console.log(tag, "filteredPoints:", filteredPoints);
    return filteredPoints;
  }

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly workbench: WorkbenchDataService,
    private readonly plotService: PlotService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.topLevelKpi$
      .subscribe(this.onTopLevelKpiChange.bind(this)),
      this.workbench.scenario$
        .pipe(skip(1))
        .subscribe(this.onScenarioChange.bind(this)),
      this.workbench.addScenario$.subscribe(this.onAddScenario.bind(this)),
      this.state.removeScenario$.subscribe(this.onRemoveScenario.bind(this)),
      this.plotService.isZoomedOut$
        .pipe(skip(1))
        .subscribe(this.onIsZoomedOutChange.bind(this)),
      this.plotService.showValues$
        .pipe(skip(1))
        .subscribe(this.onShowValuesChange.bind(this)),
      this.plotService.hoverPosition$
        .pipe(skip(1))
        .subscribe(this.onHoverPositionChange.bind(this)),
      this.plotService.tooltipPosition$
        .pipe(skip(1))
        .subscribe(this.onTooltipPositionChange.bind(this)),
      this.state.datePickerDate$
        .pipe(skip(1))
        .subscribe(this.onDoubleDataFetch.bind(this))
    ];
  }

  private onTopLevelKpiChange(topLevelKpi: TopLevelKpi): void {
    this.onScenarioChange(this.workbench.scenario);
  }

  private onDoubleDataFetch(): void {
    const tag: string = `${this.tag}.onDoubleDataFetch()`;
    const debug: boolean = this.debug || false;
    this.ignoreScenarioChange = true;
    if (debug)
      console.log(tag, "this.ignoreScenarioChange:", this.ignoreScenarioChange);
  }

  private onScenarioChange(quarterContainer: KpiValuesQuarterContainer): void {
    const tag: string = `${this.tag}.onScenarioChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    if (!quarterContainer) return;

    if (debug)
      console.log(tag, "this.ignoreScenarioChange:", this.ignoreScenarioChange);
    if (this.ignoreScenarioChange) {
      this.ignoreScenarioChange = false;
      return;
    }

    const kpi: KpiValues = quarterContainer.kpiValues;
    this.createPlot(kpi);
    this.setLoadingStatus();
  }

  private createPlot(kpi: KpiValues): void {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi:", kpi);
    this.didPlotResize = false;

    const formattedKpi: FormattedKpiValues = kpi.format();
    if (debug) console.log(tag, "formattedKpi:", formattedKpi);
    const values: number[] = getFormattedKpiValues(formattedKpi);
    if (debug) console.log(tag, "values:", values);
    const scale: Scale =
      this.state.unit !== Unit.Currency ? Scale.Million : getScale(values);
    if (debug) console.log(tag, "scale:", scale);
    // const yaxisRange: number[] = createLargerNumberRange(values);
    // if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

    const plot: Plot = merge.recursive(
      {
        configuration: Configuration.Dfs,
        layout: Layout.MonthlyForecast,
        events: this.events
      },
      createScatter({
        kpi: scaleKpi(formattedKpi, Axis.Y, scale),
        // yaxisRange,
        tooltipPosition: this.plotService.tooltipPosition
      })
    );
    if (debug) console.log(tag, "plot:", plot);

    if (this.plotService.tooltipPosition) {
      this.tooltipIndex = plot.layout.shapes.length - 1;
      if (debug) console.log(tag, "this.tooltipIndex:", this.tooltipIndex);
    }

    let range = plot.layout.xaxis.range.slice();
    let rangeFrom: number = range[0];
    let rangeTo: number = range[1];

    Object.assign(this, {
      formattedKpis: [formattedKpi],
      scale,
      plot,
      kpi,
      scenarios: array(plot.data.length),
      rangeZoomIn: {
        layout: merge(true, Layout.MonthlyForecast, {
          xaxis: {
            range: GetZoomInRangeAlignment(
              this.state.datePickerDateAsOf,
              rangeFrom,
              rangeTo
            ),
            dtick: "M1"
          }
        })
      }
    });
    // Set default layout as Zoomed in
    Object.assign(plot.layout.xaxis.range, this.rangeZoomIn.layout.xaxis.range);

    if (debug) console.log(tag, "this.rangeZoomIn:", this.rangeZoomIn);
    if (debug) console.log(tag, "this.plot:", this.plot);
  }

  private getScale(
    formattedKpis: FormattedKpiValues[] = this.formattedKpis
  ): Scale {
    const tag: string = `${this.tag}.getScale()`;
    const debug: boolean = this.debug || false;
    if (this.state.unit !== Unit.Currency) return Scale.Million;

    const traces: Trace[] = flatten(formattedKpis.map(kpi => getValues(kpi)));
    if (debug) console.log(tag, "traces:", traces);
    const values: number[] = flatten(traces.map(kpi => kpi[Axis.Y]));
    if (debug) console.log(tag, "values:", values);
    const scale: Scale = getScale(values);
    if (debug) console.log(tag, "scale:", scale);
    return scale;
  }

  private async onAddScenario(
    kpiValuesQuarter: KpiValuesQuarterContainer
  ): Promise<void> {
    const tag: string = `${this.tag}.onAddScenario()`;
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    if (debug) console.log(tag, "kpiValuesQuarter:", kpiValuesQuarter);
    const color: string = (kpiValuesQuarter as any).color;

    const formattedKpi: FormattedKpiValues = kpiValuesQuarter.kpiValues.format();
    if (debug) console.log(tag, "formattedKpi:", formattedKpi);

    const values: number[] = getFormattedKpiValues(formattedKpi);
    // if (debug) console.log(tag, 'values:', values);
    const traceScale: Scale =
      this.state.unit !== Unit.Currency ? Scale.Million : getScale(values);
    // if (debug) console.log(tag, 'traceScale:', traceScale);
    const traceScaleIsHigher: boolean = isHigherScale(traceScale, this.scale);
    // if (debug) console.log(tag, 'traceScaleIsHigher:', traceScaleIsHigher);
    const scale: Scale = traceScaleIsHigher ? traceScale : this.scale;
    // if (debug) console.log(tag, 'scale:', scale);
    (formattedKpi as any).color = color;

    this.formattedKpis = [...this.formattedKpis, formattedKpi];
    let trace: Trace = createCompareScatterTrace({
      kpi: scaleKpi(formattedKpi, Axis.Y, scale),
      name: (kpiValuesQuarter as any).title,
      color
    });
    if (debug) console.log(tag, "trace:", trace);

    const actual: Trace = this.plot.data[Forecast.Actual];
    if (debug) console.log(tag, "actual:", actual);
    // Order reversed by plotly?
    const lastActualDate: Date = actual[Axis.X][0]; // actual[Axis.X][actual[Axis.X].length - 1];
    if (debug) console.log(tag, "lastActualDate:", lastActualDate);

    const earliestTraceDate: Date = trace[Axis.X][0];
    if (debug) console.log(tag, "earliestTraceDate:", earliestTraceDate);
    const actualDate: Date = gtDate(earliestTraceDate, lastActualDate)
      ? lastActualDate
      : previousMonth(earliestTraceDate);
    if (debug) console.log(tag, "actualDate:", actualDate);

    const lastActual: Point = dateToPoint(actual, actualDate);
    if (debug) console.log(tag, "lastActual:", lastActual);

    trace = Object.assign({}, trace, {
      [Axis.X]: [lastActual[Axis.X], ...trace[Axis.X]],
      [Axis.Y]: [lastActual[Axis.Y], ...trace[Axis.Y]]
    });
    if (debug) console.log(tag, "trace:", trace);

    const id: number | string = getScenarioId(kpiValuesQuarter as any);
    const index: number = this.scenarios.push(id) - 1;
    if (debug) console.log(tag, "this.scenarios:", this.scenarios);
    if (debug) console.log(tag, "index:", index);

    await this.vclPlotly.addTraces(trace);

    if (traceScaleIsHigher) this.rescalePlot(scale, this.scale);
    if (debug) console.log(tag, "this.plot:", this.plot);
  }

  private rescalePlot(to: Scale, from: Scale): void {
    const tag: string = `${this.tag}.rescalePlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "to:", to);
    if (debug) console.log(tag, "from:", from);

    // ! TODO: Check if it's necessary to rescale the plot !

    const data: Trace[] = scaleTraces(this.plot.data, Axis.Y, to, from);
    if (debug) console.log(tag, "data:", data);
    Object.assign(this, {
      scale: to,
      plot: Object.assign({}, this.plot, {
        data
      })
    });
  }

  private async onRemoveScenario(
    scenario: Scenario,
    override: boolean = false
  ): Promise<void> {
    const tag: string = `${this.tag}.onRemoveScenario()`;
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    if (debug)
      console.log(tag, "this.removingScenario:", this.removingScenario);
    if (debug) console.log(tag, "override:", override);
    if (this.removingScenario && !override) {
      this.removeScenarioQueue.push(scenario);
      return;
    }
    this.removingScenario = true;

    setTimeout(async () => {
      if (debug) console.log(tag, "scenario:", scenario);
      const id: number | string = getScenarioId(scenario);
      const index: number | string = this.scenarios.findIndex(s => s === id);
      if (debug) console.log(tag, "index:", index);
      if (index === -1) return;

      const scenarios: (number | string)[] = this.scenarios.filter(
        s => s !== id
      );
      if (debug) console.log(tag, "scenarios:", scenarios);
      const formattedKpis: FormattedKpiValues[] = this.formattedKpis.filter(
        kpi => (kpi as any).color !== scenario.color
      );
      if (debug) console.log(tag, "formattedKpis:", formattedKpis);

      Object.assign(this, { scenarios, formattedKpis });

      await this.vclPlotly.deleteTraces(index);

      if (debug)
        console.log(tag, "this.removeScenarioQueue:", this.removeScenarioQueue);
      if (this.removeScenarioQueue.length) {
        this.onRemoveScenario(this.removeScenarioQueue.pop(), true);
        return;
      }

      const scale: Scale = this.getScale(formattedKpis);
      this.rescalePlot(scale, this.scale);
      if (debug) console.log(tag, "this.plot:", this.plot);
      this.removingScenario = false;
    });
  }

  public async onIsZoomedOutChange(isZoomedOut: boolean): Promise<void> {
    const tag: string = `${this.tag}.onIsZoomedOutChange()`;
    const debug: boolean = this.debug || false;
    // if (debug)
    console.log(tag, "isZoomedOut:", isZoomedOut);
    if (isZoomedOut) {
      return this.zoomOut();
    } else {
      return this.zoomIn();
    }
  }

  private async zoomIn(): Promise<void> {
    console.log("zoom in");
    const tag: string = `${this.tag}.zoomIn()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.rangeZoomIn:", this.rangeZoomIn);
    if (!this.rangeZoomIn) return;
    return this.vclPlotly.animate(this.rangeZoomIn, Transition);
  }

  private async zoomOut(): Promise<void> {
    const tag: string = `${this.tag}.zoomOut()`;
    const debug: boolean = this.debug || false;
    if (!this.rangeZoomOut) {
      this.rangeZoomIn.layout.yaxis = {
        range: this.plot.layout.yaxis.range.slice()
      };
    }

    const values: number[] = getTraceValues(this.plot.data);
    if (debug) console.log(tag, "values:", values);
    const usedValues: number[] = values.length ? values : [0, 0];
    if (debug) console.log(tag, "usedValues:", usedValues);
    this.rangeZoomOut = {
      layout: merge.recursive(
        true,
        Layout.MonthlyForecast,
        createZoomOut(this.state.datePickerDate, usedValues).layout,
        {
          xaxis: {
            dtick: "M3"
          }
        }
      )
    };

    // delete this.rangeZoomOut.layout.xaxis.dtick;
    if (debug) console.log(tag, "this.rangeZoomOut:", this.rangeZoomOut);
    return this.vclPlotly.animate(this.rangeZoomOut, Transition);
  }

  private onShowValuesChange(showValues: boolean): void {
    const tag: string = `${this.tag}.onShowValuesChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "showValues:", showValues);
    if (showValues) return this.showValues();
    else return this.hideValues();
  }

  private showValues(): void {
    const tag: string = `${this.tag}.showValues()`;
    const debug: boolean = this.debug || false;
    this.animationState = "shown";
    if (debug) console.log(tag, "this.animationState:", this.animationState);
  }

  private hideValues(): void {
    const tag: string = `${this.tag}.hideValues()`;
    const debug: boolean = this.debug || false;
    this.animationState = "hidden";
    if (debug) console.log(tag, "this.animationState:", this.animationState);
  }

  private onHoverPositionChange(date: Date): void {
    const tag: string = `${this.tag}.onHoverPositionChange()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag, "date:", date);
    this.hoverPosition = date;
  }

  private onTooltipPositionChange(date: Date): void {
    const tag: string = `${this.tag}.onTooltipPositionChange()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag, "date:", date);
    this.tooltipPosition = date;
    this.clicked = 2;
    this.createTooltip(this.tooltipPosition);
  }

  private createTooltip(date: Date): void {
    const tag: string = `${this.tag}.createTooltip()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag, "date:", date);

    let shapes: Shape[] = this.plot.layout.shapes;
    if (isPresent(this.tooltipIndex)) {
      shapes = shapes.filter((s, i) => i !== this.tooltipIndex);
    }

    if (date) {
      shapes = [...shapes, Shapes.Lines.TooltipLine(date)];
      this.tooltipIndex = shapes.length - 1;
    }

    this.plot.layout = merge.recursive(true, this.plot.layout, {
      shapes
    });
  }

  // TDOO: If necessary, can change the value label positioning here, at least for actual values or
  // also the machine (most likely) or organization values when either of them are missing.
  public isValueLabelVisible(point: Point): AnimationState {
    const tag: string = `${this.tag}.isValueLabelVisible()`;
    const debug: boolean = this.debugPlotEvents && false;
    // if (debug) console.log(tag, 'point:', point);
    if (!point) return 'hidden';
    // if (debug) console.log(tag, 'this.animationState:', this.animationState);
    if (this.animationState === 'shown')
      return 'shown';

    const range: number[] = this.vclPlotly.plot.layout.yaxis.range;
    // TODO: Swap if it's unwanted that the value labels go out of the plot frame.
    const usedRange: number[] = range; // createSmallerNumberRange(range, false, 0.8);
    if (debug) console.log(tag, "usedRange:", usedRange);
    const value: number = point[PointData][Axis.Y];
    // if (debug) console.log(tag, 'value:', value);
    const inRange: boolean =
      usedRange && (value > usedRange[0] && value < usedRange[1]);
    if (debug) console.log(tag, "inRange:", inRange);

    const date: Date = normalizeDate(point[PointData][Axis.X]);
    // if (debug) console.log(tag, 'date:', date);
    // if (debug) console.log(tag, 'this.hoverPosition:', this.hoverPosition);
    // if (debug) console.log(tag, 'this.tooltipPosition:', this.tooltipPosition);
    const isVisible: boolean =
      eqDate(this.hoverPosition, date) || eqDate(this.tooltipPosition, date);
    if (debug) console.log(tag, 'isVisible:', isVisible);

    const isValueLabelVisible: boolean = inRange && isVisible;
    if (debug) console.log(tag, 'isValueLabelVisible:', isValueLabelVisible);
    if (isValueLabelVisible) return 'shown';
    return 'hidden';
  }

  private purgeValueLabels(): void {
    const tag: string = `${this.tag}.purgeValueLabels()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag);
    const afterPlot: boolean = this.vclPlotly && this.vclPlotly.afterPlot;
    if (debug) console.log(tag, "afterPlot:", afterPlot);
    if (!afterPlot) return;
    this.removeTooltips();
  }

  private removeTooltips(): void {
    const tag: string = `${this.tag}.removeTooltips()`;
    const debug: boolean = this.debugPlotEvents || false;
    if (debug) console.log(tag);
    const valueLabels: Element[] = Array.from(
      document.getElementsByClassName("myfcMonthlyForecastTooltip")
    );

    valueLabels.forEach(valueLabel => {
      valueLabel.parentElement.removeChild(valueLabel);
    });
  }

  public readonly Unit = Unit;
}
