import { Injectable } from '@angular/core';
import { isString } from '../utils';

@Injectable()
export class StorageService {
  protected static readonly Tag: string = 'StorageService';

  private readonly storage: Storage = window.localStorage;

  public get(k: string) {
    return JSON.parse(this.storage.getItem(k));
  }

  public set(k: string, v) {
    this.storage.setItem(k, isString(v) ? v : JSON.stringify(v));
  }
}
