import * as merge from 'merge';

export class Configuration {
  static get Dfs() {
    return {
      displayModeBar: false,
      doubleClick: false,
    };
  }

  static get Static() {
    return merge.recursive(Configuration.Dfs, {
      staticPlot: true,
    });
  }

  static get HoverInfo() {
    return merge.recursive(Configuration.Dfs, {
      scrollZoom: false,
    });
  }
}
