import { Kpi, Values, KpiValues } from 'src/app/models/common/kpiValues';
import { Unit } from 'src/app/constants';

export class KpiValuesQuarterContainer {
  public asOfMonth: string;
  public previousQuarter: KpiValuesQuarter;
  public values: KpiValuesQuarter[];
  public kpiValues: KpiValues;
  public unit: Unit;
  public nextYearValue: KpiValuesQuarter;

  constructor({ asOfMonth, previousQuarter = new KpiValuesQuarter(), values = [], kpiValues = [], unit,  nextYearValue = new KpiValuesQuarter()}: {
    asOfMonth?: string, previousQuarter?: KpiValuesQuarter,
    values?: KpiValuesQuarter[], kpiValues?: Kpi[], unit?: Unit, nextYearValue?: KpiValuesQuarter
  } = {}) {
    this.asOfMonth = asOfMonth;
    this.previousQuarter = previousQuarter;
    this.values = values.map(kpi => new KpiValuesQuarter(kpi));
    this.kpiValues = new KpiValues({ values: kpiValues });
    this.unit = unit;
    this.nextYearValue = nextYearValue;
  }
}

export class KpiValuesQuarter {
  public year: number;
  public quarter: number;

  public quarterLabel: string;
  public quarterValue: Values;

  public kpiValues: Kpi[];

  constructor({ year, quarter, quarterLabel, quarterValue = {}, kpiValues = [] }: {
    year?: number, quarter?: number,
    quarterLabel?: string, quarterValue?: Values,
    kpiValues?: Kpi[],
  } = {}) {
    this.year = year;
    this.quarter = quarter;
    this.quarterLabel = quarterLabel;
    this.quarterValue = quarterValue;
    this.kpiValues = kpiValues;
  }
}
