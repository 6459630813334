import {
  ChangeDetectorRef,
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  OnInit
} from "@angular/core";
import { StateService } from "src/app/services/state/state.service";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { CommonDataService } from "src/app/services/data/common.data.service";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { NotifierType } from 'src/app/constants';
import { NotificationService } from "src/app/services/notification.service";
import { ConfirmationDialogComponent } from "src/app/components/common/elements/confirmationDialog/confirmationDialog.component";

const GifDuration: number = 9000;

@Component({
  selector: "submitBtn",
  templateUrl: "submitBtn.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class SubmitButtonComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "SubmitButtonComponent";
  @ViewChild("submitBtnConfirmationDialog")
  private readonly submitBtnConfirmationDialog: ConfirmationDialogComponent;
  protected readonly tag: string = SubmitButtonComponent.Tag;
  protected readonly debug: boolean = false;

  public debugConfirmationDialog: boolean = false;
  public showConfirmationDialog: boolean = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
    public readonly state: StateService,
    private readonly notification: NotificationService,
    private readonly workbench: WorkbenchDataService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;

    this.observables = [this.state.scenario$];
  }

  public async onSubmitBtnTap(
    confirmDialog: ConfirmationDialogComponent
  ): Promise<void | boolean> {
    const tag: string = `${this.tag}.onSubmitBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    const access: boolean = await this.data.hasApplicationAccess();
    if (!access) return;
    confirmDialog.Toggle();
    // this.submitBtnConfirmationDialog.popover.open();
  }

  public onConfirmationDialogCancelTap(): void {
    const tag: string = `${this.tag}.onCancelTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }

  public async onConfirmationDialogContinueTap(): Promise<void> {
    const tag: string = `${this.tag}.onConfirmationDialogContinueTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    try {
      await this.data.submitAdjustments();
    } catch (e) {
      this.notification.add({
        text: "Submitting forecasts failed",
        type: NotifierType.Error
      });

      return;
    }

    await Promise.all([
      this.data.commonReload(),
      this.workbench.setUpScenarios()
    ]);

    this.notification.add({
      text: `Forecasts submitted successfully and a new scenario (${this.state
        .scenario && this.state.scenario.title}) has been created`,
      type: NotifierType.Success
    });
  }

  public get btnDisabled() {
    let adjustable = this.state.isUnadjustableMonth();
    adjustable = (!this.state.dateRestrictionDisabled && adjustable);
    var kpi0 = this.state.kpi0 == null ? '' : this.state.kpi0?.kpi?.label;
    var kpi1 = this.state.kpi1 == null ? '' : this.state.kpi1?.kpi?.label;
    return (
      this.isLoading ||
      this.state.scenario.readOnly ||
      (this.state.organizationEntity.disableDataSubmit && !(kpi0 == 'Sales & Acquisitions' && kpi1 == 'Acquisitions')) || 
      (adjustable && !(kpi0 == 'Sales & Acquisitions' && kpi1 == 'Acquisitions'))
    );
  }
}
