import { MaxSubKpiCount } from 'src/app/constants';
import { getEnumKeys } from 'src/app/utils';
import * as Traces from 'src/app/plotting/traces';

export const Dash: string = 'dash';
export const Solid: string = 'solid';
export const LineWidth: number = 2;

// Actual value count (3 years).
export const ActualValueCount: number = 36;
// Organization forecast value count (1.5 years).
export const FutureForecastCount: number = 18;
// Used to 'pad' the plot so the last month value isn't on the y-axis.
export const XaxisActualTracePadding: number = 1;

export enum Axis {
  X = 'x',
  Y = 'y',
  Z = 'z',
}

export enum Forecast {
  Machine,
  Organization,
  Adjustment,
  Actual,
  AdjustmentFill,
}

export enum CompareTrace {
  Compare1,
  Compare2,
  Compare3,
}

export enum Annotations {
  yaxis,
}

export enum ZeroPosition {
  Top,
  Middle,
  Bottom,
}

/** Workbench */
// Top level kpi value plot width.
export const TopLevelKpiValuePlotWidth: number = 839; // 789 + 50 right margin
// Top level kpi value plot height.
export const TopLevelKpiValuePlotHeight: number = 299; // 279 + 20 bottom margin
// Sub kpi value plot height.
export const SubKpiValuePlotHeight: number = 111; // 111 + 10 bottom margin
// Sub kpi value plot margin right.
export const SubKpiValuePlotMarginRight: number = 22;
// How many sub plots are displayed.
export const WorkbenchSubPlotCount: number = MaxSubKpiCount;
// Used to create the year quarter separation shapes (rectangles).
export const MonthsBetweenMonthSeparation: number = 3;
// Actual values to show in Workbench Overview and Delta modes.
export const ActualValuesToShow: number = 2;
// Actual values to show in Workbench Adjustment mode.
export const ActualValuesToShowInAdjustmentView: number = 1;
// How many Organization forecast values to show in the Workbench Information mode.
export const InformationModeMachineValuesToShow: number = 3;

// How many digits after the scaling.
export const YaxisRangeTickPrecision: number = 0;
// How many ticks at a time are visible..
export const YaxisRangeTickCount: number = 5;
// Minimum y-axis range tick value.
export const MinYaxisRangeTick: number = 2;
// How many tick sets will be made (will be seen when scrolling).
export const YaxisRangeTickCountMultiplier: number = 10;


/** Submission */


// walkWidth: 430, // 416 + 7 left margin + 7 right margin
// walkHeight: 216, // 171 + 15 top margin + 30 bottom margin
// developmentWidth: 430, // 373 + 7 left margin + 50 right margin
// developmentHeight: 216, // 171 + 15 top margin + 30 bottom margin
// previousFcMachineFcWidth: 291, // 180 + 104 left margin + 7 right margin
// previousFcMachineFcHeight: 216,
// machineFcWidth: 291, // 180 + 104 left margin + 7 right margin
// machineFcHeight: 216,


// How many plots are shown in the PreviousFcMachineFc plots.
export const PreviousFcMachineFcPlotCount: number = 5;
// Used in plot.layout.domain to set the size/height of the sub-plots in PreviousFcMachineFc plots.
export const PreviousFcMachineFcDomainStep: number = 1 / PreviousFcMachineFcPlotCount;
// How many years are shown in the PreviousFcMachineFc plots.
export const PreviousFcMachineFcYearCount: number = 2;
// Used to center the stacked plot delta line.
export const CenterStackedPlotDeltaLine: number = 0.5;

// Various magic numbers to make the plots look like in the UX.
export const MagicNumber = {
  /** Analysis */
  CenterActualEffectMonthSelection: 0.5,
  // Annotation positioning.
  Annotation: {
    TwoValueCircularDonut: {
      // TODO: Label and percentage can be merged into one annotation if text is seperated by <br>.
      PlotTitle: {
        x: - 0.045,
        y: 1.02,
      },
      InnerLeftLabel: {
        x: 0.125,
        y: 0.53,
      },
      InnerLeftPercentage: {
        x: 0.162,
        y: 0.445,
      },
      OutterLeft: {
        x: 0.06,
        y: -0.02,
      },
      InnerRightLabel: {
        x: 0.85,
        y: 0.53,
      },
      InnerRightPercentage: {
        x: 0.85,
        y: 0.445,
      },
      OutterRight: {
        x: 1.025,
        y: -0.02,
      },
    }
  },
  /** Workbench */
  // Used to align the organization forecast values in Workbench Adjustment mode to the adjustment box lines.
  AdjustmentViewAlignToLines: 11,
  // Used to align the actual lines in Workbench Adjustment mode.
  AdjustmentViewAlignZoomOutActualLines: -3,

  /** Submission */
  // Used to set the x-axis next year's position similar to the UX.
  PreviousFcMachineFcYearInXaxis: 6,
  // Used to set the x-axis next year's position similar to the UX.
  PreviousFcMachineFcYearGap: 3,
  // Used along with CenterStackedPlotDeltaLine to center the containing y-axis labels.
  CenterStackedPlotYaxisLabels: CenterStackedPlotDeltaLine + 0.1,
  // The distance between the shared x-axis to the first sub-plot when there are PreviousFcMachineFcPlotCount plots.
  StackedBarBottomPadding: 0.048,
  // Height of stacked plot sub-plot.
  StackedPlotHeight: 0.116,
  // The distance between plots.
  StackedPlotPadding: 0.085,
  // Annotation aligment for FC Accuracy side annotations.
  FcAccuracySideAnnotationOrganizationPadding: 0.3,
  FcAccuracySideAnnotationMachinePadding: 0,
  // Annotation aligment for FC Accuracy side annotations.
  FyComponentsSideAnnotationOrganizationPadding: 0.32,
  FyComponentsSideAnnotationMachinePadding: 0.008,
};
// Year gap divided by month count in a year to get the size of each month + 'bargap'.
export const PreviousFcMachineFcMonthStep: number = MagicNumber.PreviousFcMachineFcYearInXaxis / FutureForecastCount;

export const PlotlyEvent = {
  Click: 'plotly_click',
  DoubleClick: 'plotly_doubleclick',
  Hover: 'plotly_hover',
  Unhover: 'plotly_unhover',
  AfterPlot: 'plotly_afterplot',
  Selecting: 'plotly_selecting',
  Selected: 'plotly_selected',
  Relayout: 'plotly_relayout',
  Restyle: 'plotly_restyle',
};

export enum Placement {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}


export const PointData: string = '__data__';
export const ActualTraceName: string = Traces.Scatter.Actual.name;
export const OrganizationTraceName: string = Traces.Scatter.Organization.name;
export const MachineTraceName: string = Traces.Scatter.Machine.name;
