import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from 'src/app/services/state/state.service';
import { Default } from 'src/app/services/state/defaults';
import { Page } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  templateUrl: './login.component.html',
  selector: 'login',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LoginComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'LoginComponent';

  public title: string = 'Composer Login';
  public usernameLabel: string = 'Username';
  public passwordLabel: string = 'Password';
  public buttonLabel: string = 'Login';

  public navigateToUrl: string;

  public user: string;
  public password: string;

  constructor(
    public readonly state: StateService,
    private readonly auth: AuthService,
    private readonly router: RouterService,
    private readonly configuration: ConfigurationService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = LoginComponent.Tag;
    this.debug = ConfigurationService.Debug;
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    this.navigateToUrl = `${this.configuration.baseUrl}/${Page[Default.Page].toLowerCase()}`;
    if (debug) console.log(tag, 'this.navigateToUrl:', this.navigateToUrl);

    const isLoginEnabled: boolean = ConfigurationService.Development && this.configuration.authEnabled;
    if (debug) console.log(tag, 'isLoginEnabled', isLoginEnabled);
    const isAuthorized: boolean = this.auth.isAuthorized;
    if (debug) console.log(tag, 'isAuthorized', isAuthorized);
    const redirect: boolean = !isLoginEnabled || isAuthorized;
    if (debug) console.log(tag, 'redirect', redirect);

    if (redirect) {
      this.router.goTo(this.navigateToUrl);
    }
  }

  public async authorize(): Promise<void> {
    const tag: string = `${this.tag}.authorize()`;
    const debug: boolean = this.debug || false;

    await this.auth.authorize(this.user, this.password);
    this.router.goTo(this.navigateToUrl);
  }
}
