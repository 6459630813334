import * as merge from 'merge';
import {
  Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit,
  OnChanges, AfterViewInit, Input,
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { AggregatedKpi } from 'src/app/models/common/kpiAggregated';
import { CommonDataService, FyForecastMap } from 'src/app/services/data/common.data.service';
import { StateService } from 'src/app/services/state/state.service';
import { Configuration } from 'src/app/plotting/configuration';
import { Plot } from 'src/app/plotting/interfaces';
import { Layout } from 'src/app/plotting/layout';
import {
  FyForecastGroup, createFyForecasts, createLargerNumberRange
} from 'src/app/plotting/data';

import { Color } from 'src/styles/color';
import {
  previousMonth, getYearShort, localeMonthShortYearShort,
  flatten, getScale, array, absoluteDecimal, isNumber
} from 'src/app/utils';
import {
  TopLevelKpi, YearToDateAcronym, NoValue, ForYearAcronym, Scale, Page
} from 'src/app/constants';
@Component({
  selector: 'fyForecast',
  templateUrl: 'fyForecast.component.html',
  styleUrls: ['fyForecast.styl'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FyForecastComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {
  private static readonly Tag: string = 'FyForecastComponent';
  public debugPlot: boolean;

  @Input() public topLevelKpi: TopLevelKpi;
  @Input() private page: Page;

  public scale: Scale;

  public plotClass: string = 'myfcFyForecast';

  public kpiLabels: string[] = [];

  public fyForecasts: AggregatedKpi[] = [];

  public plots: Plot[] = [];

  public xaxis: string[] = [];

  public width: number = 50;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly data: CommonDataService,
  ) {
    super(cd, state);
    this.debug = false;
    this.debugPlot = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${FyForecastComponent.Tag}.${TopLevelKpi[this.topLevelKpi]}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.data.fyForecast$.subscribe(this.onFyForecastChange.bind(this)),
    ];
  }

  public onFyForecastChange(fyForecast: FyForecastMap): void {
    const tag: string = `${this.tag}.onFyForecastChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecast:', fyForecast);
    if (!fyForecast) return;

    const FyForecastsPerGroup: number = 2;
    const FyForecastYears: number = 2;
    const fyForecasts: AggregatedKpi[] = fyForecast[this.topLevelKpi];
    if (debug) console.log(tag, 'fyForecasts:', fyForecasts);

    const topLevelKpifyForecast: AggregatedKpi = fyForecasts[0];
    const topLevelKpifyForecastGroups: FyForecastGroup[] = this.formatData(topLevelKpifyForecast);
    if (debug) console.log(tag, 'topLevelKpifyForecastGroups:', topLevelKpifyForecastGroups);

    const date: Date = previousMonth(this.state.datePickerDate);
    const dateYearShort: number = getYearShort(date);
    const dateLocaleShort: string = localeMonthShortYearShort(date);

    const xaxis: string[] = [
      `${YearToDateAcronym} ${dateLocaleShort}`,
      ...array(FyForecastYears).map((_, i) => `${ForYearAcronym}${dateYearShort + i}`)
    ];
    if (debug) console.log(tag, 'xaxis:', xaxis);
    const includeOrganization: boolean = true;
    const includeAdjustments: boolean = false;

    const values: number[] = flatten(fyForecasts.map(fyForecast =>
      fyForecast.listValues(includeOrganization, includeAdjustments)));
    if (debug) console.log(tag, 'values:', values);

    const scale: Scale = getScale(values);
    if (debug) console.log(tag, 'scale:', scale);

    const yaxisRange: number[] = createLargerNumberRange(topLevelKpifyForecast
      .listValues(includeOrganization, includeAdjustments));
    // Always start from zero / automatically draw the zero line.
    yaxisRange[0] = yaxisRange[0] > 0 ? 0 : yaxisRange[0];
    if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

    this.createPlot({
      fyForecastGroups: topLevelKpifyForecastGroups,
      xaxis,
      yaxisRange,
      scale,
      fyForecastsPerGroup: FyForecastsPerGroup
    });
    this.setUpLayout(fyForecasts.slice(1), xaxis, scale);

    Object.assign(this, {
      scale, xaxis
    });
    this.setLoadingStatus();
  }

  private createPlot({ fyForecastGroups, xaxis, yaxisRange, scale, fyForecastsPerGroup }: {
    fyForecastGroups: FyForecastGroup[], xaxis: string[], yaxisRange: number[],
    scale: Scale, fyForecastsPerGroup: number
  }): void {
    const tag: string = `${this.tag}.createPlot()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecastGroups:', fyForecastGroups);

    const plots: Plot[] = createFyForecasts({
      fyForecastGroups,
      xaxis,
      yaxisRange,
      scale,
      fyForecastsPerGroup
    }).map((fyForecast, i) => merge.recursive(fyForecast, {
      plotId: `${this.tag}.${i}`,
      plotClass: this.plotClass,
      layout: Layout.FyForecast,
      configuration: Configuration.Static,
    }));
    if (debug) console.log(tag, 'plots:', plots);

    this.plots = plots;
  }

  private setUpLayout(fyForecasts: AggregatedKpi[], xaxis: string[], scale: Scale): void {
    const tag: string = `${this.tag}.setUpLayout()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecasts:', fyForecasts);
    if (debug) console.log(tag, 'xaxis:', xaxis);

    this.fyForecasts = fyForecasts.map(fyForecast => Object.assign({}, fyForecast, {
      actual: isNumber(fyForecast.actual) && absoluteDecimal(fyForecast.actual, scale) || NoValue,

      endOfYearOrganization: isNumber(fyForecast.endOfYearOrganization) && absoluteDecimal(fyForecast.endOfYearOrganization, scale) || NoValue,
      endOfYearMachine: isNumber(fyForecast.endOfYearMachine) && absoluteDecimal(fyForecast.endOfYearMachine, scale) || NoValue,
      endOfYearAdjust: isNumber(fyForecast.endOfYearAdjust) && absoluteDecimal(fyForecast.endOfYearAdjust, scale) || NoValue,

      nextYearOrganization: isNumber(fyForecast.nextYearOrganization) && absoluteDecimal(fyForecast.nextYearOrganization, scale) || NoValue,
      nextYearMachine: isNumber(fyForecast.nextYearMachine) && absoluteDecimal(fyForecast.nextYearMachine, scale) || NoValue,
      nextYearAdjust: isNumber(fyForecast.nextYearAdjust) && absoluteDecimal(fyForecast.nextYearAdjust, scale) || NoValue,
    })) as AggregatedKpi[];
    if (debug) console.log(tag, 'this.fyForecasts:', this.fyForecasts);
  }

  private formatData(fyForecast: AggregatedKpi): FyForecastGroup[] {
    const tag: string = `${this.tag}.formatData()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'fyForecast:', fyForecast);

    const fyForecastGroups: FyForecastGroup[] = [
      [
        {
          value: fyForecast.actual,
          color: Color.Actual,
        }
      ],
      [
        {
          value: fyForecast.endOfYearOrganization,
          color: Color.Organization,
        },
        {
          value: fyForecast.endOfYearMachine,
          color: Color.Machine
        }
      ],
      [
        {
          value: fyForecast.nextYearOrganization,
          color: Color.Organization,
        },
        {
          value: fyForecast.nextYearMachine,
          color: Color.Machine
        }
      ],
    ];

    return fyForecastGroups;
  }

  public readonly Page = Page;
  public readonly TopLevelKpi = TopLevelKpi;

  public readonly NoValue = NoValue;
  public readonly ForYearAcronym = ForYearAcronym;
  public readonly YearToDateAcronym = YearToDateAcronym;

  public readonly localeMonthShortYearShort = localeMonthShortYearShort;
}
