import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RouterService } from 'src/app/services/router.service';
import { Page } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
  private static readonly Tag: string = 'LoginGuard';
  private readonly tag: string = LoginGuard.Tag;
  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    public readonly state: StateService,
    private readonly auth: AuthService,
    private readonly router: RouterService,
    private readonly configuration: ConfigurationService,
  ) { }

  public canActivate(): boolean {
    const tag: string = `${this.tag}.canActivate()`;
    const debug: boolean = this.debug || false;

    const isLoginEnabled: boolean = ConfigurationService.Development && this.configuration.authEnabled;
    if (debug) console.log(tag, 'isLoginEnabled', isLoginEnabled);
    const isAuthorized: boolean = this.auth.isAuthorized;
    if (debug) console.log(tag, 'isAuthorized', isAuthorized);
    const hasToLogin: boolean = isLoginEnabled && !isAuthorized;
    if (debug) console.log(tag, 'hasToLogin', hasToLogin);

    if (hasToLogin) {
      this.router.goTo(Page.Login);
    }

    return !hasToLogin;
  }
}
