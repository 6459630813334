import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from 'src/app/services/state/state.service';
import { RouterService } from 'src/app/services/router.service';
import { WindowService } from 'src/app/services/window.service';
import { ModalService } from 'src/app/services/modal.service';
import { Default } from 'src/app/services/state/defaults';
import { WorkbenchResolver } from 'src/app/resolvers/workbench.resolver';
import { Page, UnsavedAdjustmentsText } from 'src/app/constants';
import { UserSettingsService, UnsavedAdjustment } from 'src/app/services/userSettings.service';

import objectFitImages from 'object-fit-images';

export const MaxWidth: number = 1370;
export const OnResizeTimeout: number = 200;

import { detect } from 'detect-browser';
export const browser = detect();

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
  private static readonly Tag: string = 'AppComponent';
  protected readonly tag: string = AppComponent.Tag;
  protected readonly debug: boolean = false;

  public isInitialized: boolean = false;
  public isResizing: any;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    // Load up / activate services.
    private readonly router: RouterService,
    private readonly window: WindowService,
    public readonly state: StateService,
    private readonly user: UserSettingsService,
    private readonly modal: ModalService,
    private readonly idle: Idle,
    private readonly configuration: ConfigurationService,
    private readonly workbench: WorkbenchResolver,
  ) {
    super(cd, state);
    this.tag = `${AppComponent.Tag}.constructor()`;
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    objectFitImages();

    this.state.app = this;

    this.observables = [
      this.state.initialized$,
      this.state.page$,
    ];

    this.addEventListeners();

    if (!ConfigurationService.Development) {
      this.setUpIdle();
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    const tag: string = `${this.tag}.ngAfterViewInit()`;
    const debug: boolean = this.debug || false;
    this.setScreenSize();
  }

  private addEventListeners(): void {
    const tag: string = `${this.tag}.addEventListeners()`;
    const debug: boolean = this.debug || false;

    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      const innerTag: string = `${tag}.beforeunload()`;
      const skipConfirmationDialog: boolean = this.user.getSkipConfirmationDialog(UnsavedAdjustment);
      if (debug) console.log(tag, 'state.unsavedAdjustments:', this.state.unsavedAdjustments);
      if (debug) console.log(tag, 'skipConfirmationDialog:', skipConfirmationDialog);
      if (!this.state.unsavedAdjustments || skipConfirmationDialog) return;

      event.returnValue = UnsavedAdjustmentsText;
      return UnsavedAdjustmentsText;
    });

    window.addEventListener('unload', (event: Event) => {
      const innerTag: string = `${tag}.unload()`;
      if (debug) console.log(tag);
      this.state.clearAdjustments();
    });
  }

  private setUpIdle(): void {
    const tag: string = `${this.tag}.setUpIdle()`;
    const debug: boolean = this.debug || false;

    this.idle.setIdle(90);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setTimeout(600);
    this.idle.onTimeout.subscribe(() => {
      this.modal.title = 'Time Out';
      this.modal.text = 'You have been idle for too long and application timed out!';
      this.modal.cancelBtnLabel = null;
      this.modal.continueBtnLabel = 'Reload Application Data';
      this.modal.action = () => this.refreshApplication();
      this.modal.open();
    });
    this.idle.watch();
  }

  public refreshApplication(): void {
    const tag: string = `${this.tag}.refreshApplication()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'page:', Page[this.state.page]);
    this.state.setDefaultsForAllPages();
    // Setting the same page won't trigger resolvers.
    this.router.goTo(Page.Landing);
  }

  @HostListener('window:error', ['$event'])
  public error(msg, url, lineNo, columnNo, error) {
    const tag: string = `${this.tag}.error()`;
    if (this.debug) console.log(tag, 'msg:', msg);
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize(event): void {
    const tag: string = `${this.tag}.onWindowResize()`;
    const debug: boolean = this.debug || false;

    // WebKit double resize event workaround.
    // https://stackoverflow.com/questions/5534363/why-does-the-jquery-resize-event-fire-twice
    if (this.isResizing) clearTimeout(this.isResizing);
    this.isResizing = setTimeout(() => {
      if (debug) console.log(tag, 'event:', event);
      this.setScreenSize(event);
    }, OnResizeTimeout);
  }

  private setScreenSize(event?: Event): void {
    const tag: string = `${this.tag}.setScreenSize()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'window.innerWidth:', window.innerWidth);
    if (debug) console.log(tag, 'window.outerWidth:', window.outerWidth);

    const width: number = window.outerWidth;
    if (debug) console.log(tag, 'width:', width);
    if (width < MaxWidth) {
      this.window.isMenuExpanded = false;
      this.window.isSmallScreen = true;
    } else {
      this.window.isMenuExpanded = true;
      this.window.isSmallScreen = false;
    }
  }

  public readonly Page = Page;
}
