import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { PopoverDirective } from "@vcl/ng-vcl";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { WorkbenchDataService } from "src/app/services/data/workbench.data.service";
import { StateService } from "src/app/services/state/state.service";
import { WindowService } from "src/app/services/window.service";
import {
  Scenario,
  ScenarioContainer,
  ScenarioMonth,
  ICreateScenario,
  IScenario
} from "src/app/models/workbench/scenario";
import {
  ExpandedMenuWidth,
  CollapsedMenuWidth
} from "src/app/components/common/elements/navigation/navigation/navigation.component";
import {
  localeMonthFull,
  flatten,
  isPresent,
  localeMonthFullYearFull,
  localeMonthShortYearShort,
  monthPickerStrNonZero,
  normalizeDate,
  eqDate,
  isNumber,
  array,
  sortByDate
} from "src/app/utils";
import { Page, Adjustment } from "src/app/constants";
import {
  MaxExpandedMonths,
  YearsBackAvailable
} from "src/app/components/workbench/workbenchHeader/compare/compare.component";
import { KpiValuesQuarterContainer } from "src/app/models/workbench/kpiValuesQuarter";
import { Forecast, Axis } from "src/app/plotting/constants";
import { FormattedKpiValues } from "src/app/models/common/kpiValues";
import { Trace } from "src/app/plotting/interfaces";
import { HttpResponse } from "@angular/common/http";

export enum View {
  ScenarioList,
  CreateNewScenarioList,
  CreateNewScenario
}

export enum ScenarioOption {
  LastSubmitted,
  Previous,
  New,
  Model
}

@Component({
  selector: "scenarios",
  templateUrl: "./scenarios.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScenariosComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "ScenariosComponent";
  protected readonly tag: string = ScenariosComponent.Tag;
  protected readonly debug: boolean = false;
  public readonly debugPopover: boolean = false;

  @ViewChild("scenariosListPopOver")
  public readonly popover: PopoverDirective;

  public scenarioSubTitle: string;

  public expanded: boolean = false;
  public view: View = View.ScenarioList;
  public showAll: boolean = false;
  public scenarioOption: ScenarioOption;

  public selectedYear: number = this.state.datePickerDate.getFullYear();
  public years: number[] = array(YearsBackAvailable + 1)
    .map((_, i) => this.selectedYear - i)
    .reverse();

  private currentMonth: ScenarioMonth;
  public months: ScenarioMonth[] = [];
  public allMonths: ScenarioMonth[] = [];
  public expandedMonths: ScenarioMonth[] = [];

  public hasSubmittedScenario: boolean = false;
  public hasPreviousScenario: boolean = false;
  public hasModelValues: boolean = false;

  public inputScenarioTitle: string;
  public inputScenario: Scenario;

  public createNewScenarioButtons = [
    {
      icon: "",
      title: "Use Last Submitted Forecast (Standard)",
      description:
        "Create a new forecast using previously submitted values as the basis.",
      scenarioOption: ScenarioOption.LastSubmitted
    },
    {
      icon: "",
      title: "Use Previous Scenario",
      description:
        "Select a previous scenario on which to base your new forecast.",
      scenarioOption: ScenarioOption.Previous
    },
    // {
    //   icon: '',
    //   title: 'Create New Forecast',
    //   description: 'Start a completely new forecast with no preselected values.',
    //   scenarioOption: ScenarioOption.New,
    // },
    {
      icon: "",
      title: "Use Model Forecast",
      description: "Create a new forecast using model values as the basis.",
      scenarioOption: ScenarioOption.Model
    }
  ];

  public get title(): string {
    const tag: string = `${this.tag}.title`;
    const debug: boolean = this.debug || false;

    switch (this.view) {
      case View.ScenarioList:
        return this.showAll
          ? "All Scenarios"
          : `Forecast Scenarios ${this.scenarioSubTitle}`;
      case View.CreateNewScenarioList:
      case View.CreateNewScenario:
        return "Create New Scenario";
    }
  }

  constructor(
    public readonly state: StateService,
    public readonly window: WindowService,
    private readonly workbench: WorkbenchDataService,
    protected readonly cd: ChangeDetectorRef
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.page$,
      this.window.isMenuExpanded$,
      this.window.isSmallScreen$,
      this.state.scenario$
    ];

    this.subscriptions = [
      this.state.datePickerDate$.subscribe(
        this.onDatePickerDateChange.bind(this)
      ),
      this.state.datePickerDate$.subscribe(this.onRequestDateChange.bind(this)),
      this.workbench.scenario$.subscribe(this.onScenarioChange.bind(this)),
      this.workbench.scenarios$.subscribe(this.onScenariosChange.bind(this))
    ];
  }

  private onDatePickerDateChange(date: Date): void {
    const tag: string = `${this.tag}.onDatePickerDateChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "date:", date);
    const scenarioSubTitle: string = localeMonthFullYearFull(date);
    if (debug) console.log(tag, "scenarioSubTitle:", scenarioSubTitle);
    this.scenarioSubTitle = scenarioSubTitle;
  }

  private onRequestDateChange(date: Date): void {
    const tag: string = `${this.tag}.onRequestDateChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "date:", date);
    this.resetCurrentMonth(date);
  }

  private onScenarioChange(quarterContainer: KpiValuesQuarterContainer): void {
    const tag: string = `${this.tag}.onScenarioChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "quarterContainer:", quarterContainer);
    if (!quarterContainer) return;

    const formattedKpiValues: FormattedKpiValues = quarterContainer.kpiValues.format();
    const machine: Trace = formattedKpiValues[Forecast.Machine];
    if (debug) console.log(tag, "machine:", machine);
    const hasModelValues: boolean = machine[Axis.Y].some(isNumber);
    if (debug) console.log(tag, "hasModelValues:", hasModelValues);
    this.hasModelValues = hasModelValues;
  }

  private onScenariosChange(scenarioContainer: ScenarioContainer): void {
    const tag: string = `${this.tag}.onScenariosChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenarioContainer:", scenarioContainer);
    if (!scenarioContainer) return;

    const scenarioMonths: ScenarioMonth[] = this.workbench.scenarioMonths;
    if (debug) console.log(tag, "scenarioMonths:", scenarioMonths);
    const selectedDate: Date = this.state.datePickerDate;
    if (debug) console.log(tag, "selectedDate:", selectedDate);
    const selectedYear: number = selectedDate.getFullYear();
    if (debug) console.log(tag, "selectedYear:", selectedYear);
    const allMonths: ScenarioMonth[] = scenarioMonths.filter(
      m => m.scenarios.length
    );
    if (debug) console.log(tag, "allMonths:", allMonths);
    const year: number = this.state.datePickerDate.getFullYear();
    if (debug) console.log(tag, "year:", year);

    Object.assign(this, {
      allMonths,
      selectedYear
    });

    this.resetCurrentMonth();
    this.setLoadingStatus();
  }

  private resetCurrentMonth(
    date: Date = this.state.datePickerDate,
    months: ScenarioMonth[] = this.allMonths
  ): void {
    const tag: string = `${this.tag}.resetCurrentMonth()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "date:", date);
    if (debug) console.log(tag, "months:", months);
    if (!months.length) return;

    const currentMonth: ScenarioMonth = months.find(month =>
      eqDate(month.date, date)
    );
    if (debug) console.log(tag, "currentMonth:", currentMonth);

    const scenario: Scenario =
      (currentMonth && currentMonth.scenarios[0]) || null;
    if (debug) console.log(tag, "scenario:", scenario);
    this.state.scenario = scenario;

    const hasSubmittedScenario: boolean =
      currentMonth && currentMonth.scenarios.some(s => s.readOnly);
    if (debug) console.log(tag, "hasSubmittedScenario:", hasSubmittedScenario);
    const hasPreviousScenario: boolean =
      currentMonth && currentMonth.scenarios.length > 0;
    if (debug) console.log(tag, "hasPreviousScenario:", hasPreviousScenario);

    const year: number = date.getFullYear();
    if (debug) console.log(tag, "year:", year);

    Object.assign(this, {
      year,
      currentMonth,
      hasSubmittedScenario,
      hasPreviousScenario
    });

    this.resetMonths();
    // this.repositionPopover();
  }

  private resetMonths(): void {
    const tag: string = `${this.tag}.resetMonths()`;
    const debug: boolean = this.debug || false;

    const months: ScenarioMonth[] = this.currentMonth
      ? [this.currentMonth]
      : [];
    if (debug) console.log(tag, "months:", months);
    const expandedMonths: ScenarioMonth[] = this.currentMonth
      ? [this.currentMonth]
      : [];
    if (debug) console.log(tag, "expandedMonths:", expandedMonths);
    const showAll: boolean = false;
    if (debug) console.log(tag, "showAll:", showAll);

    Object.assign(this, {
      months,
      expandedMonths,
      showAll
    });
  }

  private expandMonth(month): void {
    const tag: string = `${this.tag}.onMonthTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "month:", month);
    if (this.expandedMonths.length === MaxExpandedMonths) return;

    this.expandedMonths.push(month);
  }

  public onYearTap(year: number, $event): void {
    const tag: string = `${this.tag}.onYearTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "year:", year);
    const months: ScenarioMonth[] = this.filterMonths(year);
    if (debug) console.log(tag, "months:", months);
    Object.assign(this, {
      selectedYear: year,
      months
    });
    this.handlePopoverEvent(event);
  }

  public filterMonths(
    year: number = this.selectedYear,
    allMonths = this.allMonths
  ): ScenarioMonth[] {
    const tag: string = `${this.tag}.filterMonths()`;
    const debug: boolean = this.debug && false;
    if (debug) console.log(tag, "year:", year);
    const months: ScenarioMonth[] = allMonths.filter(
      month => month.date.getFullYear() === year
    );
    if (debug) console.log(tag, "months:", months);
    return months;
  }

  public onMonthTap(month: ScenarioMonth, event: Event): void {
    const tag: string = `${this.tag}.onMonthTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "month:", month);
    this.handlePopoverEvent(event);

    const expanded: boolean = this.expandedMonths.includes(month);
    if (expanded) {
      return this.collapseMonth(month);
    }

    if (this.expandedMonths.length === MaxExpandedMonths) {
      this.expandedMonths.shift();
    }

    this.expandMonth(month);
  }

  private collapseMonth(month: ScenarioMonth): void {
    const tag: string = `${this.tag}.collapseMonth()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "month:", month);
    this.expandedMonths = this.expandedMonths.filter(m => m !== month);
  }

  public onScenarioTap(scenario: Scenario, event: Event): void {
    const tag: string = `${this.tag}.onScenarioTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenario", scenario);
    this.state.scenario = scenario;
    // this.onOffClick();
  }

  public onCreateNewScenarioTap(
    scenarioOption: ScenarioOption,
    event: Event
  ): void {
    const tag: string = `${this.tag}.onCreateNewScenarioTap()`;
    const debug: boolean = this.debug || false;
    event.stopPropagation();
    if (scenarioOption === null) {
      if (debug)
        console.log(tag, "this.view:", View[View.CreateNewScenarioList]);
      this.setView(View.CreateNewScenarioList);
    } else {
      if (debug)
        console.log(
          tag,
          "this.scenarioOption:",
          ScenarioOption[this.scenarioOption]
        );
      this.setView(View.CreateNewScenario, scenarioOption);
    }
    // this.repositionPopover();
  }

  public setView(
    view: View = View.ScenarioList,
    scenarioOption: ScenarioOption = null
  ): void {
    const tag: string = `${this.tag}.setView()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "view:", View[view]);
    if (debug)
      console.log(
        tag,
        "scenarioOption:",
        scenarioOption === null
          ? scenarioOption
          : ScenarioOption[scenarioOption]
      );
    this.view = null;
    this.resetInputs();
    setTimeout(() => {
      this.view = view;
    });
    this.scenarioOption = scenarioOption;
    // this.repositionPopover();
  }

  public resetInputs(): void {
    const tag: string = `${this.tag}.resetInputs()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.scenarioOption = null;
    this.inputScenario = null;
    this.inputScenarioTitle = "";
  }

  public onShowAllTap(event: Event): void {
    const tag: string = `${this.tag}.onShowAllTap()`;
    const debug: boolean = this.debug || false;
    event.stopPropagation();
    this.setView(this.view);
    this.showAll = !this.showAll;
    if (debug) console.log(tag, "this.showAll:", this.showAll);
    if (this.showAll) {
      this.months = this.filterMonths();
    } else {
      this.resetMonths();
    }
    if (debug) console.log(tag, "this.months:", this.months);
    // this.repositionPopover();
  }

  public onOffClick(): void {
    const tag: string = `${this.tag}.onOffClick()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.expanded:", this.expanded);
    if (!this.expanded) return;
    if (this.view == View.CreateNewScenario) return;

    this.expanded = false;
    this.setView();
    this.resetMonths();
  }

  public onBackBtnTap(event: Event): void {
    const tag: string = `${this.tag}.onBackBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.scenarioOption:", this.scenarioOption);
    if (debug) console.log(tag, "this.view:", this.view);
    if (this.scenarioOption !== null) {
      this.setView(View.CreateNewScenarioList);
    } else {
      this.setView();
    }
    this.handlePopoverEvent(event);
  }

  public onCreateNewScenarioCancelTap(): void {
    const tag: string = `${this.tag}.onCreateNewScenarioCancelTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (!this.expanded) return;
    this.expanded = false;
    this.setView();
    this.resetMonths();
  }

  public async onCreateNewScenarioContinueTap(): Promise<void> {
    const tag: string = `${this.tag}.onCreateNewScenarioContinueTap()`;
    const debug: boolean = this.debug || false;
    let scenario: Scenario;
    if (debug)
      console.log(
        tag,
        "this.scenarioOption:",
        ScenarioOption[this.scenarioOption]
      );
    switch (this.scenarioOption) {
      case ScenarioOption.LastSubmitted:
        scenario = await this.createScenarioLastSubmitted();
        break;
      case ScenarioOption.Previous:
        scenario = await this.createScenarioPreviousScenario();
        break;
      // case ScenarioOption.New: scenario = await this.createScenarioNewScenario(); break;
      case ScenarioOption.Model:
        scenario = await this.createScenarioModelScenario();
        break;
    }
    if (debug) console.log(tag, "scenario:", scenario);
    if (scenario) await this.workbench.setUpScenarios(null, false);
    this.setView();
  }

  private async createScenarioLastSubmitted(): Promise<Scenario> {
    const tag: string = `${this.tag}.createScenarioLastSubmitted()`;
    const debug: boolean = this.debug || false;
    // Already sorted by date in workbench.data.service.
    const submittedScenario: Scenario = this.currentMonth.scenarios.filter(
      s => s.readOnly
    ).sort((t1, t2) => {      
      const date1: Date = t1.submitted;
      const date2: Date = t2.submitted;
      return sortByDate(date1, date2);})[0];
    if (debug) console.log(tag, "submittedScenario:", submittedScenario);
    if (!submittedScenario) return;
    return this.createScenario({ id: submittedScenario.id });
  }

  public onPreviousScenarioSelect: Function = ((scenario: Scenario): void => {
    const tag: string = `${this.tag}.onPreviousScenarioSelect()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "scenario:", scenario);
    this.inputScenario = scenario;
    this.inputScenarioTitle = scenario.title;
  }).bind(this);

  private async createScenarioPreviousScenario(): Promise<Scenario> {
    const tag: string = `${this.tag}.createScenarioPreviousScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.inputScenario:", this.inputScenario);
    if (!this.inputScenario) return;
    return this.createScenario({ id: this.inputScenario.id });
  }

  private async createScenarioNewScenario(): Promise<Scenario> {
    const tag: string = `${this.tag}.createScenarioNewScenario()`;
    const debug: boolean = this.debug || false;
    return await this.createScenario();
  }

  private async createScenarioModelScenario(): Promise<Scenario> {
    const tag: string = `${this.tag}.createScenarioModelScenario()`;
    const debug: boolean = this.debug || false;
    return await this.createScenario({ useMachineValues: true });
  }

  private async createScenario({
    id,
    title = this.inputScenarioTitle,
    organization = this.state.organizationEntity.id,
    username = this.state.user.user.name,
    perMonth = monthPickerStrNonZero(this.state.datePickerDateAsOf),
    useMachineValues = false
  }: {
    id?: number;
    title?: string;
    organization?: number;
    username?: string;
    perMonth?: string;
    useMachineValues?: boolean;
  } = {}): Promise<Scenario> {
    const tag: string = `${this.tag}.createScenario()`;
    const debug: boolean = this.debug || false;
    const createScenario: ICreateScenario = Scenario.toBackend({
      title,
      organization,
      username,
      perMonth
    });
    if (debug) console.log(tag, "createScenario:", createScenario);
    const response: HttpResponse<IScenario> = await (useMachineValues
      ? this.workbench.postMachineScenario
      : this.workbench.postScenario
    ).call(this.workbench, {
      scenario: createScenario,
      id
    });
    const scenario: Scenario = new Scenario(response.body);
    if (debug) console.log(tag, "scenario:", scenario);
    return scenario;
  }

  public onUpBtnTap(event: Event): void {
    const tag: string = `${this.tag}.onUpBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.nextScenario(-1);
  }

  public onDownBtnTap(event: Event): void {
    const tag: string = `${this.tag}.onDownBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.nextScenario(1);
  }

  public nextScenario(v: 1 | -1): void {
    const tag: string = `${this.tag}.nextScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.currentMonth:", this.currentMonth);
    if (!this.currentMonth) return;

    if (debug) console.log(tag, "this.state.scenario:", this.state.scenario);
    if (!this.state.scenario) {
      const scenario: Scenario = this.currentMonth.scenarios[0];
      if (debug) console.log(tag, "scenario:", scenario);
      if (scenario) this.state.scenario = scenario;
      return;
    }

    const index: number = this.currentMonth.scenarios.findIndex(
      s => s.guid === this.state.scenario.guid
    );
    if (debug) console.log(tag, "index:", index);
    if (
      (v === 1 && index === this.currentMonth.scenarios.length - 1) ||
      (v === -1 && index === 0)
    )
      return;

    const scenario: Scenario = this.currentMonth.scenarios[index + v];
    if (debug) console.log(tag, "scenario:", scenario);
    if (!scenario) return;

    this.state.scenario = scenario;
  }

  private handlePopoverEvent(event: Event): void {
    const tag: string = `${this.tag}.handlePopoverEvent()`;
    const debug: boolean = this.debug || false;
    event.stopPropagation();
    // this.repositionPopover();
  }

  // private repositionPopover(): void {
  //   const tag: string = `${this.tag}.repositionPopover()`;
  //   const debug: boolean = this.debug || false;
  //   this.detectChanges();
  //   setTimeout(() => this.popover.reposition());
  // }

  public readonly Page = Page;

  public readonly View = View;
  public readonly ScenarioOption = ScenarioOption;

  public readonly isPresent = isPresent;

  public readonly ExpandedMenuWidth = ExpandedMenuWidth;
  public readonly CollapsedMenuWidth = CollapsedMenuWidth;
}
