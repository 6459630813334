import { Pipe, PipeTransform } from '@angular/core';
import { getValues, isNumber, absoluteDecimal } from 'src/app/utils';
import { TimeAgoPipe } from 'src/app/pipes/timeAgoPipe';

// export default class Pipes {
//   static IsNumberPipe: IsNumberPipe;
//   static ValuesPipe: ValuesPipe;
// }

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  transform = Object.keys;
}

@Pipe({ name: 'isNumber', pure: false })
export class IsNumberPipe implements PipeTransform {
  transform = isNumber;
}

@Pipe({ name: 'values', pure: false })
export class ValuesPipe implements PipeTransform {
  transform = getValues;
}

@Pipe({ name: 'absoluteDecimal', pure: false })
export class ScaleNumberPipe implements PipeTransform {
  transform = absoluteDecimal;
}

@Pipe({ name: 'split', pure: false })
export class SplitPipe implements PipeTransform {
  transform = (v: string) => v.includes('-') ? v.split('-')[0] : v.split(' ')[0];
}

export default [
  KeysPipe,
  IsNumberPipe,
  ValuesPipe,
  ScaleNumberPipe,
  SplitPipe,
  TimeAgoPipe,
];
