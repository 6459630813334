import * as merge from "merge";
import { Annotations } from "src/app/plotting/annotation";
import {
  Plot,
  Annotation,
  Line,
  Shape,
  Trace,
  Point,
  WalkAnnotation
} from "src/app/plotting/interfaces";
import {
  Axis,
  Forecast,
  ActualValuesToShow,
  XaxisActualTracePadding,
  MagicNumber,
  FutureForecastCount,
  ActualValueCount
} from "src/app/plotting/constants";
import * as Traces from "src/app/plotting/traces";
import { NoValue, Scale } from "src/app/constants";
import {
  isPresent,
  getMinMax,
  getMinMaxAbsolute,
  normalizeDate,
  flatten,
  getScale,
  isNumber,
  absoluteDecimal,
  getValues,
  sum,
  sortDate,
  gtDate,
  scaleNumber,
  scaleKpi,
  scaleArray,
  array,
  isArray,
  sortByNumber,
  eqDate,
  previousMonth,
  localeMonthShortYearShort,
  nextMonth,
  hasPositiveNegativeValues,
  getTraceValues,
  monthPickerStrNonZero
} from "src/app/utils";
import { FormattedKpiValues } from "src/app/models/common/kpiValues";
import { WalkBar } from "src/app/models/results/walk";
import { Layout } from "src/app/plotting/layout";
import { Color } from "src/styles/color";
import { FormattedAreaChart, AreaChart } from "../models/analysis/areaChart";
import * as Shapes from "src/app/plotting/shapes";

export const formatDate = (date: Date | string | number): Date =>
  normalizeDate(date);

export const notNullValueDates = (trace: Trace) =>
  trace[Axis.X].filter((date, i) => isNumber(trace[Axis.Y][i]));

export function getDelta(arr1: number[], arr2: number[]): number[] {
  const delta: number[] = [];
  for (let i = 0; i < arr1.length && i < arr2.length; i++) {
    delta.push(arr1[i] - arr2[i]);
  }
  return delta;
}

export function createLargerNumberRange(
  values: number[],
  equal: boolean = false,
  padding: number = 0.1
): number[] {
  const tag: string = "createLargerNumberRange()";
  const debug: boolean = false;
  const minMax: number[] = equal
    ? getMinMaxAbsolute(values)
    : getMinMax(values);
  if (debug) console.log(tag, "minMax:", minMax);
  const max: number = minMax[1];
  const min: number = equal ? -max : minMax[0];
  if (min === 0 && max === 0) return [0, 0];

  const range: number[] = [
    min + (min > 0 ? min * -padding : min * padding),
    max + (max > 0 ? max * padding : max * -padding)
  ];
  return range;
}

export function createSmallerNumberRange(
  values: number[],
  equal: boolean = false,
  padding: number = 0.9
): number[] {
  const tag: string = "createSmallerNumberRange()";
  const debug: boolean = false;
  const minMax: number[] = equal
    ? getMinMaxAbsolute(values)
    : getMinMax(values);
  if (debug) console.log(tag, "minMax:", minMax);
  const max: number = minMax[1];
  const min: number = equal ? -max : minMax[0];

  const range: number[] = [
    min * (min > 0 ? padding : -padding),
    max * (max > 0 ? padding : -padding)
  ];
  return range;
}

export function createDateRange(
  date: Date,
  actualValuesToShow: number = ActualValuesToShow,
  forecastValuesToShow: number = FutureForecastCount
): number[] {
  const tag: string = `createDateRange()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "date:", date);
  if (debug) console.log(tag, "actualValuesToShow:", actualValuesToShow);
  if (debug) console.log(tag, "forecastValuesToShow:", forecastValuesToShow);

  const min: Date = new Date(date);
  min.setMonth(min.getMonth() - actualValuesToShow - 6);
  if (debug) console.log(tag, "min:", min);

  const max: Date = new Date(date);
  max.setMonth(max.getMonth() + forecastValuesToShow - 6);
  if (debug) console.log(tag, "max:", max);

  const range: number[] = [min.getTime(), max.getTime()];
  return range;
}

// TODO: Remove
export function formatKpi(kpiValuesArr: any[]): any {
  return {};
}

export interface FyForecast {
  value: number;
  color: string;
}

export type FyForecastGroup = FyForecast[];

export interface UnpaddedYaxisBar {
  x: any[];
  // Optional for padding bars.
  y?: any;
  marker?: {
    color: string;
  };
}

export function createFyForecasts({
  fyForecastGroups = [],
  xaxis,
  scale,
  yaxisRange,
  fyForecastsPerGroup
}: {
  fyForecastGroups: FyForecastGroup[];
  xaxis: string[];
  scale?: Scale;
  yaxisRange?: number[];
  fyForecastsPerGroup: number;
}): Plot[] {
  const tag: string = `createFyForecasts()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "fyForecastGroups:", fyForecastGroups);
  if (debug) console.log(tag, "xaxis:", xaxis);
  if (debug) console.log(tag, "scale:", scale);
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);

  const FyForecastGroupBarGap: number = Layout.FyForecast.bargap;

  const getFyForecastGroupValues = (
    fyForecastGroup: FyForecastGroup
  ): number[] => fyForecastGroup.map(fyForecast => fyForecast.value);

  yaxisRange = yaxisRange
    ? [...yaxisRange]
    : createLargerNumberRange(
        flatten(fyForecastGroups.map(getFyForecastGroupValues))
      );
  // if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

  const topAnnotationPadding: number = yaxisRange[1] * 0.2;
  // if (debug) console.log(tag, 'topAnnotationPadding:', tzopAnnotationPadding);
  const bottomAnnotationPadding: number = yaxisRange[1] * 0.1;
  // if (debug) console.log(tag, 'bottomAnnotationPadding:', bottomAnnotationPadding);

  yaxisRange[1] += topAnnotationPadding + topAnnotationPadding;
  yaxisRange[1] += bottomAnnotationPadding;
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);

  const plots: Plot[] = fyForecastGroups.map((fyForecastGroup, i) => {
    if (debug) console.log(tag, "fyForecastGroup:", fyForecastGroup);
    const max: number =
      getMinMax(getFyForecastGroupValues(fyForecastGroup))[1] || 0;
    if (debug) console.log(tag, "max:", max);

    // Currently a two-annotation setup. If more annotations to be added  (w/ or w/o differing sizes),
    // then Annotation[`Above${i}`] might be necessary + multiplication on paddings.
    const annotations: Annotation[] = fyForecastGroup.map((fyForecast, j) =>
      (j > 0 ? Annotations.Above : Annotations.AboveLarger)({
        x: i === 0 ? 0 : 0.5,
        // No top annotation padding if it's the first actual value group (doesn'trace have 2 annotations),
        // or if it's the machine value (all values after the first one).
        y:
          max +
          bottomAnnotationPadding +
          (i === 0 || j > 0 ? 0 : topAnnotationPadding),
        text: !isNumber(fyForecast.value)
          ? NoValue
          : `${absoluteDecimal(fyForecast.value, scale)}`,
        font: {
          color: fyForecast.color
        }
      })
    );
    if (debug) console.log(tag, "annotations:", annotations);

    const data: Trace[] = [
      Traces.Bar.Waterfall({
        x: array(fyForecastGroup.length).map((_, i) => i),
        y: fyForecastGroup.map(fyForecast => fyForecast.value),
        marker: {
          color: fyForecastGroup.map(fyForecast => fyForecast.color)
        },
        width:
          i === 0
            ? 1 / fyForecastsPerGroup -
              FyForecastGroupBarGap / fyForecastsPerGroup
            : undefined
        // cornerroundness: {
        //   bottomleft: BarRounding,
        //   bottomright: BarRounding,
        //   topleft: BarRounding,
        //   topright: BarRounding,
        // }
      })
    ];
    if (debug) console.log(tag, "data:", data);

    return {
      data,
      // Passed to plotly, takes up 50% of parent width.
      width: 50,
      layout: {
        annotations,
        yaxis: {
          range: yaxisRange
        }
      }
    };
  });

  if (debug) console.log(tag, "plots:", plots);
  return plots;
}

export function createWorkbenchFyForecast({
  traces = [],
  xaxis,
  yaxisRange
}: {
  traces: Trace[];
  xaxis: string[];
  yaxisRange?: number[];
}): Plot {
  const tag: string = `createWorkbenchFyForecast()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "traces:", traces);
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);

  const data: Trace[] = traces;
  if (debug) console.log(tag, "data:", data);

  const XaxisPadding: number = 1;
  const firstTrace: Trace = data[0];
  const lastTrace: Trace = data[data.length - 1];
  const xaxisRange: number[] = [
    firstTrace[Axis.X][0] - XaxisPadding,
    lastTrace[Axis.X][lastTrace[Axis.X].length - 1] + XaxisPadding
  ];
  if (debug) console.log(tag, "xaxisRange:", xaxisRange);

  let equalRange: boolean = false;
  if (yaxisRange) yaxisRange = [...yaxisRange];
  else {
    const values: number[] = getTraceValues(traces);
    equalRange = hasPositiveNegativeValues(values);
    if (debug) console.log(tag, "equalRange:", equalRange);
    const padding: number = equalRange ? 0.5 : 0.1;
    yaxisRange = values.length
      ? createLargerNumberRange(values, equalRange, padding)
      : [0, 0];
  }
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);

  // (A)
  // Until it's necessary to pass in the yaxis range from the component,
  // setting of it manually is commented out so it's automatically calculated by Plotly.
  // Increase yaxis range to display the annotations.
  // yaxisRange[1] += topAnnotationPadding + topAnnotationPadding + bottomAnnotationPadding;

  // (A)
  // Necessary to pad the min yaxis range value so the zero line /
  // zero line shape is shown (setting it to 0 is not enough).
  // yaxisRange[0] = yaxisRange[0] > 0 ? 0 : yaxisRange[0];
  // if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

  const xaxisAnnotationValues: number[] = data.map(
    trace => sum(trace[Axis.X]) / trace[Axis.X].length
  );
  const MachineValueIndex: number = 1;
  const annotations: Annotation[] = flatten(
    data.map((trace, i) => {
      const firstTrace: boolean = i === 0;
      const minMax: number[] = getMinMax(trace[Axis.Y]);
      if (debug) console.log(tag, "minMax:", minMax);
      const min: number = minMax[0] || 0;
      const max: number = minMax[1] || 0;

      const index: number = max > 0 ? 1 : 0;
      const topAnnotationPadding: number =
        yaxisRange[index] * (equalRange ? 0.25 : 0.2); // max > 0 ? 0.2 : 0.1);
      const bottomAnnotationPadding: number =
        yaxisRange[index] * (equalRange ? 0.25 : 0.2); // max > 0 ? 0.1 : 0.2);

      if (debug)
        console.log(tag, "bottomAnnotationPadding:", bottomAnnotationPadding);
      if (debug)
        console.log(tag, "topAnnotationPadding:", topAnnotationPadding);

      return trace[Axis.Y].map((y, j) =>
        (j > 0 ? Annotations.Above : Annotations.AboveLarger)({
          x: xaxisAnnotationValues[i],
          // No top annotation padding if it's the first actual value group
          // (trace doesn't have 2 annotations), or if it's the machine value.
          y:
            (max > 0 ? max : min) +
            bottomAnnotationPadding +
            (firstTrace || j === MachineValueIndex ? 0 : topAnnotationPadding),
          text: !isNumber(y) ? NoValue : absoluteDecimal(y),
          font: {
            color: trace.marker.line.color[j]
          }
        })
      );
    })
  );
  if (debug) console.log(tag, "annotations:", annotations);

  const yaxisZeroLine: Line = Shapes.Lines.HorizontalGridLine(0);
  const shapes: Shape[] = [yaxisZeroLine];
  if (debug) console.log(tag, "shapes:", shapes);

  return {
    data,
    layout: {
      shapes,
      annotations,
      // (A)
      // yaxis: {
      //   range: yaxisRange,
      // }
      xaxis: {
        range: xaxisRange,
        tickmode: "array",
        tickvals: xaxisAnnotationValues,
        ticktext: xaxis
      }
    }
  };
}

export function createFyForecast({
  traces,
  xaxisRange
}: {
  traces: Trace[];
  xaxisRange?: number[];
}): Plot {
  const tag: string = `createFyForecast()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "traces:", traces);

  const data: Trace[] = traces.map(trace =>
    Object.assign({}, trace, {
      // Sets the bar width (in position axis units) / semi magic number.
      width: 3
    })
  );

  const firstTrace: Trace = data[0];
  const lastTrace: Trace = data[data.length - 1];
  const yaxisRange: number[] = [
    -8, // firstTrace[Axis.X][0] - XaxisPadding,
    40 // lastTrace[Axis.X][lastTrace[Axis.X].length - 1] + XaxisPadding
  ];
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);

  if (xaxisRange) xaxisRange = [...xaxisRange];
  else {
    const values: number[] = getTraceValues(traces, Axis.X);
    xaxisRange = createLargerNumberRange(values);
  }
  if (debug) console.log(tag, "xaxisRange:", xaxisRange);

  const positiveAnnotationPadding: number = xaxisRange[1] * 0.7;
  if (debug)
    console.log(tag, "positiveAnnotationPadding:", positiveAnnotationPadding);
  const negativeAnnotationPadding: number = -positiveAnnotationPadding * 1.5;
  if (debug)
    console.log(tag, "negativeAnnotationPadding:", negativeAnnotationPadding);

  // const xaxisRangePadding: number = 1.75;
  // const negativeValue: boolean = xaxisRange[0] < 0;

  // xaxisRange = [
  //   negativeValue ? xaxisRange[0] + negativeAnnotationPadding * xaxisRangePadding : -positiveAnnotationPadding,
  //   xaxisRange[1] + positiveAnnotationPadding * xaxisRangePadding,
  // ];
  // if (debug) console.log(tag, 'xaxisRange:', xaxisRange);

  const annotations: Annotation[] = flatten(
    data.map(trace =>
      trace[Axis.X].map((x, i) => {
        const nan: boolean = !isNumber(x);
        return Annotations.Dfs({
          // Always display N/A for missing values.
          visible: nan,
          x:
            x === 0 || nan
              ? 0
              : x +
                (x < 0 ? negativeAnnotationPadding : positiveAnnotationPadding),
          y: trace[Axis.Y][i],
          text: nan ? NoValue : `${absoluteDecimal(x)}`,
          font: {
            color: trace.marker.color[i]
          }
        });
      })
    )
  );

  if (debug) console.log(tag, "annotations:", annotations);

  return {
    data,
    layout: {
      annotations,
      // xaxis: {
      //   range: xaxisRange,
      // },
      yaxis: {
        range: yaxisRange
      }
    }
  };
}

// (B)
// const positiveAnnotationPadding: number = xaxisRange[1] * 0.5;
// if (debug) console.log(tag, 'positiveAnnotationPadding:', positiveAnnotationPadding);
// const negativeAnnotationPadding: number = -positiveAnnotationPadding * 1.5;
// if (debug) console.log(tag, 'negativeAnnotationPadding:', negativeAnnotationPadding);

// const xaxisRangePadding: number = 1.5;
// const negativeValue: boolean = xaxisRange[0] < 0;

// xaxisRange = [
//   negativeValue ? xaxisRange[0] + negativeAnnotationPadding * xaxisRangePadding : 0,
//   xaxisRange[1] + positiveAnnotationPadding * xaxisRangePadding,
// ];
// if (debug) console.log(tag, 'xaxisRange:', xaxisRange);

// const annotations: Annotation[] = trace[Axis.X].map((x, i) => Annotations.Dfs({
//   visible: false,
//   x: x === 0 || !isNumber(x) ? positiveAnnotationPadding : x + (x < 0 ? negativeAnnotationPadding : positiveAnnotationPadding),
//   y: trace[Axis.Y][i],
//   text: !isNumber(x) ? NoValue : `${absoluteDecimal(x, scale)}`,
//   font: {
//     color: trace.marker.color[i],
//   }
// }));
// (B)

export function createWalk(walkBars: WalkBar[], scale?: Scale) {
  const tag: string = `createWalk()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "walkBars:", walkBars);
  if (debug) console.log(tag, "scale:", scale);

  if (!scale) {
    const values: number[] = walkBars.map(bar => bar.base + bar.value);
    scale = getScale(values);
  }

  walkBars = walkBars.map(bar =>
    Object.assign({}, bar, {
      base: scaleNumber(bar.base, scale),
      value: scaleNumber(bar.value, scale)
    })
  );
  if (debug) console.log(tag, "walkBars:", walkBars);

  const xaxis: string[] = walkBars.map(b => b.label);
  if (debug) console.log(tag, "xaxis:", xaxis);
  const baseYaxis: number[] = walkBars.map(b => b.base);
  const baseTrace: Trace = Traces.Bar.Invisible({
    x: xaxis,
    y: baseYaxis
  });
  if (debug) console.log(tag, "baseTrace:", baseTrace);

  const bars: Trace[] = walkBars.map((bar, i) => {
    const leftPadding: number[] = walkBars.slice(0, i).map(() => 0);
    if (debug) console.log(tag, "leftPadding:", leftPadding);
    const rightPadding: number[] = walkBars.slice(i).map(() => 0);
    if (debug) console.log(tag, "rightPadding:", rightPadding);
    const yaxis: number[] = [...leftPadding, bar.value, ...rightPadding];
    if (debug) console.log(tag, "yaxis:", yaxis);
    return Traces.Bar.Waterfall({
      x: xaxis,
      y: yaxis,
      marker: {
        color: bar.color
      }
    });
  });
  if (debug) console.log(tag, "bars:", bars);

  const annotations: WalkAnnotation[] = bars.map((trace, i) =>
    (walkBars[i].value < 0 ? Annotations.BelowLarger : Annotations.AboveLarger)(
      {
        visible: walkBars[i].subtype ? false : true,
        subtype: walkBars[i].subtype,
        x: walkBars[i].label,
        y: baseYaxis[i] + walkBars[i].value,
        text: absoluteDecimal(walkBars[i].value) || NoValue,
        font: {
          color: trace.marker.color as string
        }
      } as Annotation
    )
  );
  if (debug) console.log(tag, "annotations:", annotations);

  const data: any[] = [baseTrace, ...bars];
  if (debug) console.log(tag, "data:", data);

  const MaxBarCountPerYear: number = [
    "FY 16",
    "IR",
    "CoR",
    "OPEX",
    "F&O",
    "FY 17"
  ].length;
  // Some MagicNumbers.
  let bargap: number = 0.8;
  if (bars.length > MaxBarCountPerYear) bargap = 0.6;

  return {
    data,
    layout: {
      bargap,
      annotations
    }
  };
}

export function createFyComponent({
  traces,
  scale
}: {
  traces: Trace[];
  scale: Scale;
}) {
  const tag: string = `createFyComponent()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "traces:", traces);
  if (debug) console.log(tag, "scale:", scale);

  const BarCount: number = 4; // fyComponent && fyComponent[0] && fyComponent[0][Axis.Y].length;
  if (debug) console.log(tag, "BarCount:", BarCount);

  const yaxis: number[] = array(BarCount)
    .map((_, i) => i)
    .reverse();
  if (debug) console.log(tag, "yaxis:", yaxis);

  let values: number[] = getTraceValues(traces, Axis.X);
  if (debug) console.log(tag, "values:", values);
  if (!values.length) return;

  scale = scale || getScale(values);
  if (debug) console.log(tag, "scale:", scale);

  traces = traces.map(trace =>
    Object.assign(trace, {
      [Axis.X]: scaleArray(trace[Axis.X], scale),
      [Axis.Y]: yaxis
    })
  );

  values = getTraceValues(traces, Axis.X);
  if (debug) console.log(tag, "values:", values);

  const negativeValue: number = values.find(v => v < 0);
  if (debug) console.log(tag, "negativeValue:", negativeValue);

  const stepCount: number = negativeValue ? 2 : 5;
  if (debug) console.log(tag, "stepCount:", stepCount);

  const max: number = getMinMaxAbsolute(values)[1] * 1.5 || 0;
  if (debug) console.log(tag, "max:", max);

  const step: number = Math.ceil(max / stepCount);
  if (debug) console.log(tag, "step:", step);
  const annotationPadding: number = step / 2;
  if (debug) console.log(tag, "annotationPadding:", annotationPadding);

  const xaxis: number[] = negativeValue
    ? [
        ...array(stepCount)
          .map((_, i) => (i + 1) * -step)
          .reverse(),
        0,
        ...array(stepCount).map((_, i) => (i + 1) * step)
      ]
    : array(stepCount).map((_, i) => i * step);
  if (debug) console.log(tag, "xaxis:", xaxis);

  const range: number[] = [
    xaxis[0] - annotationPadding,
    xaxis[xaxis.length - 1] + annotationPadding
  ];
  if (debug) console.log(tag, "range:", range);

  const verticalGridLines: Line[] = xaxis.map(x =>
    Shapes.Lines.VerticalGridLine(x)
  );
  if (debug) console.log(tag, "verticalGridLines:", verticalGridLines);

  const YaxisPadding: number = -0.5;
  const horizontalGridLineValues: number[] = yaxis
    .slice(0, yaxis.length + yaxis.length - BarCount)
    .map(y => y + YaxisPadding);
  if (debug)
    console.log(tag, "horizontalGridLineValues:", horizontalGridLineValues);

  const horizontalGridLines: Line[] = horizontalGridLineValues.map(y =>
    Shapes.Lines.HorizontalGridLine(y, {
      x0: xaxis[0],
      x1: xaxis[xaxis.length - 1]
    } as Shape)
  );
  if (debug) console.log(tag, "horizontalGridLines:", horizontalGridLines);

  const shapes: Shape[] = [...verticalGridLines, ...horizontalGridLines];
  if (debug) console.log(tag, "shapes:", shapes);

  const annotations: Annotation[] = flatten(
    traces.map(trace =>
      trace[Axis.X].map((x, i) => {
        if (debug) console.log(tag, "x:", x);
        return Annotations.Below({
          visible: false,
          x:
            x === 0 || !isNumber(x)
              ? 0
              : x + (x < 0 ? -annotationPadding * 1.5 : annotationPadding),
          y:
            yaxis[i] +
            (trace.marker.color === Color.Organization
              ? MagicNumber.FyComponentsSideAnnotationOrganizationPadding
              : MagicNumber.FyComponentsSideAnnotationMachinePadding),
          text: !isNumber(x) ? NoValue : `${absoluteDecimal(x)}`,
          font: {
            color: trace.marker.color
          }
        });
      })
    )
  );
  // const annotations: Annotation[] = flatten(traces.map(trace =>
  //   flatten(trace[Axis.X].map((x, i) => {
  //     if (debug) console.log(tag, 'x:', x);
  //     return Annotations.Below({
  //       visible: false,
  //       x: x === 0 || !isNumber(x) ? 0 : x + (x < 0 ? -annotationPadding * 1.5 : annotationPadding),
  //       y: yaxis[i] + (trace.marker.color === Color.Organization ?
  //         MagicNumber.FyComponentsSideAnnotationOrganizationPadding :
  //         MagicNumber.FyComponentsSideAnnotationMachinePadding),
  //       text: !isNumber(x) ? NoValue : `${absoluteDecimal(x)}`,
  //       font: {
  //         color: trace.marker.color,
  //       },
  //     }, );
  //   }))));
  if (debug) console.log(tag, "annotations:", annotations);

  const layout: Layout = {
    shapes,
    annotations,
    xaxis: {
      range,
      tickmode: "array",
      tickvals: xaxis,
      ticktext: xaxis
    }
  };

  if (debug) console.log(tag, "layout:", layout);

  return {
    data: traces.map(trace =>
      Object.assign(
        trace
        // {
        //   cornerroundness: {
        //     bottomleft: BarRounding,
        //     bottomright: BarRounding,
        //     topleft: BarRounding,
        //     topright: BarRounding,
        //   }
        // }
      )
    ),
    layout
  };
}

export function dateToPoint(trace: Trace, date: Date): Point {
  const tag: string = `dateToPoint()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "trace:", trace);
  if (debug) console.log(tag, "date:", date);
  return {
    [Axis.X]: normalizeDate(date),
    [Axis.Y]: trace[Axis.Y].find((y, i) => eqDate(trace[Axis.X][i], date))
  } as Point;
}

export function getFirstOrLastPointOfTrace(
  trace: Trace,
  last: boolean = true,
  withValue: boolean = true
): Point {
  const tag: string = `getFirstOrLastPointOfTrace()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "trace:", trace);

  const values: number[] = last ? trace[Axis.Y].reverse() : trace[Axis.Y];
  const dates: Date[] = last ? trace[Axis.X].reverse() : trace[Axis.X];
  const index: number = values.findIndex(isNumber);
  if (debug) console.log(tag, "index:", index);

  const value: number = index === -1 ? null : values[index];
  const date: Date = index === -1 ? null : dates[index];

  const point: Point = {
    [Axis.X]: date,
    [Axis.Y]: value
  };
  if (debug) console.log(tag, "point:", point);
  return point;
}

export function createAreaChart({
  kpi,
  subKpis,
  organization,
  machine
}: {
  kpi: FormattedAreaChart;
  subKpis: FormattedAreaChart[];
  organization?: boolean;
  machine?: boolean;
}) {
  const tag: string = `createAreaChart()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "kpi:", kpi);
  if (debug) console.log(tag, "subKpis:", subKpis);
  if (debug) console.log(tag, "organization:", organization);
  if (debug) console.log(tag, "machine:", machine);

  if (debug) console.log(tag, "kpi:", kpi);
  const scatter: Plot = createScatter({
    kpi: kpi.kpi,
    markers: false,
    fillAdjustment: false
  });
  if (debug) console.log(tag, "scatter:", scatter);
  scatter.data[Forecast.Organization].visible = organization;
  scatter.data[Forecast.Machine].visible = machine;

  const actual: Trace = scatter.data[Forecast.Actual];
  if (debug) console.log(tag, "actual:", actual);
  const lastActual: Point = getFirstOrLastPointOfTrace(actual);
  if (debug) console.log(tag, "lastActual:", lastActual);

  const areaChartToTrace = (
    kpi: FormattedAreaChart,
    forecast: Forecast
  ): Trace =>
    Object.assign({}, kpi.kpi[forecast], {
      name: kpi.label || kpi.middleLabel || kpi.shortLabel
    });

  const subKpiTraces: Trace[] = (organization || machine
    ? subKpis.map(kpi =>
        areaChartToTrace(
          kpi,
          organization ? Forecast.Organization : Forecast.Machine
        )
      )
    : []
  )
    .sort((t1, t2) => {
      const sum1: number = sum(t1[Axis.Y]);
      const sum2: number = sum(t2[Axis.Y]);
      return sortByNumber(sum1, sum2);
    })
    .map((trace, i) => {
      const color: string = Color[`AreaChart${i}`];
      if (debug) console.log(tag, "color:", color);
      trace = merge.recursive(
        i === 0 ? Traces.Scatter.FirstFilled : Traces.Scatter.Filled,
        trace,
        {
          fillcolor: color,
          marker: {
            color
          }
        }
      );

      // Add the last actual value as the common one from which to start the deviations.
      trace[Axis.X] = [lastActual[Axis.X], ...trace[Axis.X]];
      trace[Axis.Y] = [lastActual[Axis.Y], ...trace[Axis.Y]];
      return trace;
    });
  if (debug) console.log(tag, "subKpiTraces:", subKpiTraces);

  const data: Trace[] = [
    ...subKpiTraces,
    // Display only necessary/present traces. Sorted for correct Forecast enum order.
    ...[Forecast.Machine, Forecast.Organization, Forecast.Actual]
      .sort()
      .map(forecast => scatter.data[forecast])
  ];
  if (debug) console.log(tag, "data:", data);

  return Object.assign({}, scatter, {
    data
  });
}

export function createScatter({
  kpi,
  yaxisRange,
  markers = true,
  fillAdjustment = true,
  tooltipPosition
}: {
  kpi: FormattedKpiValues;
  yaxisRange?: number[];
  markers?: boolean;
  fillAdjustment?: boolean;
  tooltipPosition?: Date;
}): Plot {
  const tag: string = `createScatter()`;
  const debug: boolean = false;
  if (debug) console.log(tag, "kpi:", kpi);
  if (debug) console.log(tag, "yaxisRange:", yaxisRange);
  if (debug) console.log(tag, "markers:", markers);
  if (debug) console.log(tag, "fillAdjustment:", fillAdjustment);
  if (debug) console.log(tag, "tooltipPosition:", tooltipPosition);

  const actual: Trace = merge.recursive(
    true,
    kpi[Forecast.Actual],
    markers ? Traces.Scatter.LinesMarkers : Traces.Scatter.Lines,
    Traces.Scatter.Actual
  );
  if (debug) console.log(tag, "actual:", actual);

  const lastActual: Point = getFirstOrLastPointOfTrace(actual);
  if (debug) console.log(tag, "lastActual:", lastActual);

  const machine: Trace = merge.recursive(
    true,
    kpi[Forecast.Machine],
    markers ? Traces.Scatter.LinesMarkers : Traces.Scatter.Lines,
    Traces.Scatter.Machine
  );

  const organization: Trace = merge.recursive(
    true,
    createConnectedTrace(kpi[Forecast.Organization], [machine, actual]),
    markers ? Traces.Scatter.LinesMarkers : Traces.Scatter.Lines,
    Traces.Scatter.Organization
  );

  const scatter: FormattedKpiValues = {
    [Forecast.Machine]: machine,
    [Forecast.Organization]: organization
  };

  getValues(scatter).forEach(trace =>
    Object.assign(trace, {
      [Axis.X]: [lastActual[Axis.X], ...trace[Axis.X]],
      [Axis.Y]: [lastActual[Axis.Y], ...trace[Axis.Y]]
    })
  );

  const adjustment: Trace = merge.recursive(
    true,
    createAdjustmentTrace(kpi),
    Traces.Scatter.Lines,
    Traces.Scatter.Adjustment
  );

  // if (debug) console.log(tag, 'fillAdjustment:', fillAdjustment);
  // if (fillAdjustment) {
  //   scatter[Forecast.AdjustmentFill] = merge.recursive(true,
  //     createAdjustmentTraceFill(scatter[Forecast.Adjustment]),
  //     Traces.Scatter.Lines,
  //     Traces.Scatter.Adjustment,
  //     Traces.Scatter.Filled, {
  //       name: 'Adjustment Fill',
  //       fillcolor: Color.OrganizationAdjusted,
  //     });
  // }
  // if (debug) console.log(tag, 'scatter[Forecast.AdjustmentFill]:', scatter[Forecast.AdjustmentFill]);

  scatter[Forecast.Actual] = actual;
  scatter[Forecast.Adjustment] = adjustment;
  scatter[Forecast.Organization] = organization;
  if (debug) console.log(tag, "scatter:", scatter);

  const data: Trace[] = getValues(scatter);
  if (debug) console.log(tag, "data:", data);

  const xaxisRange: number[] = createDateRange(lastActual[Axis.X]);
  if (debug) console.log(tag, "xaxisRange:", xaxisRange);

  // Tested if default Plotly double click also resets the yaxis range
  // (resets only the xaxis range, so not necessary; currently messes up AreaChart).
  // if (!yaxisRange) {
  //   const values: number[] = flatten(data.map(trace => trace[Axis.Y]));
  //   if (debug) console.log(tag, 'values:', values);
  //   yaxisRange = createLargerNumberRange(values);
  // }
  // if (debug) console.log(tag, 'yaxisRange:', yaxisRange);

  const shapes: any[] = createScatterShapes(kpi, tooltipPosition);
  if (debug) console.log(tag, "shapes:", shapes);

  return {
    data,
    layout: {
      shapes,
      xaxis: {
        range: xaxisRange
      }
      // yaxis: {
      //   range: yaxisRange,
      // }
    }
  };
}

export function createZoomOut(
  date: Date,
  values: number[],
  equal: boolean = false
): Plot {
  const tag: string = "createZoomOut()";
  const debug: boolean = false;
  if (debug) console.log(tag, "date:", date);
  if (debug) console.log(tag, "values:", values);
  if (debug) console.log(tag, "equal:", equal);
  let xaxis = createDateRange(date, ActualValueCount);
  let yaxis = createLargerNumberRange(values, equal);

  const zoomOut: Plot = {
    layout: {
      xaxis: { range: xaxis },
      yaxis: { range: yaxis }
    }
  };
  if (debug) console.log(tag, "zoomOut:", zoomOut);
  return zoomOut;
}

// Adjust zoomIn range to quater datatable
export function GetZoomInRangeAlignment(
  _asOf: string,
  minDate: number,
  maxDate: number
): number[] {
  // On asOf change
  if (!_asOf) {
    return [minDate, maxDate];
  }
  let asOf = normalizeDate(monthPickerStrNonZero(_asOf));
  // Based on Quarter datatable layout
  switch (asOf.getMonth()) {
    case 0:
    case 1:
    case 2: {
      let minDate = new Date(asOf.getFullYear() - 1, 0, 1);
      let maxDate = new Date(asOf.getFullYear(), 11, 1);
      return [minDate.getTime(), maxDate.getTime()];
    }
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11: {
      let minDate = new Date(asOf.getFullYear(), 0, 1);
      let maxDate = new Date(asOf.getFullYear() + 1, 11, 1);
      return [minDate.getTime(), maxDate.getTime()];
    }
  }
  return [minDate, maxDate];
}

export function createConnectedTrace(trace: Trace, fallbacks: Trace[]): Trace {
  const tag: string = "createConnectedTrace()";
  const debug: boolean = false;
  if (debug) console.log(tag, "trace:", trace);
  if (debug) console.log(tag, "fallbacks:", fallbacks);

  const dates: Date[] = [];
  const values: number[] = [];

  trace[Axis.X].forEach((date, i) => {
    const value: number = trace[Axis.Y][i];
    if (debug) console.log(tag, "date:", date);
    if (debug) console.log(tag, "value:", value);
    const hasValue: boolean = isNumber(value);
    if (debug) console.log(tag, "hasValue:", hasValue);
    if (!hasValue) return;

    const nextDate: Date = nextMonth(date);
    const prevDate: Date = previousMonth(date);
    // if (debug) console.log(tag, 'prevDate:', prevDate);
    // if (debug) console.log(tag, 'nextDate:', nextDate);

    const prevPoint: Point = dateToPoint(trace, prevDate);
    const nextPoint: Point = dateToPoint(trace, nextDate);
    // if (debug) console.log(tag, 'prevPoint:', prevPoint);
    // if (debug) console.log(tag, 'nextPoint:', nextPoint);

    const hasPrevValue: boolean = isNumber(prevPoint[Axis.Y]);
    if (debug) console.log(tag, "hasPrevValue:", hasPrevValue);
    if (!hasPrevValue) {
      const point: Point = fallbacks
        .map(trace => dateToPoint(trace, prevDate))
        .find(point => isNumber(point[Axis.Y]));
      const prevValue: number = point && point[Axis.Y];
      if (debug) console.log(tag, "prevValue:", prevValue);
      if (isNumber(prevValue)) {
        dates.push(prevDate);
        values.push(prevValue);
      }
    }

    dates.push(date);
    values.push(value);

    const hasNextValue: boolean = isNumber(nextPoint[Axis.Y]);
    if (debug) console.log(tag, "hasNextValue:", hasNextValue);
    if (!hasNextValue) {
      const point: Point = fallbacks
        .map(trace => dateToPoint(trace, nextDate))
        .find(point => isNumber(point[Axis.Y]));
      const nextValue: number = point && point[Axis.Y];
      if (debug) console.log(tag, "nextValue:", nextValue);
      if (isNumber(nextValue)) {
        dates.push(nextDate);
        values.push(nextValue);
      }
    }
  });

  const connectedTrace: Trace = {
    [Axis.X]: dates,
    [Axis.Y]: values
  };

  if (debug) console.log(tag, "connectedTrace:", connectedTrace);
  return connectedTrace;
}

export function createAdjustmentTrace(kpi: FormattedKpiValues): Trace {
  const tag: string = "createAdjustmentTrace()";
  const debug: boolean = false;
  if (debug) console.log(tag, "kpi:", kpi);

  const machine: Trace = kpi[Forecast.Machine];
  if (debug) console.log(tag, "machine:", machine);
  const organization: Trace = kpi[Forecast.Organization];
  if (debug) console.log(tag, "organization:", organization);
  const adjustment: Trace = kpi[Forecast.Adjustment];
  if (debug) console.log(tag, "adjustment:", adjustment);

  const dates: Date[] = [];
  const values: number[] = [];

  adjustment[Axis.X].forEach((date, i) => {
    const value: number = adjustment[Axis.Y][i];
    const isAdjusted: boolean = isNumber(value);
    if (debug) console.log(tag, "date:", date);
    if (debug) console.log(tag, "value:", value);
    if (!isAdjusted) return;

    const isFirstAdjustment: boolean = adjustment[Axis.Y]
      .slice(0, i)
      .every(v => !isNumber(v));
    if (debug) console.log(tag, "isFirstAdjustment:", isFirstAdjustment);
    const isLastAdjustment: boolean = adjustment[Axis.Y]
      .slice(i + 1)
      .every(v => !isNumber(v));
    if (debug) console.log(tag, "isLastAdjustment:", isLastAdjustment);

    const isPrevAdjusted: boolean =
      !isFirstAdjustment && isNumber(adjustment[Axis.Y][i - 1]);
    if (debug) console.log(tag, "isPrevAdjusted:", isPrevAdjusted);
    const isNextAdjusted: boolean =
      !isLastAdjustment && isNumber(adjustment[Axis.X][i + 1]);

    if (!isPrevAdjusted) {
      const prevValue: number = isNumber(organization[Axis.Y][i - 1])
        ? organization[Axis.Y][i - 1]
        : machine[Axis.Y][i - 1];
      if (debug) console.log(tag, "prevValue:", prevValue);

      if (isNumber(prevValue)) {
        const prevDate: Date = previousMonth(date);
        if (debug) console.log(tag, "prevDate:", prevDate);

        dates.push(prevDate);
        values.push(prevValue);
      }
    }

    dates.push(date);
    values.push(value);

    if (debug) console.log(tag, "isNextAdjusted:", isNextAdjusted);
    if (!isNextAdjusted) {
      const nextValue: number = isNumber(organization[Axis.Y][i + 1])
        ? organization[Axis.Y][i + 1]
        : machine[Axis.Y][i + 1];
      if (debug) console.log(tag, "nextValue:", nextValue);

      if (isNumber(nextValue)) {
        const nextDate: Date = nextMonth(date);
        if (debug) console.log(tag, "nextDate:", nextDate);

        dates.push(nextDate);
        values.push(nextValue);
      }
    }
  });

  if (debug) console.log(tag, "dates:", dates);
  if (debug) console.log(tag, "values:", values);
  const adjustmentTrace: Trace = {
    [Axis.X]: dates,
    [Axis.Y]: values
  };
  if (debug) console.log(tag, "adjustmentTrace:", adjustmentTrace);
  return adjustmentTrace;
}

// export function createAdjustmentTraceFill(adjustment: Trace): Trace {
//   const tag: string = 'createAdjustmentTraceFill()';
//   const debug: boolean = false;
//   if (debug) console.log(tag, 'adjustment:', adjustment);

//     Traces.Scatter.Filled, {
//       name: 'Adjustment Fill',
//       fillcolor: Color.OrganizationAdjusted,
//     });
// }

export function createScatterShapes(
  kpi: FormattedKpiValues,
  tooltipPosition?: Date
): Shape[] {
  const tag: string = `createScatterShapes`;
  const debug: boolean = false;
  if (debug) console.log(tag, "kpi:", kpi);

  const lastActual: Point = getFirstOrLastPointOfTrace(kpi[Forecast.Actual]);
  if (debug) console.log(tag, "lastActual:", lastActual);
  const lastActualLine: Line = lastActual[Axis.X]
    ? Shapes.Lines.ActualLine(lastActual[Axis.X])
    : null;
  if (debug) console.log(tag, "lastActualLine:", lastActualLine);
  const tooltipLine: Line = tooltipPosition
    ? Shapes.Lines.TooltipLine(tooltipPosition)
    : null;

  const shapes: Shape[] = [lastActualLine, tooltipLine].filter(isPresent);
  if (debug) console.log(tag, "shapes:", shapes);
  return shapes;
}

export function getAnnotationColor(trace: Trace, index: number): string {
  const tag: string = "getAnnotationColor()";
  const debug: boolean = false;
  if (debug) console.log(tag, "trace:", trace);
  if (debug) console.log(tag, "index:", index);

  // Organization traces have color arrays, but machine traces just have one color
  // and marker.line.color has priority.
  const lineColor: string = (trace.marker.line &&
    (isArray(trace.marker.line.color)
      ? trace.marker.line.color[index]
      : trace.marker.line.color)) as string;
  const markerColor: string = (isArray(trace.marker.color)
    ? trace.marker.color[index]
    : trace.marker.color) as string;
  const color: string = lineColor || markerColor;
  if (debug) console.log(tag, "color:", color);
  return color;
}
