import { Scale } from 'src/app/constants';
import { Currency } from 'src/app/types';
import { AdditionalKpi, IAdditionalKpiLite } from '../additionalKpis/additionalKpis';

export class MoveReportingContainer {
  private static readonly Tag = 'MoveReportingContainer';
  private static readonly Debug: boolean = false;

  public tag: string = MoveReportingContainer.Tag;
  public debug: boolean = MoveReportingContainer.Debug;

  public moveReporting: IFormattedMoveReporting;

  constructor(moveReporting: IMoveReporting) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'moveReporting:', moveReporting);

    var opexGa = Object.keys(moveReporting.opexGa).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.opexGa[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.opexGa[0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    var opexBf = Object.keys(moveReporting.opexBf).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.opexBf[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.opexBf[0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    var opexMisc = Object.keys(moveReporting.opexMisc).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.opexMisc[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.opexMisc[0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    var fteGa = Object.keys(moveReporting.fteGa).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.fteGa[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.fteGa[0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    var fteBf = Object.keys(moveReporting.fteBf).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.fteBf[k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.fteBf[0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    var fteMisc = Object.keys(moveReporting.fteMisc ).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = Object.assign({}, moveReporting.fteMisc [k]);
      additionalKpi.values = ['endOfYear'].map(p => additionalKpi[p]);
      additionalKpi.unit = moveReporting.fteMisc [0].endOfYear.unit;
      formattedMoveReporting[k] = new AdditionalKpi(additionalKpi);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);
    this.moveReporting = { opexGa: opexGa, fteGa: fteGa, opexBf: opexBf,
      fteBf: fteBf, opexMisc: opexMisc, fteMisc: fteMisc} as IFormattedMoveReporting;
  }

  public displayFormat(scale: Scale, currency: Currency): IFormattedMoveReporting {
    const tag: string = `${this.tag}.displayFormat()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scale:', scale);
    if (debug) console.log(tag, 'currency:', currency);

    const opexGa: AdditionalKpi[] = Object.keys(this.moveReporting.opexGa).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.opexGa[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const opexBf: AdditionalKpi[] = Object.keys(this.moveReporting.opexBf).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.opexBf[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const opexMisc: AdditionalKpi[] = Object.keys(this.moveReporting.opexMisc).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.opexMisc[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const fteGa: AdditionalKpi[] = Object.keys(this.moveReporting.fteGa).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.fteGa[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const fteBf: AdditionalKpi[] = Object.keys(this.moveReporting.fteBf).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.fteBf[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const fteMisc: AdditionalKpi[] = Object.keys(this.moveReporting.fteMisc).reduce((formattedMoveReporting, k) => {
      const additionalKpi: AdditionalKpi = this.moveReporting.fteMisc[k];
      let usedScale: Scale = Scale.Million;

      formattedMoveReporting[k] = additionalKpi.displayFormat(usedScale, currency);
      return formattedMoveReporting;
    }, {} as AdditionalKpi[]);

    const formattedMoveReporting: IFormattedMoveReporting = {
      opexGa: opexGa,
      fteGa: fteGa,
      opexBf: opexBf,
      fteBf: fteBf,
      opexMisc: opexMisc,
      fteMisc: fteMisc
    }
    if (debug) console.log(tag, 'formattedMoveReporting', formattedMoveReporting);
    return formattedMoveReporting;
  }
}

export interface IFormattedMoveReporting {
  opexGa: AdditionalKpi[];
  fteGa:  AdditionalKpi[];
  opexBf: AdditionalKpi[];
  fteBf:  AdditionalKpi[];
  opexMisc: AdditionalKpi[];
  fteMisc:  AdditionalKpi[];
}

export interface IMoveReporting {
  opexGa: IAdditionalKpiLite[];
  fteGa:  IAdditionalKpiLite[];
  opexBf: IAdditionalKpiLite[];
  fteBf:  IAdditionalKpiLite[];
  opexMisc: IAdditionalKpiLite[];
  fteMisc:  IAdditionalKpiLite[];
}
