import { Injectable, } from '@angular/core';
import { environment } from 'src/environment/environment';

@Injectable()
export class ConfigurationService {
  private static readonly Tag: string = 'ConfigurationService';
  private readonly tag: string = ConfigurationService.Tag;

  public static readonly AnalyticsUrlStaging: string = '';
  public static readonly AnalyticsUrlProduction: string = '';
  public static readonly Integration: boolean = !!environment.integration;
  public static readonly Production: boolean = !!environment.production;
  public static readonly Development: boolean = !(ConfigurationService.Production || ConfigurationService.Integration);

  public static readonly Debug: boolean = false;
  public static readonly DebugCompare: boolean = false;

  private readonly debug: boolean = ConfigurationService.Debug;

  public readonly authEnabled: boolean = ConfigurationService.Development && false;
 // public readonly appName: string = 'myforecast_composer';

  public authServiceUrl: string;
  public backendBaseUrl: string;
  public baseUrl: string;

  constructor() {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;

    if (debug) console.log(tag, 'Integration:', ConfigurationService.Integration);
    if (debug) console.log(tag, 'Production:', ConfigurationService.Production);
    if (debug) console.log(tag, 'Development:', ConfigurationService.Development);
    if (debug) console.log(tag, 'this.authEnabled:', this.authEnabled);

    // if (ConfigurationService.Integration) {
    //   this.backendBaseUrl = `/${this.appName}`;
    //   this.baseUrl = `https://mydfs-int-app.fs.corpintra.net/${this.appName}`;
    // } else if (ConfigurationService.Production) {
    //   this.backendBaseUrl = `/${this.appName}`;
    //   this.baseUrl = `https://mydfs-app.fs.corpintra.net/${this.appName}`;
    // } else {
    //   this.backendBaseUrl = `http://localhost:5000`;
    //   this.authServiceUrl = 'http://localhost:4400/';
    //   this.baseUrl = 'http://localhost:3000';
    // }
    
    //NOTE : also fix gulp.js file and packages.json and enviroment.ts
    
    if(window.location.href.indexOf('localhost') > -1) {
      this.backendBaseUrl = `http://localhost:5000`;
      this.authServiceUrl = 'http://localhost:4400/';
      this.baseUrl = 'http://localhost:3000';
    }
    else if(window.location.href.indexOf('myfc-app-dev') > -1) {
      this.backendBaseUrl = `https://myfc-app-dev.azurewebsites.net`;
      this.baseUrl = `https://myfc-app-dev.azurewebsites.net`;
    }
    else{
      this.backendBaseUrl = `https://myforecast.fcs.i.mercedes-benz.com`;
      this.baseUrl = `https://myforecast.fcs.i.mercedes-benz.com`;
    }

    if (debug) console.log(tag, 'this.backendBaseUrl:', this.backendBaseUrl);
    if (debug) console.log(tag, 'this.authServiceUrl:', this.authServiceUrl);
    if (debug) console.log(tag, 'this.baseUrl:', this.baseUrl);
  }
}

if (ConfigurationService.Debug) console.log('environment:', environment);
