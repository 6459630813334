import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  Renderer2,
  ElementRef
} from "@angular/core";
import { PopoverDirective } from "@vcl/ng-vcl";
import { BaseComponent } from "src/app/components/common/base/base.component";
import { StateService } from "src/app/services/state/state.service";
import { ConnectedPosition } from "@angular/cdk/overlay";

@Component({
  selector: "confirmationDialog",
  templateUrl: "confirmationDialog.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class ConfirmationDialogComponent extends BaseComponent
  implements OnInit, OnChanges {
  private static readonly Tag: string = "ConfirmationDialogComponent";
  // TODO: This needs fixing (generated unique ID)?
  @ViewChild("confirmationDialogPopover")
  public readonly popover: PopoverDirective;

  @Input() public debug: boolean = false;

  @Input() public visible?: boolean = false;

  @Input() public target?: ElementRef | HTMLElement;
  @Input() public positions?: ConnectedPosition[] = [
    { originX: "end", overlayX: "end", originY: "bottom", overlayY: "bottom" }
  ];

  @Input() public title: string;
  @Input() public text: string;

  @Input() public cancelLabel: string = "Cancel";
  @Input() public cancelIcon: string = "myfc myfc-cancel";
  @Output() public readonly cancelTap: EventEmitter<void> = new EventEmitter<
    void
  >();

  @Input() public disableContinue: boolean = false;
  @Input() public continueLabel: string = "Continue";
  @Input() public continueIcon: string = "myfc myfc-apply";
  @Output() public readonly continueTap: EventEmitter<void> = new EventEmitter<
    void
  >();

  @Input() public containerClass: string;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
    private readonly renderer: Renderer2
  ) {
    super(cd, state);
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${ConfirmationDialogComponent.Tag}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    let context = this;
    setTimeout(() => (context.visible = true));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const tag: string = `${this.tag}.ngOnChanges()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "changes:", changes);
    if (debug) console.log(tag, "this:", this);
  }

  public onCancelTap(): void {
    const tag: string = `${this.tag}.onCancelTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.popover.close();
    this.cancelTap.emit();
  }

  public onContinueTap(): void {
    const tag: string = `${this.tag}.onContinueTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.popover.close();
    this.continueTap.emit();
  }

  public Toggle(): void {
    this.popover.toggle();
  }
}
