import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Page } from 'src/app/constants';

@Injectable()
export class LoginResolver implements Resolve<void> {
  private static readonly Tag: string = 'LoginResolver';
  private readonly tag: string = LoginResolver.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    public readonly state: StateService,
  ) { }

  public async resolve(): Promise<void> {
    const tag: string = `${this.tag}.resolve()`;
    const debug: boolean = this.debug || false;
    this.state.page = Page.Login;
    if (debug) console.log(tag, 'state.page:', this.state.page);
    this.load();
  }

  public async load(): Promise<void> {
    const tag: string = `${this.tag}.load()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
  }
}
