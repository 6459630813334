import { AggregatedKpi } from 'src/app/models/common/kpiAggregated';
import { KpiValuesChild } from 'src/app/models/common/kpiValues';
import { Walk } from 'src/app/models/results/walk';
import { AdjustmentHistoryContainer } from 'src/app/models/common/adjustment';
import { LastModifiedContainer } from 'src/app/models/common/lastModified';
import { AggregatedKpiMap } from './workbench.data.service';
import {
  FyForecastMap,
  FyComponentMap, WalkMap, FlagMap
} from 'src/app/services/data/common.data.service';
import { TopLevelKpi } from 'src/app/constants';
import { IStatusMessage } from 'src/app/models/common/sideBar';
import { KpiValuesQuarterContainer } from 'src/app/models/workbench/kpiValuesQuarter';
import { ScenarioContainer } from 'src/app/models/workbench/scenario';
import { NotificationContainer } from 'src/app/models/common/notification';
import { INavigationKpiTree } from 'src/app/models/workbench/navigation';
import { IPlanning } from 'src/app/models/planning/planning';

export const Default = {
  get FyComponents(): FyComponentMap {
    return {
      [TopLevelKpi.Portfolio]: [] as AggregatedKpi[],
      [TopLevelKpi.Ebit]: [] as AggregatedKpi[],
      [TopLevelKpi.SalesAndAcquisitions]: [] as AggregatedKpi[],
      [TopLevelKpi.Dividends]: [] as AggregatedKpi[],
      [TopLevelKpi.Capital]: [] as AggregatedKpi[],
    } as FyComponentMap;
  },
  get Flags(): FlagMap {
    return {};
  },
  get FyForecast(): FyForecastMap {
    return null;
  },
  get Comments(): AdjustmentHistoryContainer {
    return new AdjustmentHistoryContainer();
  },
  get LastModified(): LastModifiedContainer {
    return new LastModifiedContainer();
  },
  get Walk(): WalkMap {
    return {
      [TopLevelKpi.Portfolio]: null,
      [TopLevelKpi.Ebit]: new Walk(),
    } as WalkMap;
  },
  get Notifications(): NotificationContainer {
    return new NotificationContainer();
  },

  // Workbench
  get Ratios(): AggregatedKpi[] {
    return [] as AggregatedKpi[];
  },
  get Components(): AggregatedKpi[] {
    return [] as AggregatedKpi[];
  },
  get SubKpiFyForecast(): FyForecastMap {
    return {
      [TopLevelKpi.Portfolio]: [] as AggregatedKpi[],
      [TopLevelKpi.Ebit]: [] as AggregatedKpi[],
      [TopLevelKpi.SalesAndAcquisitions]: [] as AggregatedKpi[],
    } as FyForecastMap;
  },
  get TopLevelKpiFyForecast(): AggregatedKpiMap {
    return {
      [TopLevelKpi.Portfolio]: new AggregatedKpi(),
      [TopLevelKpi.Ebit]: new AggregatedKpi(),
      [TopLevelKpi.SalesAndAcquisitions]: new AggregatedKpi(),
    } as AggregatedKpiMap;
  },
  get MonthlyForecast(): KpiValuesQuarterContainer {
    return null;
  },
  get ScatterFyComponents(): KpiValuesChild[] {
    return null;
  },
  get Scenarios(): ScenarioContainer {
    return new ScenarioContainer();
  },
  get Scenario(): KpiValuesQuarterContainer {
    return null;
  },
  get StatusMessage(): IStatusMessage {
    return {
      message: 'None',
      subMessage: 'No message',
    } as IStatusMessage;
  },
  get Navigation(): INavigationKpiTree[] {
    return [] as INavigationKpiTree[];
  },
  get AdjustmentHistory(): AdjustmentHistoryContainer {
    return new AdjustmentHistoryContainer();
  },
  // Workbench
  // Planning
  get Planning(): IPlanning[] {
    return [] as IPlanning[];
  },
  // Planning
};
