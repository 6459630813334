import {
  Component, ChangeDetectionStrategy, OnInit, OnChanges,
  ChangeDetectorRef, ViewChild,
} from '@angular/core';
import { LayerRef } from '@vcl/ng-vcl';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { ModalService } from 'src/app/services/modal.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'confirmationLayer',
  templateUrl: 'confirmationLayer.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmationLayerComponent extends BaseComponent implements OnInit, OnChanges {
  private static readonly Tag: string = 'ConfirmationLayerComponent';

  @ViewChild('modalLayer') private readonly modalLayer: LayerRef;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly modal: ModalService,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = ConfirmationLayerComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    this.modal.confirmationLayer = this;
  }

  public open(): void {
    const tag: string = `${this.tag}.open()`;
    if (this.debug) console.log(tag);
    // this.detectChanges();
    this.modalLayer && this.modalLayer.open();
  }

  public close(): void {
    const tag: string = `${this.tag}.close()`;
    if (this.debug) console.log(tag);
    this.modalLayer && this.modalLayer.close();
  }

  public ngOnChanges(changes): void {
    const tag: string = `${this.tag}.ngOnChanges()`;
    if (this.debug) console.log(tag, 'changes:', changes);
  }
}
