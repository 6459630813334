import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from 'src/app/services/state/state.service';
import { RouterService } from 'src/app/services/router.service';
import { Page } from 'src/app/constants';

@Component({
  templateUrl: './landing.component.html',
  selector: 'landing',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LandingComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'LandingComponent';
  loading: boolean = true;
  constructor(
    public readonly state: StateService,
    private readonly router: RouterService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
    this.tag = LandingComponent.Tag;
    this.debug = ConfigurationService.Debug;
    setTimeout(() => (this.loading = false), 7000);
  }

  public ngOnInit(): void {
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
  }

  public showLoader(): boolean {
    return this.loading;
  }

  public onMyForecastBtnTap(): void {
    const tag: string = `${this.tag}.onMyForecastBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.pageVisibility[Page.Workbench]) {
      this.state.page = Page.Workbench;
      this.router.goTo(Page.Workbench);
    }
  }

  public onMyPlanningBtnTap(): void {
    const tag: string = `${this.tag}.onMyPlanningBtnTap`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.pageVisibility[Page.Planning]) {
      this.state.page = Page.Planning;
      this.router.goTo(Page.Planning);
    }
  }

  public onMySimulationBtnTap(): void {
    const tag: string = `${this.tag}.onSimulationBtnTap`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    window.location.href = 'https://mysimulation.fcs.i.mercedes-benz.com/shinyproxy/app/mysimulation';
  }


  public Page = Page;
}
