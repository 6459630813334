import { DefinedBelow } from '../app/constants';

export const Opacity = 1;

const Color: any = {
  Black: 'rgb(0, 0, 0)',
  Gray1: 'rgb(48, 48, 48)',
  Gray2: 'rgb(58, 58, 58)',
  Gray3: 'rgb(69, 69, 69)',
  Gray4: 'rgb(112, 112, 112)',
  Gray5: 'rgb(151, 151, 151)',
  Gray6: 'rgb(200, 200, 200)',
  Gray7: 'rgb(230, 230, 230)',
  Gray8: 'rgb(250, 250, 250)',

  White: 'rgb(255, 255, 255)',

  Transparent: 'rgb(255, 255, 255, 0)',

  Machine: 'rgb(112, 112, 112)',
  Organization: 'rgb(255, 192, 67)',

  OrganizationAdjusted: 'rgba(255, 192, 67, 0.25)',
  Actual: 'rgb(0, 170, 220)',

  Compare1: 'rgb(80, 227, 194)',
  Compare2: 'rgb(255, 60, 230)',
  Compare3: 'rgb(140, 0, 209)',

  AreaChart0: 'rgb(173, 205, 213)',
  AreaChart1: 'rgb(225, 224, 225)',
  AreaChart2: 'rgb(255, 228, 173)',

  Increasing: 'rgb(255, 216, 139)',
  Decreasing: 'rgb(184, 111, 23)',
};

Color.Font = Color.Black;
Color.Icon = Color.Gray4;
Color.Line = Color.Gray7;
Color.Dark = Color.Gray5;
Color.Darker = Color.Gray6;
Color.Background = Color.White;
Color.OpacityDeselected = Opacity / 2;
Color.Deselected = `rgba(255, 255, 255, ${Opacity - Color.OpacityDeselected})`;
Color.NotificationInfo = Color.Actual;
Color.Adjustment = Color.Organization;

export {
  Color
};
