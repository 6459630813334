import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { Scenario } from 'src/app/models/workbench/scenario';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'scenarioLabels',
  templateUrl: './scenarioLabels.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ScenarioLabelsComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ScenarioLabelsComponent';
  protected readonly tag: string = ScenarioLabelsComponent.Tag;
  protected readonly debug: boolean = false || ConfigurationService.DebugCompare;

  public scenarios: Scenario[] = [];

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.scenarios$.subscribe(this.onScenariosChange.bind(this)),
    ];
  }

  private onScenariosChange(scenarios: Scenario[]): void {
    const tag: string = `${this.tag}.onScenariosChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenarios:', scenarios);
    this.scenarios = scenarios;
  }

  public onCompareForecastTap(scenario: Scenario): void {
    const tag: string = `${this.tag}.onScenarioTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenario:', scenario);
    this.state.removeScenario(scenario);
  }
}
