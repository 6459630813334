import { Region } from "./region";
import { Currency } from "./currency";
import { Currency as CurrencyType } from "../../types";

export interface IOrganizationEntity {
  id: number;
  code: string;
  name: string;
  flagId: number;
  currencyId: CurrencyType;
  currency: Currency;
  showAdditionalKpi: boolean;
  disableAdditionalKpiWrite: boolean;
  disableDataWrite: boolean;
  disableDataSubmit: boolean;
  enableUnpaidReceivables: boolean;
  bulkUpdate: boolean;
  secondCurrencyId?: number;
  secondCurrency?: Currency;
}

export type OrganizationEntity = Partial<IOrganizationEntity>;

export interface FormattedRegions {
  items: FormattedRegionMap;
}

export interface FormattedRegionMap {
  [regionId: string]: FormattedRegion;
}

export interface FormattedRegion extends OrganizationEntity {
  items: FormattedCountryMap;
}

export interface FormattedCountries {
  items: FormattedCountryMap;
}

export interface FormattedCountryMap {
  [countryId: string]: FormattedCountry;
}

export interface FormattedCountry extends OrganizationEntity {
  items: FormattedOrganizationMap;
}

export interface FormattedOrganizations {
  items: FormattedOrganizationMap;
}

export interface FormattedOrganizationMap {
  [organizationId: string]: FormattedOrganization;
}

export interface FormattedOrganization extends OrganizationEntity {}

export class CountryContainer {
  private static readonly Tag = "KpiValues";

  public tag: string = CountryContainer.Tag;
  public debug: boolean = false;

  public countries: Country[];

  constructor(countries: Country[] = []) {
    this.countries = countries;
  }

  public format(): FormattedRegions {
    const tag: string = `${this.tag}.formatCountries()`;
    const debug: boolean = this.debug || false;

    const formattedRegions: FormattedRegions = this.countries.reduce(
      (formattedRegions, country: Country) => {
        const formattedRegion: FormattedRegion = (formattedRegions.items[
          country.regionId
        ] =
          formattedRegions.items[country.regionId] ||
          CountryContainer.formatRegion(country));
        if (debug) console.log(tag, "formattedRegion:", formattedRegion);

        const formattedCountry: FormattedCountry = (formattedRegion.items[
          country.id
        ] =
          formattedRegion.items[country.id] ||
          CountryContainer.formatCountry(country));
        if (debug) console.log(tag, "formattedCountry:", formattedCountry);

        return formattedRegions;
      },
      { items: {} as FormattedRegionMap } as FormattedRegions
    );
    if (debug) console.log(tag, "formattedRegions:", formattedRegions);
    return formattedRegions;
  }

  static formatRegion(country: Country): FormattedRegion {
    return {
      id: country.region.id,
      name: country.region.name,
      items: {} as FormattedCountryMap
    };
  }

  static formatCountry(country: Country): FormattedCountry {
    return {
      id: country.id,
      name: country.name,
      code: country.code,
      flagId: country.flagId,
      showAdditionalKpi: country.showAdditionalKpi,
      disableDataWrite: country.disableDataWrite,
      disableDataSubmit: country.disableDataSubmit,
      enableUnpaidReceivables: country.enableUnpaidReceivables,
      currencyId: country.currencyId,
      bulkUpdate: country.bulkUpdate,
      items: {} as FormattedOrganizationMap,
      currency: country.currency,
      secondCurrencyId: country.secondCurrencyId,
      secondCurrency: country.secondCurrency
    };
  }
}

export interface Mapping {
  id: number;
  countryId: number;
  mapping: string;
  year: number;
}

export class Country {
  public code: string;

  public currency: Currency;
  public currencyId: number;

  public disableAdditionalKpiSubmit: boolean;
  public disableAdditionalKpiWrite: boolean;
  public disableCountry: boolean;
  public disableDataSubmit: boolean;
  public disableDataWrite: boolean;
  public enableUnpaidReceivables: boolean;
  public bulkUpdate: boolean;
  public flag: string;
  public flagId: number;

  public id: number;

  public mapping: string;
  public mappings: Mapping[];

  public name: string;

  public organizationId: number;

  public region: Region;
  public regionId: number;

  public showAdditionalKpi: boolean;
  public specialCompany: boolean;
  public secondCurrencyId?: number;
  public secondCurrency?: Currency;

  constructor({
    code,

    currency,
    currencyId,

    disableAdditionalKpiSubmit,
    disableAdditionalKpiWrite,
    disableCountry,
    disableDataSubmit,
    disableDataWrite,
    enableUnpaidReceivables,
    bulkUpdate,
    flag,
    flagId,

    id,

    mapping,
    mappings,

    name,

    organizationId,

    region,
    regionId,

    showAdditionalKpi,
    specialCompany,
    secondCurrencyId,
    secondCurrency

  }: {
    code?: string;
    currency?: Currency;
    currencyId?: CurrencyType;

    disableAdditionalKpiSubmit?: boolean;
    disableAdditionalKpiWrite?: boolean;
    disableCountry?: boolean;
    disableDataSubmit?: boolean;
    disableDataWrite?: boolean;
    enableUnpaidReceivables?: boolean;
    bulkUpdate?: boolean;

    flag?: string;
    flagId?: number;

    id?: number;

    mapping?: string;
    mappings?: Mapping[];

    name?: string;

    organizationId?: number;

    region?: Region;
    regionId?: number;

    showAdditionalKpi?: boolean;
    specialCompany?: boolean;
    secondCurrency?: Currency;
    secondCurrencyId?: CurrencyType;
  } = {}) {
    this.code = code;

    this.currency = currency;
    this.currencyId = currencyId;

    this.disableAdditionalKpiSubmit = disableAdditionalKpiSubmit;
    this.disableAdditionalKpiWrite = disableAdditionalKpiWrite;
    this.disableCountry = disableCountry;
    this.disableDataSubmit = disableDataSubmit;
    this.disableDataWrite = disableDataWrite;
    this.enableUnpaidReceivables = enableUnpaidReceivables;
    this.bulkUpdate = bulkUpdate;
    this.flag = flag;
    this.flagId = flagId;

    this.id = id;

    this.mapping = mapping;
    this.mappings = mappings;

    this.name = name;

    this.organizationId = organizationId;

    this.region = region;
    this.regionId = regionId;

    this.showAdditionalKpi = showAdditionalKpi;
    this.specialCompany = specialCompany;
    this.secondCurrencyId = secondCurrencyId;
    this.secondCurrency = secondCurrency;
  }
}
