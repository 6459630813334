import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'colorLegend',
  templateUrl: './colorLegend.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ColorLegendComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'ColorLegendComponent';

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.tag = ColorLegendComponent.Tag;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;

    this.observables = [
      this.state.page$,
    ];
  }

  public get adjustmentsLegend(): boolean {
    return true;
  }

  public get actualsLegend(): boolean {
    return true;
  }

  public get organizationLegend(): boolean {
    return true;
  }

  public get machineLegend(): boolean {
    return true;
  }
}
