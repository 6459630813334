import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";
import { NotifierType } from 'src/app/constants';
import { BaseComponent } from "src/app/components/common/base/base.component";
import { NotificationService } from "src/app/services/notification.service";
import { StateService } from "src/app/services/state/state.service";
import { PopoverDirective, NotifierService } from "@vcl/ng-vcl";

export interface INotification {
  type?: NotifierType;
  title?: string;
  text?: string;
  footer?: string;
  visible?: boolean;
  positions?: any;

  isSuccess?: boolean;
  isWarning?: boolean;
  isInfo?: boolean;
  isError?: boolean;
  isDefault?: boolean;
}

@Component({
  selector: "notification",
  templateUrl: "notification.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "myfcFixed myfcAboveAbove myfcClickable"
  }
})
export class NotificationComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = "NotificationComponent";
  protected readonly tag: string = NotificationComponent.Tag;

  @ViewChild("notificationPopover")
  public readonly popover: PopoverDirective;
  @ViewChild("progressBar") public readonly progressBar: ElementRef;

  @Input() public timeout: number = 5000; // 5s;
  @Input() public debug: boolean = false;
  @Input() public debugPopover: boolean = false;
  @Input() public notification: INotification = {};

  private timeoutId: number;
  private intervalId: number;

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private notifier: NotifierService
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    // Uncomment to enable notification dialog
    this.subscriptions = [
      this.notificationService.notification$.subscribe(
        this.onNewNotification.bind(this)
      )
    ];
    this.onNewNotification();
  }

  public async onNewNotification(): Promise<void> {
    const tag: string = `${this.tag}.onNewNotification()`;
    const debug: boolean = this.debug || false;

    // if (this.notification && this.notification.visible) {
    //   this.popover.open();
    //   return;
    // }
    const notification: INotification = this.notificationService.queue.pop();
    if (debug) console.log(tag, "notification:", notification);
    if (!notification) return;

    this.notification = notification;

    const hideTime: number = new Date().getTime() + this.timeout;
    this.intervalId = window.setInterval(() => {
      const percentage: number =
        ((hideTime - new Date().getTime()) / this.timeout) * 100;
      this.progressBar.nativeElement.style.width = `${percentage}%`;
    }, 10);
    this.popover.open();

    this.timeoutId = window.setTimeout(() => {
      this.closeNotification();
    }, this.timeout);
  }

  public onCloseBtnTap(): void {
    const tag: string = `${this.tag}.onCloseBtnTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.closeNotification();
  }

  public closeNotification(): void {
    const tag: string = `${this.tag}.closeNotification()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.popover.close();
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  }
}
