import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/components/login/login/login.component';
import { LandingComponent } from 'src/app/components/landing/landing/landing.component';
import { PlanningComponent } from 'src/app/components/planning/planning/planning.component';
import { WorkbenchComponent } from 'src/app/components/workbench/workbench/workbench.component';
import { LoginResolver } from 'src/app/resolvers/login.resolver';
import { WorkbenchResolver } from 'src/app/resolvers/workbench.resolver';
import { LandingResolver } from 'src/app/resolvers/landing.resolver';
import { PlanningResolver } from 'src/app/resolvers/planning.resolver';
import { MasterGuard } from 'src/app/guards/master.guard';
import { Default } from 'src/app/services/state/defaults';
import { Page } from 'src/app/constants';
import { ConfigurationService } from 'src/app/services/configuration.service';

const tag: string = 'app.routes.ts';
const debug: boolean = true;

export const DefaultRoute: string = Page[Default.Page].toLowerCase();
if (debug) console.log(tag, 'DefaultRoute:', DefaultRoute);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
    canActivate: [MasterGuard],
  },
  {
    path: 'workbench',
    component: WorkbenchComponent,
    canActivate: [MasterGuard],
    resolve: {
      data: WorkbenchResolver,
    },
  },
  {
    path: 'planning',
    component: PlanningComponent,
    canActivate: [MasterGuard],
    resolve: {
      data: PlanningResolver,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      data: LoginResolver,
    },
  },
  {
    path: 'landing',
    component: LandingComponent,
    resolve: {
      data: LandingResolver,
    },
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];
if (debug) console.log(tag, 'routes:', routes);

const enableTracing: boolean = ConfigurationService.Debug && false;
if (debug) console.log(tag, 'enableTracing:', enableTracing);
export const Routing = RouterModule.forRoot(routes, { enableTracing });
