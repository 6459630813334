import { Injectable } from "@angular/core";
import {
  Page,
} from "src/app/constants";
import {
  Api,
  Controller,
  Planning,
  Notification
} from "src/app/api";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { StateService } from "src/app/services/state/state.service";
import { RequestService } from "src/app/services/request.service";
import { DataService, ParameterHandler } from "./data.service";
import { AggregatedKpi } from "src/app/models/common/kpiAggregated";
import { ModalService } from "src/app/services/modal.service";
import { HttpResponse } from '@angular/common/http';
import { IPlanningGroup, IPlanOpexAndIt, IPlanFteAndPersonnel, IPlanEquityAndInsurance, 
  IPlanPLAndBalanceSheet, IPlanMarketAndRisk, IPlan, IType, IPlanEquity, IPlanItAndProjects, IPlanDigitalKpis } from 'src/app/models/planning/planning';
import { monthPickerStrNonZero } from 'src/app/utils';
import { IStatusMessage } from "src/app/models/common/sideBar";
import { Default } from "src/app/services/data/defaults";

export interface AggregatedKpiMap {
  [topLevelKpiEnum: string]: AggregatedKpi;
}

@Injectable()
export class PlanningDataService extends DataService {
  protected static readonly Tag: string = "PlanningDataService";
  protected readonly tag: string = PlanningDataService.Tag;
  protected readonly debug: boolean = false;
  protected readonly page: Page = Page.Planning;

  public bulkAdjustments: any[] = [];

  private readonly handlers: ParameterHandler[] = [
    {
      name: "setUpPlanningStatusMessage",
      change:
        Api[Controller.Notification].endpoints[Notification.PlanningStatusMessage]
          .parameters,
      requests: [this.setUpPlanningStatusMessage.bind(this)]
    },
    {
      name: "setUpTypes",
      change: Api[Controller.Planning].endpoints[Planning.Types].parameters,
      requests: [this.setUpTypes.bind(this)]
    },
    {
      name: "setUpPlanning",
      change: Api[Controller.Planning].endpoints[Planning.Composer].parameters,
      requests: [this.setUpPlanning.bind(this)]
    },
    {
      name: "setUpMarketAndRiskData",
      change: Api[Controller.Planning].endpoints[Planning.MarketAndRiskData].parameters,
      requests: [this.setUpMarketAndRiskData.bind(this)]
    },
    {
      name: "setUpPlAndBalanceSheetData",
      change: Api[Controller.Planning].endpoints[Planning.PlAndBalanceSheetData].parameters,
      requests: [this.setUpPlAndBalanceSheetData.bind(this)]
    },
    {
      name: "setUpEquityAndInsurance",
      change: Api[Controller.Planning].endpoints[Planning.EquityAndInsurance].parameters,
      requests: [this.setUpEquityAndInsurance.bind(this)]
    },
    {
      name: "setUpOpexAndIt",
      change: Api[Controller.Planning].endpoints[Planning.OpexAndIt].parameters,
      requests: [this.setUpOpexAndIt.bind(this)]
    },
    {
      name: "setUpFteAndPersonnel",
      change: Api[Controller.Planning].endpoints[Planning.FteAndPersonnel].parameters,
      requests: [this.setUpFteAndPersonnel.bind(this)]
    },
    {
      name: "setUpItAndProjects",
      change: Api[Controller.Planning].endpoints[Planning.ItAndProjects].parameters,
      requests: [this.setUpItAndProjects.bind(this)]
    },
    {
      name: "setUpDigitalKpis",
      change: Api[Controller.Planning].endpoints[Planning.DigitalKpis].parameters,
      requests: [this.setUpDigitalKpis.bind(this)]
    },
    {
      name: "setUpEquity",
      change: Api[Controller.Planning].endpoints[Planning.Equity].parameters,
      requests: [this.setUpEquity.bind(this)]
    },
    {
      name: "setUpPlans",
      change: Api[Controller.Planning].endpoints[Planning.Plans].parameters,
      requests: [this.setUpPlans.bind(this)]
    },
  ];

  private readonly _planningStatusMessage$: BehaviorSubject<
    IStatusMessage
  > = new BehaviorSubject<IStatusMessage>(Default.StatusMessage);
  public readonly planningStatusMessage$: Observable<
    IStatusMessage
  > = this._planningStatusMessage$.asObservable();

  private readonly _types$: BehaviorSubject<IType[]> = new BehaviorSubject<IType[]>([]);
  public readonly types$: Observable<IType[]> = this._types$.asObservable();

  private readonly _marketAndRiskData$: BehaviorSubject<IPlanMarketAndRisk> = new BehaviorSubject<IPlanMarketAndRisk>(null);
  public readonly marketAndRiskData$: Observable<IPlanMarketAndRisk> = this._marketAndRiskData$.asObservable();

  private readonly _plAndBalanceSheetData$: BehaviorSubject<IPlanPLAndBalanceSheet> = new BehaviorSubject<IPlanPLAndBalanceSheet>(null);
  public readonly plAndBalanceSheetData$: Observable<IPlanPLAndBalanceSheet> = this._plAndBalanceSheetData$.asObservable();

  private readonly _equityAndInsurance$: BehaviorSubject<IPlanEquityAndInsurance> = new BehaviorSubject<IPlanEquityAndInsurance>(null);
  public readonly equityAndInsurance$: Observable<IPlanEquityAndInsurance> = this._equityAndInsurance$.asObservable();

  private readonly _opexAndIt$: BehaviorSubject<IPlanOpexAndIt> = new BehaviorSubject<IPlanOpexAndIt>(null);
  public readonly opexAndIt$: Observable<IPlanOpexAndIt> = this._opexAndIt$.asObservable();

  private readonly _fteAndPersonnel$: BehaviorSubject<IPlanFteAndPersonnel> = new BehaviorSubject<IPlanFteAndPersonnel>(null);
  public readonly fteAndPersonnel$: Observable<IPlanFteAndPersonnel> = this._fteAndPersonnel$.asObservable();

  private readonly _equity$: BehaviorSubject<IPlanEquity> = new BehaviorSubject<IPlanEquity>(null);
  public readonly equity$: Observable<IPlanEquity> = this._equity$.asObservable();

  private readonly _itAndProjects$: BehaviorSubject<IPlanItAndProjects> = new BehaviorSubject<IPlanItAndProjects>(null);
  public readonly itAndProjects$: Observable<IPlanItAndProjects> = this._itAndProjects$.asObservable();

  private readonly _digitalKpis$: BehaviorSubject<IPlanDigitalKpis> = new BehaviorSubject<IPlanDigitalKpis>(null);
  public readonly digitalKpis$: Observable<IPlanDigitalKpis> = this._digitalKpis$.asObservable();

  private readonly _plans$: BehaviorSubject<IPlan[]> = new BehaviorSubject<IPlan[]>([]);
  public readonly plans$: Observable<IPlan[]> = this._plans$.asObservable();

  constructor(
    protected readonly state: StateService,
    protected readonly modal: ModalService,
    private readonly request: RequestService,
  ) {
    super(state, modal);
    this.setUpHandlers(this.handlers);
  }

  public async reload(currentType: number = 0): Promise <void> {
    const tag: string = `${this.tag}.reload()`;
    const debug: boolean = this.debug || false;
    await this.setUpTypes(currentType);
    await this.setUpPlanning();
    await this.setUpPlans();
    await this.setUpPlanningStatusMessage();
  }

  public async setUpPlans(): Promise<void> {
    const tag: string = `${this.tag}.setUpPlans()`;
    const debug: boolean = this.debug || false;
    let plans: IPlan[] = (await this.getPlans()).body;
    let planDates: IPlan[] = [];
    plans.forEach(element => {
      planDates.push({date: new Date(element.date), label: element.label});
    });
    if (debug) console.log(tag, "plans:", plans);
    this.state.datePickerDate = planDates[0].date;
    this._plans$.next(planDates);
  }

  // [HttpGet("Plans/{countryId}/{typeId}")]
  public async getPlans({
    organization = this.state.organizationEntity.id,
    typeId = this.state.type
  }: {
    organization?: number;
    typeId?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getPlans()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.Plans].endpoint
    }/${organization}/${typeId}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpPlanning(): Promise<void> {
    const tag: string = `${this.tag}.setUpPlanning()`;
    const debug: boolean = this.debug || false;
    this.setUpMarketAndRiskData();
    this.setUpPlAndBalanceSheetData();
    this.setUpEquityAndInsurance();
    this.setUpOpexAndIt();
    this.setUpFteAndPersonnel();
    this.setUpDigitalKpis();
    this.setUpEquity();
    this.setUpItAndProjects();
  }

  public async setUpTypes(currentType: number = 0): Promise<void> {
    const tag: string = `${this.tag}.setUpTypes()`;
    const debug: boolean = this.debug || false;
    let types: IType[] = (await this.getTypes()).body;
    if (debug) console.log(tag, "types:", types);
    if (currentType == 0 || this.state.type == null || this.state.type == 0) {
      this.state.type = types[0].id;
    }
    this._types$.next(types);
  }

  // [HttpGet("Types/{countryId}")]
  public async getTypes({
    organization = this.state.organizationEntity.id
  }: {
    organization?: number;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getTypes()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.Types].endpoint
    }/${organization}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  // // [HttpGet("Latest/{countryId}/{typeId}")]
  // private async getPlanning({
  //   organization = this.state.organizationEntity.id,
  //   typeId = this.state.type
  // }: {
  //   organization?: number;
  //   typeId?: number;
  // } = {}): Promise<HttpResponse<any>> {
  //   const tag: string = `${this.tag}.getPlanning()`;
  //   const debug: boolean = this.debug || false;
  //   const url: string = `${
  //     Api[Controller.Planning].endpoints[Planning.Latest].endpoint
  //   }/${organization}/${typeId}`;
  //   if (debug) console.log(tag, "url:", url);
  //   return this.request.get(url);
  // }

  public async setUpItAndProjects(): Promise<void> {
    const tag: string = `${this.tag}.setUpItAndProjects()`;
    const debug: boolean = this.debug || false;
    const itAndProjects: IPlanItAndProjects = (await this.getItAndProjects()).body;
    if (debug) console.log(tag, "itAndProjects:", itAndProjects);
    this._itAndProjects$.next(itAndProjects);
  }

  // [HttpGet("ItAndProjects/{countryId}/{asOf}")]
  private async getItAndProjects({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getItAndProjects()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.ItAndProjects].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpEquity(): Promise<void> {
    const tag: string = `${this.tag}.setUpEquity()`;
    const debug: boolean = this.debug || false;
    const equity: IPlanEquity = (await this.getEquity()).body;
    if (debug) console.log(tag, "equity:", equity);
    this._equity$.next(equity);
  }

  // [HttpGet("Equity/{countryId}/{asOf}")]
  private async getEquity({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getEquity()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.Equity].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpDigitalKpis(): Promise<void> {
    const tag: string = `${this.tag}.setUpDigitalKpis()`;
    const debug: boolean = this.debug || false;
    const digitalKpis: IPlanDigitalKpis = (await this.getDigitalKpis()).body;
    if (debug) console.log(tag, "digitalKpis:", digitalKpis);
    this._digitalKpis$.next(digitalKpis);
  }

  // [HttpGet("DigitalKpis/{countryId}/{asOf}")]
  private async getDigitalKpis({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getDigitalKpis()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.DigitalKpis].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpMarketAndRiskData(): Promise<void> {
    const tag: string = `${this.tag}.setUpMarketAndRiskData()`;
    const debug: boolean = this.debug || false;
    const marketAndRiskData: IPlanMarketAndRisk = (await this.getMarketAndRiskData()).body;
    if (debug) console.log(tag, "marketAndRiskData:", marketAndRiskData);
    this._marketAndRiskData$.next(marketAndRiskData);
  }

  // [HttpGet("MarketAndRiskData/{countryId}/{asOf}")]
  private async getMarketAndRiskData({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getMarketAndRiskData()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.MarketAndRiskData].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpPlAndBalanceSheetData(): Promise<void> {
    const tag: string = `${this.tag}.setUpPlAndBalanceSheetData()`;
    const debug: boolean = this.debug || false;
    const plAndBalanceSheetData: IPlanPLAndBalanceSheet = (await this.getPlAndBalanceSheetData()).body;
    if (debug) console.log(tag, "plAndBalanceSheetData:", plAndBalanceSheetData);
    this._plAndBalanceSheetData$.next(plAndBalanceSheetData);
  }

  // [HttpGet("PlAndBalanceSheetData/{countryId}/{asOf}")]
  private async getPlAndBalanceSheetData({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getPlAndBalanceSheetData()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.PlAndBalanceSheetData].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpEquityAndInsurance(): Promise<void> {
    const tag: string = `${this.tag}.setUpEquityAndInsurance()`;
    const debug: boolean = this.debug || false;
    const equityAndInsurance: IPlanEquityAndInsurance = (await this.getEquityAndInsurance()).body;
    if (debug) console.log(tag, "equityAndInsurance:", equityAndInsurance);
    this._equityAndInsurance$.next(equityAndInsurance);
  }

  // [HttpGet("EquityAndInsurance/{countryId}/{asOf}")]
  private async getEquityAndInsurance({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getEquityAndInsurance()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.EquityAndInsurance].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpOpexAndIt(): Promise<void> {
    const tag: string = `${this.tag}.setUpOpexAndIt()`;
    const debug: boolean = this.debug || false;
    const opexAndIt: IPlanOpexAndIt = (await this.getOpexAndIt()).body;
    if (debug) console.log(tag, "opexAndIt:", opexAndIt);
    this._opexAndIt$.next(opexAndIt);
  }

  // [HttpGet("OpexAndIt/{countryId}/{asOf}")]
  private async getOpexAndIt({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getOpexAndIt()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.OpexAndIt].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  public async setUpFteAndPersonnel(): Promise<void> {
    const tag: string = `${this.tag}.setUpFteAndPersonnel()`;
    const debug: boolean = this.debug || false;
    const fteAndPersonnel: IPlanFteAndPersonnel = (await this.getFteAndPersonnel()).body;
    if (debug) console.log(tag, "fteAndPersonnel:", fteAndPersonnel);
    this._fteAndPersonnel$.next(fteAndPersonnel);
  }

  // [HttpGet("FteAndPersonnel/{countryId}/{asOf}")]
  private async getFteAndPersonnel({
    organization = this.state.organizationEntity.id,
    asOf = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOf?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getFteAndPersonnel()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Planning].endpoints[Planning.FteAndPersonnel].endpoint
    }/${organization}/${monthPickerStrNonZero(asOf)}`;
    if (debug) console.log(tag, "url:", url);
    return this.request.get(url);
  }

  /* Controller.Notification*/
  public async setUpPlanningStatusMessage(): Promise<void> {
    const tag: string = `${this.tag}.setUpPlanningStatusMessage()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    if (this.state.page !== Page.Planning) return;

    const planningStatusMessage: IStatusMessage = (await this.getPlanningStatusMessage())
      .body;
    if (debug)
      console.log(tag, "planningStatusMessage:", planningStatusMessage);
    this._planningStatusMessage$.next(planningStatusMessage);
  }
  // [HttpGet("notification/statusMessage/{countryId}/{asOf}")]
  private async getPlanningStatusMessage({
    organization = this.state.organizationEntity.id,
    asOfMonth = this.state.datePickerDateAsOf
  }: {
    organization?: number;
    asOfMonth?: string;
  } = {}): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.getPlanningStatusMessage()`;
    const debug: boolean = this.debug || false;
    const url: string = `${
      Api[Controller.Notification].endpoints[Notification.PlanningStatusMessage]
        .endpoint
    }/${organization}/${monthPickerStrNonZero(asOfMonth)}`;
    if (debug) console.log(tag, "url", url);
    return this.request.get(url);
  }
  /* Controller.Notification*/
}
