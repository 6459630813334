import * as merge from 'merge';
import {
    Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChild, Input, ElementRef
} from '@angular/core';
import { NotifierType } from 'src/app/constants';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AdjustmentButton, AdjustmentButtons, Adjustment } from 'src/app/constants';
import { ConfirmationDialogComponent } from 'src/app/components/common/elements/confirmationDialog/confirmationDialog.component';
import { UserSettingsService } from 'src/app/services/userSettings.service';

@Component({
    selector: 'focusReportingAdjustmentButtons',
    templateUrl: 'focusReportingAdjustmentButtons.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class FocusReportingAdjustmentButtonsComponent extends BaseComponent implements OnInit {
    private static readonly Tag: string = 'FocusReportingAdjustmentButtonsComponent';
    protected readonly debug: boolean = false;
    protected readonly tag: string = FocusReportingAdjustmentButtonsComponent.Tag;

    public readonly AdjustmentButtons = merge.recursive(true, AdjustmentButtons, {
        [AdjustmentButton.UndoAll]: {
            action: this.undoAllAction,
        },
        [AdjustmentButton.UndoLast]: {
            action: this.undoLastAction,
        },
        [AdjustmentButton.Save]: {
            action: this.saveAction,
        },
    });

    @ViewChild('adjustmentConfirmationDialog') private readonly confirmationDialog: ConfirmationDialogComponent;
    @ViewChild('undoAllBtn') undoAllBtnEl: ElementRef;
    @ViewChild('undoLastBtn') undoLastEl: ElementRef;
    @ViewChild('saveBtn') saveEl: ElementRef;
    public readonly debugConfirmationDialog: boolean = false;

    @Input() public comment: string;
    @Input() public adjustments: Adjustment[];

    public button: AdjustmentButton;

    constructor(
        protected readonly cd: ChangeDetectorRef,
        public readonly state: StateService,
        private readonly workbench: WorkbenchDataService,
        public readonly user: UserSettingsService,
        private readonly notification: NotificationService,
    ) {
        super(cd, state);
        const tag: string = `${this.tag}.constructor()`;
        if (this.debug) console.log(tag, 'FocusReportingAdjustmentButtons:', this.AdjustmentButtons);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (this.debug) console.log(`${this.tag}.ngOnInit()`);
    }

    public async onBtnTap(button: AdjustmentButton): Promise<void> {
        const tag: string = `${this.tag}.onBtnTap()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'button:', AdjustmentButton[button]);
        this.button = button;
        if (debug) console.log(tag, ' this.confirmationDialog:', this.confirmationDialog);
        if (button == AdjustmentButton.UndoAll) {
            this.confirmationDialog.target = this.undoAllBtnEl;
        }
        else if (button == AdjustmentButton.UndoLast) {
            this.confirmationDialog.target = this.undoLastEl;
        }
        else if (button == AdjustmentButton.Save) {
            this.confirmationDialog.target = this.saveEl;
        }
        this.confirmationDialog.title = AdjustmentButtons[button].title;
        this.confirmationDialog.text = AdjustmentButtons[button].confirmationText;
        const skipConfirmationDialog: boolean = this.user.getSkipConfirmationDialog(AdjustmentButton[button]);
        if (debug) console.log(tag, 'skipConfirmationDialog:', skipConfirmationDialog);
        if (skipConfirmationDialog) {
            this.onConfirmationDialogContinueTap();
        } else {
            this.confirmationDialog.Toggle();
        }
    }

    public onConfirmationDialogContinueTap(): void {
        const tag: string = `${this.tag}.onConfirmationDialogContinueTap()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag);
        this.AdjustmentButtons[this.button].action.call(this);
        this.button = null;
    }

    public onConfirmationDialogCancelTap(): void {
        const tag: string = `${this.tag}.onConfirmationDialogCancelTap()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag);
        this.button = null;
    }

    private async undoAllAction(sync: boolean = false): Promise<void> {
        const tag: string = `${this.tag}.undoAllAction()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'this.adjustments', this.adjustments);
        if (!this.adjustments.length) return;

        try {
            await this.workbench.undoAllAdjustments(this.adjustments, sync);

            this.notification.add({
                type: NotifierType.Success,
                text: 'Adjustments have been reset',
            });
        } catch (e) {
            this.notification.add({
                type: NotifierType.Error,
                text: 'Resetting adjustments failed',
            });
            return;
        }

        await this.reload();
    }

    private async undoLastAction(): Promise<void> {
        const tag: string = `${this.tag}.undoLastAction()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'this.adjustments', this.adjustments);
        await this.workbench.undoLastAdjustment(this.adjustments, this.adjustments[0]);
        await this.reload();
    }

    private async saveAction(): Promise<void> {
        const tag: string = `${this.tag}.saveAction()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'this.adjustments', this.adjustments);

        try {
            await this.workbench.applyAdjustments(this.adjustments, this.comment);

            this.notification.add({
                type: NotifierType.Success,
                text: 'Adjustments saved successfully',
            });

            this.clearComment();
        } catch (e) {
            this.notification.add({
                type: NotifierType.Error,
                text: 'Saving adjustments failed',
            });

            return;
        }

        await Promise.all([
            this.reload(),
            this.workbench.setUpAdjustmentHistory(),
        ]);
    }


    private clearComment(): void {
        const tag: string = `${this.tag}.clearComment()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag);
        this.comment = '';
    }

    private async reload() {
        const tag: string = `${this.tag}.reload()`;
        if (this.debug) console.log(tag);
        await Promise.all([
            this.workbench.setUpFocusReporting(),
        ]);
    }

    public async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy();
        const tag: string = `${this.tag}.ngOnDestroy()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag);
        await this.undoAllAction(true);
    }

    public readonly AdjustmentButton = AdjustmentButton;
}
