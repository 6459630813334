import {
    Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService, OrganizationEntity } from 'src/app/services/state/state.service';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { ForYearAcronym, KpiValue, Adjustment, AdjustmentTab } from 'src/app/constants';
import {
    AdditionalKpiContainer, IFormattedAdditionalKpis, AdditionalKpi
} from 'src/app/models/additionalKpis/additionalKpis';
import {
    isNumber, sortAdjustments, isAdjustedRemoveComma, dateToMonthPickerStr,
    createDate, removeCommaSeparatorNum, removeExtraSigns, getUsedKpiValue, getYearShort, isAfterMarch
} from 'src/app/utils';
import { Kpi } from 'src/app/models/common/kpiValues';
import { browser } from 'src/app/app.component';

@Component({
    selector: 'additionalKpi',
    templateUrl: 'additionalKpi.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class AdditionalKpiComponent extends BaseComponent implements OnInit {
    private static readonly Tag: string = 'AdditionalKpiComponent';
    protected readonly tag: string = AdditionalKpiComponent.Tag;
    protected readonly debug: boolean = false;

    private readonly debugInputEvents: boolean = false;

    public disableWrite: boolean;
    public adjustments: Adjustment[] = [];

    public years: number[];

    public isSingleColumn: boolean = false;

    public readonly Italic: string[] = [
        'insuranceIncome', 'insuranceOpex',
    ];

    public leftColumnKpis: AdditionalKpi[][] = [];
    private readonly LeftColumn: string[][] = [
        ['allOtherAssets'], ['totalRevenue'], ['insuranceIncome', 'insuranceOpex', 'ebitInsuranceBusiness'],
    ];

    constructor(
        protected readonly cd: ChangeDetectorRef,
        public readonly state: StateService,
        private readonly workbench: WorkbenchDataService,
    ) {
        super(cd, state);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        const tag: string = `${this.tag}.ngOnInit()`;
        if (this.debug) console.log(tag);

        this.subscriptions = [
            this.workbench.additionalKpis$.subscribe(this.onAdditionalKpiChange.bind(this)),
            this.state.organizationEntity$.subscribe(this.onOrganizationChange.bind(this))
        ];
    }

    private onAdditionalKpiChange(additionalKpisContainer: AdditionalKpiContainer): void {
        const tag: string = `${this.tag}.onAdditionalKpiChange()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'additionalKpisContainer', additionalKpisContainer);
        if (!additionalKpisContainer) return;

        const additionalKpis: IFormattedAdditionalKpis = additionalKpisContainer.displayFormat(null, this.state.currency);
        this.isSingleColumn = !isAfterMarch(this.state.datePickerDate);
        if (!isAfterMarch(this.state.datePickerDate)) Object.keys(additionalKpis).forEach(k => additionalKpis[k].values.pop());

        if (debug) console.log(tag, 'additionalKpis', additionalKpis);
        Object.keys(additionalKpis).forEach(k => Object.assign(additionalKpis[k], {
            isItalic: this.Italic.includes(k),
        }));

        const years: number[] = additionalKpis.insuranceIncome.values.map(kpi => getYearShort(createDate(kpi.date)));
        if (debug) console.log(tag, 'years', years);

        const leftColumnKpis: AdditionalKpi[][] = this.LeftColumn.reduce((leftColumnKpis, kpiGroup) => {
            const kpis: AdditionalKpi[] = kpiGroup.map(kpi => additionalKpis[kpi]);
            return [...leftColumnKpis, kpis];
        }, [] as AdditionalKpi[][]);

        if (debug) console.log(tag, 'leftColumnKpis', leftColumnKpis);

        const adjustments = this.getAdditionalKpiValues(additionalKpis)
            .filter(kpi => isAdjustedRemoveComma(kpi) && kpi.id)
            .sort(sortAdjustments)
            .reverse()
            .map(kpi => ({
                forecastId: kpi.id,
                perMonth: dateToMonthPickerStr(createDate(kpi.date)),
                adjustValue: removeCommaSeparatorNum(removeExtraSigns(kpi.values[KpiValue.Adjusted])),
            }));
        if (debug) console.log(tag, 'adjustments:', adjustments);

        Object.assign(this, {
            years,
            leftColumnKpis,
            adjustments,
        });

        this.setUnsavedAdjustments();
        this.setLoadingStatus();
    }

    private getAdditionalKpiValues(additionalKpis: IFormattedAdditionalKpis, onlyAdjustable: boolean = false): Kpi[] {
        const tag: string = `${this.tag}.getAdditionalKpiValues()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'additionalKpis:', additionalKpis);
        if (debug) console.log(tag, 'onlyAdjustable:', onlyAdjustable);

        const kpis: Kpi[] = Object.keys(additionalKpis).reduce((kpis, k) =>
            [...kpis, ...additionalKpis[k].values.filter(kpi => kpi.isAdjustable || !onlyAdjustable)], []);
        if (debug) console.log(tag, 'kpis:', kpis);
        return kpis;
    }

    public onAdjustmentClick(kpi: Kpi, value: number): void {
        const tag: string = `${this.tag}.onAdjustmentClick()`;
        const debug: boolean = this.debugInputEvents || false;
        if (debug) console.log(tag, 'kpi:', kpi);
        if (debug) console.log(tag, 'value:', value);
    }

    public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
        const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
        const debug: boolean = this.debugInputEvents || false;
        if (debug) console.log(tag, 'value:', value);

        event = event || window.event;
        const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
        const char: string = String.fromCharCode(charCode);
        if (debug) console.log(tag, 'char:', char);
        const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
        if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

        if (!isNumber(char) && !isValidSymbol) return false;
        return true;
    }

    public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
        const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
        const debug: boolean = this.debugInputEvents || false;
        if (debug) console.log(tag, 'kpi:', kpi);
        if (debug) console.log(tag, 'value:', value);

        if (!this.state.unsavedAdjustments) {
            const machine: string = kpi.values[KpiValue.Machine];
            const isMachine: boolean = isNumber(machine);
            const editedMachine: boolean = isMachine && machine != value;

            const organization: string = kpi.values[KpiValue.Organization];
            const isOrganization: boolean = isNumber(organization);
            const editedOrganization: boolean = isOrganization && organization != value;

            const newValue: boolean = !(editedMachine || editedOrganization);

            if (editedMachine || editedOrganization || newValue) {
                this.state.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi] = true;
            }
        }
        if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
    }

    public async onKeyUpEnter(kpi: Kpi, value: string): Promise<void> {
        const tag: string = `${this.tag}.onKeyUpEnter()`;
        const debug: boolean = this.debugInputEvents || false;
        if (debug) console.log(tag, 'kpi:', kpi);
        if (debug) console.log(tag, 'value:', value);
        if (browser.name === 'ie') await this.onAdjustmentChange(kpi, value);
    }

    public async onAdjustmentChange(kpi: Kpi, value: string = null): Promise<void> {
        const tag: string = `${this.tag}.onAdjustmentChange()`;
        const debug: boolean = this.debug || false;
        if (debug) console.log(tag, 'kpi:', kpi);
        if (debug) console.log(tag, 'value:', value);
        const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
        if (debug) console.log(tag, 'formattedValue:', formattedValue);
        await this.workbench.postAdditionalKpiAdjustment({ id: kpi.id, value: formattedValue });
        await this.reload();
        if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
    }

    private onOrganizationChange(organization: OrganizationEntity): void {
        this.disableWrite = (organization.disableAdditionalKpiWrite || organization.disableDataWrite) && !this.state.userHasAdminAccess;
    }

    private clearAdjustments(): void {
        const tag: string = `${this.tag}.clearAdjustments()`;
        if (this.debug) console.log(tag);
        this.adjustments = [];
        this.setUnsavedAdjustments();
    }

    private async reload() {
        const tag: string = `${this.tag}.reload()`;
        if (this.debug) console.log(tag);
        await Promise.all([
            this.workbench.setUpAdditionalKpis(),
        ]);
    }

    private setUnsavedAdjustments(): void {
        const tag: string = `${this.tag}.setUnsavedAdjustments()`;
        if (this.debug) console.log(tag);
        this.state.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi] = this.adjustments.length > 0;
    }

    public readonly ForYearAcronym = ForYearAcronym;
    public readonly KpiValue = KpiValue;
    public readonly getUsedKpiValue = getUsedKpiValue;
}
