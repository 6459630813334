import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService, MaxScenarios } from 'src/app/services/state/state.service';
import {
  array, flatten, eqDate, getScenarioId, getScenario,
} from 'src/app/utils';
import { Color } from 'src/styles/color';
import { ScenarioContainer, ScenarioMonth, Scenario, } from 'src/app/models/workbench/scenario';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

export const MaxExpandedMonths: number = 2;
export const YearsBackAvailable: number = 4;

export const getCompareYears = (year: number, allowedYearsBack: number = YearsBackAvailable) =>
  array(allowedYearsBack + 1).map((_, i) => year - i).reverse();

@Component({
  selector: 'compare',
  templateUrl: './compare.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompareComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'CompareComponent';
  protected readonly tag: string = CompareComponent.Tag;
  protected readonly debug: boolean = false || ConfigurationService.DebugCompare;

  public readonly colors: string[] = [Color.Compare1, Color.Compare2, Color.Compare3].slice(0, MaxScenarios);
  public readonly availableColors: boolean[] = this.colors.map(_ => true);

  public readonly debugPopover: boolean = false;
  public expanded: boolean = true;

  public selectedYear: number = this.state.datePickerDate.getFullYear();

  public years: number[] = getCompareYears(this.selectedYear);

  public allMonths: ScenarioMonth[];
  public months: ScenarioMonth[] = [];
  public expandedMonths: ScenarioMonth[] = [];

  private scenarioIdToColor: { [id: number]: string } = {};

  private get scenarios(): Scenario[] {
    const scenariosArr: Scenario[][] = this.months.map(m => m.scenarios);
    return flatten(scenariosArr);
  }

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    private readonly workbench: WorkbenchDataService,
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.workbench.scenarios$.subscribe(this.onScenariosChange.bind(this)),
      this.state.addScenario$.subscribe(this.onAddScenario.bind(this)),
      this.state.removeScenario$.subscribe(this.onRemoveScenario.bind(this)),
      this.state.datePickerDate$.subscribe(this.onRequestDateChange.bind(this)),
    ];
  }

  public onScenariosChange(scenarioContainer: ScenarioContainer): void {
    const tag: string = `${this.tag}.onScenariosChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenarioContainer:', scenarioContainer);
    const selectedDate: Date = this.state.datePickerDate;
    // if (debug) console.log(tag, 'selectedDate:', selectedDate);
    const selectedYear: number = selectedDate.getFullYear();
    if (debug) console.log(tag, 'selectedYear:', selectedYear);
    const allMonths: ScenarioMonth[] = scenarioContainer.format(this.state.user.user.name).filter(m => m.scenarios.length);
    if (debug) console.log(tag, 'allMonths:', allMonths);
    const months: ScenarioMonth[] = this.filterMonths(selectedYear, allMonths);
    if (debug) console.log(tag, 'months:', months);
    const currentMonth: ScenarioMonth = months.find(month => eqDate(month.date, selectedDate));
    if (debug) console.log(tag, 'currentMonth:', currentMonth);
    if (currentMonth) this.expandMonth(currentMonth);

    Object.assign(this, {
      selectedYear,
      allMonths,
      months,
    });
  }

  public onAddScenario(scenario: Scenario): void {
    const tag: string = `${this.tag}.onAddScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenario:', scenario);
    const id: number | string = getScenarioId(scenario);
    if (debug) console.log(tag, `this.scenarioIdToColor[${id}]:`, this.scenarioIdToColor[id]);
    if (this.scenarioIdToColor[id]) return;

    const index: number = this.availableColors.findIndex(available => available);
    if (debug) console.log(tag, 'index:', index);
    if (index === -1) return;

    const scenarios: Scenario[] = this.scenarios;
    if (debug) console.log(tag, 'scenarios:', scenarios);
    const s: Scenario = getScenario(scenarios, scenario);
    if (debug) console.log(tag, 's:', s);
    if (!s) return;

    s.color = this.scenarioIdToColor[id] = this.colors[index];
    this.availableColors[index] = false;
  }

  public onRemoveScenario(scenario: Scenario): void {
    const tag: string = `${this.tag}.removeScenario()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenario:', scenario);
    const id: number | string = getScenarioId(scenario);
    if (debug) console.log(tag, `this.scenarioIdToColor[${id}]:`, this.scenarioIdToColor[id]);
    const index: number = this.colors.indexOf(this.scenarioIdToColor[id]);
    if (debug) console.log(tag, 'index:', index);
    if (index === -1) return;

    const scenarios: Scenario[] = this.scenarios;
    if (debug) console.log(tag, 'scenarios:', scenarios);
    const s: Scenario = getScenario(scenarios, scenario);
    if (debug) console.log(tag, 's:', s);
    if (!s) return;

    delete s.color;
    delete this.scenarioIdToColor[id];
    this.availableColors[index] = true;
  }


  public onRequestDateChange(date: Date): void {
    const tag: string = `${this.tag}.onRequestDateChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'date:', date);

    const currentMonth: ScenarioMonth = this.filterMonths().find(month => eqDate(month.date, date));
    if (debug) console.log(tag, 'currentMonth:', currentMonth);
    const selectedYear: number = date.getFullYear();
    if (debug) console.log(tag, 'selectedYear:', selectedYear);
    const months: ScenarioMonth[] = this.filterMonths(selectedYear);
    if (debug) console.log(tag, 'months:', months);
    const expandedMonths: ScenarioMonth[] = currentMonth ? [currentMonth] : [];
    if (debug) console.log(tag, 'expandedMonths:', expandedMonths);

    Object.assign(this, {
      months,
      selectedYear,
      expandedMonths,
    });
  }

  public onYearTap(year: number): void {
    const tag: string = `${this.tag}.onYearTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'year:', year);
    const months: ScenarioMonth[] = this.filterMonths(year);
    if (debug) console.log(tag, 'months:', months);
    Object.assign(this, {
      selectedYear: year,
      months,
    });
  }

  public filterMonths(year: number = this.selectedYear, allMonths = this.allMonths): ScenarioMonth[] {
    const tag: string = `${this.tag}.onYearTap()`;
    const debug: boolean = false;
    if (debug) console.log(tag, 'year:', year);
    if (debug) console.log(tag, 'allMonths:', allMonths);
    const months: ScenarioMonth[] = allMonths.filter(month => month.date.getFullYear() === year);
    if (debug) console.log(tag, 'months:', months);
    return months;
  }

  public onMonthTap(month: ScenarioMonth): void {
    const tag: string = `${this.tag}.onMonthTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'month:', month);
    event.stopPropagation();
    // this.detectChanges();
    if (debug) console.log(tag, 'this.expandedMonths:', this.expandedMonths);
    this.expandedMonths.includes(month) ? this.collapseMonth(month) : this.expandMonth(month);
  }

  private collapseMonth(month: ScenarioMonth): void {
    const tag: string = `${this.tag}.collapseMonth()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'month:', month);
    this.expandedMonths = this.expandedMonths.filter(m => m !== month);
  }

  private expandMonth(month: ScenarioMonth): void {
    const tag: string = `${this.tag}.onMonthTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'month:', month);
    if (this.expandedMonths.includes(month)) return;

    if (this.expandedMonths.length === MaxExpandedMonths) this.expandedMonths.shift();

    this.expandedMonths.push(month);
  }

  public onCompareForecastTap(scenario: Scenario, month: ScenarioMonth): void {
    const tag: string = `${this.tag}.onCompareForecastTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'scenario:', scenario);
    const isSelectedScenario: boolean = this.state.scenario && this.state.scenario.guid === scenario.guid;
    if (debug) console.log(tag, 'isSelectedScenario:', isSelectedScenario);
    if (isSelectedScenario) return;

    if (scenario.color) return this.state.removeScenario(scenario);

    const index: number = this.availableColors.findIndex(available => available);
    if (debug) console.log(tag, 'index:', index);
    if (index === -1) return;

    this.state.addScenario(scenario);
  }

  public readonly eqDate = eqDate;
}
