import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { Notification, NotificationContainer } from 'src/app/models/common/notification';
import { StateService } from 'src/app/services/state/state.service';
import { CommonDataService } from 'src/app/services/data/common.data.service';
import { LayerRef, PopoverDirective } from '@vcl/ng-vcl';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NotificationsComponent extends BaseComponent {
  private static readonly Tag: string = 'NotificationsComponent';
  protected readonly tag: string = NotificationsComponent.Tag;
  protected readonly debug: boolean = false;
  public readonly debugPopover: boolean = false;

  @ViewChild('notificationLayer') private readonly layer: LayerRef;
  @ViewChild('notificationsPopover') private readonly popover: PopoverDirective;
  public unreadNotifications: boolean = false;
  public notification: Notification;
  public notifications: Notification[] = [];

  constructor(
    public readonly state: StateService,
    protected readonly cd: ChangeDetectorRef,
    private readonly data: CommonDataService,
  ) {
    super(cd, state);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.data.notifications$.subscribe(this.onNotificationsChange.bind(this)),
    ];
  }

  private onNotificationsChange(notificationContainer: NotificationContainer): void {
    const tag: string = `${this.tag}.onNotificationsChange()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'notificationContainer:', notificationContainer);
    this.notifications = notificationContainer.notifications;
    if (debug) console.log(tag, 'this.notifications:', this.notifications);
    this.setUnreadNotifications();
  }

  private setUnreadNotifications(): void {
    const tag: string = `${this.tag}.setUnreadNotifications()`;
    const debug: boolean = this.debug || false;
    this.unreadNotifications = this.notifications.some(n => !n.isRead);
  }

  public onNotificationTap(notification: Notification, index: number): void {
    const tag: string = `${this.tag}.onNotificationTap()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'notification:', notification);
    this.notification = notification;

    if (!notification.isRead) {
      this.data.hasReadNotification({ id: notification.id });
      // TODO: Check whether change detection got triggered.
      this.notifications[index] = Object.assign({}, notification, {
        isRead: true,
      });
      this.setUnreadNotifications();
    }

    this.layer.open();
  }

  public onNotificationClose(event: Event): void {
    const tag: string = `${this.tag}.onNotificationClose()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    event.stopImmediatePropagation();
    this.popover.close();
    this.layer.close();
    this.notification = null;
  }

  public onOffClick(): void {
    const tag: string = `${this.tag}.onOffClick()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    this.layer.close();
    this.popover.close();
    this.notification = null;
  }
}
