import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { eqDate } from 'src/app/utils';

export const Transition = {
  transition: {
    duration: 500,
    easing: 'cubic-in-out'
  }
};

@Injectable()
export class PlotService {
  private static readonly Tag: string = 'PlotService';
  private readonly tag: string = PlotService.Tag;
  private readonly debug: boolean = false;

  private readonly _hoverPosition$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  public readonly hoverPosition$: Observable<Date> = this._hoverPosition$.asObservable();
  public get hoverPosition(): Date {
    return this._hoverPosition$.value;
  }
  public set hoverPosition(hoverPosition: Date) {
    const tag: string = `${this.tag}.hoverPosition:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'this.tooltipPosition:', this.tooltipPosition);
    if (debug) console.log(tag, 'this.showValues:', this.showValues);
    if (this.showValues || this.tooltipPosition || eqDate(hoverPosition, this.hoverPosition)) return;

    if (debug) console.log(tag, 'hoverPosition:', hoverPosition);
    this._hoverPosition$.next(hoverPosition);
  }

  private readonly _tooltipPosition$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  public readonly tooltipPosition$: Observable<Date> = this._tooltipPosition$.asObservable();
  public get tooltipPosition(): Date {
    return this._tooltipPosition$.value;
  }
  public set tooltipPosition(tooltipPosition: Date) {
    const tag: string = `${this.tag}.tooltipPosition:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'tooltipPosition:', tooltipPosition);
    if (tooltipPosition) {
      this.showValues = false;
      this.hoverPosition = null;
    }

    if (eqDate(tooltipPosition, this.tooltipPosition)) return;
    this._tooltipPosition$.next(tooltipPosition);
  }

  private readonly _isZoomedOut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isZoomedOut$: Observable<boolean> = this._isZoomedOut$.asObservable();
  public get isZoomedOut(): boolean {
    return this._isZoomedOut$.value;
  }
  public set isZoomedOut(isZoomedOut: boolean) {
    const tag: string = `${this.tag}.isZoomedOut:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'isZoomedOut:', isZoomedOut);
    if (isZoomedOut === this.isZoomedOut) return;
    this._isZoomedOut$.next(isZoomedOut);
  }

  private readonly _showValues$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly showValues$: Observable<boolean> = this._showValues$.asObservable();
  public get showValues(): boolean {
    return this._showValues$.value;
  }
  public set showValues(showValues: boolean) {
    const tag: string = `${this.tag}.showValues:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'showValues:', showValues);
    if (showValues && this.tooltipPosition) {
      this.hoverPosition = null;
      this.tooltipPosition = null;
    }
    if (showValues === this.showValues) return;
    this._showValues$.next(showValues);
  }
}
