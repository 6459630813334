import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable()
export class WindowService {
  private static readonly Tag: string = 'WindowService';
  private readonly tag: string = WindowService.Tag;
  private readonly debug: boolean = ConfigurationService.Debug && false;

  constructor(
  ) {
    const debug: boolean = this.debug || false;
    const tag: string = `${this.tag}.constructor()`;
    if (debug) console.log(tag);
  }


  private readonly _isMenuExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly isMenuExpanded$: Observable<boolean> = this._isMenuExpanded$.asObservable();
  public get isMenuExpanded() {
    const debug: boolean = this.debug || false;
    const isMenuExpanded: boolean = this._isMenuExpanded$.value;
    if (debug) console.log(`${this.tag} get isMenuExpanded:`, isMenuExpanded);
    return isMenuExpanded;
  }
  public set isMenuExpanded(isMenuExpanded: boolean) {
    if (this.debug) console.log(`${this.tag} set isMenuExpanded:`, isMenuExpanded);
    if (isMenuExpanded === this.isMenuExpanded) return;
    this._isMenuExpanded$.next(isMenuExpanded);
  }

  private readonly _isSmallScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isSmallScreen$: Observable<boolean> = this._isSmallScreen$.asObservable();
  public get isSmallScreen() {
    const debug: boolean = this.debug || false;
    const isSmallScreen: boolean = this._isSmallScreen$.value;
    if (debug) console.log(`${this.tag} get isSmallScreen:`, isSmallScreen);
    return isSmallScreen;
  }
  public set isSmallScreen(isSmallScreen: boolean) {
    if (this.debug) console.log(`${this.tag} set isSmallScreen:`, isSmallScreen);
    if (isSmallScreen === this.isSmallScreen) return;
    this._isSmallScreen$.next(isSmallScreen);
  }


}

