import { Injectable } from "@angular/core";
import {
  Subject,
  BehaviorSubject,
  Observable,
  Subscription,
  merge
} from "rxjs";
import { skip } from "rxjs/operators";
import { MonthPickerComponent } from "src/app/components/common/elements/monthPicker/monthPicker.component";
import { Currency } from "src/app/types";
import { Default, AdjustmentMap } from "./defaults";
import {
  Page,
  AdjustmentTab,
  Unit,
  UnitToLabel,
  Portfolio,
  Ebit,
  TopLevelKpi,
  SalesAndAcquisitions,
  Dividends,
  Capital,
} from "src/app/constants";
import {
  isPresent,
  eqDate,
  gtDate,
  getYearShort,
  dateToMonthPickerStr,
  getValues,
  getScenario,
  previousMonth,
  ltDate
} from "src/app/utils";
import { ModalService } from "src/app/services/modal.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import {
  FormattedRegions,
  FormattedRegion,
  FormattedCountry,
  FormattedOrganization
} from "src/app/models/common/country";
import { ImportData } from "src/app/models/common/importData";
import { AppComponent } from "src/app/app.component";
import { PageVisibility } from "src/app/services/data/common.data.service";
import { AdjustmentButtonsComponent } from "src/app/components/workbench/adjustmentButtons/adjustmentButtons.component";
import {
  UserSettingsService,
  UnsavedAdjustment
} from "src/app/services/userSettings.service";
import { Scenario } from "src/app/models/workbench/scenario";
import { Parameter } from "src/app/api";
import { INavigationKpiTree } from "src/app/models/workbench/navigation";
import { User } from "src/app/models/common/user";

export type OrganizationEntity =
  | FormattedOrganization
  | FormattedCountry
  | FormattedRegion;

export const MaxScenarios: number = 3;

@Injectable()
export class StateService {
  private static readonly Tag: string = "StateService";
  private readonly tag: string = StateService.Tag;

  // Output debug information.
  private readonly debug: boolean = false;

  // Keep references to the service subscriptions.
  private subscriptions: Subscription[];

  public readonly now: Date = Default.Now;
  public readonly previousMonth: Date = previousMonth(this.now);
  public readonly minMonthPickerDate: Date = Default.MinMonthPickerDate;
  public readonly maxMonthPickerDate: Date = Default.MaxMonthPickerDate;

  // Set in the datePicker.component.ngAfterViewInit(). Used to undo the month selection
  // after the user clicks 'Cancel' in the modal.unsavedAdjustments() prompt.
  public datePicker: MonthPickerComponent;

  // Set in the adjustments.component.ngAfterViewInit(). Used to undo the adjustments
  // after the user clicks 'Continue' in the modal.unsavedAdjustments() prompt.
  public adjustments: AdjustmentButtonsComponent;

  // Set in the app.component.ngOnInit(). Used to undo call refreshApplication()
  // in the homeButton.component.
  public app: AppComponent;

  // Currently not used. // Set when the new page has been resolved and navigated to.
  public resolved: boolean = true;

  // Used in analysis.page.component to show or hide the image on page changes.
  public firstLoad: boolean = true;

  // Set in data.service.setUpUser() during initialize.guard.canActivate().
  public user: User;

  // Used in analysis page to show or hide notification side bar
  public showSideBar: boolean = true;

  // Bulk adjustments - optional for country based
  public get BulkAdjustment(): boolean {
    return this.country.bulkUpdate;
  }

  private readonly _topLevelKpi$: BehaviorSubject<
    TopLevelKpi
  > = new BehaviorSubject<TopLevelKpi>(Default.TopLevelKpi);
  public readonly topLevelKpi$: Observable<
    TopLevelKpi
  > = this._topLevelKpi$.asObservable();
  public get topLevelKpi(): TopLevelKpi {
    return this._topLevelKpi$.value;
  }
  public set topLevelKpi(topLevelKpi: TopLevelKpi) {
    if (this.debug) console.log(`${this.tag}.topLevelKpi:`, topLevelKpi);
    if (topLevelKpi === this.topLevelKpi) return;
    this._topLevelKpi$.next(topLevelKpi);
  }

  public setReadonly(): boolean {
    const tag: string = `${this.tag}.setReadonly()`;
    const debug: boolean = this.debug || false;

    if (debug) console.log(tag, "state.writeAccess:", this.writeAccess);
    if (!this.writeAccess) return (this.isReadonly = true);

    if (debug) console.log(tag, "this.scenario:", this.scenario);
    if (this.scenario && this.scenario.readOnly)
      return (this.isReadonly = true);

    const kpi: INavigationKpiTree = this.kpi;
    if (debug) console.log(tag, "kpi:", kpi);
    const isUnadjustable: boolean = !(
      kpi &&
      kpi.kpi.kpiType &&
      kpi.kpi.kpiType.adjustable
    );
    if (debug) console.log(tag, "isUnadjustable:", isUnadjustable);
    if (isUnadjustable) return (this.isReadonly = true);

    if (debug)
      console.log(
        tag,
        "this.dateRestrictionDisabled:",
        this.dateRestrictionDisabled
      );
    if (!this.dateRestrictionDisabled) {
      const isUnadjustableMonth: boolean = this.isUnadjustableMonth();
      if (debug) console.log(tag, "isUnadjustableMonth:", isUnadjustableMonth);
      if (isUnadjustableMonth) return (this.isReadonly = true);
    }

    return (this.isReadonly = false);
  }

  public isUnadjustableMonth(): boolean {
    return ltDate(this.datePickerDate, this.now);
  }

  // Set in the dataTable.component to determine whether the current kpi is adjustable or not.
  private readonly _isReadonly$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(Default.IsReadonly);
  public readonly isReadonly$: Observable<
    boolean
  > = this._isReadonly$.asObservable();
  public get isReadonly(): boolean {
    return this._isReadonly$.value;
  }
  public set isReadonly(isReadonly: boolean) {
    if (this.debug) console.log(`${this.tag}.isReadonly:`, isReadonly);
    if (isReadonly === this.isReadonly) return;
    this._isReadonly$.next(isReadonly);
  }

  private readonly _excelImportEnabled$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.ExcelImportEnabled);
  public readonly excelImportEnabled$: Observable<
    boolean
  > = this._excelImportEnabled$.asObservable();
  public get excelImportEnabled(): boolean {
    return this._excelImportEnabled$.value;
  }
  public set excelImportEnabled(excelImportEnabled: boolean) {
    if (this.debug)
      console.log(`${this.tag}.excelImportEnabled:`, excelImportEnabled);
    if (excelImportEnabled === this.excelImportEnabled) return;
    this._excelImportEnabled$.next(excelImportEnabled);
  }

  private readonly _unsavedAdjustments$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.UnsavedAdjustments);
  public readonly unsavedAdjustments$: Observable<
    boolean
  > = this._unsavedAdjustments$.asObservable();
  public get unsavedAdjustments(): boolean {
    const tag: string = `${this.tag}.unsavedAdjustments()`;
    const debug: boolean = this.debug && false;
    if (debug)
      console.log(
        tag,
        "this.unsavedAdjustmentsMap:",
        this.unsavedAdjustmentsMap
      );
    const unsavedAdjustments: boolean = getValues(
      this.unsavedAdjustmentsMap
    ).some(v => v);
    if (debug) console.log(tag, "unsavedAdjustments:", unsavedAdjustments);
    return unsavedAdjustments;
  }

  private readonly _unsavedAdjustmentsMap$: BehaviorSubject<
    AdjustmentMap
  > = new BehaviorSubject<AdjustmentMap>(Default.UnsavedAdjustmentsMap);
  public readonly unsavedAdjustmentsMap$: Observable<
    AdjustmentMap
  > = this._unsavedAdjustmentsMap$.asObservable();
  public get unsavedAdjustmentsMap(): AdjustmentMap {
    return this._unsavedAdjustmentsMap$.value;
  }
  public set unsavedAdjustmentsMap(unsavedAdjustmentsMap: AdjustmentMap) {
    if (this.debug)
      console.log(`${this.tag}.unsavedAdjustmentsMap:`, unsavedAdjustmentsMap);
    if (unsavedAdjustmentsMap === this.unsavedAdjustmentsMap) return;

    const prevUnsavedAdjustments: boolean = this.unsavedAdjustments;
    this._unsavedAdjustmentsMap$.next(unsavedAdjustmentsMap);
    const newUnsavedAdjustments: boolean = this.unsavedAdjustments;
    if (prevUnsavedAdjustments !== newUnsavedAdjustments)
      this._unsavedAdjustments$.next(this.unsavedAdjustments);
  }

  // Set in the additionalKpis.page.component.ngAfterViewInit(). Used to undo the adjustments
  // after the user clicks 'Continue' in the modal.unsavedAdjustments() prompt.
  // TODO:
  // public additionalKpiPage: AdditionalKpisPageComponent;

  // Set in the requests.page.component after all the resolvers (except the new page's resolver)
  // have been resolved. Used so the back end caches the required data for the initial setup
  // (organization, date, kpi, etc) and in-between page changes are faster.
  private readonly _initialized$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.Initialized);
  public readonly initialized$: Observable<
    boolean
  > = this._initialized$.asObservable();
  public get initialized(): boolean {
    return this._initialized$.value;
  }
  public set initialized(initialized: boolean) {
    if (this.debug) console.log(`${this.tag}.initialized:`, initialized);
    if (initialized === this.initialized) return;
    this._initialized$.next(initialized);
  }

  // Set in data.service.setUpUserAdminAccess() during initialize.guard.canActivate().
  private readonly _userHasAdminAccess$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.UserHasAdminAccess);
  public readonly userHasAdminAccess$: Observable<
    boolean
  > = this._userHasAdminAccess$.asObservable();
  public get userHasAdminAccess() {
    return this._userHasAdminAccess$.value;
  }
  public set userHasAdminAccess(userHasAdminAccess: boolean) {
    if (this.debug)
      console.log(`${this.tag}.userHasAdminAccess:`, userHasAdminAccess);
    if (userHasAdminAccess === this.userHasAdminAccess) return;
    this._userHasAdminAccess$.next(userHasAdminAccess);
  }

  // Set in data.service.setUpOrganizationAccess() during initialize.guard.canActivate().
  private readonly _userHasWriteAccess$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.UserHasWriteAccess);
  public readonly userHasWriteAccess$: Observable<
    boolean
  > = this._userHasWriteAccess$.asObservable();
  public get userHasWriteAccess() {
    return this._userHasWriteAccess$.value;
  }
  public set userHasWriteAccess(userHasWriteAccess: boolean) {
    const debug: boolean = this.debug && false;
    if (debug)
      console.log(`${this.tag}.userHasWriteAccess:`, userHasWriteAccess);
    if (this.userHasWriteAccess === userHasWriteAccess) return;
    this._userHasWriteAccess$.next(userHasWriteAccess);
  }

  // Set in data.service.setUpApplicationAccess() during initialize.guard.canActivate().
  private readonly _allowWriteAccessToApplication$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.AllowWriteAccessToApplication);
  public readonly allowWriteAccessToApplication$: Observable<
    boolean
  > = this._allowWriteAccessToApplication$.asObservable();
  public get allowWriteAccessToApplication() {
    return this._allowWriteAccessToApplication$.value;
  }
  public set allowWriteAccessToApplication(
    allowWriteAccessToApplication: boolean
  ) {
    const debug: boolean = this.debug && false;
    if (debug)
      console.log(
        `${this.tag}.allowWriteAccessToApplication:`,
        allowWriteAccessToApplication
      );
    if (allowWriteAccessToApplication === this.allowWriteAccessToApplication)
      return;
    this._allowWriteAccessToApplication$.next(allowWriteAccessToApplication);
  }

  private readonly _dateRestrictionDisabled$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.DateRestrictionDisabled);
  public readonly dateRestrictionDisabled$: Observable<
    boolean
  > = this._dateRestrictionDisabled$.asObservable();
  public get dateRestrictionDisabled() {
    return this._dateRestrictionDisabled$.value;
  }
  public set dateRestrictionDisabled(dateRestrictionDisabled: boolean) {
    if (this.debug)
      console.log(
        `${this.tag}.dateRestrictionDisabled:`,
        dateRestrictionDisabled
      );
    if (dateRestrictionDisabled === this.dateRestrictionDisabled) return;
    this._dateRestrictionDisabled$.next(dateRestrictionDisabled);
  }

  private readonly _powerBiUrl$: BehaviorSubject<
    string
  > = new BehaviorSubject<string>(Default.PowerBiUrl);
  public readonly powerBiUrl$: Observable<
    string
  > = this._powerBiUrl$.asObservable();
  public get powerBiUrl() {
    return this._powerBiUrl$.value;
  }
  public set powerBiUrl(powerBiUrl: string) {
    if (this.debug)
      console.log(
        `${this.tag}.powerBiUrl:`,
        powerBiUrl
      );
    if (powerBiUrl === this.powerBiUrl) return;
    this._powerBiUrl$.next(powerBiUrl);
  }

  private readonly _appliedAdjustmentsEnabled$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.AppliedAdjustmentsEnabled);
  public readonly appliedAdjustmentsEnabled$: Observable<
    boolean
  > = this._appliedAdjustmentsEnabled$.asObservable();
  public get appliedAdjustmentsEnabled() {
    return this._appliedAdjustmentsEnabled$.value;
  }
  public set appliedAdjustmentsEnabled(appliedAdjustmentsEnabled: boolean) {
    if (this.debug)
      console.log(
        `${this.tag}.appliedAdjustmentsEnabled:`,
        appliedAdjustmentsEnabled
      );
    if (appliedAdjustmentsEnabled === this.appliedAdjustmentsEnabled) return;
    this._appliedAdjustmentsEnabled$.next(appliedAdjustmentsEnabled);
  }

  public get canAdjust() {
    const tag: string = `${this.tag}.canAdjust`;
    const debug: boolean = this.debug && false;

    const validDate: boolean =
      this.currentMonthSelected || this.dateRestrictionDisabled;
    if (debug)
      console.log(tag, "currentMonthSelected:", this.currentMonthSelected);
    if (debug)
      console.log(
        tag,
        "dateRestrictionDisabled:",
        this.dateRestrictionDisabled
      );
    if (debug) console.log(tag, "validDate:", validDate);

    const canAdjust: boolean = this.kpi && !this.isReadonly && validDate;
    if (debug) console.log(tag, "kpi:", this.kpi);
    if (debug) console.log(tag, "writeAccess:", this.writeAccess);
    if (debug) console.log(tag, "canAdjust:", canAdjust);

    return canAdjust;
  }

  constructor(
    private readonly modal: ModalService,
    public readonly userSettings: UserSettingsService,
    private readonly configuration: ConfigurationService
  ) {
    const tag: string = `${this.tag}.constructor()`;
    const debug: boolean = this.debug || false;

    const anyParameterChangeObservables$: Observable<any>[] = [
      this.kpi$,
      this.organizationEntity$,
      this.currency$,
      this.datePickerDate$,
      this.scenario$
    ];
    const anyParameterChange$: Observable<null> = merge(
      ...anyParameterChangeObservables$
    ).pipe(skip(anyParameterChangeObservables$.length));

    const writeAccessObservables$: Observable<boolean>[] = [
      this.allowWriteAccessToApplication$,
      this.userHasWriteAccess$
    ];
    const writeAccessChange$: Observable<boolean> = merge(
      ...writeAccessObservables$
    ).pipe(skip(writeAccessObservables$.length));

    this.subscriptions = [
      anyParameterChange$.subscribe(() => this._anyParameterChange$.next(null)),
      writeAccessChange$.subscribe(this.onWriteAccessChange.bind(this))
    ];
  }

  private onKpiChange(): void {
    const tag: string = `${this.tag}.onKpiChange()`;
    const debug: boolean = this.debug || false;

    this.kpi = this.getKpi();
    if (debug) console.log(tag, "kpi:", this.kpi);

    this.topLevelKpi =
      this.kpi0 && this.kpi0.kpi.kpiTypeId === Portfolio.kpi.kpiTypeId
        ? TopLevelKpi.Portfolio
        : this.kpi0 && this.kpi0.kpi.kpiTypeId === Ebit.kpi.kpiTypeId
        ? TopLevelKpi.Ebit
        : this.kpi0 && this.kpi0.kpi.kpiTypeId === SalesAndAcquisitions.kpi.kpiTypeId
        ? TopLevelKpi.SalesAndAcquisitions
        : this.kpi0 && this.kpi0.kpi.kpiTypeId === Dividends.kpi.kpiTypeId
        ? TopLevelKpi.Dividends
        : this.kpi0 && this.kpi0.kpi.kpiTypeId === Capital.kpi.kpiTypeId
        ? TopLevelKpi.Capital
        : null;
    if (debug)
      console.log(tag, "this.topLevelKpi:", TopLevelKpi[this.topLevelKpi]);

    this.setReadonly();

    // Un-comment to reset the topLevelKpiComparison.component's
    // selected month on kpi change as well.
    // this.resetTopLevelStackedBarActualEffectMonthSelection();
  }

  private onWriteAccessChange(): void {
    const tag: string = `${this.tag}.onWriteAccessChange()`;
    this.writeAccess =
      this.allowWriteAccessToApplication && this.userHasWriteAccess;
    this.setReadonly();
  }

  private readonly _page$: BehaviorSubject<Page> = new BehaviorSubject<Page>(
    Default.Page
  );
  public readonly page$: Observable<Page> = this._page$.asObservable();
  public get page() {
    return this._page$.value;
  }
  public set page(page: Page) {
    const tag: string = `${this.tag}.page:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, Page[page]);
    if (page === this.page) return;

    const pageVisible: boolean =
      this.pageVisibility[page] || page === Page.Login || page === Page.Loading || page === Page.Landing;
    if (debug) console.log(tag, "pageVisible:", pageVisible);
    if (!pageVisible) return;

    this.showUnsavedAdjustmentsModal(() => {
      this._page$.next(page);
    });
  }

  private async showUnsavedAdjustmentsModal(
    action: Function,
    cancel?: Function
  ): Promise<void> {
    const tag: string = `${this.tag}.showUnsavedAdjustmentsModal()`;
    const debug: boolean = this.debug || false;
    const canAdjust: boolean = this.canAdjust;
    if (debug) console.log(tag, "canAdjust:", canAdjust);

    const adjustedWorkbench: boolean =
      this.page === Page.Workbench &&
      this.unsavedAdjustmentsMap[AdjustmentTab.Workbench];
    const adjustedAdditionalKpis: boolean =
      this.page === Page.Workbench &&
      this.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi];
    const adjusted: boolean = adjustedWorkbench || adjustedAdditionalKpis;
    if (debug) console.log(tag, "adjustedWorkbench:", adjustedWorkbench);
    if (debug)
      console.log(tag, "adjustedAdditionalKpis:", adjustedAdditionalKpis);
    if (debug) console.log(tag, "adjusted:", adjusted);

    const skipUnsavedAdjustmentsModal: boolean = this.userSettings.getSkipConfirmationDialog(
      UnsavedAdjustment
    );
    if (debug) console.log(tag, "skipUnsavedAdjustmentsModal:", adjusted);

    const showUnsavedAdjustmentsModal: boolean =
      !skipUnsavedAdjustmentsModal && canAdjust && adjusted;
    if (debug)
      console.log(
        tag,
        "showUnsavedAdjustmentsModal:",
        showUnsavedAdjustmentsModal
      );

    if (showUnsavedAdjustmentsModal) {
      this.modal.unsavedAdjustments(async () => {
        await this.clearAdjustments();
        return action();
      }, cancel);
    } else {
      return action();
    }
  }

  public async clearAdjustments(sync: boolean = true) {
    const tag: string = `${this.tag}.clearAdjustments()`;
    const debug: boolean = this.debug || false;
    this.unsavedAdjustmentsMap = Default.UnsavedAdjustmentsMap;
    await this.adjustments.undoAllAction(sync, false);
  }

  public setDefaultsForPage(page: Page = this.page): void {
    switch (page) {
      case Page.Planning:
        return this.setDefaultPlanning();
      case Page.Workbench:
        return this.setDefaultWorkbench();
    }
  }

  public setDefaultsForAllPages(): void {
    if (this.debug) console.log(`${this.tag}.setDefaultsForAllPages()`);
    this.setDefaultPlanning();
    this.setDefaultWorkbench();
  }

  public setDefaultWorkbench(): void {
    if (this.debug) console.log(`${this.tag}.setDefaultWorkbench()`);
    //
  }

  public setDefaultPlanning(): void {
    if (this.debug) console.log(`${this.tag}.setDefaultPlanning()`);
    //
  }

  private readonly _writeAccess$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.HasWriteAccess);
  public readonly writeAccess$: Observable<
    boolean
  > = this._writeAccess$.asObservable();
  public get writeAccess() {
    return this._writeAccess$.value;
  }
  public set writeAccess(writeAccess: boolean) {
    if (this.debug) console.log(`${this.tag}.writeAccess:`, writeAccess);
    if (writeAccess === this.writeAccess) return;
    this._writeAccess$.next(writeAccess);
  }

  private readonly _kpi0$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi0);
  public readonly kpi0$: Observable<
    INavigationKpiTree
  > = this._kpi0$.asObservable();
  public get kpi0() {
    return this._kpi0$.value;
  }
  public set kpi0(kpi0: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi0:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi0:", kpi0);
    // Trigger lower kpi reset.
    this.kpi1 = Default.Kpi1;
    if (this.kpi0 === kpi0) return;
    this._kpi0$.next(kpi0);
  }
  // Resets the kpi selection to the top level kpi.
  public setTopLevelKpi(): void {
    if (this.debug) console.log(`${this.tag}.setTopLevelKpi()`);
    this.setKpi({ kpi0: this.kpi0 });
  }
  private readonly _kpi1$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi1);
  public readonly kpi1$: Observable<
    INavigationKpiTree
  > = this._kpi1$.asObservable();
  public get kpi1() {
    return this._kpi1$.value;
  }
  public set kpi1(kpi1: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi1:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi1:", kpi1);
    // Trigger lower kpi reset.
    // this.kpi2 = Default.Kpi2;
    if (this.kpi1 === kpi1) return;
    this._kpi1$.next(kpi1);
  }

  private readonly _kpi2$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi2);
  public readonly kpi2$: Observable<
    INavigationKpiTree
  > = this._kpi2$.asObservable();
  public get kpi2() {
    return this._kpi2$.value;
  }
  public set kpi2(kpi2: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi2:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi2:", kpi2);
    // Trigger lower kpi reset.
    // this.kpi3 = Default.Kpi3;
    if (this.kpi2 === kpi2) return;
    this._kpi2$.next(kpi2);
  }

  private readonly _kpi3$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi3);
  public readonly kpi3$: Observable<
    INavigationKpiTree
  > = this._kpi3$.asObservable();
  public get kpi3() {
    return this._kpi3$.value;
  }
  public set kpi3(kpi3: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi3:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi3:", kpi3);
    // Trigger lower kpi reset.
    // this.kpi4 = Default.Kpi4;
    if (this.kpi3 === kpi3) return;
    this._kpi3$.next(kpi3);
  }

  private readonly _kpi4$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi4);
  public readonly kpi4$: Observable<
    INavigationKpiTree
  > = this._kpi4$.asObservable();
  public get kpi4() {
    return this._kpi4$.value;
  }
  public set kpi4(kpi4: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi4:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi4:", kpi4);
    // Trigger lower kpi reset.
    // this.kpi5 = Default.Kpi5;
    if (this.kpi4 === kpi4) return;
    this._kpi4$.next(kpi4);
  }

  private readonly _kpi5$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi5);
  public readonly kpi5$: Observable<
    INavigationKpiTree
  > = this._kpi5$.asObservable();
  public get kpi5() {
    return this._kpi5$.value;
  }
  public set kpi5(kpi5: INavigationKpiTree) {
    const tag: string = `${this.tag}.kpi5:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi5:", kpi5);
    if (this.kpi5 === kpi5) return;
    this._kpi5$.next(kpi5);
  }

  private readonly _kpi$: BehaviorSubject<
    INavigationKpiTree
  > = new BehaviorSubject<INavigationKpiTree>(Default.Kpi);
  public readonly kpi$: Observable<
    INavigationKpiTree
  > = this._kpi$.asObservable();
  public get kpi() {
    return this._kpi$.value;
  }
  public set kpi(kpi: INavigationKpiTree) {
    if (this.debug) console.log(`${this.tag}.kpi:`, kpi);
    if (this.kpi === kpi) return;
    this._kpi$.next(kpi);
  }

  public get kpiTypeId(): number {
    const tag: string = `${this.tag}.kpiTypeId:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(`${this.tag}.kpi:`, this.kpi);
    return (this.kpi && this.kpi.kpi.kpiTypeId) || null;
  }

  public setKpi({
    kpi0 = Default.Kpi0,
    kpi1 = Default.Kpi1,
    kpi2 = Default.Kpi2,
    kpi3 = Default.Kpi3,
    kpi4 = Default.Kpi4,
    kpi5 = Default.Kpi5
  }: {
    kpi0?: INavigationKpiTree;
    kpi1?: INavigationKpiTree;
    kpi2?: INavigationKpiTree;
    kpi3?: INavigationKpiTree;
    kpi4?: INavigationKpiTree;
    kpi5?: INavigationKpiTree;
  }) {
    const tag: string = `${this.tag}.setKpi()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi0:", kpi0);
    if (debug) console.log(tag, "kpi1:", kpi1);
    if (debug) console.log(tag, "kpi2:", kpi2);
    if (debug) console.log(tag, "kpi3:", kpi3);
    if (debug) console.log(tag, "kpi4:", kpi4);
    if (debug) console.log(tag, "kpi5:", kpi5);
    this.showUnsavedAdjustmentsModal(() => {
      Object.assign(this, { kpi0, kpi1, kpi2, kpi3, kpi4, kpi5 });
      this.onKpiChange();
    });
  }

  public getKpi(): INavigationKpiTree {
    const tag: string = `${this.tag}.getKpi()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi0:", this.kpi0);
    if (debug) console.log(tag, "kpi1:", this.kpi1);
    if (!this.kpi1) return this.kpi0;

    if (debug) console.log(tag, "kpi2:", this.kpi2);
    if (!this.kpi2) return this.kpi1;

    if (debug) console.log(tag, "kpi3:", this.kpi3);
    if (!this.kpi3) return this.kpi2;

    if (debug) console.log(tag, "kpi4:", this.kpi4);
    if (!this.kpi4) return this.kpi3;

    if (debug) console.log(tag, "kpi5:", this.kpi5);
    if (!this.kpi5) return this.kpi4;

    return this.kpi5;
  }

  public listKpis({
    kpi0 = true,
    kpi1 = true,
    kpi2 = true,
    kpi3 = true,
    kpi4 = true,
    kpi5 = true
  }: {
    kpi0?: boolean;
    kpi1?: boolean;
    kpi2?: boolean;
    kpi3?: boolean;
    kpi4?: boolean;
    kpi5?: boolean;
  } = {}): INavigationKpiTree[] {
    const tag: string = `${this.tag}.listKpis()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "kpi0:", this.kpi0);
    if (!this.kpi0) return kpi0 ? [null] : [];

    const kpis: INavigationKpiTree[] = kpi0 ? [this.kpi0] : [];
    if (debug) console.log(tag, "kpi1:", this.kpi1);
    if (this.kpi1) kpis.push(this.kpi1);
    else if (kpi1) kpis.push(null);

    if (debug) console.log(tag, "kpi2:", this.kpi2);
    if (this.kpi2) kpis.push(this.kpi2);
    else if (kpi2) kpis.push(null);

    if (debug) console.log(tag, "kpi3:", this.kpi3);
    if (this.kpi3) kpis.push(this.kpi3);
    else if (kpi3) kpis.push(null);

    if (debug) console.log(tag, "kpi4:", this.kpi4);
    if (this.kpi4) kpis.push(this.kpi4);
    else if (kpi4) kpis.push(null);

    if (debug) console.log(tag, "kpi5:", this.kpi5);
    if (this.kpi5) kpis.push(this.kpi5);
    else if (kpi5) kpis.push(null);

    if (debug) console.log(tag, "kpis:", kpis);
    return kpis;
  }

  public generateKpiPath(
    separator: string = " / ",
    displayedKpis: {
      kpi0?: boolean;
      kpi1?: boolean;
      kpi2?: boolean;
      kpi3?: boolean;
      kpi4?: boolean;
      kpi5?: boolean;
    } = { kpi0: !isPresent(this.kpi1), kpi4: !isPresent(this.kpi5) }
  ): string {
    const tag: string = `${this.tag}.generateKpiPath()`;
    const debug: boolean = this.debug || false;
    const kpis: INavigationKpiTree[] = this.listKpis(displayedKpis);
    if (debug) console.log(tag, "kpis:", kpis);
    const path: string = kpis
      .filter(isPresent)
      .map(kpi => kpi.kpi.label || kpi.kpi.label)
      .join(separator);
    if (debug) console.log(tag, "path:", path);
    return path;
  }

  // The selected date in the datePicker.component.
  private readonly _datePickerDate$: BehaviorSubject<
    Date
  > = new BehaviorSubject<Date>(Default.DatePickerDate);
  public readonly datePickerDate$: Observable<
    Date
  > = this._datePickerDate$.asObservable();
  public get datePickerDate(): Date {
    return this._datePickerDate$.value;
  }
  public set datePickerDate(datePickerDate: Date) {
    const tag: string = `${this.tag}.datePickerDate:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "datePickerDate:", datePickerDate);
    if (!datePickerDate) return;

    const sameDate: boolean = eqDate(this.datePickerDate, datePickerDate);
    if (debug) console.log(tag, "sameDate:", sameDate);
    if (sameDate) return;

    this.showUnsavedAdjustmentsModal(
      () => {
        const innerTag: string = `${tag}.action()`;

        if (debug)
          console.log(
            innerTag,
            "this.scenarioLastAvailableMonth:",
            this.scenarioLastAvailableMonth
          );
        const noScenario: boolean =
          this.scenarioLastAvailableMonth &&
          gtDate(datePickerDate, this.scenarioLastAvailableMonth);
        if (debug) console.log(innerTag, "noScenario:", noScenario);
        if (noScenario) this.scenario = null;

        this._datePickerDate$.next(datePickerDate);
        this._datePickerDateAsOf = dateToMonthPickerStr(datePickerDate);
        this._yearShort = getYearShort(this.datePickerDate);
        this._nextYearShort = getYearShort(this.datePickerDate) + 1;

        this.setReadonly();
      },
      // Select back the old date in the datePicker.component.
      () => {
        const innerTag: string = `${tag}.cancel()`;
        if (debug) console.log(innerTag);
        if (this.datePicker) {
          this.datePicker.selectMonth(
            this.datePickerDate.getFullYear(),
            this.datePickerDate.getMonth()
          );
        }
      }
    );
  }
  private _datePickerDateAsOf = dateToMonthPickerStr(this.datePickerDate);
  public get datePickerDateAsOf(): string {
    return this._datePickerDateAsOf;
  }

  private _yearShort: number = getYearShort(this.datePickerDate);
  public get yearShort(): number {
    return this._yearShort;
  }
  private _nextYearShort: number = getYearShort(this.datePickerDate) + 1;
  public get nextYearShort(): number {
    return this._nextYearShort;
  }

  // The latest month with data for the current organization. Set in data.service.setUpLastAvailableMonth().
  private readonly _organizationLastAvailableMonth$: BehaviorSubject<
    Date
  > = new BehaviorSubject<Date>(this.maxMonthPickerDate);
  public readonly organizationLastAvailableMonth$: Observable<
    Date
  > = this._organizationLastAvailableMonth$.asObservable();
  public get organizationLastAvailableMonth() {
    return this._organizationLastAvailableMonth$.value;
  }
  public set organizationLastAvailableMonth(
    organizationLastAvailableMonth: Date
  ) {
    const tag: string = `${this.tag}.organizationLastAvailableMonth:`;
    const debug: boolean = this.debug || false;
    if (debug)
      console.log(
        tag,
        "organizationLastAvailableMonth:",
        organizationLastAvailableMonth
      );
    if (!organizationLastAvailableMonth) return;

    const sameDate: boolean = eqDate(
      this.organizationLastAvailableMonth,
      organizationLastAvailableMonth
    );
    if (debug) console.log(tag, "sameDate:", sameDate);
    if (sameDate) return;

    this._organizationLastAvailableMonth$.next(organizationLastAvailableMonth);
  }

  // The latest month with an available scenario.
  private readonly _scenarioLastAvailableMonth$: BehaviorSubject<
    Date
  > = new BehaviorSubject<Date>(null);
  public readonly scenarioLastAvailableMonth$: Observable<
    Date
  > = this._scenarioLastAvailableMonth$.asObservable();
  public get scenarioLastAvailableMonth() {
    return this._scenarioLastAvailableMonth$.value;
  }
  public set scenarioLastAvailableMonth(scenarioLastAvailableMonth: Date) {
    const tag: string = `${this.tag}.scenarioLastAvailableMonth:`;
    const debug: boolean = this.debug || false;
    if (debug)
      console.log(
        tag,
        "scenarioLastAvailableMonth:",
        scenarioLastAvailableMonth
      );
    if (!scenarioLastAvailableMonth) return;

    if (debug)
      console.log(
        tag,
        "this.scenarioLastAvailableMonth:",
        this.scenarioLastAvailableMonth
      );
    const sameDate: boolean = eqDate(
      this.scenarioLastAvailableMonth,
      scenarioLastAvailableMonth
    );
    if (debug) console.log(tag, "sameDate:", sameDate);
    if (sameDate) return;

    this._scenarioLastAvailableMonth$.next(scenarioLastAvailableMonth);
  }

  // Is the current month selected.
  public get currentMonthSelected(): boolean {
    return eqDate(this.now, this.datePickerDate);
  }

  private readonly _scenario$: BehaviorSubject<Scenario> = new BehaviorSubject<
    Scenario
  >(Default.Scenario);
  public readonly scenario$: Observable<
    Scenario
  > = this._scenario$.asObservable();
  public get scenario(): Scenario {
    return this._scenario$.value;
  }
  public set scenario(scenario: Scenario) {
    const tag: string = `${this.tag}.scenario:`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "this.scenario:", this.scenario);
    if (debug) console.log(tag, "scenario:", scenario);
    if (
      (!scenario && !this.scenario) ||
      (scenario && this.scenario && scenario.guid === this.scenario.guid)
    )
      return;

    this.showUnsavedAdjustmentsModal(
      () => {
        const innerTag: string = `${tag}.action()`;
        if (debug) console.log(innerTag);
        this.removeScenario(scenario);

        this._scenario$.next(scenario);

        if (scenario) {
          if (debug)
            console.log(tag, "this.datePickerDate:", this.datePickerDate);
          if (debug) console.log(tag, "scenario.perMonth:", scenario.perMonth);
          this.datePickerDate = scenario.perMonth;
        }

        this.setReadonly();
      },
      () => {
        const innerTag: string = `${tag}.cancel()`;
        if (debug) console.log(innerTag);
      }
    );
  }

  private readonly _addScenario$: Subject<Scenario> = new Subject<Scenario>();
  public readonly addScenario$: Observable<
    Scenario
  > = this._addScenario$.asObservable();

  private readonly _removeScenario$: Subject<Scenario> = new Subject<
    Scenario
  >();
  public readonly removeScenario$: Observable<
    Scenario
  > = this._removeScenario$.asObservable();

  private readonly _scenarios$: BehaviorSubject<
    Scenario[]
  > = new BehaviorSubject<Scenario[]>(Default.Scenarios);
  public readonly scenarios$: Observable<
    Scenario[]
  > = this._scenarios$.asObservable();
  public get scenarios(): Scenario[] {
    return this._scenarios$.value;
  }
  public set scenarios(scenarios: Scenario[]) {
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    const tag: string = `${this.tag}.scenarios:`;
    if (debug) console.log(tag, "scenarios:", scenarios);
    if (scenarios === this.scenarios) return;

    scenarios = scenarios.slice(0, MaxScenarios);
    if (debug) console.log(tag, "scenarios:", scenarios);
    this._scenarios$.next(scenarios);
  }
  public addScenario(scenario: Scenario): void {
    const tag: string = `${this.tag}.addScenario()`;
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    if (debug) console.log(tag, "scenario:", scenario);

    const existingScenario: Scenario = getScenario(this.scenarios, scenario);
    if (debug) console.log(tag, "existingScenario:", existingScenario);
    if (debug) console.log(tag, "this.scenarios:", this.scenarios);
    if (existingScenario || this.scenarios.length === MaxScenarios) return;

    this.scenarios = [...this.scenarios, scenario];
    if (debug) console.log(tag, "scenarios:", this.scenarios);
    this._addScenario$.next(scenario);
  }
  public removeScenario(scenario: Scenario): void {
    const tag: string = `${this.tag}.removeScenario()`;
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    if (debug) console.log(tag, "scenario:", scenario);
    if (debug) console.log(tag, "this.scenarios:", this.scenarios);

    const existingScenario: Scenario = getScenario(this.scenarios, scenario);
    if (debug) console.log(tag, "existingScenario:", existingScenario);
    if (!existingScenario) return;

    this._removeScenario$.next(scenario);
    this.scenarios = this.scenarios.filter(s => s !== existingScenario);
  }
  public refetchScenarios(): void {
    const tag: string = `${this.tag}.refetchScenarios()`;
    const debug: boolean = this.debug || ConfigurationService.DebugCompare;
    if (debug) console.log(tag, "this.scenarios:", this.scenarios);
    this.scenarios.forEach(scenario => this._addScenario$.next(scenario));
  }

  // TODO: Currency change is not implemented yet.
  private readonly _currency$: BehaviorSubject<Currency> = new BehaviorSubject<
    Currency
  >(Default.Currency);
  public readonly currency$: Observable<
    Currency
  > = this._currency$.asObservable();
  public get currency(): Currency {
    return this._currency$.value;
  }
  public set currency(currency: Currency) {
    if (this.debug) console.log(`${this.tag}.currency:`, currency);
    if (currency === this.currency) return;
    this.showUnsavedAdjustmentsModal(() => {
      // TODO: Y/n?
      this.setDefaultsForAllPages();
      this._currency$.next(currency);
    });
  }

  private readonly _type$: BehaviorSubject<number> = new BehaviorSubject<number>(
    Default.Type
  );
  public readonly type$: Observable<number> = this._type$.asObservable();
  public get type(): number {
    return this._type$.value;
  }
  public set type(type: number) {
    if (this.debug) console.log(`${this.tag}.type:`, type);
    if (type === this.type) return;
    this._type$.next(type);
  }

  private readonly _unit$: BehaviorSubject<Unit> = new BehaviorSubject<Unit>(
    Default.Unit
  );
  public readonly unit$: Observable<Unit> = this._unit$.asObservable();
  public get unit(): Unit {
    return this._unit$.value;
  }
  public set unit(unit: Unit) {
    if (this.debug) console.log(`${this.tag}.unit:`, unit);
    if (unit === this.unit) return;
    this._unit$.next(unit);
  }
  public unitToLabel(
    unit: Unit = this.unit,
    currency: Currency = this.currency
  ): string {
    return unit === Unit.Currency
      ? (isPresent(currency) && Currency[currency]) || "LC"
      : isPresent(unit) && UnitToLabel[unit];
  }
  public get unitLabel(): string {
    return this.unitToLabel();
  }

  private readonly _regions$: BehaviorSubject<
    FormattedRegions
  > = new BehaviorSubject<FormattedRegions>(Default.Regions);
  public readonly regions$: Observable<
    FormattedRegions
  > = this._regions$.asObservable();
  public get regions() {
    return this._regions$.value;
  }
  public set regions(regions: FormattedRegions) {
    if (this.debug) console.log(`${this.tag}.regions:`, regions);
    if (regions === this.regions) return;
    this._regions$.next(regions);
  }

  private readonly _region$: BehaviorSubject<
    FormattedRegion
  > = new BehaviorSubject<FormattedRegion>(Default.Region);
  public readonly region$: Observable<
    FormattedRegion
  > = this._region$.asObservable();
  public get region(): FormattedRegion {
    return this._region$.value;
  }
  private set _region(region: FormattedRegion) {
    if (this.debug) console.log(`${this.tag}.region:`, region);
    if (region === this.region) return;
    this._region$.next(region);
  }

  private readonly _country$: BehaviorSubject<
    FormattedCountry
  > = new BehaviorSubject<FormattedCountry>(Default.Country);
  public readonly country$: Observable<
    FormattedCountry
  > = this._country$.asObservable();
  public get country(): FormattedCountry {
    return this._country$.value;
  }
  private set _country(country: FormattedCountry) {
    if (this.debug) console.log(`${this.tag}.country:`, country);
    if (country === this.country) return;
    this._country$.next(country);
  }

  private readonly _organization$: BehaviorSubject<
    FormattedOrganization
  > = new BehaviorSubject<FormattedOrganization>(Default.Organization);
  public readonly organization$: Observable<
    FormattedOrganization
  > = this._organization$.asObservable();
  public get organization(): FormattedOrganization {
    return this._organization$.value;
  }
  private set _organization(organization: FormattedOrganization) {
    if (this.debug) console.log(`${this.tag}.organization:`, organization);
    if (organization === this.organization) return;
    this._organization$.next(organization);
  }

  private readonly _organizationEntity$: BehaviorSubject<
    OrganizationEntity
  > = new BehaviorSubject<OrganizationEntity>(Default.OrganizationEntity);
  public readonly organizationEntity$: Observable<
    OrganizationEntity
  > = this._organizationEntity$.asObservable();
  public get organizationEntity(): OrganizationEntity {
    return this.organization || this.country || this.region;
  }

  public setOrganizationEntity(
    region: FormattedRegion = Default.Region,
    country: FormattedCountry = Default.Country,
    organization: FormattedOrganization = Default.Organization
  ) {
    const tag: string = `${this.tag}.setOrganizationEntity()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, "region:", region);
    if (debug) console.log(tag, "country:", country);
    if (debug) console.log(tag, "organization:", organization);
    this.showUnsavedAdjustmentsModal(() => {
      this.setTopLevelKpi();
      this._region = region;
      this._country = country;
      this._organization = organization;
      this._organizationEntity$.next(this.organizationEntity);
      if (this.organizationEntity)
        this.currency = this.organizationEntity.currencyId;
    });
  }

  private readonly _importData$: BehaviorSubject<
    ImportData
  > = new BehaviorSubject<ImportData>(Default.ImportData);
  public readonly importData$: Observable<
    ImportData
  > = this._importData$.asObservable();
  public get importData() {
    return this._importData$.value;
  }
  public set importData(importData: ImportData) {
    if (this.debug) console.log(`${this.tag} set importData:`, importData);
    if (importData === this.importData) return;
    this._importData$.next(importData);
  }

  private readonly _valueLabels$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(Default.ValueLabels);
  public readonly valueLabels$: Observable<
    boolean
  > = this._valueLabels$.asObservable();
  public get valueLabels(): boolean {
    return this._valueLabels$.value;
  }
  public set valueLabels(valueLabels: boolean) {
    if (this.debug) console.log(`${this.tag}.valueLabels:`, valueLabels);
    if (valueLabels === this.valueLabels) return;
    this._valueLabels$.next(valueLabels);
  }

  private readonly _requests: string[] = [];
  public readonly requests = {
    push: () => {
      this._requests.push("");
      this.setIsLoading();
    },
    pop: () => {
      this._requests.pop();
      this.setIsLoading();
    }
  };
  private setIsLoading(): void {
    const anyRequests: boolean = this._requests.length > 0;
    if (!anyRequests) this.callCount = 0;
    this.isLoading = anyRequests;
  }
  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(Default.IsLoading);
  public readonly isLoading$: Observable<
    boolean
  > = this._isLoading$.asObservable();
  public get isLoading() {
    const isLoading: boolean = this._isLoading$.value;
    const debug: boolean = this.debug && false;
    if (debug) console.log(`${this.tag}.isLoading`, isLoading);
    return isLoading;
  }
  public set isLoading(isLoading: boolean) {
    const debug: boolean = this.debug && false;
    if (debug) console.log(`${this.tag}.isLoading:`, isLoading);
    if (isLoading === this.isLoading) return;
    this._isLoading$.next(isLoading);
  }

  private readonly _callCount$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(Default.CallCount);
  public readonly callCount$: Observable<
    number
  > = this._callCount$.asObservable();
  public get callCount() {
    const callCount: number = this._callCount$.value;
    const debug: boolean = this.debug && false;
    if (debug) console.log(`${this.tag}.callCount`, callCount);
    return callCount;
  }
  public set callCount(callCount: number) {
    const debug: boolean = this.debug && false;
    if (debug) console.log(`${this.tag}.callCount:`, callCount);
    if (callCount === this.callCount) return;
    this._callCount$.next(callCount);
  }

  private readonly _pageVisibility$: BehaviorSubject<
    PageVisibility
  > = new BehaviorSubject<PageVisibility>(Default.PageVisibility);
  public readonly pageVisibility$: Observable<
    PageVisibility
  > = this._pageVisibility$.asObservable();
  public get pageVisibility() {
    const pageVisibility: PageVisibility = this._pageVisibility$.value;
    if (this.debug)
      console.log(`${this.tag} get pageVisibility:`, pageVisibility);
    return pageVisibility;
  }
  public set pageVisibility(pageVisibility: PageVisibility) {
    if (this.debug)
      console.log(`${this.tag} set pageVisibility:`, pageVisibility);
    if (pageVisibility === this.pageVisibility) return;
    this._pageVisibility$.next(pageVisibility);
  }

  // public reloadApp(): void {
  //   const tag: string = `${this.tag}.reloadApp()`;
  //   const debug: boolean = this.debug || false;
  //   if (debug) console.log(tag);
  //   document.location.href = this.configuration.baseHref;
  // }

  private readonly _anyParameterChange$: Subject<Parameter> = new Subject<
    Parameter
  >();
  public readonly anyParameterChange$: Observable<
    Parameter
  > = this._anyParameterChange$.asObservable();
}
