import "hammerjs";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ConfigurationService } from './app/services/configuration.service';

const LogoGifDuration: number = 4000;

if (ConfigurationService.Development) {
  platformBrowserDynamic().bootstrapModule(AppModule);
} else {
  enableProdMode();
  // Bootstrap after myForecast logo has been shown.
  setTimeout(() => platformBrowserDynamic().bootstrapModule(AppModule), LogoGifDuration);
}

