import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { TopLevelKpi, Kpi1, Kpi2, Kpi3, Kpi4, } from 'src/app/constants';
import { isPresent } from 'src/app/utils';

@Component({
  selector: 'workbenchHeader',
  templateUrl: 'workbenchHeader.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WorkbenchHeaderComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'WorkbenchHeaderComponent';

  public header: string;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    public readonly state: StateService,
  ) {
    super(cd, state);
    this.debug = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tag = `${WorkbenchHeaderComponent.Tag}`;

    const tag: string = `${this.tag}.ngOnInit()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);

    this.subscriptions = [
      this.state.topLevelKpi$.subscribe(this.onKpiChange.bind(this)),
      this.state.kpi1$.subscribe(this.onKpiChange.bind(this)),
      this.state.kpi2$.subscribe(this.onKpiChange.bind(this)),
      this.state.kpi3$.subscribe(this.onKpiChange.bind(this)),
      this.state.kpi4$.subscribe(this.onKpiChange.bind(this)),
      this.state.kpi5$.subscribe(this.onKpiChange.bind(this))
    ];
  }

  private onKpiChange(): void {
    const tag: string = `${this.tag}.onKpiChange()`;
    const debug: boolean = this.debug || false;
    const header: string = this.state.generateKpiPath();
    if (debug) console.log(tag, 'header:', header);
    this.header = header;
  }
}
