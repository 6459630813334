import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { StateService } from 'src/app/services/state/state.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Page } from 'src/app/constants';
import { CommonDataService } from '../services/data/common.data.service';
import { RouterService } from '../services/router.service';

@Injectable()
export class LandingResolver implements Resolve<void> {
  private static readonly Tag: string = 'LandingResolver';
  private readonly tag: string = LandingResolver.Tag;

  private readonly debug: boolean = ConfigurationService.Debug;

  constructor(
    public readonly state: StateService,
    private readonly data: CommonDataService,
    private readonly router: RouterService,
  ) { }

  public async resolve(): Promise<void> {
    const tag: string = `${this.tag}.resolve()`;
    const debug: boolean = this.debug || false;
    this.state.page = Page.Landing;
    if (debug) console.log(tag, 'state.page:', this.state.page);
    await this.load();
    if (!this.state.pageVisibility[Page.Planning]) {
      this.state.page = Page.Workbench;
      this.router.goTo(Page.Workbench);
    }
  }

  public async load(): Promise<void> {
    const tag: string = `${this.tag}.load()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag);
    await Promise.all([
      await this.data.setUpConfiguration()
    ]);
  }
}
