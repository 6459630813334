import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import { BaseComponent } from 'src/app/components/common/base/base.component';
import { StateService } from 'src/app/services/state/state.service';
import { ForYearAcronym, KpiValue, Adjustment, AdjustmentTab, Unit, Scale, Precision } from 'src/app/constants';
import {
  isNumber, removeCommaSeparatorNum, removeExtraSigns, getPlanningKpiValue, isAdjustedRemoveComma, dateToMonthPickerStr, createDate, sortAdjustments, getFormatter,
} from 'src/app/utils';
import { Kpi, Values } from 'src/app/models/common/kpiValues';
import { browser } from 'src/app/app.component';
import { PlanningDataService } from 'src/app/services/data/planning.data.service';
import { IPlanning, IPlanMarketAndRisk } from 'src/app/models/planning/planning';
import { WorkbenchDataService } from 'src/app/services/data/workbench.data.service';

@Component({
  selector: 'marketAndRisk',
  templateUrl: 'marketAndRisk.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class MarketAndRiskComponent extends BaseComponent implements OnInit {
  private static readonly Tag: string = 'MarketAndRiskComponent';
  protected readonly tag: string = MarketAndRiskComponent.Tag;
  protected readonly debug: boolean = false;

  private readonly debugInputEvents: boolean = false;

  public adjustments: Adjustment[] = [];
  public planningData: IPlanMarketAndRisk = null;

  constructor(
      protected readonly cd: ChangeDetectorRef,
      public readonly state: StateService,
      private readonly planning: PlanningDataService,
      private readonly workbench: WorkbenchDataService,
  ) {
      super(cd, state);
  }

  public ngOnInit(): void {
      super.ngOnInit();

      const tag: string = `${this.tag}.ngOnInit()`;
      if (this.debug) console.log(tag);

      this.subscriptions = [
          this.planning.marketAndRiskData$.subscribe(this.onPlanningDataChange.bind(this))
      ];
  }

  private onPlanningDataChange(data: IPlanMarketAndRisk): void {
      const tag: string = `${this.tag}.onPlanningDataChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'data', data);
      if (!data) return;
      let marketAndRisk = data;
      marketAndRisk.marketData.forEach(kpi => kpi.total = this.displayFormat(kpi.total, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.mbc = this.displayFormat(kpi.mbc, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.ice = this.displayFormat(kpi.ice, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.phev = this.displayFormat(kpi.phev, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.bev = this.displayFormat(kpi.bev, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.sm = this.displayFormat(kpi.sm, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.vans = this.displayFormat(kpi.vans, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.vaice = this.displayFormat(kpi.vaice, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.vabev = this.displayFormat(kpi.vabev, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.pcnd = this.displayFormat(kpi.pcnd, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.trucks = this.displayFormat(kpi.trucks, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.bus = this.displayFormat(kpi.bus, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.cvnd = this.displayFormat(kpi.cvnd, Scale.Million));
      marketAndRisk.marketData.forEach(kpi => kpi.other = this.displayFormat(kpi.other, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.total = this.displayFormat(kpi.total, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.mbc = this.displayFormat(kpi.mbc, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.ice = this.displayFormat(kpi.ice, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.phev = this.displayFormat(kpi.phev, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.bev = this.displayFormat(kpi.bev, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.sm = this.displayFormat(kpi.sm, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.vans = this.displayFormat(kpi.vans, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.vaice = this.displayFormat(kpi.vaice, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.vabev = this.displayFormat(kpi.vabev, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.pcnd = this.displayFormat(kpi.pcnd, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.trucks = this.displayFormat(kpi.trucks, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.bus = this.displayFormat(kpi.bus, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.cvnd = this.displayFormat(kpi.cvnd, Scale.Million));
      marketAndRisk.riskData.forEach(kpi => kpi.other = this.displayFormat(kpi.other, Scale.Million));
      this.planningData = marketAndRisk;
      this.adjustments = this.getPlanningKpiValues(marketAndRisk, true)
      .filter(kpi => isAdjustedRemoveComma(kpi) && kpi.id)
      .sort(sortAdjustments)
      .reverse()
      .map(kpi => ({
          forecastId: kpi.id,
          perMonth: dateToMonthPickerStr(createDate(kpi.date)),
          adjustValue: removeCommaSeparatorNum(removeExtraSigns(kpi.values[KpiValue.Adjusted])),
      }));
      if (debug) console.log(tag, 'adjustments:', this.adjustments);
      this.setUnsavedAdjustments();
      this.setLoadingStatus();
  }

  public onAdjustmentClick(kpi: Kpi, value: number): void {
      const tag: string = `${this.tag}.onAdjustmentClick()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
  }

  public displayUnit(unit: Unit): string {
    switch (unit) {
        case Unit.Currency:
            return "mil (LC)";
        case Unit.Percentage:
            return "%";
        case Unit.Unit:
            return "u";
        case Unit.FullTimeEquivalent:
            return "fte";
        default:
            return "mil (LC)";
    }
  }

  public onAdjustmentKeyPress(value: string, input: HTMLElement, event): boolean {
      const tag: string = `${this.tag}.onAdjustmentKeyPress()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'value:', value);

      event = event || window.event;
      const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
      const char: string = String.fromCharCode(charCode);
      if (debug) console.log(tag, 'char:', char);
      const isValidSymbol: boolean = ['-', ',', '.'].includes(char);
      if (debug) console.log(tag, 'isValidSymbol:', isValidSymbol);

      if (!isNumber(char) && !isValidSymbol) return false;
      return true;
  }

  public onAdjustmentKeyUp(kpi: Kpi, value: string): void {
      const tag: string = `${this.tag}.onAdjustmentKeyUp()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);

      if (!this.state.unsavedAdjustments) {
          const machine: string = kpi.values[KpiValue.Machine];
          const isMachine: boolean = isNumber(machine);
          const editedMachine: boolean = isMachine && machine != value;

          const organization: string = kpi.values[KpiValue.Organization];
          const isOrganization: boolean = isNumber(organization);
          const editedOrganization: boolean = isOrganization && organization != value;

          const newValue: boolean = !(editedMachine || editedOrganization);

          if (editedMachine || editedOrganization || newValue) {
              this.state.unsavedAdjustmentsMap[AdjustmentTab.AdditionalKpi] = true;
          }
      }
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  public async onKeyUpEnter(kpi: Kpi, value: string): Promise<void> {
      const tag: string = `${this.tag}.onKeyUpEnter()`;
      const debug: boolean = this.debugInputEvents || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      if (browser.name === 'ie') await this.onAdjustmentChange(kpi, value);
  }

  public async onAdjustmentChange(kpi: Kpi, value: string = null): Promise<void> {
      const tag: string = `${this.tag}.onAdjustmentChange()`;
      const debug: boolean = this.debug || false;
      if (debug) console.log(tag, 'kpi:', kpi);
      if (debug) console.log(tag, 'value:', value);
      const formattedValue = removeCommaSeparatorNum(removeExtraSigns(value)) || null;
      if (debug) console.log(tag, 'formattedValue:', formattedValue);
      await this.workbench.postAdditionalKpiAdjustment({ id: kpi.id, value: formattedValue });
      await this.reload();
      if (debug) console.log(tag, 'state.unsavedAdjustmentsMap:', this.state.unsavedAdjustmentsMap);
  }

  private clearAdjustments(): void {
      const tag: string = `${this.tag}.clearAdjustments()`;
      if (this.debug) console.log(tag);
      this.adjustments = [];
      this.setUnsavedAdjustments();
  }

  private getPlanningKpiValues(kpis: IPlanMarketAndRisk, onlyAdjustable: boolean = false): Kpi[] {
    const tag: string = `${this.tag}.getPlanningKpiValues()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'kpis:', kpis);
    if (debug) console.log(tag, 'onlyAdjustable:', onlyAdjustable);
    let result: Kpi[] = [];
    if (kpis.marketData != null ) {
        kpis.marketData.forEach(kpi => {
            if (!onlyAdjustable || (onlyAdjustable && kpi.mbc.isAdjustable)) {
                result.push(kpi.mbc.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.ice.isAdjustable)) {
                result.push(kpi.ice.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.phev.isAdjustable)) {
                result.push(kpi.phev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.bev.isAdjustable)) {
                result.push(kpi.bev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.sm.isAdjustable)) {
                result.push(kpi.sm.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vans.isAdjustable)) {
                result.push(kpi.vans.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vaice.isAdjustable)) {
                result.push(kpi.vaice.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vabev.isAdjustable)) {
                result.push(kpi.vabev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.pcnd.isAdjustable)) {
                result.push(kpi.pcnd.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.trucks.isAdjustable)) {
                result.push(kpi.trucks.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.bus.isAdjustable)) {
                result.push(kpi.bus.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.cvnd.isAdjustable)) {
                result.push(kpi.cvnd.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.other.isAdjustable)) {
                result.push(kpi.other.endOfYear);
            }
        });
    }
    if (kpis.riskData != null ) {
        kpis.riskData.forEach(kpi => {
            if (!onlyAdjustable || (onlyAdjustable && kpi.total.isAdjustable)) {
                result.push(kpi.total.endOfYear);
            }   
            if (!onlyAdjustable || (onlyAdjustable && kpi.mbc.isAdjustable)) {
                result.push(kpi.mbc.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.ice.isAdjustable)) {
                result.push(kpi.ice.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.phev.isAdjustable)) {
                result.push(kpi.phev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.bev.isAdjustable)) {
                result.push(kpi.bev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.sm.isAdjustable)) {
                result.push(kpi.sm.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vans.isAdjustable)) {
                result.push(kpi.vans.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vaice.isAdjustable)) {
                result.push(kpi.vaice.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.vabev.isAdjustable)) {
                result.push(kpi.vabev.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.pcnd.isAdjustable)) {
                result.push(kpi.pcnd.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.trucks.isAdjustable)) {
                result.push(kpi.trucks.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.bus.isAdjustable)) {
                result.push(kpi.bus.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.cvnd.isAdjustable)) {
                result.push(kpi.cvnd.endOfYear);
            }
            if (!onlyAdjustable || (onlyAdjustable && kpi.other.isAdjustable)) {
                result.push(kpi.other.endOfYear);
            }
        });
    }
    return result;
  }

  private async reload() {
      const tag: string = `${this.tag}.reload()`;
      if (this.debug) console.log(tag);
      await Promise.all([
          this.planning.setUpMarketAndRiskData(),
      ]);
  }

  private setUnsavedAdjustments(): void {
      const tag: string = `${this.tag}.setUnsavedAdjustments()`;
      if (this.debug) console.log(tag);
      this.state.unsavedAdjustmentsMap[AdjustmentTab.Planning] = this.adjustments.length > 0;
  }

  private displayFormat(data: IPlanning, scale: Scale): IPlanning {
    const format: Function = getFormatter(data.unit);
    const precision: number = data.unit === Unit.Currency || Unit.FullTimeEquivalent ? Precision : 0;
    let formattedData = new Object(data) as IPlanning;
    const values: Values = formattedData.endOfYear.values;
    const isActual: boolean = isNumber(values[KpiValue.Actual]);
    const isAdjusted: boolean = isNumber(values[KpiValue.Adjusted]);
    const isOrganization: boolean = isNumber(values[KpiValue.Organization]);
    const isMachine: boolean = isNumber(values[KpiValue.Machine]);
    const hasValue: boolean = isActual || isAdjusted || isOrganization || isMachine;
    Object.assign(formattedData.endOfYear.values, {
        isActual,
        isAdjusted,
        isOrganization: !isAdjusted && isOrganization,
        isMachine: !isAdjusted && !isOrganization,
        hasValue,
        values: {
            [KpiValue.Actual]: isActual ? format(values[KpiValue.Actual], scale, precision) : null,
            [KpiValue.Adjusted]: isAdjusted ? format(values[KpiValue.Adjusted], scale, precision) : null,
            [KpiValue.Organization]: isOrganization ? format(values[KpiValue.Organization], scale, precision) : null,
            [KpiValue.Machine]: isMachine ? format(values[KpiValue.Machine], scale, precision) : null
        }
    });
    return formattedData;
  }

  public readonly ForYearAcronym = ForYearAcronym;
  public readonly KpiValue = KpiValue;
  public readonly getPlanningKpiValue = getPlanningKpiValue;
}
