import { normalizeDate, localeDateMonthShortYearShort, localeMonthShortYearShort, sortByDate, createDate, localeDateMonthShortYearShortTimeShort } from "src/app/utils";

export const You: string = 'you';

export class ScenarioContainer {
    protected static readonly Tag: string = 'ScenarioContainer';
    public tag: string = ScenarioContainer.Tag;
    public debug: boolean = false;

    public scenarioMonths: ScenarioMonth[];

    constructor(scenarioMonths: IScenarioMonth[] = []) {
        this.scenarioMonths = scenarioMonths.map(month => new ScenarioMonth(month));
    }

    public format(username: string, onlyScenarios: boolean = false): ScenarioMonth[] {
        const scenarioMonths: ScenarioMonth[] = this.scenarioMonths.map((month, i) => Object.assign({}, month, {
            label: localeMonthShortYearShort(month.date),
            scenarios: (onlyScenarios ? month.scenarios.filter(s => s.guid) : month.scenarios).map(scenario => {
                const submittedText: string = scenario.submitted && `submitted on ${localeDateMonthShortYearShortTimeShort(scenario.submitted)}${
                    scenario.lastModifiedUser ?
                        ' by ' + (scenario.lastModifiedUser === username ? You : scenario.lastModifiedUser)
                        : ''}`;
                const lastModifiedText: string = !submittedText && scenario.lastModified &&
                    `modified on ${localeDateMonthShortYearShortTimeShort(scenario.lastModified)}${
                    scenario.lastModifiedUser ?
                        ' by ' + (scenario.lastModifiedUser === username ? You : scenario.lastModifiedUser)
                        : ''}`;
                return Object.assign({}, scenario, {
                    date: month.date,
                    submittedText,
                    lastModifiedText,
                });
            })
        })).sort((a, b) => sortByDate(a, b, 'date'));
        return scenarioMonths;
    }
}

export class ScenarioMonth {
    public date: Date;
    public scenarios: Scenario[];

    constructor({ date, scenarios = [] }: {
        date?: string, scenarios?: IScenario[]
    } = {}) {
        this.date = normalizeDate(date);
        this.scenarios = scenarios.map(scenario => new Scenario(scenario));
    }
}

export interface IScenarioMonth {
    date: string;
    scenarios: IScenario[];
}

export class Scenario {
    protected static readonly Tag: string = 'Scenario';
    public tag: string = Scenario.Tag;
    public debug: boolean = false;

    private countryId: number;
    // public countryName: string;
    public createdOn: Date;
    public id: number;
    public isMain: boolean;
    // public lastModifiedOn: Date;
    public perMonth: Date;
    public readOnly: boolean;
    public lastModified: Date;
    public submitted: Date;
    public title: string;
    public lastModifiedUser: string;
    // Added from ScenarioMonth for a non-scenario Scenario.
    public date: Date;
    // Added through scenarios.component.
    public color: string;
    // Used as id.
    public guid: string;

    constructor(scenario: IScenario = {} as IScenario) {
        this.guid = scenario.guid;
        this.createdOn = createDate(scenario.createdOn);
        this.id = scenario.id;
        this.isMain = scenario.isMain;
        this.title = scenario.title;
        this.countryId = scenario.countryId;
        this.lastModifiedUser = scenario.username;
        this.perMonth = normalizeDate(scenario.perMonth);
        this.readOnly = scenario.readOnly;
        this.lastModified = createDate(scenario.lastModifiedOn);
        this.submitted = createDate(scenario.submitted);
    }

    public static toBackend({ title, organization, username, perMonth, }: {
        title: string, organization: number, username: string, perMonth: string
    }): ICreateScenario {
        const tag: string = `${Scenario.Tag}.toBackend() `;
        const debug: boolean = false;
        const scenario: ICreateScenario = {
            title,
            countryId: organization,
            username,
            perMonth,
        };
        if (debug) console.log(tag, 'scenario:', scenario);
        return scenario;
    }

    // public toBackend(): ICreateScenario {
    //     const tag: string = `${ this.tag }.toBackend() `;
    //     const debug: boolean = false;
    //     const scenario: ICreateScenario = {
    //         title,
    //         countryId: organization,
    //         username,
    //         perMonth,
    //     };
    //     if (debug) console.log(tag, 'scenario:', scenario);
    //     return scenario;
    // }
}

export interface IScenario {
    guid: string;
    id: number;
    isMain: boolean;
    title: string;
    countryId: number;
    countryName: string;
    username: string;
    perMonth: string;
    readOnly: boolean;
    createdOn: string;
    lastModifiedOn?: string;
    submitted?: string;
}

export interface ICreateScenario {
    title: string;
    countryId: number;
    username: string;
    perMonth: string;
}
