import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from 'src/app/services/state/state.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { Page } from 'src/app/constants';

// HttpResponse<any>Type

export enum ErrorHandlingMechanism {
  Notify,
  Retry
}

@Injectable()
export class RequestService {
  private static readonly Tag: string = 'RequestService';
  private readonly tag: string = RequestService.Tag;
  private readonly debug: boolean = false;
  private readonly errorHandling: ErrorHandlingMechanism = ErrorHandlingMechanism.Retry;

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly modal: ModalService,
    private readonly configuration: ConfigurationService,
    public readonly state: StateService
  ) { }


  private get httpOptions(): any {
    const tag: string = `${this.tag}.httpOptions`;
    const debug: boolean = this.debug || false;

    let headers: HttpHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    });

    if (this.configuration.authEnabled) {
      const authorization = this.auth.authorizationHeader;
      if (debug) console.log(tag, 'authorization:', authorization);
      headers = headers.append(authorization.key, authorization.value);
    }

    const httpOptions = {
      headers,
      withCredentials: true,
      observe: 'response',
    };

    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    return httpOptions;
  }

  private applyQueryParams(url: string): string {
    const tag: string = `${this.tag}.applyQueryParams()`;
    const debug: boolean = this.debug && false;
    if (debug) console.log(tag, 'url:', url);
    url = `${url}${url.includes('?') ? '&' : '?'}${this.getCacheBuster()}`;
    if (debug) console.log(tag, 'url:', url);
    return url;
  }

  private getCacheBuster(): string {
    const tag: string = `${this.tag}.getCacheBuster()`;
    const debug: boolean = this.debug && false;
    const queryParam: string = 'time=' + Date.now();
    if (debug) console.log(tag, 'queryParam:', queryParam);
    return queryParam;
  }

  private async errorHandler(err: HttpErrorResponse, method?: string, parameters?: any[]): Promise<boolean> {
    const tag: string = `${this.tag}.errorHandler()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'err:', err);
    if (debug) console.log(tag, 'method:', method);
    if (debug) console.log(tag, 'parameters:', parameters);

    if (err.status === 401) {
      this.auth.authorizationData = null;
      window.location.href = `${this.configuration.backendBaseUrl}/api/oauth2/login`;
      return;
    }

    switch (this.errorHandling) {
      case ErrorHandlingMechanism.Notify: {
        const innerTag: string = `${this.tag}.notify()`;
        if (debug) console.log(innerTag);
        return this.modal.notifyError(err);
      }
      case ErrorHandlingMechanism.Retry: {
        const innerTag: string = `${this.tag}.retry()`;
        if (debug) console.log(innerTag);
        if (!method || !parameters) {
          return this.modal.notifyError(err);
        }

        const retry: Function = async () => {
          const tag: string = `${this.tag}.retryFunction()`;
          const debug: boolean = this.debug || false;
          if (debug) console.log(tag, 'this:', this);
          if (debug) console.log(tag, 'method:', method);
          if (debug) console.log(tag, 'parameters:', parameters);
          const request: HttpRequest<any> = this[method](...parameters);
          if (debug) console.log(tag, 'request:', request);
          const response: HttpResponse<any> = await request as any;
          if (debug) console.log(tag, 'response:', response);
        };
        if (debug) console.log(innerTag, 'retry:', retry);
        return this.modal.retryRequest(err, retry);
      }
    }
  }

  public get httpFileOptions(): any {
    const tag: string = `${this.tag}.httpOptions`;
    const debug: boolean = this.debug || false;

    let headers: HttpHeaders = new HttpHeaders({});

    if (this.configuration.authEnabled) {
      const authorization = this.auth.authorizationHeader;
      if (debug) console.log(tag, 'authorization:', authorization);
      headers = headers.append(authorization.key, authorization.value);
    }

    const httpOptions = {
      headers,
      withCredentials: true,
      observe: 'response',
    };

    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    return httpOptions;
  }

  public async get(url: string, httpOptions = this.httpOptions, base: string = this.configuration.backendBaseUrl): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.get()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'url:', url);
    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    if (debug) console.log(tag, 'base:', base);
    this.state.requests.push();

    const request: HttpRequest<any> = (this.http.get(this.applyQueryParams(`${base}/${url}`), httpOptions).toPromise() as any).catch(err => {
      if (debug) console.log(tag, 'err:', err);
      this.errorHandler(err, 'get', [url, httpOptions, base]);
    }).finally(() => {
      this.state.requests.pop();
    });
    if (debug) console.log(tag, 'request:', request);

    const response: HttpResponse<any> = await request as any;
    if (debug) console.log(tag, 'response:', response);
    return response;
  }

  public async post(url: string, body?: any, httpOptions = this.httpOptions, base: string = this.configuration.backendBaseUrl): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.post()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'url:', url);
    if (debug) console.log(tag, 'body:', body);
    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    if (debug) console.log(tag, 'base:', base);
    this.state.requests.push();

    const request: HttpRequest<any> = (this.http.post(this.applyQueryParams(`${base}/${url}`), body, httpOptions).toPromise() as any).catch(err => {
      if (debug) console.log(tag, 'err:', err);
      this.errorHandler(err, 'post', [url, body, httpOptions, base]);
    }).finally(() => {
      this.state.requests.pop();
    });
    if (debug) console.log(tag, 'request:', request);

    const response: HttpResponse<any> = await request as any;
    if (debug) console.log(tag, 'response:', response);
    return response;
  }

  public async put(url: string, body?: any, httpOptions = this.httpOptions, base: string = this.configuration.backendBaseUrl): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.put()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'url:', url);
    if (debug) console.log(tag, 'body:', body);
    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    if (debug) console.log(tag, 'base:', base);
    this.state.requests.push();

    const request: HttpRequest<any> = (this.http.put(this.applyQueryParams(`${base}/${url}`), body, httpOptions).toPromise() as any).catch(err => {
      if (debug) console.log(tag, 'err:', err);
      this.errorHandler(err, 'put', [url, body, httpOptions, base]);
    }).finally(() => {
      this.state.requests.pop();
    });
    if (debug) console.log(tag, 'request:', request);

    const response: HttpResponse<any> = await request as any;
    if (debug) console.log(tag, 'response:', response);
    return response;
  }

  public async delete(url: string, body?: any, httpOptions = this.httpOptions, base: string = this.configuration.backendBaseUrl): Promise<HttpResponse<any>> {
    const tag: string = `${this.tag}.delete()`;
    const debug: boolean = this.debug || false;
    this.state.requests.push();
    if (debug) console.log(tag, 'url:', url);
    if (debug) console.log(tag, 'body:', body);
    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    if (debug) console.log(tag, 'base:', base);
    this.state.requests.push();

    const request: HttpRequest<any> = (this.http.delete(this.applyQueryParams(`${base}/${url}`), httpOptions).toPromise() as any).catch(err => {
      if (debug) console.log(tag, 'err:', err);
      this.errorHandler(err, 'delete', [url, httpOptions, base]);
    }).finally(() => {
      this.state.requests.pop();
    });
    if (debug) console.log(tag, 'request:', request);

    const response: HttpResponse<any> = await request as any;
    if (debug) console.log(tag, 'response:', response);
    return response;
  }

  public async postSync(url: string, body?: any, httpOptions = this.httpOptions, base: string = this.configuration.backendBaseUrl) {
    const tag: string = `${this.tag}.postSync()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'url:', url);
    if (debug) console.log(tag, 'body:', body);
    if (debug) console.log(tag, 'httpOptions:', httpOptions);
    this.state.requests.push();

    body = JSON.stringify(body);

    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', this.applyQueryParams(`${base}/${url}`), false);
    httpOptions.headers.forEach(k => {
      if (debug) console.log(tag, 'k:', k);
      const value: string = httpOptions.headers.get(k);
      if (debug) console.log(tag, 'value:', value);
      xhr.setRequestHeader(k, value);
    });
    xhr.send(body);

    // TODO: Probably not working as intended, have to promisify sooner?
    try {
      const request: Promise<XMLHttpRequest> = Promise.resolve(xhr);
      if (debug) console.log(tag, 'request:', request);
      const response: XMLHttpRequest = await request;
      if (debug) console.log(tag, 'response:', response);
      this.state.requests.pop();
      return response;
    } catch (err) {
      if (debug) console.log(tag, 'err:', err);
      this.state.requests.pop();
      this.errorHandler(err, 'postSync', [url, body, httpOptions, base]);
    }
  }
}
