import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { empty as observableEmpty, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';

export interface AuthorizationData {
  access_token: string;
  token_type: string;
  expires_in: number;
}

export interface Header {
  key: string;
  value: string;
}

@Injectable()
export class AuthService {
  private static readonly Tag: string = 'AuthService';
  private readonly tag: string = AuthService.Tag;
  private readonly debug: boolean = false;

  public authorizationData: AuthorizationData;

  constructor(
    private readonly http: HttpClient,
    private readonly configuration: ConfigurationService,

  ) {
    const tag: string = `${this.tag}.constructor()`;
  }

  private readonly headers: HttpHeaders = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  private readonly httpOptions = {
    headers: this.headers,
    withCredentials: true,
    observe: 'response',
    response: 'json',
  } as any;


  public get isAuthorized(): boolean {
    return !!this.authorizationData;
  }

  public get authorizationHeader(): Header {
    const tag: string = `${this.tag}.getAuthorizationHeader`;
    const debug: boolean = this.debug || false;
    const authorizationData: AuthorizationData = this.authorizationData || {} as AuthorizationData;
    // if (debug) console.log(tag, 'authorizationData:', authorizationData);
    const authorizationHeader: Header = {
      key: 'Authorization',
      value: `${authorizationData.token_type} ${authorizationData.access_token}`,
    };
    // if (debug) console.log(tag, 'authorizationHeader:', authorizationHeader);
    return authorizationHeader;
  }
  // : Observable<ArrayBuffer>
  public async authorize(username: string, password: string) {
    const tag: string = `${this.tag}.authorize()`;
    const debug: boolean = this.debug || false;
    if (debug) console.log(tag, 'username:', username);
    if (debug) console.log(tag, 'password:', password);

    const url: string = this.configuration.authServiceUrl + 'connect/token';
    if (debug) console.log(tag, 'url:', url);

    const body: string =
      'grant_type=password&' +
      'username=' + username + '&' +
      'password=' + password + '&' +
      'client_id=roclient.reference';
    if (debug) console.log(tag, 'body:', body);

    const response: HttpResponse<any> = await this.http.post(url, body, this.httpOptions).toPromise() as any;
    const authorizationData: AuthorizationData = response.body;
    if (debug) console.log(tag, 'authorizationData:', authorizationData);
    this.authorizationData = authorizationData;
  }

  public revoke(): Observable<any> {
    const tag: string = `${this.tag}.revoke()`;
    const debug: boolean = this.debug || false;
    const authorizationData: AuthorizationData = this.authorizationData;
    if (debug) console.log(tag, 'authorizationData:', authorizationData);
    if (!authorizationData) {
      return observableEmpty();
    }

    const url: string = this.configuration.authServiceUrl + 'connect/revocation';
    if (debug) console.log(tag, 'url:', url);

    const body: string =
      'grant_type=password&' +
      'client_id=roclient.reference&' +
      'token=' + authorizationData.access_token + '&' +
      'token_type_hint=access_token';
    if (debug) console.log(tag, 'body:', body);

    return this.http.post(url, body, this.httpOptions).pipe(
      tap(() => this.authorizationData = null));
  }

  // private handleError(error: any) {
  //   const tag: string = `${this.tag}.handleError()`;
  //   const debug: boolean = this.debug || false;
  //   if (debug) console.log(tag, 'error:', error);

  //   let errorContent = JSON.parse(error['_body']);
  //   let errMsg = (errorContent.error) ? errorContent.error_description :
  //     error.status ? `${error.status} - ${error.statusText}` : 'Server error';
  //   return observableThrowError(errMsg);
  // }
}
