/** Enum operations */
// https://basarat.gitbooks.io/typescript/docs/types/literal-types.html
export const StrEnum = <T extends string>(keys: Array<T>): { [K in T]: K } => keys.reduce((StrEnum, k) => {
  StrEnum[k] = k;
  return StrEnum;
}, Object.create(null));

export const SymbolEnum = (keys: string[]): { [k: string]: Symbol } => keys.reduce((SymbolEnum, k) => {
  SymbolEnum[SymbolEnum[k] = Symbol(k)] = k;
  return SymbolEnum;
}, Object.create(null));
/** Enum operations  */

export const DefinedBelow = undefined;

export const Environment = StrEnum([
  'Development',
  'Production',
  'Integration'
]);
export type Environment = keyof typeof Environment;

export const Precision: number = 2;
export const NumberLocale: string = 'en-US';

export const MaxSubKpiCount: number = 4;

// Actual value count (last 3 years).
export const ActualValueCount = 36;
// Organization forecast value count (1.5 years).
export const FutureForecastCount = 18;

export const MonthPickerAllowedYearsBack = 4;

export const ForecastAcronym = 'FC';
export const ForYearAcronym = 'FY';
export const YearToDateAcronym = 'YTD';
export const NoMonthsAvailable = 'No months available';
export const NoValue = 'N/A';

export const DefaultScale = 'm';
export const AsOf = 'As of';

export const FyForecastCount = 5;
export const ResultsFyForecastCount = 4;
export const DataTableInputFieldCount = 8;
export const DataTableMonthsInputFieldCount = 12;

export const OpexViewInfoMessage: string = 'P&L view: please enter all values negatively';

export enum KpiValue {
  Machine = 'machineForecast',
  Organization = 'organizationForecast',
  Actual = 'actual',
  Adjusted = 'adjusted',
  Fitted = 'fitted',
  Corrected = 'corrected',
}

export enum NotifierType {
  None = "none",
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error"
}

// Properties from the KpiValues endpoint.
export const KpiValues = {
  AdjustedMessage: 'adjustedMessage',
  AdjustedTime: 'adjustedTime',
};

export const ForecastName = {
  Actual: 'Actual',
  Machine: 'Model',
  Organization: 'Country',
  Adjustment: 'Adjustment',
  Accuracy: 'Accuracy',
  Fitted: 'Fitted',
  Corrected: 'Corrected',
};
export const ForecastNameKeys = StrEnum(Object.keys(ForecastName));

export const UnsavedAdjustmentsText: string = `Are you sure you want to discard all your ${ForecastName.Adjustment} entries?`;

export const YTD: any = Object.keys(ForecastName).reduce((YTD, k) => {
  YTD[k] = `${k}${YearToDateAcronym}`;
  return YTD;
}, {});

export const YTDLabels: any = Object.keys(YTD).reduce((YTDLabels, k) => {
  YTDLabels[k] = `${k[0]}${YearToDateAcronym}`;
  return YTDLabels;
}, {});

export enum Unit {
  Currency,
  Percentage,
  Unit,
  Headcount,
  FullTimeEquivalent,
}

export const UnitToLabel = {
  [Unit.Currency]: '',
  [Unit.Percentage]: '%',
  [Unit.Unit]: 'Unit',
  [Unit.Headcount]: 'hc',
  [Unit.FullTimeEquivalent]: 'FTE',
};

export enum Page {
  Workbench = 'Workbench',
  Login = 'Login',
  Loading = 'Loading',
  Planning = 'Planning',
  Landing = 'Landing'
}

export enum KpiLevel {
  TopLevelKpi,
  Kpi1,
  Kpi2,
  Kpi3,
  Kpi4,
  Kpi5
}

export enum TopLevelKpi {
  Portfolio = 'Portfolio',
  Ebit = 'EbIT',
  SalesAndAcquisitions = 'SalesAndAcquisitions',
  Dividends = 'Dividends',
  Capital = 'Capital',
  AdditionalKpis = 'AdditionalKPIs',
  MoveReporting = 'MoveReporting',
  FocusReporting = 'FocusReporting',
  CurrencySplit = 'CurrencySplit',
  PowerBI = 'PowerBI',
  FinancialServices = 'FinancialServices',
  Insurance = 'Insurance',
  ProjectFteCosts = 'ProjectFteCosts',
  Opex = 'Opex',
  Fte = 'Fte',
  PersonnelData = 'PersonnelData',
  SplitItCosts = 'SplitItCosts',
  DigitalKpis = 'DigitalKpis',
  ItAndProjects = 'ItAndProjects',
  Equity = 'Equity'
}

export enum Kpi1 {
  // Portfolio
  Retail,
  Wholesale,
  PortfolioSubjectToCreditRisk,
  PortfolioSubjectToResidualValueRisk,
  AcquisitionVolume,
  AcquisitionUnits,
  // Portfolio

  // EbIT
  InterestResult,
  CostOfRisk,
  Opex,
  FeeAndOther,
  // EbIT
}

export enum Kpi2 {
  // Portfolio
  Sales,
  Penetration,
  AcquisitionUnits,
  // Portfolio
  // EbIT
  CostOfCreditRisk,
  CostOfResidualValueRisk,
  CostOfUnpaidReceivablesRisk,

  OpexCore,
  OpexNonCore,

  FeeIncome,
  OtherExpenses,
  // EbIT
}

export enum Kpi3 {
  // Portfolio
  Mbc,
  Tr,
  Va,
  Bu,
  Ot,
  // Portfolio

  // EbIT
  Retail,
  Corporate,
  Dealer,
  Other,
  // EbIT
}

export enum Kpi4 {
}

export enum Kpi5 {
}

export enum WorkbenchMode {
  Overview,
  Adjust,
  Delta,
  Information,
}

export enum Information {
  Gdp,
  OilPrice,
  UnemploymentRate,
}

export enum WalkDevelopment {
  Walk,
  Development
}

export enum PreviousFcMachineFc {
  PreviousFc,
  MachineFc
}

export enum RatioAbsolute {
  Ratio,
  Absolute
}

export enum ForecastPeriod {
  OneMonth = 'oneMonthAhead',
  SixMonths = 'sixMonthsAhead',
  TwelveMonths = 'twelveMonthsAhead',
}

export function forecastPeriodToMonths(fp: ForecastPeriod): number {
  switch (fp) {
    case ForecastPeriod.OneMonth: return 1;
    case ForecastPeriod.SixMonths: return 6;
    case ForecastPeriod.TwelveMonths: return 12;
  }
}

export enum ForecastType {
  Accuracy,
  ActualEffect
}

export enum AdjustmentTab {
  AdditionalKpi,
  Workbench,
  CurrencySplit,
  Planning,
  MoveReporting,
  FocusReporting,
  ItReporting
}

export enum AdjustmentButton {
  LastSubmitted,
  AddAll,
  UndoAll,
  UndoLast,
  // SaveAs,
  Save,
  BulkSave,
}

export enum WorkbenchButton {
  LastSubmission
}

export interface IButton {
  title: string;
  id: string;
  confirmationText: string;
}

export const AdjustmentButtons = {
  [AdjustmentButton.LastSubmitted]: {
    id: 'adjustmentLastSubmittedBtn',
    title: `Last Submitted ${ForecastAcronym} values`,
    confirmationText: `Are you sure you want to use the last month ${ForecastName.Organization} ${ForecastAcronym} entries?`,
  } as IButton,
  [AdjustmentButton.AddAll]: {
    id: 'adjustmentAddAllBtn',
    title: `${ForecastName.Machine} ${ForecastAcronym} values`,
    confirmationText: `Are you sure you want to add all ${ForecastName.Machine} ${ForecastAcronym} to your ${ForecastName.Adjustment} entries?`,
  } as IButton,
  [AdjustmentButton.UndoAll]: {
    id: 'adjustmentUndoAllBtn',
    title: 'Undo all',
    confirmationText: `Are you sure you want to discard all your ${ForecastName.Adjustment} entries?`,
  } as IButton,
  [AdjustmentButton.UndoLast]: {
    id: 'adjustmentUndoLastBtn',
    title: 'Undo',
    confirmationText: `Are you sure you want to discard your last ${ForecastName.Adjustment} entry?`,
  } as IButton,
  [AdjustmentButton.Save]: {
    id: 'adjustmentSaveBtn',
    title: 'Save',
    confirmationText: `${ForecastName.Adjustment}s will be applied to your ${ForecastName.Organization} ${ForecastAcronym}.
    To submit your adjustments, go to Submission.`,
  } as IButton,
  [AdjustmentButton.BulkSave]: {
    id: 'adjustmentBulkSaveBtn',
    title: 'Apply',
    confirmationText: `${ForecastName.Adjustment}s will be applied to your ${ForecastName.Organization} ${ForecastAcronym}.
    To submit your adjustments, go to Submission.`,
  } as IButton,
};

export const WorkbenchButtons = {
  [WorkbenchButton.LastSubmission]: {
    title: 'Last Submission',
    id: 'lastSubmissionBtn',
    confirmationText: `Are you sure you want to discard all your entries and load last submission values?`,
  } as IButton,
};

export interface Adjustment {
  forecastId?: number;
  asOf?: string;
  perMonth: string;
  originalValue?: string | number;
  adjustValue: string | number;
}

export enum CommentType {
  General = 1,
  OneTime = 2,
}

export const NoActualsCountries: string[] = [
  'OMS-KK',
  'HO-APP',
  'AUTGRA',
  'HO-AM',
  'ELI-AT',
  'ELI-NL',
  'HO-ATH',
  'MYT-KK',
  'ZZFSCO',
  'PAYKK',
  'DEUETF',
  'HO-E',
  'DEU-IN',
  'DMS-KK',
  'M@V',
  'MB-CS',
  'MOOVKK',
  'RFH-KK',
  'ZZFSHQ'
];

// The countries for which get scaled in the workbenchFyForecast components.
export const ScaleCountries: string[] = [
  'JPN',
  'KOR'
];

export enum Scale {
  Quintillion = 'qt',
  Quadrillion = 'q',
  Trillion = 't',
  Billion = 'b',
  Million = 'm',
  Thousand = 'k',
  Hundred = '',
}

export enum Location {
  Region,
  Country,
  Organization
}

export const CometButton = {
  title: 'Please be aware!',
  id: 'cometBtn',
  confirmationText: 'Forecast data transfer to COMET may take up to 15 minutes.'
} as IButton;

export const Quarter: number = 3;
export const QuarterAcronym: string = 'Q';


export const January: number = 0;
export const March: number = 2;
export const June: number = 5;
export const August: number = 7;
export const December: number = 11;
export const MonthCount: number = 12;

export const Portfolio = {
  kpi: {
    kpiTypeId: 820,
    label: 'Portfolio',
  },
  children: [],
};

export const Ebit = {
  kpi: {
    kpiTypeId: 102,
    label: 'EbIT',
  },
  children: [],
};


export const SalesAndAcquisitions = {
  kpi: {
    kpiTypeId: 420,
    label: 'Sales & Acquisitions',
  },
  children: [],
};

export const Dividends = {
  kpi: {
    kpiTypeId: 830,
    label: 'Dividend Payments',
  },
  children: [],
}

export const Capital = {
  kpi: {
    kpiTypeId: 831,
    label: 'Capital Injections'
  },
  children: [],
}
